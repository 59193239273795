import styled from 'styled-components';
import { midBreakPoint } from '../../../_shared/layout-constants';

export const Divider = styled.div`
  background: greyLight;
  width: 100%;
  height: 1px;
  @media (min-width: ${midBreakPoint}px) {
    width: 2px;
    height: 106px;
    margin: 0 20px 0 16px;
  }
`;
