import gql from 'graphql-tag';

/* queries */
export const GET_BASIC_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      id
      firstName
      lastName
    }
  }
`;
export const GET_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      id
      lastLogin
      isSuperuser
      email
      gender
      phoneNumber
      dateOfBirth
      username
      firstName
      lastName
      isStaff
      isActive
      dateJoined
      contacts
    }
  }
`;

export const GET_VEHICLES = gql`
  query GetVehicles($limit: Int) {
    getVehicles(limit: $limit) {
      id
      displayName
      status
      registrationNumber
      registrationState
      defaultresourceSet {
        id
        vehicle {
          id
        }
      }
    }
  }
`;

export const GET_INTROSPECTION = gql`
  query __TYPE($name: String!) {
    __type(name: $name) {
      enumValues {
        name
        description
      }
    }
  }
`;

export const GET_CREDIT_CARDS = gql`
  query GetCreditCards($limit: Int) {
    getCreditCards(limit: $limit) {
      id
      cardNumber
      cardType
      defaultresourceSet {
        creditCard {
          id
        }
      }
    }
  }
`;

export const GET_CREDIT_CARD = gql`
  query GetCreditCard($id: ID!) {
    getCreditCard(id: $id) {
      id
      cardNumber
      cardType
      defaultresourceSet {
        creditCard {
          id
        }
      }
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query GetBankAccounts($limit: Int) {
    getBankAccounts(limit: $limit) {
      id
      acc
      bsb
      accountName
      defaultresourceSet {
        bankAccount {
          id
        }
      }
    }
  }
`;

export const GET_FAVOURITES = gql`
  query GetFavourites($limit: Int) {
    getFavourites(limit: $limit) {
      id
      parking {
        id
        title
        parkingTypes {
          id
          name
        }
        description
        address {
          coordinate {
            lat
            lon
          }
          value
          state
          country
          locality
          postalCode
        }
      }
    }
  }
`;

export const GET_DEFAULT_RESOURCE = gql`
  query GetDefaultResource($limit: Int) {
    getDefaultResource(limit: $limit) {
      id
      vehicle {
        id
        displayName
        status
        registrationNumber
      }
      creditCard {
        id
        cardHolder
        cardNumber
        expiryDate
      }
      bankAccount {
        id
        acc
        bsb
        accountName
      }
    }
  }
`;

export const GET_PARKING_SPACES = gql`
  query GetParkingSpaces($limit: Int) {
    drafts: getParkingDrafts(limit: $limit) {
      id
      title
      description
      address
      accessInstruction
      availableDateFrom
      keyDeliveryInstruction
      contacts
      priceOptions
      parkingLotNumber
      filters
      coordinate {
        lon
        lat
      }
      price {
        unit
        type
        value
      }
      meta {
        image {
          description
          url
        }
      }
      maxHeight {
        id
        value
        unit
        normalized
      }
      amenities {
        id
        description
        name
      }
      parkingTypes {
        id
        description
        name
      }
      accessTypes {
        id
        name
        description
      }
      suitableVehicleTypes {
        id
        name
        description
      }
      page
    }
    active: getActiveParkingSpaces(limit: $limit) {
      id
      title
      status
      description
      address {
        coordinate {
          lon
          lat
        }
        value
        subpremise
        streetNumber
        route
        locality
        postalCode
        state
        stateCode
        country
        countryCode
        tz
      }
      lot
      priceOptions
      maxHeight {
        id
        normalized
        value
        unit
      }
      amenities {
        id
        description
        name
      }
      parkingTypes {
        id
        description
        name
      }
      accessTypes {
        id
        name
        description
      }
      suitableVehicleTypes {
        id
        name
        description
      }
      owner {
        id
        isSuperuser
        email
        gender
        phoneNumber
        username
        firstName
        lastName
        isStaff
        dateJoined
        dateOfBirth
      }
    }
    inactive: getInactiveParkingSpaces(limit: $limit) {
      id
      title
      status
      description
      address {
        coordinate {
          lon
          lat
        }
        value
        subpremise
        streetNumber
        route
        locality
        postalCode
        state
        stateCode
        country
        countryCode
        tz
      }
      priceOptions
      maxHeight {
        id
        normalized
        value
        unit
      }
      amenities {
        id
        description
        name
      }
      parkingTypes {
        id
        description
        name
      }
      accessTypes {
        id
        name
        description
      }
      suitableVehicleTypes {
        id
        name
        description
      }
      owner {
        id
        isSuperuser
        email
        gender
        phoneNumber
        username
        firstName
        lastName
        isStaff
        dateJoined
        dateOfBirth
      }
    }
  }
`;

/* mutations */
export const ADD_PARKING_SPACE = gql`
  mutation AddParkingSpace(
    $id: ID
    $accessInstruction: String
    $accessTypes: [UUID]
    $address: String!
    $amenities: [UUID]
    $availableDateFrom: DateTime
    $contacts: [JSONString]
    $deliveryInstruction: String
    $description: String
    $draftId: ID
    $filters: [JSONString]
    $lot: String
    $maxHeight: UUID
    $operation: ParkingSpaceOperationType
    $parkingTypes: [UUID]
    $priceOptions: [JSONString]
    $suitableVehicleTypes: [UUID]
    $title: String!
  ) {
    addParkingSpace(
      accessInstruction: $accessInstruction
      accessTypes: $accessTypes
      address: $address
      amenities: $amenities
      availableDateFrom: $availableDateFrom
      contacts: $contacts
      deliveryInstruction: $deliveryInstruction
      description: $description
      draftId: $draftId
      filters: $filters
      id: $id
      lot: $lot
      maxHeight: $maxHeight
      operation: $operation
      parkingTypes: $parkingTypes
      priceOptions: $priceOptions
      suitableVehicleTypes: $suitableVehicleTypes
      title: $title
    ) {
      parking {
        id
        title
        description
        address
        coordinate {
          lat
          lon
        }
        price {
          unit
          type
          value
        }
        meta {
          image {
            description
            url
          }
        }
        maxHeight {
          id
          normalized
          value
          unit
        }
        amenities {
          id
          description
          name
        }
        parkingTypes {
          id
          description
          name
        }
        accessTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        availableDateFrom
        accessInstruction
        deliveryInstruction
        lot
      }
    }
  }
`;

export const UPDATE_USER_INFORMATION = gql`
  mutation UpdateUserInformation(
    $firstName: String
    $lastName: String
    $dob: Date
    $contacts: [JSONString]
  ) {
    updateUserInformation(
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      contacts: $contacts
    ) {
      user {
        id
        lastLogin
        isSuperuser
        email
        gender
        dateOfBirth
        gender
        phoneNumber
        username
        firstName
        lastName
        isStaff
        isActive
        dateJoined
      }
    }
  }
`;

export const ADD_VEHICLE = gql`
  mutation AddVehicle(
    $displayName: String!
    $registrationNumber: String!
    $registrationState: String!
    $status: VehicleStatusType!
  ) {
    addVehicle(
      displayName: $displayName
      registrationNumber: $registrationNumber
      registrationState: $registrationState
      status: $status
    ) {
      vehicle {
        id
        displayName
        status
        registrationNumber
        registrationState
      }
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      vehicle {
        displayName
        status
        registrationNumber
        registrationState
      }
    }
  }
`;

export const GET_PHONE_NUMBER_VERIFICATION = gql`
  mutation GetPhoneNumberVerification($phoneNumber: String!) {
    getPhoneNumberVerification(phoneNumber: $phoneNumber) {
      user {
        id
        lastLogin
        isSuperuser
        email
        gender
        phoneNumber
        username
        firstName
        lastName
        isStaff
        isActive
        dateJoined
        dateOfBirth
      }
      token
    }
  }
`;

export const VERIFY_USER_INFORMATION = gql`
  mutation VerifyUserInformation(
    $dob: Date!
    $phoneNumber: String!
    $token: String!
    $verificationCode: String!
  ) {
    verifyUserInformation(
      dob: $dob
      phoneNumber: $phoneNumber
      token: $token
      verificationCode: $verificationCode
    ) {
      user {
        id
        lastLogin
        isSuperuser
        email
        gender
        phoneNumber
        username
        firstName
        lastName
        isStaff
        isActive
        dateJoined
        dateOfBirth
      }
    }
  }
`;

export const ADD_CREDIT_CARD = gql`
  mutation AddCreditCard($fileName: String!, $id: ID) {
    addCreditCard(fileName: $fileName, id: $id) {
      creditCard {
        id
        cardNumber
        cardType
      }
    }
  }
`;

export const ADD_BANK_ACCOUNT = gql`
  mutation AddBankAccount($fileName: String!) {
    addBankAccount(fileName: $fileName) {
      bankAccount {
        id
        acc
        accountName
        bsb
      }
    }
  }
`;

export const DELETE_CREDIT_CARD = gql`
  mutation DeleteCreditCard($id: ID!) {
    deleteCreditCard(id: $id) {
      creditCard {
        id
        cardNumber
        cardType
      }
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($id: ID!) {
    deleteBankAccount(id: $id) {
      bankAccount {
        accountName
      }
    }
  }
`;

export const ADD_PARKING_DRAFT = gql`
  mutation AddParkingDraft(
    $accessInstruction: String
    $accessTypes: [UUID]
    $address: String!
    $amenities: [UUID]
    $availableDateFrom: DateTime
    $contacts: [JSONString]
    $description: String
    $id: ID
    $keyDeliveryInstruction: String
    $maxHeight: UUID
    $page: String
    $parkingLotNumber: String
    $parkingTypes: [UUID]
    $priceOptions: [JSONString]
    $filters: [JSONString]
    $suitableVehicleTypes: [UUID]
    $title: String!
  ) {
    addParkingDraft(
      accessInstruction: $accessInstruction
      accessTypes: $accessTypes
      address: $address
      amenities: $amenities
      availableDateFrom: $availableDateFrom
      contacts: $contacts
      description: $description
      id: $id
      keyDeliveryInstruction: $keyDeliveryInstruction
      maxHeight: $maxHeight
      page: $page
      parkingLotNumber: $parkingLotNumber
      parkingTypes: $parkingTypes
      priceOptions: $priceOptions
      suitableVehicleTypes: $suitableVehicleTypes
      title: $title
      filters: $filters
    ) {
      parking {
        id
        title
        description
        address
        accessInstruction
        keyDeliveryInstruction
        contacts
        priceOptions
        parkingLotNumber
        filters
        coordinate {
          lat
          lon
        }
        price {
          unit
          type
          value
        }
        meta {
          image {
            description
            url
          }
        }
        maxHeight {
          id
          normalized
          value
          unit
        }
        amenities {
          id
          description
          name
        }
        parkingTypes {
          id
          description
          name
        }
        accessTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        availableDateFrom
        page
      }
    }
  }
`;

export const ADD_FAVOURITE = gql`
  mutation AddFavourite($id: ID!, $type: ResourceType!) {
    addFavourite(id: $id, type: $type) {
      favourite {
        id
        parking {
          id
          title
          description
        }
      }
    }
  }
`;

export const REMOVE_FAVOURITE = gql`
  mutation RemoveFavourite($id: ID!, $type: ResourceType!) {
    removeFavourite(id: $id, type: $type) {
      favourite {
        parking {
          id
          title
          description
        }
      }
    }
  }
`;

export const SET_DEFAULT_RESOURCE = gql`
  mutation SetDefaultResource($id: ID!, $type: ResourceType!) {
    setDefaultResource(id: $id, type: $type) {
      defaultResource {
        id
        vehicle {
          id
          displayName
          status
          registrationNumber
        }
        creditCard {
          id
        }
        bankAccount {
          id
        }
      }
    }
  }
`;

export const DISMISS_SYSTEM_NOTIFICATION = gql`
  mutation dismissSystemNotification($notificationType: String!) {
    dismissSystemNotification(notificationType: $notificationType) {
      dismissed
    }
  }
`;

export const GET_SYSTEM_NOTIFICATION = gql`
  query getSystemNotifications($notificationType: String!) {
    getSystemNotifications(notificationType: $notificationType) {
      dismissed
    }
  }
`;

export const ALLOW_TO_REQUEST_REIMBURSEMENT = gql`
  query allowToRequestReimbursement($bookingTransactionId: ID!) {
    allowToRequestReimbursement(bookingTransactionId: $bookingTransactionId) {
      allowReimbursement
      message
    }
  }
`;

export const ALLOW_TO_REQUEST_REFUND_BOND = gql`
  query allowToRequestRefundBond {
    allowToRequestRefundBond {
      allowRefund
      message
    }
  }
`;

export const REQUEST_REIMBURSEMENT_BOND = gql`
  mutation requestReimbursementBond($bookingTransactionId: ID!) {
    requestReimbursementBond(bookingTransactionId: $bookingTransactionId) {
      refundBondAmount
      bookingTransactionId
    }
  }
`;

export const REQUEST_REFUND_BOND = gql`
  mutation {
    requestRefundBond {
      refundBondAmount
    }
  }
`;

export const DRAFT_PARKING_WEEKLY_PRICE = gql`
  query draftParkingWeeklyPrice($draftParkingId: ID!, $dailyPrice: Float!) {
    draftParkingWeeklyPrice(id: $draftParkingId, dailyPrice: $dailyPrice) {
      total
      totalDiscount
      cost
      costDiscount
      commissionDiscount
      commission
    }
  }
`;
