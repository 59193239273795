import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-flexa';
import styled from 'styled-components';
import { Field } from 'redux-form';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import VehicleInfo from '../../../common/components/vehiclesInfo';
import {
  ReduxSelectField,
  ReduxCheckBoxComponent
} from '../../../_shared/components/FormElements';
import {
  ReduxCheckbox,
  Checkboxes
} from '../../../_shared/components/CheckBoxes';
import ToolTip from '../../../_shared/components/Tooltips';
import { ImgixUrl } from '../../../_shared/utils';
import { MATCHING_AMENITIES } from './_autopopulateAmenities';
import { MATCHING_VEHICLES } from './_autopopulateVehicles';

const Amenities = styled.div`
  margin-top: 40px;
`;

const SuitableVehicle = styled.div`
  margin-top: 30px;
`;

const Subtitle = styled.div`
  margin-bottom: 24px;
`;

const InfoIcon = styled.img`
  width: 16px;
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
`;

class Features extends Component {
  state = {
    isVehicleInfoOpen: false,
    amenitySet: null,
    suitableVehicleSet: null
  };

  componentDidMount() {
    this.updateField = debounce(e => {
      const obj = {};
      obj[e.target.name] = e.target.value;
      this.updateLocalData(obj);
    }, 300);

    this.updateFieldRaw = debounce(e => {
      const obj = {};
      obj[e.key] = e.value;
      this.updateLocalData(obj);
    }, 300);
  }

  updateLocalData(obj) {
    if (!!this.props.draftId) {
      this.props.localData.addItem(this.props.draftId, obj);
    }
  }

  updateFieldWithoutDebounce = e => {
    const obj = {};
    obj[e.key] = e.value;
    this.updateLocalData(obj);
  };

  autopopulateAmenities(autoAmenitiesDict, parkingType) {
    this.props.change('amenities', autoAmenitiesDict[parkingType]);
    this.updateFieldWithoutDebounce({
      key: 'amenities',
      value: autoAmenitiesDict[parkingType]
    });
  }

  autopopulateVehicles(autoVehiclesDict, height) {
    this.props.change('suitableVehicles', autoVehiclesDict[height]);
    this.updateFieldWithoutDebounce({
      key: 'suitableVehicles',
      value: autoVehiclesDict[height]
    });
  }

  render() {
    const {
      allParkingTypes,
      allAccessTypes,
      allAmenities,
      allHeightOptions,
      allVehicleTypes
    } = this.props;
    const parkingTypesOptions = [];
    const maxHeightOptions = [];
    const accessTypesOptions = [];
    const autopopulatedAmenitiesDict = {};
    const autopopulatedVehiclesDict = {};

    if (typeof allParkingTypes.allParkingTypes !== 'undefined') {
      allParkingTypes.allParkingTypes.map(type =>
        parkingTypesOptions.push({
          value: type.name,
          label: type.name
        })
      );
    }

    if (typeof allAccessTypes.allAccessTypes !== 'undefined') {
      allAccessTypes.allAccessTypes.map(access =>
        accessTypesOptions.push({
          value: access.name,
          label: access.name
        })
      );
    }

    if (typeof allHeightOptions.allHeightOptions !== 'undefined') {
      allHeightOptions.allHeightOptions.map(height =>
        maxHeightOptions.push({
          value: height.value,
          label: height.value
        })
      );
      maxHeightOptions.push({
        value: 'Unrestricted',
        label: 'Unrestricted'
      });
    }

    if (
      !allAmenities.loading &&
      typeof allAmenities.allAmenities !== 'undefined'
    ) {
      let parkingTypes = Object.keys(MATCHING_AMENITIES);

      function filterArray(type) {
        let amenities = [...allAmenities.allAmenities];
        autopopulatedAmenitiesDict[type] = _.remove(amenities, function(
          amenity
        ) {
          return MATCHING_AMENITIES[type].includes(amenity.name);
        });
      }

      parkingTypes.forEach(filterArray);
    }

    if (
      !allVehicleTypes.loading &&
      typeof allVehicleTypes.allVehicleTypes !== 'undefined'
    ) {
      let heights = Object.keys(MATCHING_VEHICLES);

      function filterArray(height) {
        let vehicles = [...allVehicleTypes.allVehicleTypes];
        autopopulatedVehiclesDict[height] = _.remove(vehicles, function(
          vehicle
        ) {
          return MATCHING_VEHICLES[height].includes(vehicle.name);
        });
      }

      heights.forEach(filterArray);
    }

    return (
      <Fragment>
        <VehicleInfo
          isShow={this.state.isVehicleInfoOpen}
          hide={() => this.setState({ isVehicleInfoOpen: false })}
        />
        <div>
          <div>
            <Row justifyContent="flex-start">
              <Col xs={12} md={5}>
                <Field
                  name="parkingTypes"
                  label="Parking type"
                  component={ReduxSelectField}
                  options={parkingTypesOptions}
                  onChange={e => {
                    this.autopopulateAmenities(
                      autopopulatedAmenitiesDict,
                      e.value
                    );
                    this.updateFieldRaw({ key: 'parkingTypes', value: e });
                  }}
                />
              </Col>
              <Col md={1} />
              <Col xs={12} md={5}>
                <Field
                  name="accessTypes"
                  label="Access"
                  component={ReduxSelectField}
                  options={accessTypesOptions}
                  onChange={e => {
                    this.updateFieldRaw({ key: 'accessTypes', value: e });
                  }}
                />
              </Col>
              <Col xs={1} />
            </Row>
            {/* TODO: tooltip copy update */}
            <ToolTip style={{ marginBottom: 21 }}>
              Max height can also be referred as Max Clearance which indicates
              the maximum vehicle height that the parking space allows. You
              should always find the max height sign at the entrance of the
              carpark.
            </ToolTip>
            <Row justifyContent="flex-start" className="manual-input-row">
              <Col xs={12} md={5}>
                <Field
                  name="maxHeight"
                  label="Max height (unit: meter)"
                  component={ReduxSelectField}
                  options={maxHeightOptions}
                  onChange={e => {
                    this.autopopulateVehicles(
                      autopopulatedVehiclesDict,
                      e.value
                    );
                    this.updateFieldRaw({ key: 'maxHeight', value: e });
                  }}
                />
              </Col>
              <Col xs={12} md={1} />
            </Row>
          </div>

          <Amenities>
            <Subtitle>Amenities</Subtitle>
            {!allAmenities.loading &&
              typeof allAmenities.allAmenities !== 'undefined' && (
                <Field
                  name="amenities"
                  itemComponent={ReduxCheckBoxComponent}
                  component={ReduxCheckbox(Checkboxes)}
                  data={allAmenities.allAmenities}
                  textField="name"
                  valueField="id"
                  valueField2="name"
                  pathType="amenities"
                  onChange={e => {
                    this.updateFieldRaw({ key: 'amenities', value: e });
                  }}
                />
              )}
          </Amenities>

          <SuitableVehicle>
            <Subtitle>
              Suitable for
              <InfoIcon
                src={`${ImgixUrl}/web-image-assets/icons/info.png`}
                alt="info"
                onClick={() => this.setState({ isVehicleInfoOpen: true })}
              />
            </Subtitle>
            {!allVehicleTypes.loading &&
              typeof allVehicleTypes.allVehicleTypes !== 'undefined' && (
                <Field
                  name="suitableVehicles"
                  itemComponent={ReduxCheckBoxComponent}
                  component={ReduxCheckbox(Checkboxes)}
                  data={allVehicleTypes.allVehicleTypes}
                  textField="name"
                  valueField="id"
                  valueField2="name"
                  pathType="vehicles"
                  onChange={e => {
                    this.updateFieldRaw({ key: 'suitableVehicles', value: e });
                  }}
                />
              )}
          </SuitableVehicle>
        </div>
      </Fragment>
    );
  }
}

export default Features;
