import React, { useContext } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field, getFormValues, getFormSyncErrors } from 'redux-form';
import { PhoneInputField } from '../../../_shared/components/FormElements';
import { ModalStoreContext } from '../../../common/context/modal/store';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  ReduxInputField,
  ReduxToggleButtonGroup
} from '../../../_shared/components/FormElements';
import debounce from 'lodash/debounce';

const ContactName = styled.div``;

const ContactNumber = styled.div``;

const Email = styled.div``;

const SelfDescription = styled.div``;

const updateFields = debounce((draftId, localData, names, values) => {
  if (!!draftId) {
    const obj = {};
    for (let idx in names) {
      obj[names[idx]] = values[idx];
    }
    localData.addItem(draftId, obj);
  }
}, 300);

const UpdateContactModal = props => {
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const {
    // updateLocalStates,
    //redux-form props
    composeContactFormValues,
    invalid,
    values,
    change,
    formSyncErrors
  } = props;

  const isOpen = activeModal.includes('UpdateContact');
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Update contact person"
      handleClose={() => handleHideModal('UpdateContact')}
      contentLabel="Add Contact Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      isForm={false}
      buttonDisabled={invalid || formSyncErrors.contactNumber}
      handleSubmit={() => {
        if (invalid || formSyncErrors.contactNumber) return;
        composeContactFormValues({ isUpdate: true, ...values });
        handleHideModal('UpdateContact');
      }}
    >
      <ContactName>
        <Field
          name="contactName"
          label="Contact name"
          component={ReduxInputField}
          onChange={(event, value) => {
            updateFields(
              props.draftId,
              props.localData,
              [event.target.name],
              [value]
            );
          }}
        />
      </ContactName>

      <ContactNumber>
        <PhoneInputField
          name="contactNumber"
          label="Contact number"
          value={values.contactNumber}
          placeholder="Enter phone number"
          error={formSyncErrors.contactNumber}
          onChange={phone => {
            change('contactNumber', phone);
            updateFields(
              props.draftId,
              props.localData,
              ['contactNumber'],
              [phone]
            );
          }}
        />
      </ContactNumber>

      <Email>
        <Field
          name="email"
          label="Email"
          component={ReduxInputField}
          onChange={(event, value) => {
            updateFields(
              props.draftId,
              props.localData,
              [event.target.name],
              [value]
            );
          }}
        />
      </Email>

      <SelfDescription>
        <Field
          name="contactType"
          label="You..."
          options={[
            {
              label: 'Own this place',
              value: 'Owner'
            },
            {
              label: 'Manage this place',
              value: 'Manager'
            }
          ]}
          component={ReduxToggleButtonGroup}
          onChange={contactType => {
            updateFields(
              props.draftId,
              props.localData,
              ['contactType'],
              [contactType]
            );
          }}
        />
      </SelfDescription>
    </Modal>
  );
};

export default connect(state => ({
  formSyncErrors: getFormSyncErrors('createListingForm')(state),
  values: getFormValues('createListingForm')(state)
}))(UpdateContactModal);
