import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { StandardFooter } from '../../../common/components';
import { GET_USER_INFO } from '../../../common/graphql/adminSchema';
import {
  RubikSemiBold,
  greyExtraLight,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { PrimaryButton } from '../../../_shared/components/Buttons';
import { ImgixUrl } from '../../../_shared/utils';

const LayoutWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  background-color: ${greyExtraLight};
  @media (min-width: ${midBreakPoint}px) {
    grid-template-rows: auto 380px;
  }
`;

const Content = styled.div`
  grid-column: 1 / 4;
  height: 100%;
  width: 100%;
  margin-bottom: 150px;
  background-color: #ffffff;
  padding: 24px 16px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 3;
    padding: 0;
    width: 1155px;
    height: 600px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: ${midBreakPoint}px) {
    text-align: left;
    display: grid;
    grid-template-columns: 98px 642px;
    grid-template-rows: 120px auto;
    margin: 120px 0 80px 200px;
    width: 740px;
  }
`;

const MessageIcon = styled.img`
  height: 64px;
  margin: 20px auto;
  @media (min-width: ${midBreakPoint}px) {
    margin: 0;
    margin-right: 32px;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
`;

const MessageTitle = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.3px;
  margin-bottom: 12px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 28px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
`;

const MessageContent = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 18px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
`;

const ButtonSection = styled.div`
  justify-self: center;
`;

const Footer = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / 4;
    grid-row: 2;
  }
`;

const CTAButton = styled(PrimaryButton)`
  padding: 12px 24px;
  height: 48px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: ${midBreakPoint}px) {
    margin-top: 50px;
  }
`;

class BookingRequestSent extends Component {
  render() {
    const { getUserInfo } = this.props;

    return (
      <LayoutWrapper>
        <Content>
          <Message>
            <MessageIcon
              src={`${ImgixUrl}/web-image-assets/icons/request-sent.svg`}
              alt="request sent icon"
            />
            <MessageTitle>
              Awesome! Your booking request has been submitted.
            </MessageTitle>
            <MessageContent>
              <div>
                We'll notify you{' '}
                {getUserInfo && getUserInfo.getUserInfo && (
                  <span>
                    by your email{' '}
                    <u>{_.get(getUserInfo.getUserInfo, 'email')}</u>
                  </span>
                )}{' '}
                as soon as your booking request has been confirmed by the
                property owner.{' '}
              </div>
              <br />
              <div>
                Following successful booking request, you will be charged. You
                won't be charged if booking request is pending or denied by the
                property owner.{' '}
              </div>
            </MessageContent>
          </Message>

          <ButtonSection>
            <CTAButton>
              <Link to="/search">Make another booking</Link>
            </CTAButton>
          </ButtonSection>
        </Content>

        <Footer>
          <StandardFooter />
        </Footer>
      </LayoutWrapper>
    );
  }
}

export default graphql(GET_USER_INFO, {
  name: 'getUserInfo',
  options: {
    fetchPolicy: 'network-only'
  }
})(BookingRequestSent);
