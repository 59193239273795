import styled from 'styled-components';
import { graphite } from '../../../_shared/layout-constants';

export const Description = styled.div`
  width: 100%;
  height: 38px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${({ align }) => (align ? `${align}` : 'left')};
  color: ${graphite};
`;
