export class localData {
  constructor(key, reserved_keys = [], limit = 10) {
    this.key = key;
    this.limit = limit;
    this.reserved_keys = reserved_keys;
    this.localStorage = localStorage;
    if (this.localStorage.getItem(key) === null) {
      this.localStorage.setItem(key, JSON.stringify({}));
    }
  }

  addItem(key, obj, override = false) {
    if (this.limit <= 0) {
      return {};
    }

    if (typeof obj !== 'object') {
      return {};
    }

    let data = JSON.parse(this.localStorage.getItem(this.key));

    if (key in data) {
      if (override) {
        data[key] = { ...obj };
      } else {
        data[key] = { ...data[key], ...obj };
      }
      this.localStorage.setItem(this.key, JSON.stringify(data));
      return data[key];
    }

    if (
      Object.keys(data).filter(k => this.reserved_keys.indexOf(k) === -1)
        .length < this.limit
    ) {
      data[key] = { ...obj };
      this.localStorage.setItem(this.key, JSON.stringify(data));
      return data[key];
    }

    let _key = Object.keys(data).find(
      k => this.reserved_keys.indexOf(k) === -1
    );

    delete data[_key];
    data[key] = { ...obj };
    this.localStorage.setItem(this.key, JSON.stringify(data));
    return data[key];
  }

  deleteItem(key) {
    let data = JSON.parse(this.localStorage.getItem(this.key));
    let _data = data[key];
    delete data[key];
    this.localStorage.setItem(this.key, JSON.stringify(data));
    return _data;
  }

  getItem(key, filters = []) {
    let data = JSON.parse(this.localStorage.getItem(this.key));

    if (filters === undefined || filters === null) {
      return;
    }

    if (data[key] === undefined || data[key] === null) {
      return;
    }

    if (filters.length > 0) {
      return filters.reduce((_v, v) => {
        if (data[key][v] !== undefined && data[key][v] !== null) {
          _v[v] = data[key][v];
          return _v;
        }
        return _v;
      }, {});
    }

    return data[key];
  }
}
