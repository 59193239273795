import { useEffect, useState } from 'react';
import cssMediaQuery from 'css-mediaquery';

// TODO: possibly need a debounce in this, we'll see how we go
const initialMountDeviceValues = {
  type: 'screen',
  width: 320
};

export default function useMatchMedia(
  queries,
  initialValues = initialMountDeviceValues
) {
  const [mediaValues, setMediaValues] = useState(() => {
    // create initial state ("server render"; doesn't look at window)
    const media = Object.keys(queries).reduce((acc, queryName) => {
      const query = queries[queryName];
      acc[queryName] = cssMediaQuery.match(query, initialValues);
      return acc;
    }, {});
    return media;
  });

  useEffect(
    () => {
      const mediaQueryListInstances = [];

      const createMediaQueryListener = queryName => ({ matches }) => {
        // pass a callback because we don't want the effect to always run
        setMediaValues(media => ({
          ...media,
          [queryName]: matches
        }));
      };

      const media = Object.keys(queries).reduce((acc, queryName) => {
        const query = queries[queryName];
        const mediaQueryList = window.matchMedia(query);
        const listener = createMediaQueryListener(queryName);
        mediaQueryList.addListener(listener);
        mediaQueryListInstances.push({
          mediaQueryList,
          listener
        });
        acc[queryName] = mediaQueryList.matches;
        return acc;
      }, {});

      setMediaValues(media);

      return () => {
        mediaQueryListInstances.forEach(instance => {
          instance.mediaQueryList.removeListener(instance.listener);
        });
      };
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      ...Object.keys(queries)
        .sort()
        .map(queryName => `${queryName}:${queries[queryName]}`)
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return mediaValues;
}
