import gql from 'graphql-tag';

export const LOAD_DATA = gql`
  query LoadData {
    allAmenities {
      id
      name
      description
    }
    allParkingTypes {
      id
      name
      description
    }
    allAccessTypes {
      id
      name
      description
    }
  }
`;
export const ALL_AMENITIES = gql`
  query AllAmenities($limit: Int) {
    allAmenities(limit: $limit) {
      id
      name
      description
    }
  }
`;

export const ALL_PARKING_TYPES = gql`
  query AllParkingTypes($limit: Int) {
    allParkingTypes(limit: $limit) {
      id
      name
      description
    }
  }
`;

export const ALL_ACCESS_TYPES = gql`
  query AllAccessTypes($limit: Int) {
    allAccessTypes(limit: $limit) {
      id
      name
      description
    }
  }
`;

export const ALL_HEIGHT_OPTIONS = gql`
  query AllHeightOptions($limit: Int) {
    allHeightOptions(limit: $limit) {
      id
      value
      unit
      normalized
    }
  }
`;

export const ALL_VEHICLE_TYPES = gql`
  query AllVehicleTypes($limit: Int) {
    allVehicleTypes(limit: $limit) {
      id
      name
      description
    }
  }
`;

export const PARKING_SPACES_SEARCH = gql`
  query ParkingSpacesSearch(
    $term: String!
    $filters: JSONString
    $parkingCoordinate: JSONString
    $pageSize: Int
    $pageFrom: Int
    $sortType: ParkingSortType
    $sortOrder: ParkingSortOrder
  ) {
    parkingSpacesSearchResult(
      term: $term
      filters: $filters
      pageFrom: $pageFrom
      pageSize: $pageSize
      sortType: $sortType
      sortOrder: $sortOrder
      parkingCoordinate: $parkingCoordinate
    ) {
      total
      parkingSpaces {
        id
        title
        description
        address
        distance
        createdDate
        coordinate {
          lat
          lon
        }
        price {
          value
          unit
          type
        }
        maxHeight {
          id
          value
          unit
          normalized
        }
        amenities {
          id
          name
          description
        }
        accessTypes {
          id
          name
          description
        }
        parkingTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        meta {
          image {
            url
            description
          }
        }
      }
    }
  }
`;

export const PARKING_SPACES_SEARCH_RECOMMENDATIONS = gql`
  query parkingSpacesSearchRecommendations(
    $term: String!
    $filters: JSONString
    $parkingCoordinate: JSONString
  ) {
    parkingSpacesSearchRecommendations(
      term: $term
      filters: $filters
      parkingCoordinate: $parkingCoordinate
    ) {
      total
      parkingSpaces {
        id
        title
        description
        address
        distance
        createdDate
        coordinate {
          lat
          lon
        }
        price {
          value
          unit
          type
        }
        maxHeight {
          id
          value
          unit
          normalized
        }
        amenities {
          id
          name
          description
        }
        accessTypes {
          id
          name
          description
        }
        parkingTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        meta {
          image {
            url
            description
          }
        }
      }
    }
  }
`;

export const GET_PARKING_SPACE = gql`
  query getParkingSpace($id: ID!) {
    getParkingSpace(id: $id) {
      id
      title
      description
      status
      lot
      accessInstruction
      deliveryInstruction
      createdDate
      modifiedDate
      contacts
      amenities {
        id
        name
      }
      parkingTypes {
        id
        name
      }
      accessTypes {
        id
        name
      }
      suitableVehicleTypes {
        id
        name
        description
      }
      address {
        coordinate {
          lat
          lon
        }
        tz
        value
        subpremise
        streetNumber
        route
        locality
        postalCode
        state
        stateCode
        country
        countryCode
      }
      priceOptions
      maxHeight {
        id
        unit
        value
        normalized
      }
      owner {
        id
        username
        firstName
        lastName
        dateJoined
        gender
        email
      }
      favouritesSet {
        id
        user {
          id
        }
        parking {
          id
        }
      }
      filters
      availableDateFrom
      bookingOccupiedDates {
        startTime
        endTime
      }
    }
  }
`;

export const GET_AMENITY = gql`
  query GetAmenity($id: ID, $name: String) {
    getAmenity(id: $id, name: $name) {
      id
      name
      description
    }
  }
`;

export const GET_PARKING_TYPE = gql`
  query GetParkingType($id: ID, $name: String) {
    getParkingType(id: $id, name: $name) {
      id
      name
      description
    }
  }
`;

export const GET_ACCESS_TYPE = gql`
  query GetAccessType($id: ID, $name: String) {
    getAccessType(id: $id, name: $name) {
      id
      name
      description
    }
  }
`;

export const GET_HEIGHT_OPTION = gql`
  query GetHeightOption($id: ID, $name: String) {
    getAmenity(id: $id, name: $name) {
      id
      value
      unit
      normalized
    }
  }
`;

export const UPDATE_PARKING_ADDRESS = gql`
  mutation updateParkingAddress($id: ID!, $lot: String, $address: String!) {
    updateParkingAddress(id: $id, lot: $lot, address: $address) {
      parking {
        id
        address
        lot
      }
    }
  }
`;

export const UPDATE_PARKING_FEATURES = gql`
  mutation updateParkingFeatures(
    $id: ID!
    $maxHeight: UUID
    $accessTypes: [UUID]
    $amenities: [UUID]
    $parkingTypes: [UUID]
    $suitableVehicleTypes: [UUID]
  ) {
    updateParkingFeatures(
      id: $id
      maxHeight: $maxHeight
      accessTypes: $accessTypes
      amenities: $amenities
      parkingTypes: $parkingTypes
      suitableVehicleTypes: $suitableVehicleTypes
    ) {
      parking {
        id
        suitableVehicleTypes {
          id
          name
        }
        accessTypes {
          id
          name
        }
        amenities {
          id
          name
        }
        parkingTypes {
          id
          name
        }
        maxHeight {
          id
          value
        }
      }
    }
  }
`;

export const UPDATE_PARKING_DETAILS = gql`
  mutation updateParkingDetails(
    $id: ID!
    $title: String!
    $description: String
    $deliveryInstruction: String
    $accessInstruction: String
  ) {
    updateParkingDetails(
      id: $id
      title: $title
      description: $description
      deliveryInstruction: $deliveryInstruction
      accessInstruction: $accessInstruction
    ) {
      parking {
        id
        title
        description
        deliveryInstruction
        accessInstruction
      }
    }
  }
`;

export const UPDATE_PARKING_CONTACTS = gql`
  mutation updateParkingContacts($id: ID!, $contacts: [JSONString]) {
    updateParkingContacts(id: $id, contacts: $contacts) {
      parking {
        id
        contacts {
          email
          name
          contactType
          contactNumber
        }
      }
    }
  }
`;

export const UPDATE_PARKING_PRICING = gql`
  mutation updateParkingPricing($id: ID!, $priceOptions: [JSONString]) {
    updateParkingPricing(id: $id, priceOptions: $priceOptions) {
      parking {
        id
        price {
          value
          unit
        }
      }
    }
  }
`;

export const UPDATE_PARKING_AVAILABILITY = gql`
  mutation updateParkingAvailability(
    $id: ID!
    $availableDateFrom: DateTime
    $filters: [JSONString]
  ) {
    updateParkingAvailability(
      id: $id
      availableDateFrom: $availableDateFrom
      filters: $filters
    ) {
      parking {
        id
        availableDateFrom
        filters
      }
    }
  }
`;

export const UPDATE_PARKING_STATUS = gql`
  mutation updateParkingStatus($id: ID!, $status: ParkingStatusEnum) {
    updateParkingStatus(id: $id, status: $status) {
      parking {
        id
        status
      }
    }
  }
`;
