import React, { useContext } from 'react';
import styled from 'styled-components';
import { createSkeletonElement } from '@trainline/react-skeletor';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { loadGoogleMaps } from '../../../_shared/load-google-map';
import {
  navy,
  defaultBoxShadow,
  midBreakPoint,
  gutter,
  RubikSemiBold,
  graphite,
  greyLight,
  grey,
  greyDark,
  teal,
  spacing
} from '../../../_shared/layout-constants';
import {
  BackButtonWithIcon,
  NextButton,
  FullWidthPrimaryButton as NextFullWidthButton,
  RoundSecondaryButton
  //SmallFullWidthBorderButton
} from '../../../_shared/components/Buttons';
import { ImgixUrl } from '../../../_shared/utils';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import Notification from '../../../_shared/components/Notification';

const Wrapper = createSkeletonElement(styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 524px;

  @media (min-width: ${midBreakPoint}px) {
    box-shadow: ${({ loader }) => (loader ? defaultBoxShadow : null)};
  }
`);

const Skip = styled.button`
  position: absolute;
  top: 64px;
  right: 28px;
  color: ${graphite};
`;

const Children = styled.div`
  flex: 1;
  padding: ${gutter(2)} ${gutter(1)} 0 ${gutter(1)};
`;

const Title = styled.div`
  padding: 28px ${gutter(1)} 4px ${gutter(1)};
  min-height: 58px;
  font-size: ${gutter(1)};
  font-weight: ${RubikSemiBold};
  color: ${graphite};
`;

const Subtitle = styled.div`
  width: 100%;
  padding: 12px ${gutter(1)} 0px ${gutter(1)};
  min-height: 28px;
  opacity: 0.75;
  font-size: 12px;
`;

const ButtonSection = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${gutter(1)};
  @media (min-width: ${midBreakPoint}px) {
    margin-top: ${gutter(2)};
  }
`;

const StyledButton = styled(NextFullWidthButton)`
  border-radius: 28px;
  width: 100%;
`;

const StyledNextButton = styled(NextButton)`
  border-radius: 28px;
`;

const SecondaryButton = styled(RoundSecondaryButton)`
  width: 100%;
  height: 48px;
  align-self: center;
  margin-top: 20px;
`;

// const DiscardButton = styled(SmallFullWidthBorderButton)`
//   display: none;
//   @media (max-width: ${midBreakPoint}px) {
//     display: block;
//     margin: 8px ${gutter(1)} 21px ${gutter(1)};
//     width: auto;
//   }
// `;

const Close = styled.img`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: block;
    width: ${gutter(1)}
    align-self: center;
    margin-top: 16px;
    cursor: pointer;
  }
`;

const modifyError = error => {
  let e = error.replace('GraphQL error: ', '');
  return e.charAt(0).toUpperCase() + e.slice(1);
};

const ProgressCircle = styled.div`
  background-color: ${({ isCurrent, isVisited }) =>
    isVisited ? (isCurrent ? 'white' : navy) : 'white'};
  border: 2px solid ${({ isVisited }) => (isVisited ? navy : greyLight)};
  color: ${({ isCurrent, isVisited }) =>
    isCurrent ? navy : isVisited ? 'white' : greyLight};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-bottom: 9px;
  z-index: 1;
`;

const ProgressWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 32px 24px 0 24px;
  justify-content: space-between;
  @media (max-width: ${midBreakPoint}px) {
    font-size: 12px;
  }
`;

const ProgressDotWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ProgressName = styled.div`
  color: ${({ isCurrent }) => (isCurrent ? graphite : grey)};
`;

const ProgressLine = styled.div`
  border-bottom: 2px solid
    ${({ isHighlight }) => (isHighlight ? navy : greyLight)};
  width: calc(50% - 24px);
  top: 16px;
  position: absolute;
`;

const ProgressLine1 = styled(ProgressLine)`
  left: 0;
`;
const ProgressLine2 = styled(ProgressLine)`
  left: calc(50% - 24px);
`;

const Info = styled.div`
  font-size: 14px;
  letter-spacing: normal !important;
  line-height: 1.33;
  color: ${greyDark};
  margin: -${spacing(4)} 0 ${spacing(2)} 0;
  z-index: 2;
  padding: 10px 12px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-color: ${teal};
  background-color: white;
  @media (min-width: ${midBreakPoint}px) {
    display: ${({ hide, hideOn }) =>
      hide || hideOn === 'big' ? 'none' : 'flex'};
  }
`;

const Icon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  object-fit: contain;
  @media (min-width: ${midBreakPoint}px) {
    height: 28px;
    width: 28px;
    margin-right: 12px;
  }
`;

const icon = type => {
  return `${ImgixUrl}/web-image-assets/icons/alert/GreenInfoIcon.png`;
};

const ProgressDot = ({ index, currentStep, name }) => {
  return (
    <ProgressDotWrapper>
      <ProgressCircle
        isCurrent={index === currentStep}
        isVisited={index <= currentStep}
      >
        {index + 1}
      </ProgressCircle>
      <ProgressName isCurrent={index === currentStep}>{name}</ProgressName>
    </ProgressDotWrapper>
  );
};

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <ProgressWrapper>
      {steps.map((step, i) => (
        <ProgressDot name={step} index={i} currentStep={currentStep} />
      ))}
      <ProgressLine1 isHighlight={currentStep > 0} />
      <ProgressLine2 isHighlight={currentStep > 1} />
    </ProgressWrapper>
  );
};

const AskForExpressInterestInfo =
  'Sorry, the listing currently has been booked. Please express your interest and Quokka will try best to match a listing for you as soon as possible !';

const BookingStepWrapper = props => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);

  const {
    subtitle,
    children,
    back,
    next,
    current,
    button,
    displayClose,
    isLoading,
    toggleBookingStep,
    loader,
    preview,
    disableNextButton,
    skip,
    error,
    showExpressInterestButton,
    address
  } = props;

  return (
    <Wrapper subtitle={subtitle} back={back}>
      {displayClose && (
        <Close
          src={`${ImgixUrl}/web-image-assets/icons/Expand-arrow.svg`}
          onClick={() => toggleBookingStep(false)}
        />
      )}
      {isLoading && <SpinnerLoader />}
      {skip && <Skip onClick={next}>skip</Skip>}
      {!loader && <Title>Book this parking space</Title>}
      {current !== 0 && (
        <ProgressBar
          currentStep={current}
          steps={['Booking duration', 'Driver information', 'Review']}
        />
      )}
      {error && (
        <Notification
          type="highlight"
          text={modifyError(error)}
          hasClose={false}
          style={{ margin: '24px 24px 0 24px' }}
        />
      )}
      {!loader && subtitle && <Subtitle>{subtitle}</Subtitle>}
      {!loader && children && <Children>{children}</Children>}
      {!loader && (
        <ButtonSection>
          {back && <BackButtonWithIcon handleClick={back} />}
          {back ? (
            <StyledNextButton
              type="submit"
              value="Submit"
              onClick={next}
              disabled={disableNextButton}
            >
              {current === 2 ? 'Submit' : 'Next'}
            </StyledNextButton>
          ) : !button ? null : button &&
            button === 'Cannot book inactive listing' ? (
            <Info>
              {icon() && <Icon src={icon()} />}
              <span title={AskForExpressInterestInfo}>
                {AskForExpressInterestInfo}
              </span>
            </Info>
          ) : (
            <StyledButton
              type="submit"
              value="Submit"
              onClick={preview || next}
              disabled={disableNextButton}
            >
              {button ? button : 'Next'}
            </StyledButton>
          )}
          {showExpressInterestButton && (
            <SecondaryButton
              onClick={() => {
                handleShowModal('ExpressInterest', '', address);
                loadGoogleMaps();
              }}
              paddingTop={8}
              fontSize={16}
            >
              Express interest
            </SecondaryButton>
          )}
        </ButtonSection>
      )}
      {/* {displayClose && (
        <DiscardButton onClick={() => toggleBookingStep(false)}>
          Discard booking
        </DiscardButton>
      )} */}
    </Wrapper>
  );
};

export default BookingStepWrapper;
