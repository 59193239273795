import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { reduxForm, Field } from 'redux-form';
import compose from 'lodash/flowRight';
import { ADD_VEHICLE } from '../../../common/graphql/adminSchema';
import { ReduxToggleButtonGroup } from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  ReduxInputField,
  ReduxSelectField
} from '../../../_shared/components/FormElements';
import { midBreakPoint } from '../../../_shared/layout-constants';
import './index.css';

const RegistrationNumber = styled.div``;

const Nickname = styled.div``;

const State = styled.div`
  padding: 0 2px;
`;

const VehicleType = styled.div`
  @media (max-width: ${midBreakPoint}px) {
    font-size: 12px;
  }
`;

const validate = values => {
  const errors = {};
  if (!values.registrationNumber) {
    errors.registrationNumber = 'Registration number is required';
  } else if (values.registrationNumber.length > 8) {
    errors.registrationNumber = 'Please enter valid registeration number';
  }

  if (
    values.nickname &&
    (values.nickname.length > 50 ||
      !values.nickname.match(/^([^\s]+\s{1}){0,5}[^\s]+$/))
  ) {
    errors.nickname =
      'The nickname should be no more than 6 words and less than 50 characters';
  }

  if (!values.vehicleType) {
    errors.vehicleType = 'Please describe your vehicle';
  }
  return errors;
};

const AddVehicleModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const addVehicle = async value => {
    setLoading(true);
    try {
      await props.addVehicle({
        variables: {
          displayName: value.nickname,
          registrationState: value.state.value,
          registrationNumber: value.registrationNumber,
          status: value.vehicleType
        }
      });
      //console.log('@@@', result);
      // refetch the getVehicle gql query; booking process step 2
      props.updateVehicles && props.updateVehicles();
      setLoading(false);
      setError(null);
      handleHideModal('AddVehicle');
    } catch (e) {
      //console.log('error', e);
      setLoading(false);
      setError('Updated Failed. Please try again.');
    }
  };

  const isOpen = activeModal.includes('AddVehicle');
  const { handleSubmit, invalid } = props;

  let stateOptions = [
    { value: 'Victoria', label: 'Victoria' },
    { value: 'New South Wales', label: 'New South Wales' },
    { value: 'Queensland', label: 'Queensland' },
    { value: 'Western Australia', label: 'Western Australia' },
    { value: 'South Australia', label: 'South Australia' },
    { value: 'Tasmania', label: 'Tasmania' },
    {
      value: 'Australia Capital Territory',
      label: 'Australia Capital Territory'
    },
    { value: 'Northern Territory', label: 'Northern Territory' }
  ];

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Add a new vehicle"
      handleClose={() => handleHideModal('AddVehicle')}
      handleSubmit={handleSubmit(addVehicle)}
      contentLabel="Add Vehicle Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      loading={loading}
      buttonDisabled={invalid}
      notification={error}
      modalDescription={
        "Please provide us the vehicle information, and it's also the same vehicle you will park at requested parking space in order for property owner to verify vehicle via registration number."
      }
      showIcon={false}
    >
      <State>
        <Field
          name="state"
          label="State"
          component={ReduxSelectField}
          options={stateOptions}
        />
      </State>

      <RegistrationNumber>
        <Field
          name="registrationNumber"
          label="Registration number"
          component={ReduxInputField}
        />
      </RegistrationNumber>

      <Nickname>
        <Field
          name="nickname"
          label="Vehicle nickname"
          component={ReduxInputField}
        />
      </Nickname>

      <VehicleType>
        <Field
          name="vehicleType"
          label="What is your relationship to the vehicle?"
          options={[
            {
              label: 'I own it',
              value: 'OWNING'
            },
            {
              label: "I'm borrowing",
              value: 'BORROWING'
            },
            {
              label: 'I rent it',
              value: 'RENTING'
            }
          ]}
          component={ReduxToggleButtonGroup}
        />
      </VehicleType>
    </Modal>
  );
};

const formOptions = {
  form: 'AddVehicleModal',
  validate,
  initialValues: {}
};

export default compose(
  graphql(ADD_VEHICLE, { name: 'addVehicle' }),
  reduxForm(formOptions)
)(AddVehicleModal);
