import React, { Component } from 'react';
import styled from 'styled-components';
import { Transportation } from '../../../carpark';
import { RubikSemiBold } from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';

const SurroundingWrapper = styled.div`
  padding: 60px 24px 60px 24px;
`;

const Title = createSkeletonElement(styled.h2`
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
  text-align: left;
  margin: 0 0 40px 0;
`);

class Surrounding extends Component {
  render() {
    return (
      <SurroundingWrapper>
        <Title>Surrounding areas</Title>
        <Transportation noIcon={this.props.noIcon} />
      </SurroundingWrapper>
    );
  }
}

export default Surrounding;
