import styled from 'styled-components';
import { midBreakPoint } from '../../../_shared/layout-constants';
import { navy, teal } from '../../../_shared/layout-constants';

export const Link = styled.a`
  display: flex;
  justify-content: ${({ mobileAlign }) =>
    mobileAlign ? `${mobileAlign}` : 'flex-start'};
  width: 100%;
  height: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  cursor: pointer;
  color: ${teal};
  margin-top: 20px;

  &:hover {
    color: ${navy};
  }
  @media (min-width: ${midBreakPoint}px) {
    justify-content: ${({ desktopAlign }) =>
      desktopAlign ? `${desktopAlign}` : 'flex-end'};
  }
`;
