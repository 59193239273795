import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import {
  teal,
  greyLight,
  midBreakPoint,
  greyExtraLight
} from '../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../_shared/utils';

const Wrapper = styled.a`
  height: 64px;
  color: #fff;
  cursor: pointer;
  text-decoration: none !important;
  border: 1px dashed ${greyLight};
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr 32px 8px 211px 1fr;
  grid-template-rows: 1fr 32px 1fr;
  @media (min-width: ${midBreakPoint}px) {
    width: 285px;
    height: 187px;
  }
  &:hover {
    background-color: ${greyExtraLight};
  }
`;

const AddIcon = styled.img`
  grid-column: 2;
  grid-row: 2;
  width: 32px;
  height: 32px;
`;

const AddText = styled.div`
  grid-column: 4;
  grid-row: 2;
  padding: 6px 0 6px 0;
  width: 100%;
  height: 20px;
  color: ${teal};
`;

const AddPaymentMethod = () => {
  const { actions } = useContext(ModalStoreContext);
  return (
    <Wrapper onClick={() => actions.handleShowModal('AddPaymentMethod')}>
      <AddIcon
        src={`${ImgixUrl}/web-image-assets/icons/create-new-listing.svg`}
        alt="add a new payment option"
      />
      <AddText>Add a new payment method</AddText>
    </Wrapper>
  );
};

export default AddPaymentMethod;
