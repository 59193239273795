import React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import './index.css';
import { Link } from 'react-router-dom';
import {
  navy,
  grey,
  midBreakPoint
} from '../../../../_shared/layout-constants';
import { trackHomePage } from '../../../../_shared/tracker';
import { ImgixUrl } from '../../../../_shared/utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  min-height: 151px;
  background-color: ${navy};
  grid-template-columns: 24px 1fr 1fr 24px;
  grid-template-rows: 60px 1px 45px 45px;
  border-radius: 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 400px 200px 200px 200px 165px 1fr;
    grid-template-rows: 60px 46px 220px 80px;
    min-height: 380px;
    box-shadow: inset 0 1px 0 0 ${grey};
  }
`;

const QuokkaLogo = styled.img`
  display: none;
  width: 300px;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 2;
    grid-row: 2;
    cursor: pointer;
  }
`;

const Description = styled.p`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 2;
    grid-row: 3;
  }
`;

const QuokkaParking = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 3;
    grid-row: 2 / 3;
  }
`;

const FindParkingSpaces = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 4;
    grid-row: 2 / 3;
  }
`;

const ListAndEarn = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    margin-top: 0;
    grid-column: 5;
    grid-row: 2 / 3;
  }
`;

const SocialMedia = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 6;
    grid-row: 2;
  }
`;

// const Media = styled.div`
//   display: none;
//   @media (min-width: ${midBreakPoint}px) {
//     display: block;
//     margin-top: 19px;
//     grid-column: 6;
//     grid-row: 3;
//   }
// `;

const CompanyLogo = styled.div`
  grid-column: 2 / 4;
  grid-row: 1;
  margin-top: 27px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 30px;
    grid-column: 2;
    grid-row: 4;
  }
`;

const Career = styled.div`
  grid-column: 3;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 30px;
    grid-column: 4;
    grid-row: 4;
  }
`;

const ContactUs = styled.div`
  grid-column: 2;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 30px;
    grid-column: 3;
    grid-row: 4;
  }
`;

const TermsConditions = styled.div`
  grid-column: 2;
  grid-row: 3;
  margin-top: 16px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 30px;
    grid-column: 5;
    grid-row: 4;
  }
`;

const Privacy = styled.div`
  grid-column: 3;
  grid-row: 3;
  margin-top: 16px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 30px;
    grid-column: 6;
    grid-row: 4;
  }
`;

const Hr = styled.hr`
  width: 100%;
  border: 0;
  height: 1;
  border-top: 1px solid #ffffff;
  margin: 0;
  grid-column: 2 / 4;
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 7;
    grid-row: 4;
  }
`;
const urlInSuburb = suburb =>
  `/find-parking-space/address-${suburb}/search?startDate=${moment().format(
    'DD-MM-YYYY'
  )}&endDate=${moment()
    .add(1, 'months')
    .format(
      'DD-MM-YYYY'
    )}&parkingTypes=&priceUnit={%22value%22:%22m%22,%22label%22:%22Monthly%22}&priceRange={%22min%22:0,%22max%22:1500}`;

export default ({ homepage = false, ...props }) => (
  <Wrapper {...props}>
    <QuokkaLogo
      src={`${ImgixUrl}/web-image-assets/branding/logoMarkWhite.svg`}
      className="footer-logo"
      onClick={() => (window.location.href = '/')}
    />

    <Description className="quokka-parking-footer-decription">
      Quokka Parking offers long-term (semi-annually or yearly) parking service,
      and global parking database search and navigation service. Quokka Parking
      enables our users to share parking space. You can earn money as a space
      manager but also provide convenience for travellers and commuters. Come
      onboard today!
      <br />
      <br />
      <div>Platform version v{global.appVersion}</div>
    </Description>

    <QuokkaParking>
      <div className="footer-title">Quokka Parking</div>
      <div className="footer-content">
        <a
          className="footer-link"
          href="https://quokkaparking.crunch.help/faq-articles/"
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQ & Support
        </a>
      </div>
      <div className="footer-content">
        <a className="footer-link" href="/how-it-works">
          How it works
        </a>
      </div>
      <div className="footer-content">
        <a className="footer-link" href="/about-us">
          About us
        </a>
      </div>
      <div className="footer-content">
        <a className="footer-link" href="/sitemap">
          Sitemap
        </a>
      </div>
    </QuokkaParking>

    <FindParkingSpaces>
      <div className="footer-title">Find parking</div>
      <div className="footer-content">
        <Link
          to={urlInSuburb('sydney-cbd-nsw-australia')}
          className="footer-link"
        >
          Sydney CBD parking
        </Link>
      </div>
      <div className="footer-content">
        <Link
          to={urlInSuburb('cbd-melbourne-vic-australia')}
          className="footer-link"
        >
          Melbourne CBD parking
        </Link>
      </div>
      <div className="footer-content">
        <Link
          to={urlInSuburb('brisbane-city-qld-australia')}
          className="footer-link"
        >
          Brisbane CBD parking
        </Link>
      </div>
      <div className="footer-content">
        <Link
          to={urlInSuburb('adelaide-cbd-adelaide-sa-australia')}
          className="footer-link"
        >
          Adelaide CBD parking
        </Link>
      </div>
      <div className="footer-content">
        <Link
          to={urlInSuburb('cbd-perth-wa-australia')}
          className="footer-link"
        >
          Perth CBD parking
        </Link>
      </div>
    </FindParkingSpaces>

    <ListAndEarn>
      <div className="footer-title">List and earn</div>
      <div className="footer-content">
        <Link to="/how-it-works" className="footer-link">
          Why list with Quokka?
        </Link>
      </div>
      <div className="footer-content">
        <Link to="/admin/my-listings" className="footer-link">
          List with Quokka!
        </Link>
      </div>
      <div className="footer-content">
        <Link to="/admin/my-listings" className="footer-link">
          Manage space
        </Link>
      </div>
      <div className="footer-content">
        <Link to="/admin/bookings/manage-bookings" className="footer-link">
          Manage bookings
        </Link>
      </div>
    </ListAndEarn>

    <SocialMedia>
      <a
        onClick={
          homepage ? () => trackHomePage('socialMedia', 'linkedin') : () => {}
        }
        href="https://www.linkedin.com/company/quokka-technology/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${ImgixUrl}/web-image-assets/icons/socialMedia/linkedin.svg`}
          className="footer-media-icon"
          alt="linkedin icon"
        />
      </a>
      <a
        href="https://www.facebook.com/Quokka-Parking-1463368273755746/"
        onClick={
          homepage ? () => trackHomePage('socialMedia', 'facebook') : () => {}
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${ImgixUrl}/web-image-assets/icons/socialMedia/facebook.svg`}
          className="footer-media-icon"
          alt="facebook icon"
        />
      </a>
      <a
        href="https://www.instagram.com/quokkaparking/"
        onClick={
          homepage ? () => trackHomePage('socialMedia', 'instagram') : () => {}
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${ImgixUrl}/web-image-assets/icons/socialMedia/instagram.svg`}
          className="instagram-icon"
          alt="instagram icon"
        />
      </a>
      <a
        href="https://twitter.com/QuokkaTechnolo1"
        onClick={
          homepage ? () => trackHomePage('socialMedia', 'twitter') : () => {}
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${ImgixUrl}/web-image-assets/icons/socialMedia/twitter.svg`}
          className="footer-media-icon"
          alt="twitter icon"
        />
      </a>
    </SocialMedia>

    {/* <Media>
      <div className="footer-content">
        <a href="/media" className="footer-link">
          Media
        </a>
      </div>
      <div className="footer-content">
        <a href="/blog" className="footer-link">
          Blog
        </a>
      </div>
      <div className="footer-content">
        <a href="/disclaimer" className="footer-link">
          Disclaimer
        </a>
      </div>
    </Media> */}

    <Hr />

    <CompanyLogo>
      <a href="https://quokkaparking.com.au">
        <img
          className="quokka-image-placeholder"
          src={`${ImgixUrl}/web-image-assets/icons/Quokka-icon-footer.svg`}
          alt="quokka placeholder"
        />
        <div className="quokka-technology-company">
          All rights reserved © Quokka Parking 2022
        </div>
      </a>
    </CompanyLogo>

    <ContactUs>
      <a className="footer-link" href="/contact-us">
        Contact us
      </a>
    </ContactUs>

    <Career>
      <a
        className="footer-link"
        href="https://quokkatechnology.zohorecruit.com/recruit/Portal.na?digest=RY9VVYVIaZ6q1gvIcSfaeHGAUnkE7bVo.a85bxdBEmQ-&iframe=false&mode=register&embedsource=CareerSite"
        target="_blank"
        rel="noopener noreferrer"
      >
        Career
      </a>
    </Career>

    <TermsConditions>
      <a className="footer-link" href="/terms-and-conditions">
        Terms and Conditions
      </a>
    </TermsConditions>

    <Privacy>
      <a className="footer-link" href="/privacy-policy">
        Privacy
      </a>
    </Privacy>
  </Wrapper>
);
