import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import shortid from 'shortid';
import moment from 'moment-timezone';
import { BookingTicket } from '../../../admin';
import {
  midBreakPoint,
  greyDark,
  RubikSemiBold
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import { BOOKING_STATUS } from '../query';

const PAGINATION_MAX_ITEMS = 100;

const BookingsSection = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  @media (min-width: ${midBreakPoint}px) {
    flex-wrap: wrap;
    max-width: 792px;
  }
`;

const Illustration = styled.img`
  width: 200px;
  margin-top: 120px;
  @media (min-width: ${midBreakPoint}px) {
    width: 300px;
  }
`;

const BookingSegmentTitle = styled.div`
  font-size: 20px;
  margin: 24px 0;
  font-weight: ${RubikSemiBold};
`;

const IllustrationText = styled.div`
  margin-top: 30px;
  font-size: 10px;
  color: ${greyDark};

  @media (min-width: ${midBreakPoint}px) {
    font-size: 20;
  }
`;

const EmptyState = ({ text }) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Illustration
      src={`${ImgixUrl}/web-image-assets/illustration/waiting.png`}
      alt="empty"
    />
    <IllustrationText>{text}</IllustrationText>
  </div>
);

const MyBookingTicket = ({ booking, status, type }) => (
  <BookingTicket
    status={status}
    ticketId={get(booking, 'id')}
    parkingName={get(booking, 'parking.title')}
    address={get(booking, 'parking.address.value')}
    isMonthlyOngoing={get(booking, 'bookings[0].isMonthlyBooking')}
    startTime={moment
      .tz(
        get(booking, 'bookings[0].startTime'),
        get(booking, 'parking.address.tz')
      )
      .format('Do MMM YYYY')}
    endTime={moment
      .tz(
        get(booking, 'bookings[0].endTime'),
        get(booking, 'parking.address.tz')
      )
      .format('Do MMM YYYY')}
    accessInstruction={get(booking, 'parking.accessInstruction')}
    parkingId={get(booking, 'parking.id')}
    type={type}
  />
);

const BookingSegment = ({ setEmpty, setLoading, status, title }) => {
  const role = BOOKING_STATUS[status].renter;
  const { data, loading } = useQuery(role.query, {
    handleError: false,
    variables: { limit: PAGINATION_MAX_ITEMS }
  });
  const listEmpty = role.name.every(s => {
    return get(data, `${s}.length`) === 0;
  });
  useEffect(() => {
    setLoading(loading);
    setEmpty(listEmpty);
  }, [loading, listEmpty, setEmpty, setLoading]);
  if (loading || listEmpty) return null;
  return (
    <Fragment>
      {title && <BookingSegmentTitle>{title}</BookingSegmentTitle>}
      <BookingsSection>
        {role.name.map(s =>
          get(data, `${s}`, []).map(i => (
            <MyBookingTicket
              key={shortid.generate()}
              booking={i}
              status={status}
            />
          ))
        )}
      </BookingsSection>
    </Fragment>
  );
};

const Status = ({ status }) => {
  const [requestsEmpty, setRequestsEmpty] = useState(false);
  const [pendingEmpty, setPendingEmpty] = useState(false);
  const [confirmationsEmpty, setConfirmationsEmpty] = useState(false);
  const [historyEmpty, setHistoryEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      {loading && <SpinnerLoader />}
      {status === 'currentbookings'
        ? requestsEmpty &&
          pendingEmpty &&
          confirmationsEmpty && (
            <EmptyState text={'You have no current booking requests yet'} />
          )
        : historyEmpty && (
            <EmptyState text={'You have no history booking requests yet'} />
          )}
      {status === 'currentbookings' ? (
        <div>
          <BookingSegment
            setEmpty={setRequestsEmpty}
            setLoading={setLoading}
            status={'requests'}
            title="Requests"
          />
          <BookingSegment
            setEmpty={setPendingEmpty}
            setLoading={setLoading}
            status={'pending'}
            title="Pending"
          />
          <BookingSegment
            setEmpty={setConfirmationsEmpty}
            setLoading={setLoading}
            status={'confirmations'}
            title="Confirmation"
          />
        </div>
      ) : (
        <BookingSegment
          setEmpty={setHistoryEmpty}
          setLoading={setLoading}
          status={'history'}
        />
      )}
    </Fragment>
  );
};

export default Status;
