import React, { Component } from 'react';
import { StationIconWrapper } from '../../../common/components';

export default class Transportation extends Component {
  render() {
    return (
      <StationIconWrapper
        noIcon={this.props.noIcon}
        iconPath="Train.svg"
        iconName="Southern Cross"
      />
    );
  }
}
