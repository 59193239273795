import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import compose from 'lodash/flowRight';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { graphql } from '@apollo/react-hoc';
import {
  ReduxCheckbox,
  Checkboxes
} from '../../../_shared/components/CheckBoxes';
import { Field } from 'redux-form';
import {
  ReduxSelectField,
  ReduxCheckBoxComponent
} from '../../../_shared/components/FormElements';
import {
  ALL_PARKING_TYPES,
  ALL_AMENITIES,
  ALL_ACCESS_TYPES,
  ALL_HEIGHT_OPTIONS,
  ALL_VEHICLE_TYPES
} from '../../../common/graphql/carparkSchema';
import './index.css';
import Modal from '../../../_shared/components/ModalWrapper';
import { midBreakPoint } from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const ParkingType = styled.div``;

const Access = styled.div``;

const MaxHeight = styled.div``;

const Amenities = styled.div`
  margin-bottom: 24px;
`;

const SuitableVehicles = styled.div``;

const Subtitle = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
`;

const ArrowIcon = styled.img`
  width: 14px;
  display: none;
  margin-left: 5px;
  @media (min-width: ${midBreakPoint}px) {
    display: inline;
    transform: ${({ show }) => (show ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;

const ModifyFeaturesModal = props => {
  const [showAmenities, setShowAmenities] = useState(true);
  const [showSuitableVehicles, setShowSuitableVehicles] = useState(false);
  const toggleAmenities = () => setShowAmenities(!showAmenities);
  const toggleSuitableVehicles = () =>
    setShowSuitableVehicles(!showSuitableVehicles);
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const isOpen = activeModal.includes('ModifyFeatures');
  const {
    allParkingTypes,
    allAccessTypes,
    allAmenities,
    allVehicleTypes,
    allHeightOptions,
    //redux-form props
    invalid
  } = props;
  if (
    allParkingTypes.loading ||
    allAccessTypes.loading ||
    allHeightOptions.loading ||
    allAmenities.loading ||
    allVehicleTypes.loading
  ) {
    return <div>loading...</div>;
  }
  const parkingTypesOptions = [];
  const maxHeightOptions = [];
  const accessTypesOptions = [];

  allParkingTypes.allParkingTypes.map(type =>
    parkingTypesOptions.push({
      value: type.name,
      label: type.name
    })
  );

  allAccessTypes.allAccessTypes.map(access =>
    accessTypesOptions.push({
      value: access.name,
      label: access.name
    })
  );

  allHeightOptions.allHeightOptions.map(height =>
    maxHeightOptions.push({
      value: height.value,
      label: height.value
    })
  );
  maxHeightOptions.push({
    value: 'Unrestricted',
    label: 'Unrestricted'
  });

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Modify features"
      handleClose={() => handleHideModal('ModifyFeatures')}
      contentLabel="Modify Carpark Features Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      buttonDisabled={invalid}
      handleSubmit={() => {
        if (invalid) return;
        props.changeFeatures({ isReview: true, ...props.values });
        handleHideModal('ModifyFeatures');
      }}
      isForm={false}
    >
      <ParkingType>
        <Field
          name="parkingTypes"
          label="Type"
          component={ReduxSelectField}
          options={parkingTypesOptions}
        />
      </ParkingType>

      <Access>
        <Field
          name="accessTypes"
          label="Access"
          component={ReduxSelectField}
          options={accessTypesOptions}
        />
      </Access>

      <MaxHeight>
        <Field
          name="maxHeight"
          label="Max height (unit: meter)"
          component={ReduxSelectField}
          options={maxHeightOptions}
        />
      </MaxHeight>

      <Amenities>
        <Subtitle onClick={toggleAmenities}>
          Amenities
          <ArrowIcon
            show={showAmenities}
            src={`${ImgixUrl}/web-image-assets/icons/collapse_arrow.svg`}
          />
        </Subtitle>
        {showAmenities &&
          !allAmenities.loading &&
          typeof allAmenities.allAmenities !== 'undefined' && (
            <Field
              name="amenities"
              itemComponent={ReduxCheckBoxComponent}
              component={ReduxCheckbox(Checkboxes)}
              data={allAmenities.allAmenities}
              textField="name"
              valueField="id"
              valueField2="name"
            />
          )}
      </Amenities>
      <SuitableVehicles>
        <Subtitle onClick={toggleSuitableVehicles}>
          Suitable for
          <ArrowIcon
            show={showSuitableVehicles}
            src={`${ImgixUrl}/web-image-assets/icons/collapse_arrow.svg`}
          />
        </Subtitle>
        {showSuitableVehicles &&
          !allVehicleTypes.loading &&
          typeof allVehicleTypes.allVehicleTypes !== 'undefined' && (
            <Field
              name="suitableVehicles"
              itemComponent={ReduxCheckBoxComponent}
              component={ReduxCheckbox(Checkboxes)}
              data={allVehicleTypes.allVehicleTypes}
              textField="name"
              valueField="id"
              valueField2="name"
            />
          )}
      </SuitableVehicles>
    </Modal>
  );
};

export default compose(
  graphql(ALL_PARKING_TYPES, { name: 'allParkingTypes' }),
  graphql(ALL_ACCESS_TYPES, { name: 'allAccessTypes' }),
  graphql(ALL_HEIGHT_OPTIONS, { name: 'allHeightOptions' }),
  graphql(ALL_AMENITIES, { name: 'allAmenities' }),
  graphql(ALL_VEHICLE_TYPES, { name: 'allVehicleTypes' })
)(ModifyFeaturesModal);
