import React from 'react';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import GridView from './_gridView';

const MyBookingsPage = () => {
  return (
    <AdminWrapper title="My bookings" subtitle="Traveller">
      <GridView />
    </AdminWrapper>
  );
};

export default MyBookingsPage;
