import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import compose from 'lodash/flowRight';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { reduxForm, Field } from 'redux-form';
import PlacesAutocomplete from 'react-places-autocomplete';
import axios from 'axios';
import { ModalStoreContext } from '../../../common/context/modal/store';
import {
  PhoneInputField,
  ReduxTextArea,
  ReduxInputFieldWithIcon
} from '../../../_shared/components/FormElements';
import { SLACK_BOT_WEBHOOK_CUSTOMER_SERVICE } from '../../../_shared/constants';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  midBreakPoint,
  greyDark,
  defaultBorder,
  RubikSemiBold
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const autoFindStyles = {
  root: {
    position: 'relative',
    paddingBottom: '0px',
    height: '100%',
    width: '100%'
  },
  input: {
    padding: '12px 16px',
    fontSize: '16px',
    color: greyDark,
    width: '100%',
    outline: '0',
    border: defaultBorder,
    textOverflow: 'ellipsis',
    height: '100%',
    marginBottom: '12px'
  },
  autocompleteContainer: {
    backgroundColor: '#FFFFFF',
    marginTop: '10px',
    borderRadius: '24px',
    border: defaultBorder,
    color: greyDark,
    position: 'absolute',
    zIndex: '1'
  },
  autocompleteItem: {
    height: '54px',
    backgroundColor: '#ffffff',
    padding: '18px 24px 8px 24px',
    color: '#555555',
    cursor: 'pointer',
    borderRadius: '32px'
  },
  autocompleteItemActive: {
    backgroundColor: '#fafafa',
    borderRadius: '28px'
  }
};

const Label = styled.div`
  margin-bottom: 12px;
  font-weight: ${RubikSemiBold};
`;

const NameField = styled.div`
  margin-bottom: 8px;
`;

const PhoneNumberField = styled.div``;

const EmailField = styled.div`
  margin-bottom: -14px;

  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: -12px;
  }
`;

const InterestedAddressField = styled.div`
  margin-top: 4px;
`;

const QuestionsRequirementsField = styled.div`
  margin-top: 8px;
`;

const Hr = styled.hr`
  margin: 4px 0 28px 0;
`;

const slackMessage = (details, initState, interestedAddress) => {
  let location = initState ? interestedAddress : details.address;

  let msg = `
    Quokka users have raised interests in parking space! 
    Request details: 
    Name: ${details.name}
    Contact: ${details.phoneNumber}
    Email: ${details.email}
    Address: ${location}
    Questions/Requirements: ${details.requirements}
  `;

  return JSON.stringify({ text: msg });
};

const PhoneInputFieldComponent = e => {
  return (
    <PhoneInputField
      name="phoneNumber"
      placeholder="Phone number"
      value={e.input.value}
      onChange={e.input.onChange}
    />
  );
};

const AddressComponent = props => {
  return (
    <Field
      name="address"
      label="Address"
      inputProps={{
        className: 'address',
        value: props.location,
        onChange: props.input.onChange,
        placeholder: 'Interested parking space address'
      }}
      component={PlacesAutocomplete}
      styles={autoFindStyles}
      options={{
        componentRestrictions: {
          country: 'au'
        }
      }}
    />
  );
};

const ExpressInterestModal = props => {
  const {
    state: { activeModal, msg, interestedAddress },
    actions: {
      handleHideModal
      //handleInterestedAddress
    }
  } = useContext(ModalStoreContext);

  const [notification, setNotification] = useState(null);
  const [phone, setPhone] = useState(null);
  const [tempSearchLocation, setTempSearchLocation] = useState('');

  const isOpen = activeModal.includes('ExpressInterest');

  useEffect(() => {
    setNotification(msg);
    setTempSearchLocation(interestedAddress);
  }, [msg, interestedAddress]);

  const sendSlackMessage = async values => {
    // send slack notification
    await axios
      .post(
        SLACK_BOT_WEBHOOK_CUSTOMER_SERVICE,
        slackMessage(values, interestedAddress, interestedAddress)
      )
      .then(function(response) {
        console.log('slackbot message sent.');
        handleHideModal('ExpressInterest');
      })
      .catch(function(error) {
        console.log('### failed to notify QTPL. ### error details: ' + error);
        handleHideModal('ExpressInterest');
      });
  };

  const { handleSubmit, invalid } = props;

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      // isAuth={true}
      title="Express your interest"
      handleClose={() => handleHideModal('ExpressInterest')}
      modalDescription="Let us know your interested parking space location, and we'll match your need ! Please ensure your correct contact details are provided. Once your request is submitted, we'll aim to get back to you as soon as possible. "
      showIcon={false}
      isOpen={isOpen}
      notification={notification}
      handleSubmit={handleSubmit(sendSlackMessage)}
      contentLabel="Express Interest Modal"
      buttonLabel="Submit"
      buttonDisabled={invalid}
    >
      <InterestedAddressField>
        <Label>Interested parking location *</Label>
        {!interestedAddress ? (
          <Field
            name="address"
            label="Address"
            location={tempSearchLocation}
            onChange={location => {
              setTempSearchLocation(location);
              //handleInterestedAddress(location);
            }}
            component={AddressComponent}
          />
        ) : (
          <p>{interestedAddress}</p>
        )}
      </InterestedAddressField>
      <QuestionsRequirementsField>
        <Field
          name="requirements"
          label="Additional requirements or questions"
          component={ReduxTextArea}
          height={48}
        />
      </QuestionsRequirementsField>

      <Hr />

      <NameField>
        <Label>Your contact details *</Label>
        <Field
          name="name"
          label="Name"
          component={ReduxInputFieldWithIcon}
          icon={`${ImgixUrl}/web-image-assets/icons/Name.svg`}
        />
      </NameField>

      <PhoneNumberField>
        <Field
          name="phoneNumber"
          value={phone}
          onChange={phone => setPhone(phone)}
          component={PhoneInputFieldComponent}
        />
      </PhoneNumberField>

      <EmailField>
        <Field
          name="email"
          label="Email"
          component={ReduxInputFieldWithIcon}
          icon={`${ImgixUrl}/web-image-assets/icons/Email.svg`}
        />
      </EmailField>
    </Modal>
  );
};

const validate = values => {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Email format is not valid';
  }

  if (!values.address) {
    errors.address = 'Please enter your interested address';
  }

  if (!values.name) {
    errors.name = 'Please enter your name';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Please enter the contact phone number';
  } else if (!isValidPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'Please enter a vaild phone number';
  }

  return errors;
};

const formOptions = {
  form: 'ExpressInterest',
  validate,
  enableReinitialize: true
};

export default compose(reduxForm(formOptions))(ExpressInterestModal);
