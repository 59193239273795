import React, { Component, useContext } from 'react';
import { graphql } from '@apollo/react-hoc';
import { Query } from '@apollo/react-components';
import compose from 'lodash/flowRight';
import moment from 'moment-timezone';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { StandardFooter } from '../../../common/components';
// import { getToggleEnabled, TOGGLES } from '../../../common/toggles';
// import Notification, {
//   NOTIFICATION_TYPE
// } from '../../../_shared/components/Notification';
import { CarParkNotFound } from '../../../carpark';
import {
  trackBookingStep,
  trackBookingFormAction,
  trackPDP
} from '../../../_shared/tracker';
import { GET_PARKING_SPACE } from '../../../common/graphql/carparkSchema';
import {
  ADD_FAVOURITE,
  REMOVE_FAVOURITE
} from '../../../common/graphql/adminSchema';
import './CarparkDetailsLayout.css';
import * as Components from './_styles';
import CarparkDetailsLoader from './_loader';
import PageBody from './_pageBody';

const DAILY = 'D';
const WEEKLY = 'W';
const MONTHLY = 'm';

const priceUnitDict = {
  [DAILY]: {
    short: DAILY,
    index: 1,
    unit: 'day',
    otherOptions: ['weekly', 'monthly']
  },
  [WEEKLY]: {
    short: WEEKLY,
    index: 2,
    unit: 'week',
    otherOptions: ['daily', 'monthly']
  },
  [MONTHLY]: {
    short: MONTHLY,
    index: 3,
    unit: 'month',
    otherOptions: ['daily', 'weekly']
  }
};

const DaysMapping = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
};

class CarparkDetailsLayout extends Component {
  state = {
    step: 1,
    showBookingStep: false,
    priceUnit: 'm',
    preview: null,
    duration: {
      fromDate: null,
      toDate: null,
      rangeArray: [],
      monthlyOngoing: true
    },
    vehicles: { selectedOption: null },
    contacts: { selectedOption: null },
    payment: { selectedOption: null }
  };

  componentDidMount() {
    trackBookingFormAction('appear', this.props.match.params.id);
    let params = new URLSearchParams(this.props.location.search);
    this.setState({
      priceUnit: params.get('priceUnit') || 'm'
    });
  }

  setPreview = preview => {
    this.setState({
      preview
    });
  };

  toggleHandler = step => {
    const { step: currentStep } = this.state;
    this.setState({ step });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // currently only track next button
    trackBookingStep(currentStep, step, this.props.match.params.id);
  };

  capitalizeWords = str => {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  toggleBookingStep = bool => {
    this.setState({
      showBookingStep: bool
    });
  };

  isFavourite = set => {
    return (
      set.filter(fav => fav.parking.id === this.props.match.params.id)
        .length !== 0
    );
  };

  addFavourite = async refetch => {
    trackPDP('addFavourite', this.props.match.params.id);
    await this.props
      .addFavourite({
        variables: {
          id: this.props.match.params.id,
          type: 'PARKING'
        },
        update: (_, { data: { addFavourite } }) => {
          if (addFavourite) {
            refetch();
          } else {
            console.log('failed to add favourite');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  removeFavourite = async refetch => {
    trackPDP('removeFavourite', this.props.match.params.id);
    await this.props
      .removeFavourite({
        variables: {
          id: this.props.match.params.id,
          type: 'PARKING'
        },
        update: (_, { data: { removeFavourite } }) => {
          if (removeFavourite) {
            refetch();
          } else {
            console.log('failed to remove favourite');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  isDateUnavailable = (filtersArray, date) => {
    return !filtersArray.includes(DaysMapping[date.weekday()]);
  };

  getNextAvailableDate = (filtersArray, date, endDate) => {
    while (
      this.isDateUnavailable(filtersArray, date) &&
      !date.isSame(endDate, 'day')
    ) {
      date.add(1, 'days');
    }
    return date.clone();
  };

  getBookingLength = array => {
    if (array.length === 1 && array[0].endTime === null) {
      return 'monthly ongoing basis';
    }

    return (
      array &&
      array.length > 0 &&
      array
        .map(item =>
          Math.ceil(
            moment(item.endTime).diff(moment(item.startTime), 'days', true)
          )
        )
        .reduce((a, b) => a + b, 0)
    );
  };

  startOfTheDay = date => {
    return moment(date.clone().format('YYYY-MM-DD')).startOf('day');
  };

  endOfTheDay = date => {
    return moment(date.clone().format('YYYY-MM-DD')).endOf('day');
  };

  startOfTheDayIfSameDay = (startDate, endDate) => {
    return startDate.isSame(endDate, 'day')
      ? moment(startDate.clone().format('YYYY-MM-DD')).startOf('day')
      : startDate;
  };

  endOfTheDayIfSameDay = (startDate, endDate) => {
    return startDate.isSame(endDate, 'day')
      ? moment(endDate.clone().format('YYYY-MM-DD')).endOf('day')
      : endDate;
  };

  onDatesSelection = (
    startDateInput,
    endDateInput,
    filters,
    bookingPreview
  ) => {
    // startDate, endDate are localtime based on timezone/tz, need to convert them into UTC
    // before store into state

    let startDateUTC = startDateInput && startDateInput.utc();
    let endDateUTC = endDateInput && endDateInput.utc();

    let filtersArray = filters[0].value.map(i => DaysMapping[i]);
    let rangeArray = [];
    let indexDate = this.startOfTheDay(startDateUTC);
    let tempJSON = {
      startTime: this.startOfTheDay(startDateUTC).toJSON(),
      endTime: null
    };

    // console.log('on date selection');
    // console.log(endDateUTC);

    if (endDateUTC !== null) {
      if (this.state.duration.monthlyOngoing) {
        this.toggleMonthlyOngoing();
      }

      if (startDateUTC.isSame(endDateUTC, 'day')) {
        //console.log(this.startOfTheDayIfSameDay(startDateUTC, endDateUTC));
        tempJSON['startTime'] = this.startOfTheDayIfSameDay(
          startDateUTC,
          endDateUTC
        ).toJSON();
        //console.log(this.endOfTheDayIfSameDay(startDateUTC, endDateUTC));
        tempJSON['endTime'] = this.endOfTheDayIfSameDay(
          startDateUTC,
          endDateUTC
        ).toJSON();
      } else {
        while (!indexDate.isSame(endDateUTC, 'day')) {
          // if endDateUTC is not null, iterate a function so that days not in range are filtered out
          // and formulate duration as an array of JSON;
          // if endDateUTC is a null, the duration is [{fromDate: startDateUTC, toDate: null}]
          if (this.isDateUnavailable(filtersArray, indexDate)) {
            // if indexDate moves to the blocked dates range, then we met a breaking point;
            // so we segregate a new date array
            indexDate.subtract(1, 'days');
            tempJSON['endTime'] = this.endOfTheDay(indexDate).toJSON();
            rangeArray.push(JSON.parse(JSON.stringify(tempJSON)));

            indexDate.add(1, 'days');
            indexDate = this.getNextAvailableDate(
              filtersArray,
              indexDate.clone(),
              endDateUTC
            );

            tempJSON = {
              startTime: this.startOfTheDay(indexDate).toJSON(),
              endTime: null
            };
          } else {
            // no blocked dates, continue to increment
            indexDate.add(1, 'days');
          }
        }
        tempJSON['endTime'] = this.endOfTheDay(endDateUTC).toJSON();
      }
    } else if (
      !this.state.duration.monthlyOngoing &&
      startDateUTC !== null &&
      endDateUTC === null
    ) {
      // if it's not monthly ongoing and only one day selected, then assume 1 day and make endDate as the startDate

      //console.log('making end date');
      tempJSON['endTime'] = this.endOfTheDay(startDateUTC).toJSON();
    }

    //console.log(this.state.duration.monthlyOngoing);

    rangeArray.push(tempJSON);
    //console.log(rangeArray);

    this.setState(
      {
        duration: {
          ...this.state.duration,
          fromDate: this.startOfTheDayIfSameDay(startDateUTC, endDateUTC),
          toDate: this.endOfTheDayIfSameDay(startDateUTC, endDateUTC),
          rangeArray: rangeArray,
          bookingLength: this.getBookingLength(rangeArray)
        }
      },
      () => {
        //console.log('preview booking');
        endDateInput && bookingPreview && bookingPreview(this.state.duration);
      }
    );
  };

  handleVehicleChange = selectedOption => {
    this.setState({
      vehicles: {
        selectedOption
      }
    });
  };

  handleContactChange = selectedOption => {
    this.setState({
      contacts: {
        selectedOption
      }
    });
  };

  handlePaymentChange = selectedOption => {
    this.setState({
      payment: {
        selectedOption
      }
    });
  };

  toggleMonthlyOngoing = () => {
    if (this.state.duration.monthlyOngoing) {
      this.setState({
        duration: {
          ...this.state.duration,
          monthlyOngoing: !this.state.duration.monthlyOngoing
        }
      });
    } else {
      this.setState({
        duration: {
          ...this.state.duration,
          toDate: null,
          rangeArray: this.state.duration.rangeArray.length > 0 && [
            { startTime: this.state.duration.rangeArray[0].startTime }
          ],
          bookingLength: 'monthly ongoing basis',
          monthlyOngoing: this.state.duration.monthlyOngoing // only support long-term booking
        }
      });
    }
  };

  render() {
    const { step, showBookingStep } = this.state;
    const currentPriceUnit = priceUnitDict[this.state.priceUnit];
    const mobileFullScreenDisplay = step !== 1 || showBookingStep;
    const { handleShowModal, isAuthenticated } = this.props;
    // const toggleEnabled = getToggleEnabled(TOGGLES.TEST);

    return (
      <Query
        query={GET_PARKING_SPACE}
        variables={{ id: this.props.match.params.id }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <CarparkDetailsLoader />;
          if (error) return <CarParkNotFound />;

          if (data) {
            //console.log(data);
            return (
              <Components.LayoutWrapper>
                {/* {toggleEnabled && (
                  <Components.Notification>
                    <Notification
                      type="highlight"
                      notificationType={NOTIFICATION_TYPE.PDP}
                      text={
                        'Hey quokka friends: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
                      }
                      hasClose={true}
                    />
                  </Components.Notification>
                )} */}
                <PageBody
                  {...data.getParkingSpace}
                  currentPriceUnit={currentPriceUnit}
                  mobileFullScreenDisplay={mobileFullScreenDisplay}
                  refetch={refetch}
                  state={this.state}
                  isFavourite={this.isFavourite}
                  capitalizeWords={this.capitalizeWords}
                  removeFavourite={this.removeFavourite}
                  addFavourite={this.addFavourite}
                  toggleHandler={this.toggleHandler}
                  toggleBookingStep={this.toggleBookingStep}
                  handleShowModal={handleShowModal}
                  toggleMonthlyOngoing={this.toggleMonthlyOngoing}
                  onDatesSelection={this.onDatesSelection}
                  handleVehicleChange={this.handleVehicleChange}
                  handleContactChange={this.handleContactChange}
                  handlePaymentChange={this.handlePaymentChange}
                  parkingId={this.props.match.params.id}
                  isAuthenticated={isAuthenticated}
                  setPreview={this.setPreview}
                />
                <Components.Footer>
                  <StandardFooter />
                </Components.Footer>
              </Components.LayoutWrapper>
            );
          }

          return null;
        }}
      </Query>
    );
  }
}

const CarparkDetailsLayoutWithModal = props => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);
  const isAuthenticated = localStorage.getItem('authenticate') === 'true';
  return (
    <CarparkDetailsLayout
      handleShowModal={handleShowModal}
      isAuthenticated={isAuthenticated}
      {...props}
    />
  );
};

export default compose(
  graphql(ADD_FAVOURITE, { name: 'addFavourite' }),
  graphql(REMOVE_FAVOURITE, { name: 'removeFavourite' })
)(CarparkDetailsLayoutWithModal);
