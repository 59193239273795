import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import { ExpiryInput } from '../../../../_shared/components/FormElements';
import { StandardFooter } from '../../../../common/components';
import { ReduxInputField } from '../../../../_shared/components/FormElements';
import SpinnerLoader from '../../../../_shared/components/SpinnerLoader';
import { ListingTicket } from '../../../../admin';
import {
  GET_ACTIVE_CREDIT_CARDS,
  GET_BOOKING_TRANSACTION,
  RETRY_PAYMENT
} from '../../../../common/graphql/paymentSchema';
import { GET_USER_INFO } from '../../../../common/graphql/adminSchema';
import {
  spacing,
  gutter,
  midBreakPoint,
  RubikSemiBold,
  graphite,
  greyLight
} from '../../../../_shared/layout-constants';
import { SelectField } from '../../../../_shared/components/FormElements';
import { FullWidthPrimaryButton } from '../../../../_shared/components/Buttons';
import Notification from '../../../../_shared/components/Notification';
import { panSecretEncrypt } from '../../../../_shared/security';
import './index.css';

const Layout = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 151px;

  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 1008px 1fr;
    grid-template-rows: 1fr 380px;
  }
`;

const Footer = styled.div`
  display: block;
  grid-column: 1 / -1;
  grid-row: 2;

  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const PageContent = styled.div`
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 720px auto;

  @media (min-width: ${midBreakPoint}px) {
    width: 100%;
    grid-row: 1;
    grid-column: 2;
    grid-template-columns: 343px ${spacing(8)} 568px;
    grid-template-rows: auto;
    margin: 64px 0 150px 0;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const BookingInfo = styled.div`
  grid-row: 1;
  grid-column: 2;
  width: 343px;
  height: 697px;
  margin: auto;
  padding: ${spacing(4)};
  border: solid 1px ${greyLight};

  @media (min-width: ${midBreakPoint}px) {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${spacing(4)};
    padding: ${spacing(4)};
  }
`;

const BookingInfoTitle = styled.div`
  font-size: 20px;
  margin-bottom: ${spacing(4)};
  margin-top: ${spacing(2)};
`;

const BookingContent = styled.div`
  color: rgba(51, 63, 72, 0.7);
  margin-bottom: ${spacing(4)};
`;

const BoldContent = styled.span`
  font-weight: ${RubikSemiBold};
  color: ${graphite};
`;

const PaymentSection = styled.div`
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto;
  grid-template-rows: 60px 95px auto;
  margin: ${spacing(4)};
  padding: ${spacing(1)};

  @media (min-width: ${midBreakPoint}px) {
    grid-row: 1;
    grid-column: 3;
    width: 100%;
    padding: ${spacing(1)};
  }
`;

const PaymentSectionTitle = styled.div`
  grid-row: 1;
  grid-column: 1;
  font-size: ${gutter(1)};
  font-weight: ${RubikSemiBold};
  margin-bottom: ${spacing(4)};
`;

const PaymentSectionSubtitle = styled.div`
  grid-row: 2;
  grid-column: 1;
  line-height: 1.25;
  margin-bottom: ${spacing(5)};
`;

const PaymentOptions = styled.div`
  grid-row: 3;
  grid-column: 1;
  display: flex;
  flex-direction: column;
`;

const PaymentSectionMargin = styled.div`
  margin-left: ${spacing(6)};
`;

const Cardholder = styled.div``;

const CardNumber = styled.div``;

const CardInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Expiry = styled.div`
  min-width: 175px;
`;

const CVV = styled.div`
  margin-left: 30px;
  max-width: 175px;
`;

const Button = styled(FullWidthPrimaryButton)`
  margin-top: ${spacing(4)};
`;

const validate = values => {
  const numbers = /[0-9]/g;
  const errors = {};
  if (!values.cardHolder) {
    errors.cardHolder = "Please enter card holder's name";
  }
  if (!values.cardNumber) {
    errors.cardNumber = 'Please enter card number';
  } else if (!values.cardNumber.match(/[0-9]{16}/)) {
    errors.cardNumber = 'Please enter a vaild card number';
  }
  if (!values.cvv) {
    errors.cvv = "Please enter card's CVV number";
  } else if (!values.cvv.match(/[0-9]{3}/)) {
    errors.cvv = 'Please enter vaild CVV number';
  }

  const thisYear = moment().year() - 2000;
  if (!values.month || !values.year) {
    errors.month = 'Please enter card expire date';
    errors.year = 'Please enter card expire date';
  } else if (
    !values.month.match(numbers) ||
    !values.year.match(numbers) ||
    values.month > 12 ||
    Number(values.year) < thisYear ||
    (Number(values.year) === thisYear &&
      Number(values.month) <= moment().month())
  ) {
    errors.month = 'Please enter a vaild expire date';
    errors.year = 'Please enter a vaild expire date';
  }
  return errors;
};

class Payment extends Component {
  state = {
    options: [],
    existingCreditCardSelected: {},
    activeCreditCards: null,
    booking: null,
    parking: null,
    existingPaymentOptionChecked: false,
    newPaymentOptionChecked: false,
    buttonDisabled: true,
    loading: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.getActiveCreditCards.loading &&
      prevProps.getActiveCreditCards.loading
    ) {
      //console.log(this.props.getActiveCreditCards);
      let creditCards = this.props.getActiveCreditCards.getActiveCreditCards;
      if (creditCards) {
        this.setState({
          activeCreditCards: creditCards
        });
      }
    }
    if (
      !this.props.getBookingTransaction.loading &&
      prevProps.getBookingTransaction.loading
    ) {
      //console.log(this.props.getBookingTransaction);
      let parkingBookingInfo = this.props.getBookingTransaction
        .getBookingTransaction;
      if (
        parkingBookingInfo &&
        parkingBookingInfo.bookings &&
        parkingBookingInfo.parking
      ) {
        this.setState({
          booking: parkingBookingInfo.bookings[0],
          parking: parkingBookingInfo.parking
        });
      }
    }
  }

  handleSelectionChange = selectedOption => {
    this.setState({ existingCreditCardSelected: selectedOption }, () => {
      if (this.state.existingCreditCardSelected.label) {
        this.setState({
          buttonDisabled: false
        });
      }
    });
  };

  handleExistingPaymentSubmit = async () => {
    this.setState({
      loading: true
    });
    try {
      await this.props.retryPayment({
        variables: {
          token: this.props.match.params.token,
          creditCardId: this.state.existingCreditCardSelected.value
        }
      });
      this.setState({
        loading: false,
        errorMsg: null
      });
      window.location.href = '/search';
    } catch (e) {
      this.setState({
        loading: false,
        errorMsg: 'Updated failed. Please try again.'
      });
    }
  };

  handleNewPaymentSubmit = async value => {
    this.setState({
      loading: true
    });
    // for now, '20' prefix for the year entered is fine
    var expiryDate = moment(['20' + value.year, parseInt(value.month, 10) - 1])
      .endOf('month')
      .format('YYYY-MM-DD');
    try {
      let payload = {
        cardHolder: value.cardHolder,
        cardNumber: value.cardNumber.replace(/ /g, ''),
        expiryDate: expiryDate,
        cvv: value.cvv
      };
      let userInfo = this.props.getUserInfo;
      let filename = await panSecretEncrypt(
        userInfo && userInfo.getUserInfo.id,
        payload,
        'credit card'
      );
      //console.log('@@@ filename @@@', filename);
      await this.props.retryPayment({
        variables: {
          token: this.props.match.params.token,
          fileName: filename
        }
      });
      window.location.href = '/search';
    } catch (e) {
      this.setState({
        loading: false,
        errorMsg: 'Updated failed. Please try again.'
      });
    }
  };

  transformPaymentOptions = creditCards => {
    return creditCards.map(i => {
      return { value: i.id, label: i.cardNumber };
    });
  };

  onExistingPaymentRadioChange = () => {
    this.setState(
      {
        existingPaymentOptionChecked: !this.state.existingPaymentOptionChecked
      },
      () => {
        if (this.state.existingPaymentOptionChecked) {
          this.setState({
            newPaymentOptionChecked: false
          });
        }
      }
    );
  };

  onNewPaymentOptionChecked = () => {
    this.setState(
      {
        newPaymentOptionChecked: !this.state.newPaymentOptionChecked
      },
      () => {
        if (this.state.newPaymentOptionChecked) {
          this.setState({
            existingPaymentOptionChecked: false
          });
        }
      }
    );
  };

  render() {
    const {
      options,
      existingCreditCardSelected,
      parking,
      booking,
      activeCreditCards,
      existingPaymentOptionChecked,
      newPaymentOptionChecked,
      buttonDisabled,
      loading,
      errorMsg
    } = this.state;

    const {
      getBookingTransaction,
      getActiveCreditCards,
      handleSubmit,
      invalid
    } = this.props;

    //booking && console.log(booking);
    //parking && console.log(parking);
    //activeCreditCards && console.log(activeCreditCards);
    //console.log(this.props);

    if (getActiveCreditCards.loading || getBookingTransaction.loading)
      return <SpinnerLoader />;

    return (
      <Layout>
        <PageContent>
          <BookingInfo>
            <BookingInfoTitle>
              <BoldContent>Booking information</BoldContent>
            </BookingInfoTitle>
            <BookingContent>
              <div>You are booking this parking space</div>
              <div>
                from{' '}
                <BoldContent>
                  {booking && moment(booking.startTime).format('LLLL')}
                </BoldContent>{' '}
                to{' '}
                <BoldContent>
                  {booking && moment(booking.endTime).format('LLLL')}
                </BoldContent>
              </div>
            </BookingContent>
            <BookingContent>
              Your parking fee is{' '}
              <BoldContent>
                ${booking && Math.round(booking.total * 100) / 100}
              </BoldContent>
            </BookingContent>
            <ListingTicket
              id={parking && parking.id}
              status={parking && parking.status === 'A' && 'live'}
              title={parking && parking.title}
              parkingType={parking && parking.parkingTypes[0].name}
              address={parking && parking.address.value}
              redirectToPDP={true}
            />
          </BookingInfo>

          <PaymentSection>
            <PaymentSectionTitle>
              Try a different payment option
            </PaymentSectionTitle>
            {errorMsg && (
              <Notification
                type="alert1"
                text={errorMsg}
                style={{ marginBottom: 20 }}
              />
            )}
            <PaymentSectionSubtitle>
              We’re sorry that your previous payment didn’t go through. To
              secure your parking space, let’s try again with a different one.
            </PaymentSectionSubtitle>
            <PaymentOptions>
              <label className="radio-container">
                Use an existing payment option
                <input
                  type="radio"
                  name="paymentMethod"
                  value="ExistingPaymentOption"
                  checked={this.state.existingPaymentOptionChecked}
                  onChange={this.onExistingPaymentRadioChange}
                />
                <span className="radio-checkmark" />
              </label>

              {existingPaymentOptionChecked ? (
                <PaymentSectionMargin>
                  <SelectField
                    value={existingCreditCardSelected}
                    handleChange={this.handleSelectionChange}
                    placeholder="VISA"
                    options={
                      activeCreditCards
                        ? this.transformPaymentOptions(activeCreditCards)
                        : options
                    }
                  />
                </PaymentSectionMargin>
              ) : null}

              <label className="radio-container">
                Use a new payment option
                <input
                  type="radio"
                  name="paymentMethod"
                  value="NewPaymentOption"
                  checked={this.state.newPaymentOptionChecked}
                  onChange={this.onNewPaymentOptionChecked}
                />
                <span className="radio-checkmark" />
              </label>

              {newPaymentOptionChecked ? (
                <form
                  onSubmit={handleSubmit(
                    this.handleNewPaymentSubmit.bind(this)
                  )}
                  noValidate
                >
                  <PaymentSectionMargin>
                    <Cardholder>
                      <Field
                        name="cardHolder"
                        label="Card holder"
                        component={ReduxInputField}
                      />
                    </Cardholder>

                    <CardNumber>
                      <Field
                        name="cardNumber"
                        label="Card number"
                        maxLength={16}
                        component={ReduxInputField}
                      />
                    </CardNumber>

                    <CardInfoWrapper>
                      <Expiry>
                        <ExpiryInput label="Expiry" />
                      </Expiry>

                      <CVV>
                        <Field
                          name="cvv"
                          label="CVV"
                          maxLength={3}
                          component={ReduxInputField}
                        />
                      </CVV>
                    </CardInfoWrapper>
                  </PaymentSectionMargin>
                  <Button
                    type="submit"
                    onClick={handleSubmit(
                      this.handleNewPaymentSubmit.bind(this)
                    )}
                    disabled={invalid}
                  >
                    {loading ? 'Processing...' : 'Proceed payment'}
                  </Button>
                </form>
              ) : null}
              {existingPaymentOptionChecked ? (
                <Button
                  type="submit"
                  onClick={this.handleExistingPaymentSubmit}
                  disabled={buttonDisabled}
                >
                  {loading ? 'Processing...' : 'Proceed payment'}
                </Button>
              ) : null}
            </PaymentOptions>
          </PaymentSection>
        </PageContent>

        <Footer>
          <StandardFooter />
        </Footer>
      </Layout>
    );
  }
}

const formOptions = {
  form: 'newPaymentMethodPage',
  validate,
  initialValues: {}
};

export default compose(
  reduxForm(formOptions),
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  graphql(GET_ACTIVE_CREDIT_CARDS, {
    name: 'getActiveCreditCards',
    options: props => ({
      variables: {
        token: props.match.params.token
      }
    }),
    handleError: false
  }),
  graphql(GET_BOOKING_TRANSACTION, {
    name: 'getBookingTransaction',
    options: props => ({
      variables: {
        token: props.match.params.token
      }
    }),
    handleError: false
  }),
  graphql(RETRY_PAYMENT, { name: 'retryPayment' })
)(Payment);
