import React, { Suspense, useContext, Fragment, useEffect } from 'react';
import { Header } from './components';
import {
  BrowserRouter,
  Route as RouteComponent,
  Switch
  //Redirect
} from 'react-router-dom';
import { getQueryString } from '../_shared/utils';
import { ModalStoreContext } from './context/modal/store';
import PrivateRoute from './PrivateRoute';
import history from '../_shared/history';
import ErrorBoundary from './errorBoundary';
import SpinnerLoader from '../_shared/components/SpinnerLoader';
// import ExpressInterestModal from './components/expressInterest';
import {
  Sitemap,
  HowItWorks,
  AboutUs,
  HomeLayout,
  ContactUs,
  SearchLayout,
  CarparkDetailsLayout,
  BookingRequestSent,
  BookingSuccess,
  BookingDeny,
  MyListingsPage,
  MyBookingsPage,
  ManageBookingsPage,
  CreateListingPage,
  CreateListingSuccessPage,
  ProfilePage,
  FavouriteBookingsPage,
  BillingsPage,
  BookingDetailPage,
  ListingDetailPage,
  DashboardPage,
  SettingsPage,
  ServiceNotAvailable,
  UnderMaintenance,
  PageNotFound,
  RetryPayment,
  PaymentExpired,
  PrivacyPolicy,
  TermsAndConditions,
  ContactInfo,
  HandleEmailButton,
  HandleBookingRequest,
  HandleBondRefund,
  HandleBookingCompletion,
  HandleReimbursementConfirmation,
  HandleCancellation,
  VehiclesInfo,
  QPMSListPage,
  QPMSGeneralPage,
  QPMSHoursPage,
  QPMSRatesPage,
  QPMSFacilitiesPage,
  QPMSImagesPage,
  QPMSCarparkDetailsPage
} from './Routes.list';
import { LoginModal, SignupModal, ForgotPasswordModal } from '../auth';

// wrap route inside error boundary
const Route = ({ component: Component, ...args }) => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);
  // show the error msg from auth0
  const queryError = getQueryString('error_description');
  useEffect(() => {
    if (getQueryString('error')) {
      handleShowModal('Login', queryError);
      history.push('/'); // remove the error in url once modal displayed
    }
  }, [queryError, handleShowModal]);

  return (
    <RouteComponent
      {...args}
      render={props => (
        <ErrorBoundary>
          <Component {...props} />
          <LoginModal />
          <SignupModal />
          <ForgotPasswordModal />
          {/* <ExpressInterestModal /> */}
        </ErrorBoundary>
      )}
    />
  );
};

export default () => {
  return (
    <Suspense fallback={<SpinnerLoader />}>
      <BrowserRouter>
        <Fragment>
          <Header />
          <Switch>
            <Route exact path="/" component={HomeLayout} />
            <Route exact path="/sitemap" component={Sitemap} />
            <Route exact path="/index.html" component={HomeLayout} />
            <Route exact path="/how-it-works" component={HowItWorks} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/maintenance" component={UnderMaintenance} />
            <Route
              exact
              path="/service-not-available"
              component={ServiceNotAvailable}
            />
            <Route exact path="/retryPayment/:token" component={RetryPayment} />
            <Route exact path="/paymentExpired" component={PaymentExpired} />
            <Route
              path="/find-parking-space/:address?/search/:params?"
              component={SearchLayout}
            />
            <Route path="/search/:params?" component={SearchLayout} />
            <Route path="/carpark/booking/:id" component={BookingRequestSent} />
            <Route path="/carpark/booking-success" component={BookingSuccess} />
            <Route
              path="/carpark/:name/:id/:sortBy?"
              component={CarparkDetailsLayout}
            />
            <Route path="/contact-info/:params?" component={ContactInfo} />
            <Route
              path="/handle-email-button/:params?"
              component={HandleEmailButton}
            />
            <Route
              path="/handle-booking-request/:params?"
              component={HandleBookingRequest}
            />
            <Route
              path="/handle-bond-refund/:params?"
              component={HandleBondRefund}
            />
            <Route
              path="/handle-booking-completion/:params?"
              component={HandleBookingCompletion}
            />
            <Route
              path="/handle-reimbursement-confirmation/:params?"
              component={HandleReimbursementConfirmation}
            />
            <Route
              path="/handle-cancellation/:params?"
              component={HandleCancellation}
            />
            <Route path="/vehicles-info" component={VehiclesInfo} />
            <PrivateRoute path="/login" />
            <PrivateRoute path="/reset-password" />

            <PrivateRoute path="/admin/dashboard" component={DashboardPage} />
            <PrivateRoute
              path="/admin/my-listings/listing-detail/live/:id"
              component={ListingDetailPage}
            />
            <PrivateRoute
              path="/admin/my-listings/listing-detail/off/:id"
              component={ListingDetailPage}
            />
            <PrivateRoute
              path="/admin/my-listings/listing-detail/draft/:id"
              component={CreateListingPage}
            />
            <PrivateRoute
              path="/admin/my-listings/:status?"
              component={MyListingsPage}
            />
            <PrivateRoute
              path="/admin/bookings/my-bookings/booking-detail/:type/:id"
              component={BookingDetailPage}
            />
            <PrivateRoute
              path="/admin/bookings/my-bookings/:status?/:subStatus?"
              component={MyBookingsPage}
            />
            <PrivateRoute
              path="/admin/bookings/manage-bookings/booking-detail/:type/:id"
              component={BookingDetailPage}
            />
            <PrivateRoute
              path="/admin/bookings/manage-bookings/:status?/:subStatus?"
              component={ManageBookingsPage}
            />
            <PrivateRoute exact path="/admin/qpms" component={QPMSListPage} />
            <PrivateRoute
              path="/admin/qpms/general"
              component={QPMSGeneralPage}
            />
            <PrivateRoute path="/admin/qpms/hours" component={QPMSHoursPage} />
            <PrivateRoute path="/admin/qpms/rates" component={QPMSRatesPage} />
            <PrivateRoute
              path="/admin/qpms/facilities"
              component={QPMSFacilitiesPage}
            />
            <PrivateRoute
              path="/admin/qpms/images"
              component={QPMSImagesPage}
            />
            <PrivateRoute
              path="/admin/qpms/carpark-details/:id"
              component={QPMSCarparkDetailsPage}
            />
            <PrivateRoute
              path="/bookingDeny/denyReasons/:token"
              component={BookingDeny}
            />
            <PrivateRoute path="/admin/billings" component={BillingsPage} />
            <PrivateRoute
              path="/admin/favourite-bookings"
              component={FavouriteBookingsPage}
            />
            <PrivateRoute
              exact
              path="/admin/create-listing"
              component={CreateListingPage}
            />
            <PrivateRoute
              path="/admin/create-listing/success/:id"
              component={CreateListingSuccessPage}
            />
            <PrivateRoute path="/admin/profile" component={ProfilePage} />
            <PrivateRoute path="/admin/settings" component={SettingsPage} />
            <Route exact component={PageNotFound} />
          </Switch>
        </Fragment>
      </BrowserRouter>
    </Suspense>
  );
};
