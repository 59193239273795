import React from 'react';
import styled from 'styled-components';
import { ImgixUrl } from '../utils';

const SummaryNote = styled.div`
  margin-top: 24px;
`;

const SummaryNoteText = styled.span`
  padding: 0 0 0 8px;
  height: 28px;
  opacity: 0.7;
  font-size: 12px;
`;

const InfoTip = ({ children, ...otherProps }) => (
  <SummaryNote {...otherProps}>
    <img src={`${ImgixUrl}/web-image-assets/icons/Info.svg`} alt="info icon" />
    <SummaryNoteText>{children}</SummaryNoteText>
  </SummaryNote>
);

export default InfoTip;
