import React, { Component } from 'react';
import styled from 'styled-components';
import {
  graphite,
  RubikSemiBold,
  aqua,
  red,
  greyExtraLight
} from '../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  margin-bottom: 16px;
`;

const DateTitle = styled.div`
  width: 100%;
  height: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 1.25;
  color: ${graphite};
  margin-bottom: 5px;
`;

const TransactionRow = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 40px;
  border-left: 6px solid ${({ type }) => (type === 'in' ? `${aqua}` : `${red}`)};
  padding: 8px 16px;
  background: ${greyExtraLight};
  margin: 0;
`;

const Info = styled.span`
  height: 24px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${graphite};
  padding: ${({ padding }) => (padding ? `${padding}px` : '0')};
  margin-right: ${({ margin }) => (margin ? `${margin}px` : '0')};
`;

export default class Transaction extends Component {
  render() {
    const { date, amount, type, description } = this.props;

    return (
      <Wrapper>
        <DateTitle>{date}</DateTitle>

        <TransactionRow type={type}>
          <Info margin={24} fontSize={20}>
            {type === 'in' ? '+  $ ' : '-  $ '}
            {amount}
          </Info>
          <Info margin={48} fontSize={16} padding={2}>
            {type === 'in' ? 'Money in' : 'Money out'}
          </Info>
          <Info fontSize={16} padding={2}>
            {description}
          </Info>
        </TransactionRow>
      </Wrapper>
    );
  }
}
