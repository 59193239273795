import React, { Component, useContext } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { ModalStoreContext } from '../../common/context/modal/store';
import {
  FullWidthBorderButton,
  FullWidthAlertButton,
  FullWidthSecondaryButton
} from './Buttons';
import {
  RubikSemiBold,
  midBreakPoint,
  gutter,
  RubikFontFamily
} from '../layout-constants';
import { ImgixUrl } from '../utils';

const AlertWrapper = styled.div`
  min-height: 250px;
  width: auto;
  max-width: 420px;
  font-family: ${RubikFontFamily};
  background-color: #ffffff;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 54px auto 64px;
  padding: ${gutter(1)};
  @media (min-width: ${midBreakPoint}px) {
    padding: ${gutter(1)};
    width: 420px;
  }
`;

const Title = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: normal;
`;

const Close = styled.img`
  grid-column: 12 / -1;
  grid-row: 1 / 2;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const Description = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
`;

const Button = styled.div`
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  margin-top: 15px;
  display: flex;
  & > :nth-child(2) {
    margin-left: 12px;
  }
`;

export class PopupAlertComponent extends Component {
  render() {
    const {
      handleHideModal,
      handleClick,
      isAlert,
      title,
      message,
      button,
      secondaryButton,
      enableRedirect,
      redirectTo,
      disableClose = false,
      type
    } = this.props;

    const ButtonType = isAlert
      ? FullWidthAlertButton
      : secondaryButton
      ? FullWidthSecondaryButton
      : FullWidthBorderButton;

    return (
      /* eslint-disable jsx-a11y/anchor-has-content */
      <AlertWrapper>
        <Title>{title}</Title>
        {!disableClose && (
          <Close
            src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
            className="alert-close"
            onClick={() => {
              handleHideModal(type);
              if (enableRedirect) {
                window.location.href = redirectTo;
              }
            }}
            data-dismiss="modal"
          />
        )}

        <Description>{message}</Description>

        <Button>
          <ButtonType
            bsClass="button"
            type="submit"
            onClick={() => {
              handleClick && handleClick();
              handleHideModal(type);
              if (enableRedirect) {
                window.location.href = redirectTo;
              }
            }}
          >
            {button}
          </ButtonType>
          {secondaryButton && (
            <FullWidthBorderButton
              onClick={() => {
                handleHideModal(type);
              }}
              data-dismiss="modal"
            >
              {secondaryButton}
            </FullWidthBorderButton>
          )}
        </Button>
      </AlertWrapper>
      /* eslint-enable */
    );
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'unset',
    padding: '0'
  }
};

const AlertComponent = props => {
  const {
    actions: { handleHideModal },
    state: { activeModal }
  } = useContext(ModalStoreContext);
  const { contentLabel, type, isAlert = false, ...otherProps } = props;
  const isOpen = activeModal.includes(type);

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel={contentLabel}
      onRequestClose={() => handleHideModal(type)}
    >
      <PopupAlertComponent
        handleHideModal={handleHideModal}
        type={type}
        isAlert={isAlert}
        {...otherProps}
      />
    </Modal>
    /* eslint-enable */
  );
};

export default AlertComponent;
