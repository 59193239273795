import styled from 'styled-components';
import React from 'react';
import shortid from 'shortid';
import Notification from '../../../_shared/components/Notification';
import {
  navy,
  RubikSemiBold,
  RubikBold,
  RubikNormal,
  graphite,
  greyDark,
  midBreakPoint
} from '../../../_shared/layout-constants';
import {
  BackButtonWithIcon,
  NextButton as NextButtonBase
} from '../../../_shared/components/Buttons';
import { CREATE_LISTING_STEPS } from './_constants';

const Wrapper = styled.div`
  width: 100%;
  grid-gap: 0;
  display: grid;
  grid-template-rows: 53px auto 32px 57px auto 36px auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    min-height: 700px;
    grid-template-columns: 167px 512px 167px;
    grid-template-rows: 70px 21px 58px auto auto 156px;
    padding: 0 36px 80px 36px;
    margin: 0 0 80px 0;
  }
`;

const Step = styled.div`
  grid-column: ${({ index }) => index + 1};
  grid-row: 1;
  width: 121px;
  height: 17px;
  font-size: 14px;
  &:hover {
    cursor: ${({ isAllow }) => (isAllow ? 'pointer' : 'default')};
    font-weight: ${({ currentStepIndex, index, isAllow }) =>
      currentStepIndex === index || isAllow ? RubikBold : RubikNormal};
  }
  color: ${({ currentStepIndex, index }) =>
    currentStepIndex === index ? graphite : greyDark};
  font-weight: ${({ currentStepIndex, index }) =>
    currentStepIndex === index ? RubikBold : RubikNormal};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  color: ${graphite};
  grid-row: 1;
  grid-column: 1 / 3;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 24px;
    grid-column: 1 / 4;
    grid-row: 1;
  }
`;

const PageTitle = styled.div`
  font-size: 14px;
  color: ${greyDark};
  grid-row: 4;
  grid-column: 1 / 3;
  @media (min-width: ${midBreakPoint}px) {
    color: ${graphite};
    grid-column: 1 / 4;
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
    font-weight: ${RubikSemiBold};
    letter-spacing: -0.2px;
  }
`;

const ProgressTitle = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 4;
    grid-row: 2;
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(7, 121px);
    grid-template-rows: 17px;
  }
`;

const NotificationWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / 3;
  margin-bottom: 20px;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 4;
    grid-column: 1 / 4;
  }
`;

const ProgressBar = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 4;
    grid-row: 3;
    border-radius: 0;
    margin-bottom: 40px;
    display: block;
  }
`;

const MobileHeading = styled.div`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: block;
    font-weight: ${RubikSemiBold};
    grid-row: 3;
  }
`;

const Children = styled.div`
  grid-row: 5;
  grid-column: 1 / 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 4;
  }
`;

const Bar = styled.div`
  width: ${({ currentStepIndex }) => `${currentStepIndex * 14}%`};
  background-color: ${navy};
  height: 19px;
`;

const NextButton = styled(NextButtonBase)`
  grid-column: 2 / 3;
  grid-row: 7;
  border-radius: 28px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3 / 4;
    grid-row: 7;
    border-radius: 28px;
  }
`;

const BackButton = styled.div`
  grid-column: 1 / 2;
  grid-row: 7;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 7;
  }
`;

const MobileStepIndicator = styled.span`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: inline;
    color: ${greyDark};
    font-size: 16px;
    font-weight: ${RubikNormal};
    margin-left: 20px;
  }
`;

const CreateListingPageWrapper = ({
  children,
  updateState,
  currentStepIndex,
  lastStepIndex,
  errorMsg,
  loading,
  onNextButtonClick,
  jumpToPage,
  disabled,
  invalid = false
}) => {
  const goToPreviousPage = () => {
    updateState(prevState => ({
      currentStepIndex: prevState.currentStepIndex - 1
    }));
  };

  const isAllowedToJump = stepIndex => {
    // not allow to jump forwards when invalid
    // not allow to jump beyond the last validated step
    const bool =
      (invalid && stepIndex < currentStepIndex) || stepIndex <= lastStepIndex;
    return bool;
  };

  return (
    <Wrapper>
      {currentStepIndex === 6 ? (
        <Title>Listing details</Title>
      ) : (
        <Title>
          Create a new listing
          <MobileStepIndicator>
            Step {currentStepIndex + 1}/{CREATE_LISTING_STEPS.length}
          </MobileStepIndicator>
        </Title>
      )}
      <ProgressTitle>
        {CREATE_LISTING_STEPS.map(value => (
          <Step
            currentStepIndex={currentStepIndex}
            key={shortid.generate()}
            index={value.step}
            isAllow={isAllowedToJump(value.step)}
            onClick={() =>
              isAllowedToJump(value.step) && jumpToPage(value.step)
            }
          >
            {value.step + 1}. {value.section}
          </Step>
        ))}
      </ProgressTitle>
      <ProgressBar className="progress">
        <Bar
          currentStepIndex={currentStepIndex}
          className="progress-bar"
          role="progressbar"
          aria-valuenow={currentStepIndex * 14}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </ProgressBar>
      <MobileHeading>
        {CREATE_LISTING_STEPS[currentStepIndex].section}
      </MobileHeading>
      {errorMsg && (
        <NotificationWrapper>
          <Notification type="alert1" text={errorMsg} hasClose={false} />
        </NotificationWrapper>
      )}
      <PageTitle>{CREATE_LISTING_STEPS[currentStepIndex].pageTitle}</PageTitle>
      <Children>{children}</Children>
      {currentStepIndex > 0 && (
        <BackButton>
          <BackButtonWithIcon handleClick={goToPreviousPage} />
        </BackButton>
      )}
      <NextButton
        type="submit"
        value="Submit"
        disabled={loading || disabled}
        onClick={onNextButtonClick}
      >
        {loading ? 'loading...' : currentStepIndex === 6 ? 'Submit' : 'Next'}
      </NextButton>
    </Wrapper>
  );
};

export default CreateListingPageWrapper;
