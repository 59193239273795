export const AUTH_TOKEN = 'auth-token';
export const AUTH0_TOKEN = 'auth0-token';

export const SLACK_BOT_WEBHOOK_LISTING_UPDATE =
  'https://hooks.slack.com/services/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';

export const SLACK_BOT_WEBHOOK_CUSTOMER_SERVICE =
  'https://hooks.slack.com/services/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';

export const SLACK_BOT_WEBHOOK_PAYMENT =
  'https://hooks.slack.com/services/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';

export const DAYS_MAPPING = [
  {
    id: 0,
    name: 'Sunday'
  },
  {
    id: 1,
    name: 'Monday'
  },
  {
    id: 2,
    name: 'Tuesday'
  },
  {
    id: 3,
    name: 'Wednesday'
  },
  {
    id: 4,
    name: 'Thursday'
  },
  {
    id: 5,
    name: 'Friday'
  },
  {
    id: 6,
    name: 'Saturday'
  }
];

export const BOOKING_STATUS = {
  Requested: 'R',
  Confirmed: 'C',
  Complete: 'CE',
  RequestToCancel: 'RC',
  Canceled: 'CA',
  PendingComplete: 'PCE',
  UnderInvestigation: 'UI'
};
