import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createSkeletonElement } from '@trainline/react-skeletor';

const Ul = createSkeletonElement('ul');

const Breadcrumb = styled(Ul)`
  list-style: none;
  padding: 0;
  background-color: #ffffff;
  display: flex;
  font-size: 12px;
  width: 100%;
`;

const Item = styled.span`
  opacity: 0.7;
`;

const Arrow = styled.span`
  margin: 0 10px;
`;

const ArrowLink = styled.span`
  width: auto;
`;

export default class BreadcrumbNav extends Component {
  render() {
    const { state, locality, postalCode } = this.props;

    return (
      <Breadcrumb>
        <Item>
          <Link to="/search">Parking space</Link>
        </Item>
        {state && (
          <ArrowLink>
            <Arrow>></Arrow>
            <Item>
              <a href="https://quokkaparking.com.au">{'Melbourne'}</a>
            </Item>
          </ArrowLink>
        )}
        {locality && postalCode && (
          <ArrowLink>
            <Arrow>></Arrow>
            <Item>
              <a href="https://quokkaparking.com.au">
                {locality + ' ' + postalCode}
              </a>
            </Item>
          </ArrowLink>
        )}
      </Breadcrumb>
    );
  }
}
