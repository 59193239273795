import React, { Fragment, useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import shortid from 'shortid';
import { GET_VEHICLES } from '../../../common/graphql/adminSchema';
import MyDetails from '../components/myDetails/MyDetails';
import MyCar from '../components/myCar/MyCar';
import AddVehicleButton from '../components/addVehicleButton/AddVehicleButton';
import ProfileImageUploader from '../components/profileImageUploader/ProfileImageUploader';
import { AdminSubtitle, AdminSectionWrapper } from '../../_shared/styles';
import { UserContext } from '../../../common/context/user';
import Notification from '../../../_shared/components/Notification';
import { AddVehicleModal, AddContactModal } from '../../../bookingProcess';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import ToolTip from '../../../_shared/components/Tooltips';
import { midBreakPoint } from '../../../_shared/layout-constants';

const MyDetailsSection = styled.div`
  margin-top: 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 82px;
  }
`;

const MyCarsSection = styled.div`
  margin-top: 48px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 82px;
  }
`;

const CarInfoWrapper = styled(AdminSectionWrapper)`
  min-height: 147px;
`;

const MyDetailsWrapper = styled(AdminSectionWrapper)`
  min-height: 188px;
`;

const MobileHr = styled.hr`
  margin-bottom: 32px;
  display: block;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const ProfileImageSection = styled.div`
  @media (max-width: ${midBreakPoint}px) {
    margin-bottom: 32px;
  }
`;

const ProfilePageComponent = ({ setLoading, getVehicles }) => {
  const { user } = useContext(UserContext);
  // need to have getUser to obtain the user's info in the first place
  const [vehicleError, setVehicleErrorMsg] = useState(null);
  const [photoError, setPhotoErrorMsg] = useState(null);

  if (getVehicles.loading || !user) {
    setLoading(true);
    return null;
  } else {
    setLoading(false);
  }

  const handleVehicleStatus = status => {
    switch (status) {
      case 'O':
        return 'Owning';
      case 'B':
        return 'Borrowing';
      case 'R':
        return 'Renting';
      default:
        return status;
    }
  };

  return (
    <Fragment>
      <AddVehicleModal updateVehicles={getVehicles.refetch} />
      <AddContactModal />
      <ProfileImageSection>
        <AdminSubtitle>Photo</AdminSubtitle>
        {/* TODO: tooltip copy update */}
        <ToolTip style={{ marginTop: 20 }}>
          Quokka said: Adding avatar to increase profile credibility.
        </ToolTip>
        {photoError && (
          <Notification
            type="alert1"
            text={photoError}
            hasClose={false}
            style={{ marginTop: 20 }}
          />
        )}
        <ProfileImageUploader
          setErrorMsg={setPhotoErrorMsg}
          userId={user && user.id}
        />
      </ProfileImageSection>
      <MobileHr />
      <MyDetailsSection>
        <AdminSubtitle>Details</AdminSubtitle>
        <MyDetailsWrapper>
          <MyDetails user={user} />
        </MyDetailsWrapper>
      </MyDetailsSection>
      <MobileHr />
      <MyCarsSection>
        <AdminSubtitle>Vehicles</AdminSubtitle>
        {vehicleError && (
          <Notification
            type="alert1"
            text={vehicleError}
            hasClose={false}
            style={{ marginTop: 20 }}
          />
        )}
        {getVehicles.getVehicles
          .sort(
            (a, b) => b.defaultresourceSet.length - a.defaultresourceSet.length
          )
          .map(vehicle => (
            <CarInfoWrapper key={shortid.generate()}>
              <MyCar
                vehicleId={vehicle.id}
                displayName={vehicle.displayName}
                status={handleVehicleStatus(vehicle.status)}
                registrationNumber={vehicle.registrationNumber}
                state={vehicle.registrationState}
                setErrorMsg={setVehicleErrorMsg}
                setLoading={setLoading}
                updateVehicles={getVehicles.refetch}
                defaultStatus={
                  vehicle.defaultresourceSet.length > 0
                    ? vehicle.id === vehicle.defaultresourceSet[0].vehicle.id
                    : false
                }
              />
            </CarInfoWrapper>
          ))}
        <AddVehicleButton />
      </MyCarsSection>
    </Fragment>
  );
};

const ProfilePage = props => {
  const [loading, setLoading] = useState(false);
  return (
    <AdminWrapper title="Profile" loading={loading}>
      <ProfilePageComponent setLoading={setLoading} {...props} />
    </AdminWrapper>
  );
};

export default graphql(GET_VEHICLES, { name: 'getVehicles' })(ProfilePage);
