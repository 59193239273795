import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import {
  GET_VEHICLES,
  GET_USER_INFO
} from '../../../common/graphql/adminSchema';
import { useQuery } from '@apollo/react-hooks';
import { VerifyListingModal } from '../../../common/modal';
import BookingStepWrapper from '../_shared/BookingStepWrapper';
import { AddVehicleModal } from '../../../bookingProcess';
import { SelectField } from '../../../_shared/components/FormElements';
import { TextButton } from '../../../_shared/components/Buttons';
import { RubikSemiBold, graphite } from '../../../_shared/layout-constants';

const AddButton = styled(TextButton)`
  font-size: 14px;
  margin-top: 28px;
  display: block;
`;

const Note = styled.span`
  height: 14px;
  opacity: 0.7;
  font-size: 12px;
  padding: 0 0 0 2px;
`;

const Subtitle = styled.label`
  height: 24px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  color: ${graphite};
  letter-spacing: -0.1px;
`;

const BreakLine = styled.hr`
  margin: 16px 0 32px 0;
`;

const StepTwo = ({
  toggleHandler,
  handleShowModal,
  handleVehicleChange,
  handleContactChange,
  selectedVehicleOption,
  selectedContactOption
}) => {
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);

  const { data: vehicles, refetch: refetchVehicles } = useQuery(GET_VEHICLES);
  const { data: contacts, refetch: refetchContacts } = useQuery(GET_USER_INFO);

  useEffect(() => {
    const loadVehicleOptions = get(vehicles, 'getVehicles', []).map(
      vehicle => ({
        value: vehicle.id,
        label: vehicle.registrationNumber
      })
    );
    // map all the vehicles to options
    setVehicleOptions(loadVehicleOptions);
  }, [vehicles]);

  useEffect(() => {
    handleVehicleChange(selectedVehicleOption || vehicleOptions[0]); // default the first option
  }, [vehicleOptions, selectedVehicleOption, handleVehicleChange]);

  useEffect(() => {
    const loadContactOptions = get(contacts, 'getUserInfo', []);
    // map all the contacts to options
    setContactOptions([
      {
        value: loadContactOptions.phoneNumber,
        label: loadContactOptions.phoneNumber
      }
    ]);
  }, [contacts]);

  useEffect(() => {
    handleContactChange(contactOptions[0]); // default the first option
  }, [contactOptions, handleContactChange]);

  return (
    <BookingStepWrapper
      back={() => toggleHandler(1)}
      next={() => toggleHandler(3)}
      current={1}
      disableNextButton={!selectedVehicleOption}
    >
      <Subtitle>Select your vehicle</Subtitle>
      <AddVehicleModal updateVehicles={refetchVehicles} />
      <VerifyListingModal handleCallback={refetchContacts} />
      {/** vehicle */}
      <SelectField
        style={{ marginBottom: 12 }}
        value={selectedVehicleOption}
        handleChange={handleVehicleChange}
        placeholder="Vehicle"
        options={vehicleOptions}
      />
      <Note>
        This is the car that will be parking at the requested parking space.
      </Note>
      <AddButton type="submit" onClick={() => handleShowModal('AddVehicle')}>
        Add a new vehicle
      </AddButton>

      <BreakLine />

      {/** contact */}
      <Subtitle>Driver contact</Subtitle>
      <SelectField
        style={{ marginBottom: 12 }}
        value={selectedContactOption}
        handleChange={handleContactChange}
        placeholder="Person"
        options={contactOptions}
      />
      <Note>
        This is the person who has requested this space. If you want to update
        the contact number, you can use "Update contact" button to update the
        contact person.
      </Note>

      <AddButton
        type="submit"
        onClick={() => handleShowModal('VerifyListingModal')}
      >
        Update contact
      </AddButton>
    </BookingStepWrapper>
  );
};

export default StepTwo;
