import React, { Component } from 'react';
import styled from 'styled-components';
import {
  greyLight,
  RubikSemiBold
} from '../../../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 74px;
  height: 24px;
  border-radius: 3px;
  background-color: ${greyLight};
  padding: 3px 18px 4px 20px;
`;

const Status = styled.div`
  width: 21px;
  height: 17px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  text-align: center;
`;

export default class DraftStatus extends Component {
  render() {
    return (
      <Wrapper>
        <Status>Draft</Status>
      </Wrapper>
    );
  }
}
