import React, { Component } from 'react';
import styled from 'styled-components';
import { aqua, RubikSemiBold } from '../../../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 74px;
  height: 24px;
  border-radius: 3px;
  background-color: ${aqua};
  padding: 3px 22px 4px 23px;
`;

const Status = styled.div`
  width: 29px;
  height: 17px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  text-align: center;
`;

export default class LiveStatus extends Component {
  render() {
    return (
      <Wrapper>
        <Status>Live</Status>
      </Wrapper>
    );
  }
}
