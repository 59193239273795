import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { ProfileDropdown } from '../../../common/components';
import MobileNav from '../mobileNav/MobileNav';
import { useAuth0 } from '../../../common/hooks/auth0';
import {
  midBreakPoint,
  greyDark,
  greyLight,
  defaultBorder
} from '../../../_shared/layout-constants';
import SearchBar from '../searchBar';
import { ImgixUrl } from '../../../_shared/utils';
import { loadGoogleMaps } from '../../../_shared/load-google-map';

const Wrapper = styled.div`
  z-index: 3;
  background-color: #ffffff;
  height: display: ${({ displayOnMobile }) =>
    displayOnMobile ? '100px' : '64px'}
  padding: 16px;
  position: relative;
  display: grid;

  @media (min-width: ${midBreakPoint}px) {
    display: flex;
    justify-content: flex-start;
    height: 82px;
  }
`;

const MenuIcon = styled.img`
  width: 28px;
  height: 24px;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 24px;

  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const Breakline = styled.hr`
  margin: 0;
  border: none;
  border-bottom: 1px solid ${greyLight};
  /* box-shadow: inset 0 4px 4px -4px rgba(0, 0, 0, 0.5); */
`;

const Logo = styled.img`
  padding-left: 0;
  max-height: auto;
  cursor: pointer;
  max-width: 190px;
  margin: auto;
  @media (min-width: ${midBreakPoint}px) {
    max-width: 240px;
    flex: 1.8;
    max-height: 48px;
  }
`;

const NavItems = styled.div`
  flex: 4.5 !important;
  display: none !important;
  @media (min-width: ${midBreakPoint}px) {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
`;

const NavItem = styled.div`
  width: auto !important;
  margin-right: 40px;
  text-align: center !important;
  cursor: pointer !important;
  text-decoration: none;
  > a {
    &:hover {
      color: ${greyDark} !important;
    }
  }
  &:hover {
    color: ${greyDark} !important;
  }
`;

const SearchBarHeader = styled(SearchBar)`
  flex: 4.5;
  /* display: none; */
  border-radius: 28px;
  border: ${defaultBorder};
  height: 48px;
  width: 100%;
  display: ${({ displayOnMobile }) => (displayOnMobile ? 'flex' : 'none')}
  margin-top: 16px;
  max-width: 520px;

  @media (min-width: ${midBreakPoint}px) {
    display:  flex;
    margin-top: 2px;
    margin-left: 20px;
  }
`;

const Header = props => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);
  const { logout } = useAuth0();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const localStorageAuthStatus =
    localStorage.getItem('authenticate') === 'true';
  useEffect(() => {
    setIsAuthenticated(localStorageAuthStatus);
  }, [localStorageAuthStatus]);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleLogout = async e => {
    await logout();
    localStorage.clear();
    setIsAuthenticated(false);
    window.location.href = '/';
  };

  const {
    withBottomBorder = true,
    searchLocation,
    updateSearchLocation,
    displayOnMobile = false,
    location
  } = props;

  if (
    location.pathname === '/' ||
    (location.pathname.includes('search') && !updateSearchLocation) ||
    location.pathname.includes('find-parking-space' && !updateSearchLocation) ||
    location.pathname === '/index.html' ||
    location.pathname === '/contact-us' ||
    location.pathname === '/privacy-policy' ||
    location.pathname === '/terms-and-conditions' ||
    location.pathname === '/sitemap' ||
    location.pathname.includes('/vehicles-info')
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <Wrapper displayOnMobile={displayOnMobile}>
        {!isMobileNavOpen && (
          <MenuIcon
            src={`${ImgixUrl}/web-image-assets/icons/menu.svg`}
            alt="menu"
            onClick={() => {
              setMobileNavOpen(true);
            }}
          />
        )}
        {/* {isMobileNavOpen && (
          <MenuIcon
            src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
            alt="close"
            onClick={() => {
              setMobileNavOpen(false);
            }}
          />
        )} */}
        <Logo
          src={`${ImgixUrl}/web-image-assets/branding/logoMarkNavy.svg`}
          alt="logo"
          onClick={() => (window.location.href = '/')}
        />
        <SearchBarHeader
          placeholder="Where are you parking?"
          address={searchLocation}
          updateSearchLocation={updateSearchLocation}
          displayOnMobile={displayOnMobile}
        />
        <NavItems>
          <NavItem
            onClick={() => {
              loadGoogleMaps();
              handleShowModal('ExpressInterest');
            }}
          >
            Express interest
          </NavItem>
          <NavItem>
            <Link to="/admin/my-listings">
              <span>List your space</span>
            </Link>
          </NavItem>
          {!isAuthenticated ? (
            <NavItem onClick={() => handleShowModal('Login')}>Log in</NavItem>
          ) : (
            <NavItem>
              <Link to="/admin/bookings/my-bookings">Bookings</Link>
            </NavItem>
          )}
          {!isAuthenticated ? (
            <NavItem onClick={() => handleShowModal('Signup')}>Sign up</NavItem>
          ) : (
            <ProfileDropdown
              setIsAuthenticated={setIsAuthenticated}
              handleLogout={handleLogout}
            />
          )}
        </NavItems>
      </Wrapper>
      {withBottomBorder && <Breakline />}
      <MobileNav
        isMobileNavOpen={isMobileNavOpen}
        isAuthenticated={isAuthenticated}
        handleShowModal={handleShowModal}
        handleLogout={handleLogout}
        hideMobileNav={() => {
          setMobileNavOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(Header);
