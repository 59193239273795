import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  REQUEST_BOOKING,
  GET_RENTER_PARKING_PRICE_OPTIONS
} from '../../../common/graphql/bookingSchema';
import {
  RubikSemiBold,
  midBreakPoint,
  //greyLight,
  graphite
} from '../../../_shared/layout-constants';
import { UserContext } from '../../../common/context/user';
import BookingStepWrapper from '../_shared/BookingStepWrapper';
import BookingLength from '../../../_shared/components/BookingLength';
import InfoTip from '../../../_shared/components/InfoTip';
import { VerifyListingModal } from '../../../common/modal';
import Summary from '../_shared/Summary';

const Title = styled.span`
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.1px;
  color: ${graphite};
  @media (min-width: ${midBreakPoint}px) {
    height: 24px;
    font-weight: ${RubikSemiBold};
    font-size: 14px;
    color: ${graphite};
  }
`;

const PricingOption = styled.div``;

const PriceOptionTerms = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 17px;
  padding: 0 0 0 12px;
`;

const PriceOptionTerm = styled.div`
  width: 100%;
`;

const PriceOptionPrice = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: ${RubikSemiBold};
  @media (min-width: ${midBreakPoint}px) {
    font-size: 24px;
  }
`;

const PriceOptionUnit = styled.div`
  font-size: 14px;
  text-align: center;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

//const VerticleLine = styled.div`
//  border-left: 1px solid ${greyLight};
//  height: 28px;
//`;

const Hr = styled.hr`
  margin-bottom: 30px;
`;

// function compare availableFromDate with moment() today,
// if availableFromDate is later than today, use availableFromDate
const availableFromDateAfterToday = availableFromDate => {
  return moment(availableFromDate).isSameOrAfter(moment());
};

const dateFromPreCondition = (availableFromDate, lister) => {
  // e.g. booking available date set by lister is 22nd Jan 2021, the next bookable date will be 24th Jan 2021
  // for quokka management company shared account, listings.management@quokka-tech.com, the bookable date will
  // be 14 days away in order for CS team to match booking
  if (
    lister &&
    (lister.email === 'listings.management@quokka-tech.com' ||
      lister.email === 'quokkatechnology@gmail.com')
  ) {
    return availableFromDateAfterToday(availableFromDate)
      ? moment(availableFromDate)
          .add(12, 'days')
          .format('YYYY-MM-DD[T]HH:mm:ss')
      : moment()
          .add(12, 'days')
          .format('YYYY-MM-DD[T]HH:mm:ss');
  } else {
    return availableFromDateAfterToday(availableFromDate)
      ? moment(availableFromDate).format('YYYY-MM-DD[T]HH:mm:ss')
      : moment().format('YYYY-MM-DD[T]HH:mm:ss');
  }
};

const refineFromDate = (fromDate, availableFromDate, lister) => {
  if (fromDate) {
    return fromDate;
  } else {
    if (
      lister &&
      (lister.email === 'listings.management@quokka-tech.com' ||
        lister.email === 'quokkatechnology@gmail.com')
    ) {
      return availableFromDateAfterToday(availableFromDate)
        ? moment(availableFromDate).add(14, 'days')
        : moment().add(14, 'days');
    } else {
      return availableFromDateAfterToday(availableFromDate)
        ? moment(availableFromDate).add(2, 'days')
        : moment().add(2, 'days');
    }
  }
};

const hasContact = p => {
  return p && p !== null;
};

const processMessage = msg => {
  let m = msg.replace(
    'GraphQL error: user is not authenticated',
    'You are not authenticated, please login to continue booking'
  );
  return m.charAt(0).toUpperCase() + m.slice(1);
};

const StepOne = props => {
  const {
    toggleHandler,
    toggleBookingStep,
    priceOptions,
    showBookingStep,
    loader,
    status,
    filters,
    availableDateFrom,
    bookingOccupiedDates,
    tz,
    duration,
    onDatesSelection,
    isAuthenticated,
    handleShowModal,
    toggleMonthlyOngoing,
    parkingId,
    preview,
    setPreview,
    address,
    lister
  } = props;

  console.log(props);

  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  console.log(duration);

  const fromDate = get(duration, 'fromDate');
  const toDate = get(duration, 'toDate');
  const monthlyOngoing = get(duration, 'monthlyOngoing');
  const deposit =
    priceOptions && priceOptions.find(p => get(p, 'type.short') === 'deposit');
  const depositValue = get(deposit, 'value', 0);
  const depositUnit = get(deposit, 'unit', '$');

  const [previewBooking, { data, error: previewBookingError }] = useMutation(
    REQUEST_BOOKING,
    {
      errorPolicy: 'all'
    }
  );

  const { data: priceOptionsData } = useQuery(
    GET_RENTER_PARKING_PRICE_OPTIONS,
    {
      handleError: false,
      variables: { parkingId }
    }
  );

  const displayPrice = type => {
    const price = get(
      priceOptionsData,
      `renterParkingPriceOptions.${type}`,
      null
    );
    return price ? price.toFixed(2) : '-';
  };

  useEffect(() => {
    if (data) {
      setPreview(get(data, 'requestBooking.bookingRequest.bookings'));
      setLoading(false);
      setError(null);
    }
  }, [data, setPreview]);

  useEffect(() => {
    if (previewBookingError) {
      setLoading(false);
      setError(processMessage(previewBookingError.message));
    }
  }, [previewBookingError]);

  const requestBookingPreview = async context => {
    let array = duration.rangeArray;

    if (context && context.rangeArray) {
      array = context.rangeArray;
    }

    try {
      setLoading(true);
      setError(null);
      await previewBooking({
        variables: {
          preview: true,
          bookings: array,
          parkingId: parkingId,
          isMonthlyBooking: monthlyOngoing
        },
        errorPolicy: 'all'
      });
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const handleNext = async () => {
    if (!isAuthenticated) {
      handleShowModal('Signup');
      return null;
    }
    if (!hasContact(user && user.phoneNumber)) {
      handleShowModal('VerifyListingModal');
      return null;
    }
    if (!preview) {
      await requestBookingPreview();
      return null;
    }
    toggleHandler(2);
  };

  return (
    <BookingStepWrapper
      next={handleNext}
      current={0}
      button={
        !preview
          ? 'Preview booking'
          : status === 'A'
          ? 'Request to book'
          : 'Cannot book inactive listing'
      }
      isLoading={loading}
      error={error}
      displayClose={showBookingStep}
      toggleBookingStep={toggleBookingStep}
      loader={loader}
      disableNextButton={
        (!fromDate || !toDate) && !monthlyOngoing && status !== 'A'
      }
      showExpressInterestButton={true}
      address={address}
    >
      <PricingOption>
        <Title>Price options</Title>
        <PriceOptionTerms>
          {/* <PriceOptionTerm>
            <PriceOptionPrice>{displayPrice('dailyPrice')}</PriceOptionPrice>
            <PriceOptionUnit>per day</PriceOptionUnit>
          </PriceOptionTerm>
          <VerticleLine /> 
          <PriceOptionTerm>
            <PriceOptionPrice>{displayPrice('weeklyPrice')}</PriceOptionPrice>
            <PriceOptionUnit>per week</PriceOptionUnit>
          </PriceOptionTerm>
          <VerticleLine />*/}
          <PriceOptionTerm>
            <PriceOptionPrice>{displayPrice('monthlyPrice')}</PriceOptionPrice>
            <PriceOptionUnit>per month</PriceOptionUnit>
          </PriceOptionTerm>
          <PriceOptionTerm>
            <InfoTip>
              Indicative weekly price is ${displayPrice('weeklyPrice')} per week
            </InfoTip>
          </PriceOptionTerm>
        </PriceOptionTerms>
        <Hr />
      </PricingOption>
      <BookingLength
        fromDate={
          availableDateFrom &&
          lister &&
          refineFromDate(fromDate, availableDateFrom, lister)
        }
        toDate={toDate}
        handleDatesChange={onDatesSelection}
        bookingPreview={requestBookingPreview}
        filters={filters}
        availableDateFrom={
          availableDateFrom &&
          lister &&
          dateFromPreCondition(availableDateFrom, lister)
        }
        bookingOccupiedDates={bookingOccupiedDates}
        tz={tz}
        monthlyOngoing={get(duration, 'monthlyOngoing')}
        toggleMonthlyOngoing={toggleMonthlyOngoing}
        bookingLength={get(duration, 'bookingLength')}
      />
      {preview && <Hr />}
      {preview && (
        <Summary
          bookingLength={get(duration, 'bookingLength')}
          preview={preview}
          monthlyOngoing={monthlyOngoing}
          depositValue={depositValue}
          depositUnit={depositUnit}
        />
      )}
      <VerifyListingModal />
    </BookingStepWrapper>
  );
};

export default StepOne;
