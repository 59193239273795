import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ModifyDetailsModal } from '../../../../../common/modal';
import { ImageUploader } from '../../../../../admin';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import {
  defaultBorder,
  midBreakPoint
} from '../../../../../_shared/layout-constants';
import './DetailsSection.css';

const Wrapper = styled.div`
  width: 100%;
  min-height: 309px;
  display: grid;
  grid-gap: 0;
  padding: 24px 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 51px;
    grid-template-rows: 254px repeat(8, auto) auto;
    padding: 40px 24px 40px 24px;
    border: ${defaultBorder};
  }
`;

const Photos = styled.div`
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 3;
    grid-row: 1;
    height: 255px;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 44px 141px 25px 50px;
  }
`;

const PhotosTitle = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
`;

const PhotoOne = styled.div`
  padding: 12px 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 2;
    padding: 0 27px;
  }
`;

const PhotoTwo = styled.div`
  padding: 12px 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 2;
    padding: 0 27px;
  }
`;

const PhotoThree = styled.div`
  padding: 12px 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 2;
    padding: 0 27px;
  }
`;

const RemoveButtons = styled.div`
  grid-column: 1 / 3;
  grid-row: 3;
`;

const BreaklineWrapper = styled.div`
  grid-column: 1 / 4;
  grid-row: 4;
  margin-top: 33px;
`;

const Title = styled.div`
  grid-column: 1 / 2;
  grid-row: 2;
  padding: 32px 0 24px 0;
`;

const Modify = styled.div`
  cursor: pointer;
  grid-row: 10;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 3;
    grid-row: 2;
    padding: 32px 0 24px 0;
  }
`;

const DescriptionTitle = styled.div`
  grid-column: 1 / 2;
  grid-row: 4;
`;

const Description = styled.div`
  grid-column: 1 / 2;
  grid-row: 5;
  margin-bottom: 24px;
`;

const AccessInstructionTitle = styled.div`
  grid-column: 1 / 2;
  grid-row: 6;
`;

const AccessInstruction = styled.div`
  grid-column: 1 / 2;
  grid-row: 7;
  margin-bottom: 24px;
`;

const KeyDeliveryInstructionTitle = styled.div`
  grid-column: 1 / 2;
  grid-row: 8;
`;

const KeyDeliveryInstruction = styled.div`
  grid-column: 1 / 2;
  grid-row: 9;
  margin-bottom: 24px;
`;

const photoNumbers = status => {
  const resultArr = Object.values(status);
  if (resultArr.length !== 3) return undefined; // to avoid flickering error msg
  const result = status && Object.values(status).filter(v => v === true).length;
  return result;
};

const DetailsSection = props => {
  const [imageStatus, setImageStatus] = useState({});

  useEffect(() => {
    props.change('enoughImageUploaded', photoNumbers(imageStatus));
  }, [imageStatus]); //eslint-disable-line

  const changeUploadStatus = imageIndex => uploadStatus =>
    setImageStatus({
      ...imageStatus,
      [`image${imageIndex}`]: uploadStatus
    });

  const { actions } = useContext(ModalStoreContext);
  const { parkingId, isPublic, changeDetails, invalid } = props;

  const {
    listingTitle,
    description,
    accessInstruction,
    deliveryInstruction
  } = props.values;

  return (
    <Wrapper>
      <ModifyDetailsModal
        changeDetails={changeDetails}
        invalid={invalid}
        values={props.values}
      />

      <Photos>
        <PhotosTitle className="admin-section-subtitle">Photos</PhotosTitle>

        <PhotoOne>
          <ImageUploader
            imgPosition={0}
            parkingId={parkingId}
            isPublic={isPublic}
            changeUploadStatus={changeUploadStatus(1)}
          />
        </PhotoOne>

        <PhotoTwo>
          <ImageUploader
            imgPosition={1}
            parkingId={parkingId}
            isPublic={isPublic}
            changeUploadStatus={changeUploadStatus(2)}
          />
        </PhotoTwo>

        <PhotoThree>
          <ImageUploader
            imgPosition={2}
            parkingId={parkingId}
            isPublic={isPublic}
            changeUploadStatus={changeUploadStatus(3)}
          />
        </PhotoThree>

        <RemoveButtons />

        <BreaklineWrapper>
          <hr />
        </BreaklineWrapper>
      </Photos>

      <Title>
        <div className="admin-section-subtitle">Listing title</div>
        <div className="admin-content-font">{listingTitle}</div>
      </Title>

      <DescriptionTitle>
        <span className="admin-section-subtitle">Listing description</span>
      </DescriptionTitle>

      <Description className="admin-content-font">{description}</Description>

      <AccessInstructionTitle>
        <span className="admin-section-subtitle">Access instruction</span>
      </AccessInstructionTitle>

      <AccessInstruction className="admin-content-font">
        {accessInstruction}
      </AccessInstruction>

      <KeyDeliveryInstructionTitle>
        <span className="admin-section-subtitle">Key delivery instruction</span>
      </KeyDeliveryInstructionTitle>

      <KeyDeliveryInstruction className="admin-content-font">
        {deliveryInstruction}
      </KeyDeliveryInstruction>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyDetails')}
        >
          Modify
        </div>
      </Modify>
    </Wrapper>
  );
};

export default DetailsSection;
