import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import moment from 'moment';
import { reduxForm, Field, getFormValues, getFormSyncErrors } from 'redux-form';
import {
  ReduxInputField,
  PhoneInputField
} from '../../../_shared/components/FormElements';
import compose from 'lodash/flowRight';
import {
  UPDATE_USER_INFORMATION,
  GET_USER_INFO
} from '../../../common/graphql/adminSchema';
import { UserContext } from '../../../common/context/user';
import { ModalStoreContext } from '../../../common/context/modal/store';
import './index.css';
import DateOfBirthField from '../../../_shared/components/DateOfBirthField';
import Modal from '../../../_shared/components/ModalWrapper';

const Name = styled.div`
  width: 100%;
  -ms-display: flexbox;
  display: flex;
`;

const Firstname = styled.span`
  -ms-flex: 1;
  flex: 1;
  width: 45%;
  margin-right: 10px;
`;

const Lastname = styled.span`
  -ms-flex: 1;
  flex: 1;
  width: 45%;
`;

const DateOfBirth = styled.div``;

const PhoneNumber = styled.div``;

const Email = styled.div``;

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Please enter the contact first name';
  } else if (values.firstName === 'Quokka') {
    errors.firstName = 'Sorry, Quokka is a reserved name';
  } else if (values.firstName === 'Kimple') {
    errors.firstName = 'Sorry, Kimple is a reserved name';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter the contact last name';
  } else if (values.lastName === 'Quokka' || values.lastName === 'Technology') {
    errors.firstName = 'Sorry, Quokka is a reserved name';
  }

  if (!values.email) {
    errors.email = 'Please enter the contact email';
  }

  if (!values.year) {
    errors.year = 'Please enter the year';
  }

  if (!values.month) {
    errors.month = 'Please enter the month';
  }

  if (!values.day) {
    errors.day = 'Please enter the day';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Please enter the contact phone number';
  } else if (!isValidPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'Please enter a vaild phone number';
  }

  return errors;
};

const UpdateMyDetailsModal = props => {
  // const [setMobileCodeStatus] = useState(null);
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) return;
    props.initialize({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      day: user.dateOfBirth ? user.dateOfBirth.split('-')[2] : null,
      month: user.dateOfBirth ? user.dateOfBirth.split('-')[1] : null,
      year: user.dateOfBirth ? user.dateOfBirth.split('-')[0] : null,
      phoneNumber: user.phoneNumber
    });
  }, [user]); //eslint-disable-line

  const updateUserInfo = async value => {
    setLoading(true);
    const dob = moment(
      (value.year.value || value.year) +
        '-' +
        (value.month.value || value.month) +
        '-' +
        (value.day.value || value.day)
    ).format('YYYY-MM-DD');
    try {
      await props.updateUserInformation({
        variables: {
          dob: dob,
          firstName: value.firstName,
          lastName: value.lastName,
          contacts: JSON.stringify({
            phoneNumber: value.phoneNumber,
            email: value.email
          })
        }
      });
      setLoading(false);
      setNotification('');
      handleHideModal('UpdatePersonalDetails');
    } catch (err) {
      setLoading(false);
      setNotification('Updated failed. Please try again.');
    }
  };

  const {
    invalid,
    handleSubmit,
    currentValues,
    formSyncErrors,
    change
  } = props;
  const isOpen = activeModal.includes('UpdatePersonalDetails');
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Update your details"
      handleClose={() => handleHideModal('UpdatePersonalDetails')}
      contentLabel="Update Personal Details Modal"
      buttonLabel="Save"
      buttonDisabled={invalid}
      handleSubmit={handleSubmit(updateUserInfo)}
      loading={loading}
      notification={notification}
      isOpen={isOpen}
    >
      <Name>
        <Firstname>
          <Field
            name="firstName"
            label="First name"
            component={ReduxInputField}
          />
        </Firstname>

        <Lastname>
          <Field
            name="lastName"
            label="Last name"
            component={ReduxInputField}
          />
        </Lastname>
      </Name>

      <DateOfBirth>
        <DateOfBirthField />
      </DateOfBirth>

      <PhoneNumber>
        <PhoneInputField
          name="phoneNumber"
          label="Contact number"
          value={currentValues && currentValues.phoneNumber}
          placeholder="Enter phone number"
          error={
            currentValues &&
            currentValues.phoneNumber &&
            formSyncErrors.phoneNumber
          }
          onChange={phone => change('phoneNumber', phone)}
        />
      </PhoneNumber>

      <Email>
        <Field name="email" label="Email" component={ReduxInputField} />
      </Email>
    </Modal>
  );
};

const formOptions = {
  form: 'UpdateMyDetailsModalForm',
  validate
};

export default compose(
  connect(state => ({
    formSyncErrors: getFormSyncErrors('UpdateMyDetailsModalForm')(state),
    currentValues: getFormValues('UpdateMyDetailsModalForm')(state)
  })),
  reduxForm(formOptions),
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  graphql(UPDATE_USER_INFORMATION, { name: 'updateUserInformation' })
)(UpdateMyDetailsModal);
