import React from 'react';
import styled, { css } from 'styled-components';
import {
  red,
  yellow,
  yellowDark,
  graphite,
  teal,
  navy,
  navyDark,
  RubikFontFamily,
  RubikSemiBold,
  defaultBoxShadow,
  greyLight,
  grey
} from '../layout-constants';
import { ImgixUrl } from '../utils';

const sharedStyle = css`
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 3px;
  font-size: 20px;
  text-align: center;
  &:disabled {
    background-color: ${greyLight};
    cursor: not-allowed;
    &:hover {
      background-color: ${grey};
      cursor: not-allowed;
    }
  }
`;

export const PrimaryButton = styled.button`
  ${sharedStyle}
  background-color: ${yellow};
  &:hover,
  &:active {
    background-color: ${yellowDark};
  }
`;

export const SecondaryButton = styled.button`
  ${sharedStyle}
  background-color: ${navy};
  color: #fff;
  &:hover,
  &:active {
    background-color: ${navyDark};
  }
`;

export const AlertButton = styled.button`
  ${sharedStyle}
  background-color: ${red};
  color: #fff;
  &:hover,
  &:active {
    background-color: ${red};
  }
`;

export const BorderButton = styled.button`
  ${sharedStyle}
  border: solid 1px ${graphite};
  background-color: #ffffff;
  &:hover,
  &:active {
    background-color: ${greyLight};
  }
`;

export const TextButton = styled.button`
  font-size: 20px;
  color: ${teal};
  font-family: ${RubikFontFamily};
  padding: 0;
  background: none;
  border: none;
`;

const BackButton = styled.button`
  width: 167px;
  display: flex;
  align-items: center;
  font-size: 20px;
  letter-spacing: -0.2px;
  height: 56px;
  min-height: 56px;
  outline: none !important;
  background-color: #fff;
  padding: 10px 36px;
  border-radius: 3px;
  border: 1px solid ${graphite};
  cursor: pointer;
  border-radius: 28px;
  &:hover,
  &:active {
    background-color: ${greyLight};
  }
`;

const BackButtonIcon = styled.img`
  padding: 0 12px 2px 0;
`;

export const BackButtonWithIcon = ({ handleClick }) => (
  <BackButton type="reset" value="Reset" onClick={handleClick}>
    <BackButtonIcon
      src={`${ImgixUrl}/web-image-assets/icons/arrows_long_left.svg`}
      alt="arrow left"
    />
    Back
  </BackButton>
);

export const NextButton = styled(PrimaryButton)`
  width: 167px;
  height: 56px;
  min-height: 56px;
`;

export const NextRoundButton = styled(NextButton)`
  border-radius: 28px;
`;

const FullWidthButton = styled.button`
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  height: 48px;
  min-height: 48px;
  font-size: 20px;
  text-align: center;
  width: 100%;
`;

export const SmallFullWidthBorderButton = styled(FullWidthButton)`
  font-size: 16px;
  border: 1px solid ${grey};
  height: 37px;
  min-height: 37px;
  color: ${grey};
`;

const fullWidthStyle = css`
  width: 100%;
  height: 48px;
  min-height: 48px;
`;

export const FullWidthPrimaryButton = styled(PrimaryButton)`
  ${fullWidthStyle}
`;

export const FullWidthSecondaryButton = styled(SecondaryButton)`
  ${fullWidthStyle}
`;

export const FullWidthBorderButton = styled(BorderButton)`
  ${fullWidthStyle}
`;

export const FullWidthAlertButton = styled(AlertButton)`
  ${fullWidthStyle}
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
  padding: 8px 30px;
  height: 42px;
  min-height: 42px;
  font-size: 16px;
`;

export const SmallBorderButton = styled(BorderButton)`
  padding: 8px 30px;
  height: 42px;
  min-height: 42px;
  border-radius: 3px;
  font-size: 16px;
`;

export const FullWidthRoundButton = styled(FullWidthPrimaryButton)`
  border-radius: 28px;
`;

export const RoundPrimaryButton = styled(PrimaryButton)`
  padding: ${({ paddingTop }) =>
    paddingTop ? paddingTop + 'px 40px' : '16px 40px'};
  font-size: ${({ fontSize }) => (fontSize ? fontSize + 'px' : '16px')};
  font-weight: ${RubikSemiBold};
  border-radius: 30px;
  width: 100%;
  box-shadow: ${({ boxshadow }) => (boxshadow ? boxshadow : defaultBoxShadow)};
`;

export const RoundSecondaryButton = styled(SecondaryButton)`
  padding: ${({ paddingTop }) =>
    paddingTop ? paddingTop + 'px 40px' : '16px 40px'};
  font-size: ${({ fontSize }) => (fontSize ? fontSize + 'px' : '16px')};
  font-weight: ${RubikSemiBold};
  border-radius: 30px;
  box-shadow: ${({ boxshadow }) => (boxshadow ? boxshadow : defaultBoxShadow)};
  ${fullWidthStyle}
`;
