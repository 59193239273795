import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import { midBreakPoint, greyLight } from '../../_shared/layout-constants';
import { AdminSectionWrapper, AdminSubtitle } from '../_shared/styles';
import PopupAlert from '../../_shared/components/PopupAlert';
import Notification from '../../_shared/components/Notification';
import SpinnerLoader from '../../_shared/components/SpinnerLoader';
import AdminWrapper from '../_shared/components/AdminWrapper';
import ToolTip from '../../_shared/components/Tooltips';
import Transactions from '../components/transactions/Transactions';
import { ModalStoreContext } from '../../common/context/modal/store';
import {
  AddPaymentMethodModal,
  AddPayoutOptionModal
} from '../../common/modal';
import { GET_INVOICE_HISTORY } from '../../common/graphql/bookingSchema';
import {
  GET_CREDIT_CARDS,
  GET_BANK_ACCOUNTS,
  GET_PARKING_SPACES
} from '../../common/graphql/adminSchema';
import PaymentMethod from './components/paymentMethod';
import PayoutOption from './components/payoutOption';
import AddPaymentMethod from './components/addPaymentMethod';
import AddPayoutOption from './components/addPayoutOption';
import RequestRefund from './components/requestRefund';

const PAGINATION_MAX_ITEMS = 100;

const PaymentMethodTitle = styled(AdminSubtitle)`
  margin-bottom: 24px;
`;

const PaymentMethodsSection = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-bottom: 48px;
  grid-row-gap: 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 80px;
    grid-template-rows: min max(195px, auto);
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PayoutOptionTitle = styled(AdminSubtitle)``;

const PayoutOptionsSection = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: minmax(147px, auto);
  margin-bottom: 48px;
`;

const PayoutOptionWrapper = styled(AdminSectionWrapper)`
  height: 148px;
  @media (max-width: ${midBreakPoint}px) {
    height: auto;
    border: 1px dashed ${greyLight};
  }
`;

const TransactionsTitle = styled(AdminSubtitle)`
  margin-bottom: 24px;
`;

const TransactionsSection = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: minmax(500px, auto);
`;

const handleCreateListingAlert = (handleHideModal, modelName) => {
  handleHideModal(modelName);
  window.location.href = '/admin/create-listing';
};

const BillingsPage = ({
  getParkingSpaces,
  getCreditCards,
  getBankAccounts,
  getInvoiceHistory
}) => {
  const [creditCardsError, setCreditCardsErrorMsg] = useState(null);
  const [bankAccountsError, setBankAccountsErrorMsg] = useState(null);
  const {
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  if (getCreditCards.loading || getBankAccounts.loading)
    return <SpinnerLoader />;

  return (
    <AdminWrapper title="Billings">
      <AddPaymentMethodModal updatePaymentMethod={getCreditCards.refetch} />
      <AddPayoutOptionModal
        updatePayoutOption={getBankAccounts.refetch}
        modelDisplay={'redirectToCreateListingAlert'}
        noOfPublishedSpace={get(getParkingSpaces, 'active.length')}
      />
      <PopupAlert
        contentLabel="redirectToCreateListingAlert"
        type="redirectToCreateListingAlert"
        title="Create a listing"
        message="All sorted! Now you are ready to create a new listing!"
        button="Navigate to My Listings"
        handleClick={() =>
          handleCreateListingAlert(
            handleHideModal,
            'redirectToCreateListingAlert'
          )
        }
      />

      <PaymentMethodTitle>Payment methods</PaymentMethodTitle>
      {/* TODO: tooltip copy update */}
      <ToolTip style={{ marginTop: 20, marginBottom: 20 }}>
        Your default payment method or credit card will be used for making the
        payment to parking space owner when you book a parking space.
      </ToolTip>
      {creditCardsError && (
        <Notification
          type="alert1"
          text={creditCardsError}
          hasClose={false}
          style={{ marginTop: 20, marginBottom: 20 }}
        />
      )}
      <PaymentMethodsSection>
        {!!getCreditCards.getCreditCards
          ? getCreditCards.getCreditCards
              .sort((a, b) => {
                const bb =
                  get(b, 'defaultresourceSet[0].creditCard.id') === b.id;
                const aa =
                  get(a, 'defaultresourceSet[0].creditCard.id') === a.id;
                return bb - aa;
              })
              .map((creditCard, key) => (
                <PaymentMethod
                  key={shortid.generate()}
                  creditCardId={creditCard.id}
                  setErrorMsg={setCreditCardsErrorMsg}
                  updatePaymentOption={getCreditCards.refetch}
                  logo={creditCard.cardType}
                  cardNumber={creditCard.cardNumber}
                  defaultStatus={
                    creditCard.defaultresourceSet.length > 0 &&
                    creditCard.defaultresourceSet[0].creditCard
                      ? creditCard.id ===
                        creditCard.defaultresourceSet[0].creditCard.id
                      : false
                  }
                  numberOfCreditCards={getCreditCards.getCreditCards.length}
                />
              ))
          : null}
        <AddPaymentMethod />
      </PaymentMethodsSection>

      <PayoutOptionTitle>Payout options</PayoutOptionTitle>
      {/* TODO: tooltip copy update */}
      <ToolTip style={{ marginTop: 20 }}>
        As a property owner, you will earn income when your listed parking space
        is booked. And your defaut payout option or bank account will receive
        payment.
      </ToolTip>
      {bankAccountsError && (
        <Notification
          type="alert1"
          text={bankAccountsError}
          hasClose={false}
          style={{ marginTop: 20 }}
        />
      )}

      <PayoutOptionsSection>
        {!!getBankAccounts.getBankAccounts
          ? getBankAccounts.getBankAccounts
              .sort((a, b) => {
                const bb =
                  get(b, 'defaultresourceSet[0].bankAccount.id') === b.id;
                const aa =
                  get(a, 'defaultresourceSet[0].bankAccount.id') === a.id;
                return bb - aa;
              })
              .map((bankAccount, key) => (
                <PayoutOptionWrapper key={shortid.generate()}>
                  {bankAccount && (
                    <PayoutOption
                      bankAccountId={bankAccount.id}
                      bsb={bankAccount.bsb}
                      acc={bankAccount.acc}
                      accName={bankAccount.accountName}
                      setErrorMsg={setBankAccountsErrorMsg}
                      updatePayoutOption={getBankAccounts.refetch}
                      defaultStatus={
                        bankAccount.defaultresourceSet.length > 0 &&
                        bankAccount.defaultresourceSet[0].bankAccount
                          ? bankAccount.id ===
                            bankAccount.defaultresourceSet[0].bankAccount.id
                          : false
                      }
                      numberOfBankAccounts={
                        getBankAccounts.getBankAccounts.length
                      }
                    />
                  )}
                </PayoutOptionWrapper>
              ))
          : null}
        <AddPayoutOption />
      </PayoutOptionsSection>

      <RequestRefund />

      {getInvoiceHistory &&
        getInvoiceHistory.getInvoiceHistory &&
        getInvoiceHistory.getInvoiceHistory.invoices &&
        getInvoiceHistory.getInvoiceHistory.invoices.length > 0 && (
          <div>
            <TransactionsTitle>Invoices & Receipts</TransactionsTitle>

            <TransactionsSection>
              <Transactions
                subtitle={null}
                title="List all payments and receipts"
                invoices={
                  getInvoiceHistory &&
                  getInvoiceHistory.getInvoiceHistory &&
                  getInvoiceHistory.getInvoiceHistory.invoices
                }
                maxItems={PAGINATION_MAX_ITEMS}
              />
            </TransactionsSection>
          </div>
        )}
    </AdminWrapper>
  );
};

export default compose(
  graphql(GET_PARKING_SPACES, { name: 'getParkingSpaces' }),
  graphql(GET_CREDIT_CARDS, { name: 'getCreditCards' }),
  graphql(GET_BANK_ACCOUNTS, { name: 'getBankAccounts' }),
  graphql(GET_INVOICE_HISTORY, {
    name: 'getInvoiceHistory',
    handleError: false,
    options: props => ({
      variables: {
        pageFrom: 1,
        pageSize: PAGINATION_MAX_ITEMS
      }
    })
  })
)(BillingsPage);
