import React from 'react';
import styled from 'styled-components';
import { ScaleLoader } from 'react-spinners';
import { navy } from '../layout-constants';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export default () => (
  <Overlay>
    <ScaleLoader sizeUnit={'px'} size={150} color={navy} />
  </Overlay>
);
