import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import Jimp from 'jimp';
import _ from 'lodash';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { greyExtraLight, greyDark } from '../../../../_shared/layout-constants';
import { ImgixUrl, AwsApi, ImgixApiKey } from '../../../../_shared/utils';

export const ACCEPTED_TYPES = ['.jpeg', '.png', '.jpg'];
export const MAX_SIZE = 5242880; // 5MB

const Wrapper = styled.div`
  height: 120px;
  width: 120px;
  margin-top: 26px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
`;

const ProfileImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({ preview }) => (preview ? '100%' : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${greyExtraLight};
`;

const Prview = styled.img`
  height: 100%;
  flex: 1;
  object-fit: cover;
  max-width: 100%;
  border-radius: 100%;
  cursor: pointer;
`;

const Placeholder = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

const Remove = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const Uploading = styled.div`
  color: ${greyDark};
`;

// var ReactDOMServer = require('react-dom/server');

const ImageUploader = ({ userId, setErrorMsg }) => {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // fetch from listing, only triggered after page load
    if (!load) {
      setLoading(true);
      axios
        .get(
          `${AwsApi}/list-images?userId=${userId}&resourceId=profile&directory=public`
        )
        .then(async res => {
          let data = res.data.body;
          if (data && !_.isEmpty(data.imagesPath)) {
            let imagePath = data.imagesPath[0];
            let base64String = imagePath && imagePath['base64String'];
            if (!base64String) return; // if empty initially, return
            setPreview(`data:image/png;base64,${base64String}`);
          }
          setLoading(false);
        })
        .catch(function(error) {
          // handle error
          setLoading(false);
        });
    }
    setLoad(true);

    URL.revokeObjectURL(preview);
  }, [preview]); // eslint-disable-line

  const handleDelete = async () => {
    try {
      await axios.delete(`${AwsApi}/delete-image`, {
        data: {
          userId: userId,
          resourceId: 'profile',
          directory: 'public',
          filename: 'profile',
          fileExtension: '.png'
        }
      });
      await axios.post(
        'https://api.imgix.com/v2/image/purger',
        {
          url: `${ImgixUrl}/${userId}/profile/profile.png`
        },
        {
          auth: {
            username: ImgixApiKey,
            password: ''
          }
        }
      );
      setPreview(null);
      setProgress(0);
      setErrorMsg(null);
    } catch (e) {
      setErrorMsg('Photo deleting failed. Please try again.');
    }
  };

  const onDropRejected = () => {
    alert('Maximum file upload size is 5MB'); // TODO: replace it with our alert component
  };

  const onDrop = useCallback(
    acceptedFiles => {
      if (!acceptedFiles || acceptedFiles.length === 0) return;
      //console.log(acceptedFiles[0]); // you should have a look at the format, not simply assume it's a binary file

      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async readerEvt => {
        try {
          const bufferString = readerEvt.target.result;
          const jimpFile = await Jimp.read(bufferString);
          const base64PngDataURI = await jimpFile.getBase64Async(Jimp.MIME_PNG);
          // Very important!: generate VALID base64String for img; otherwise potentially lead to CORS issue
          const base64String = base64PngDataURI.slice(22); // convert data URI to base64string
          setProgress(1);
          await axios.post(
            'https://api.imgix.com/v2/image/purger',
            {
              url: `${ImgixUrl}/${userId}/profile/profile.png`
            },
            {
              auth: {
                username: ImgixApiKey,
                password: ''
              }
            }
          );
          await axios.post(
            `${AwsApi}/create-update-images`,
            {
              userId: userId, // define valid logged in user id (not user token!); note replace placeholder userId 1 to the real userId
              resourceId: 'profile',
              directory: 'public',
              filename: 'profile',
              fileExtension: '.png', // ext starts with a dot, you should double check what you've uploaded/created in s3
              base64String: base64String
            },
            {
              onUploadProgress: progressEvent => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              }
            }
          );
          setProgress(0);
          setPreview(URL.createObjectURL(acceptedFiles[0]));
        } catch (e) {
          setErrorMsg('Photo uploading failed. Please try again.');
        }
      };

      reader.readAsArrayBuffer(acceptedFiles[0]);
    },
    [] // eslint-disable-line
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: MAX_SIZE,
    accept: ACCEPTED_TYPES
  });

  return (
    <Wrapper>
      <ProfileImage
        preview={preview}
        isDragActive={isDragActive}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {loading ? (
          <BeatLoader size={15} margin={'2px'} color={greyDark} />
        ) : progress > 0 ? (
          <Uploading>{progress}%</Uploading>
        ) : preview ? (
          <Prview src={preview} alt="profile-pic" />
        ) : (
          <Placeholder
            src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
            alt="upload"
          />
        )}
      </ProfileImage>
      {preview && (
        <Remove
          src={`${ImgixUrl}/web-image-assets/icons/Delete.svg`}
          alt="remove"
          onClick={handleDelete}
        />
      )}
    </Wrapper>
  );
};

export default ImageUploader;
