import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import {
  DELETE_VEHICLE,
  SET_DEFAULT_RESOURCE
} from '../../../../common/graphql/adminSchema';
import {
  teal,
  greyLight,
  midBreakPoint,
  greyDark,
  spacing
} from '../../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-rows: 40px 40px 20px;
  grid-template-columns: 296px 1fr 100px;
  @media (max-width: ${midBreakPoint}px) {
    grid-template-rows: 44px 44px 52px 1fr;
    grid-template-columns: 1fr 1fr;
    border: solid 1px ${greyLight};
    padding: ${spacing(4)} ${spacing(3)};
  }
`;

const Make = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

const Status = styled.div`
  grid-row: 1;
  text-align: right;
  grid-column: 2;
  color: ${greyDark};
  @media (min-width: ${midBreakPoint}px) {
    width: 100px;
    grid-column: 3;
  }
`;

const RegistrationNumber = styled.div`
  grid-column: 1 / 3;
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
  }
`;

const RegistrationState = styled.div`
  grid-column: ;
  grid-row: 2;
  @media (max-width: ${midBreakPoint}px) {
    grid-row: 3;
    grid-column: 1 / 3;
  }
`;

const Default = styled.div`
  grid-column: 1;
  grid-row: 3;
  @media (max-width: ${midBreakPoint}px) {
    grid-row: 4;
  }
`;

const MakeDefault = styled.a`
  grid-column: 1;
  grid-row: 3;
  color: ${teal};
  cursor: pointer;
  @media (max-width: ${midBreakPoint}px) {
    grid-row: 4;
  }
`;

const RemoveButton = styled.div`
  grid-row: 3;
  grid-column: 3;
  text-align: right;
  cursor: pointer;
  @media (max-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 4;
  }
`;

class MyCar extends Component {
  deleteVehicle = async () => {
    this.props.setLoading(true);
    try {
      await this.props.deleteVehicle({
        variables: {
          id: this.props.vehicleId
        }
      });
      this.props.setLoading(false);
      this.props.setErrorMsg(null);
      this.props.updateVehicles && this.props.updateVehicles();
    } catch (e) {
      this.props.setLoading(false);
      this.props.setErrorMsg(
        'Failed to delete this vehicle. Please try again.'
      );
    }
  };

  makeVehicleDefault = async () => {
    this.props.setLoading(true);
    try {
      await this.props.setDefaultResource({
        variables: {
          id: this.props.vehicleId,
          type: 'VEHICLE'
        }
      });
      this.props.setLoading(false);
      this.props.setErrorMsg(null);
      this.props.updateVehicles && this.props.updateVehicles();
    } catch (e) {
      this.props.setLoading(false);
      this.props.setErrorMsg(
        'Failed to make this vehicle default. Please try again.'
      );
    }
  };

  render() {
    const {
      displayName,
      status,
      registrationNumber,
      state,
      defaultStatus
    } = this.props;
    return (
      <Wrapper>
        <Make className="admin-section-subtitle">{displayName}</Make>

        <Status>{status}</Status>

        <RegistrationNumber>
          <span className="admin-section-subtitle">Registration number:</span>
          <span className="admin-content-font">{registrationNumber}</span>
        </RegistrationNumber>

        <RegistrationState>
          <span className="admin-section-subtitle">Registration state:</span>
          <span className="admin-content-font">{state}</span>
        </RegistrationState>

        {defaultStatus ? (
          <Default>Default</Default>
        ) : (
          <MakeDefault onClick={this.makeVehicleDefault}>
            Make default
          </MakeDefault>
        )}

        <RemoveButton>
          <div
            className="admin-modify-button"
            onClick={this.deleteVehicle.bind(this)}
          >
            Remove
          </div>
        </RemoveButton>
      </Wrapper>
    );
  }
}

export default compose(
  graphql(DELETE_VEHICLE, { name: 'deleteVehicle' }),
  graphql(SET_DEFAULT_RESOURCE, { name: 'setDefaultResource' })
)(MyCar);
