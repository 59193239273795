import { decryptEnvVar, envVar } from './decryptEnvVar';

export const loadGoogleMaps = callback => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${decryptEnvVar(
      envVar('REACT_APP_GOOGLE_MAP_API_KEY')
    )}&libraries=places`;
    script.id = 'googleMaps';
    document.getElementsByTagName('head')[0].appendChild(script); // append to head
    if (script.readyState) {
      script.onreadystatechange = function() {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => {
        // setTimeout(() => {
        if (callback) callback();
        // }, 5000)
      };
    }
  }
};
