import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { PrimaryButton, TextButton } from '../../../_shared/components/Buttons';
import ChatRoom from '../../../admin/dashboard/components/chatRoom/ChatRoom';
import { midBreakPoint } from '../../../_shared/layout-constants';

const ButtonSection = styled.div`
  display: flex;
  height: 36px;
  margin-bottom: 12px;
`;

export const FirstButton = styled(PrimaryButton)`
  height: 36px;
  padding: 5px 12px;
  margin-right: 24px;
  margin-bottom: 8px;
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

export const SecondaryButton = styled(TextButton)`
  height: 20px;
  margin: 8px 0;
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

const MessageButtons = props => {
  const { approveText, denyText, person } = props;
  const { actions } = useContext(ModalStoreContext);

  return (
    <div>
      <ChatRoom person={person} />

      <ButtonSection>
        {approveText === 'Reply' ? (
          <FirstButton onClick={() => actions.handleShowModal('ChatRoom')}>
            {approveText}
          </FirstButton>
        ) : (
          <FirstButton>{approveText}</FirstButton>
        )}
        {denyText ? <SecondaryButton>{denyText}</SecondaryButton> : null}
      </ButtonSection>
    </div>
  );
};

export default MessageButtons;
