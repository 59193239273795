import React from 'react';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import ListView from './_listView';

const ManageBookingsPage = () => {
  return (
    <AdminWrapper title="Manage bookings" subtitle="Manager">
      <ListView />
    </AdminWrapper>
  );
};

export default ManageBookingsPage;
