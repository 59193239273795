import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  getFormValues
  //Field
} from 'redux-form';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { SingleDatePicker } from 'react-dates';
// import {
//   ReduxCheckbox,
//   Checkboxes
// } from '../../../_shared/components/CheckBoxes';
// import { ReduxCheckBoxComponent } from '../../../_shared/components/FormElements';
import ToolTip from '../../../_shared/components/Tooltips';
// import { DAYS_MAPPING } from '../../../_shared/constants';
import './Availability.css';

const FromDate = styled.div`
  margin-bottom: 60px;
`;

class Availability extends Component {
  state = {
    focused: false
  };

  onDateChange = date => {
    this.props.updateFormValue({
      availableDateFrom: date.toISOString(),
      page: 'Availability'
    });
    this.props.change('availableDateFrom', date);
    this.updateLocalData({ availableDateFrom: date });
  };

  componentDidMount() {
    this.updateField = debounce(e => {
      const obj = {};
      obj[e.target.name] = e.target.value;
      this.updateLocalData(obj);
    }, 300);

    this.updateFieldRaw = debounce(e => {
      const obj = {};
      obj[e.key] = e.value;
      this.updateLocalData(obj);
    }, 300);
  }

  updateLocalData(obj) {
    if (!!this.props.draftId) {
      this.props.localData.addItem(this.props.draftId, obj);
    }
  }

  render() {
    const { availableDateFrom = moment().add(2, 'days') } = this.props.values; //TODO: remote add 2 days
    // const isDayChecked = isDayCheckedInArray(daysAvailable);
    // const onWeekdaysCheck = this.onWeekdaysCheckInArr(daysAvailable);
    return (
      <div>
        {/* TODO: tooltip copy update */}
        <ToolTip style={{ marginBottom: 21 }}>
          Note: Quokka Parking offers long-term parking service, therefore the
          listings will be made available between Monday to Sunday.
        </ToolTip>
        <FromDate>
          <span className="create-listing-subtitle">
            This space will be available from
          </span>
          <div className="create-listing-from-date">
            <SingleDatePicker
              id="create-listing-from-date"
              date={availableDateFrom}
              onDateChange={this.onDateChange}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              numberOfMonths={1}
              enableOutsideDays
              displayFormat="DD/MM/YYYY"
              showDefaultInputIcon
            />
          </div>
        </FromDate>
        {/* <div>
          <span>
            If your space is only available on certain days, select the
            weekday(s) that you’d like to take bookings.
          </span>
          <div style={{ marginTop: 24 }}>
            <Field
              disabled={true}
              name="daysAvailable"
              itemComponent={ReduxCheckBoxComponent}
              component={ReduxCheckbox(Checkboxes)}
              data={DAYS_MAPPING}
              textField="name"
              valueField="id"
              onChange={e => {
                this.updateFieldRaw({ key: 'daysAvailable', value: e });
              }}
            />
          </div>
        </div> */}
      </div>
    );
  }
}

export default connect(state => ({
  values: getFormValues('createListingForm')(state)
}))(Availability);
