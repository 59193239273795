import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//
import { red } from '../layout-constants';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ErrorMsg = styled.span`
  color: ${red};
  margin-top: 8px;
`;

export const ReduxCheckbox = Component => ({ input, meta, ...props }) => {
  // console.log(input, meta)
  return (
    <React.Fragment>
      <Component {...{ ...input, ...props }} />
      {meta.touched && meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </React.Fragment>
  );
};

export class Checkboxes extends React.Component {
  item = ({ input, label }) => (
    <label>
      <input type="checkbox" {...input} />
      <span>{label}</span>
    </label>
  );

  handleChange = ({ option }) => event => {
    const { value = [], onChange } = this.props;
    let arr = [...value];
    if (event.target.checked) {
      arr.push(option.value);
    } else {
      arr = arr.filter(a => a.id !== option.value.id);
    }

    if (onChange) {
      return onChange(arr);
    }
  };

  getLabelAndValue(option) {
    const { textField, valueField, valueField2 } = this.props;

    if (typeof option === 'string') {
      return { label: option, value: option };
    }

    return {
      label: option[textField],
      value: {
        [valueField]: option[valueField],
        [valueField2]: option[valueField2]
      }
    };
  }

  render() {
    const {
      itemComponent = this.item,
      data = [],
      value: values,
      pathType
    } = this.props;
    const checkboxes = data.map((option, index) => {
      const { label, value } = (option = this.getLabelAndValue(option));
      const key = `checkbox-${index}`;

      return React.createElement(itemComponent, {
        input: {
          onChange: this.handleChange({ option }),
          checked: values && values.some(e => e.id === value.id),
          value
        },
        label,
        option,
        data,
        key,
        pathType
      });
    });

    return <Wrapper>{checkboxes}</Wrapper>;
  }
}

Checkboxes.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.node.isRequired
      })
    ),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  itemComponent: PropTypes.func,
  textField: PropTypes.string,
  valueField: PropTypes.string,
  valueField2: PropTypes.string
};

Checkboxes.defaultProps = {
  textField: 'label',
  valueField: 'value'
};
