import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/react-hoc';
import get from 'lodash/get';
import PopupAlert from '../../../../../_shared/components/PopupAlert';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import {
  RubikSemiBold,
  teal,
  midBreakPoint
} from '../../../../../_shared/layout-constants';
import {
  REQUEST_REIMBURSEMENT_BOND,
  ALLOW_TO_REQUEST_REIMBURSEMENT
} from '../../../../../common/graphql/adminSchema';
import Notification from '../../../../../_shared/components/Notification';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 48px auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-rows: 20px 24px auto;
    grid-template-columns: 1fr auto;
    padding: 24px;
  }
`;

const Title = styled.div`
  grid-column: 1;
  grid-row: 1;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
`;

// const ModifyBooking = styled.a`
//   color: ${teal};
//   @media (min-width: ${midBreakPoint}px) {
//     grid-column: 1;
//     grid-row: 3;
//   }
// `;

const Request = styled.a`
  color: ${teal};
  margin-top: 24px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 4;
  }
`;

const NotificationWrapper = styled(Notification)`
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 /3;
    grid-row: 3;
  }
`;

const Reimbursement = props => {
  const {
    actions: { handleShowModal, handleHideModal }
  } = useContext(ModalStoreContext);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(null);

  const requestReimbursement = async () => {
    const { data, errors } = await props.requestReimbursement({
      errorPolicy: 'all'
    });
    if (errors) {
      setError(errors[0].message);
      return;
    }
    if (get(data, 'requestReimbursementBond.refundBondAmount')) {
      setAmount(get(data, 'requestReimbursementBond.refundBondAmount'));
    }
    setError(null);
    handleHideModal('requestReimbursementAlert');
  };

  const handleShowRequestReimbursementAlert = () => {
    handleShowModal('requestReimbursementAlert');
  };

  return (
    <Wrapper>
      <Title>Reimbursement</Title>
      {!get(
        props.allowToRequestReimbursement,
        'allowToRequestReimbursement.allowReimbursement'
      ) && (
        <NotificationWrapper
          text={get(
            props.allowToRequestReimbursement,
            'allowToRequestReimbursement.message'
          )}
          type="neutral"
          hasClose={false}
        />
      )}
      {error && (
        <NotificationWrapper text={error} type="alert1" hasClose="true" />
      )}
      {amount && (
        <NotificationWrapper
          text={`You are qualified to get $${amount} reimbursement. We've sent a email to your inbox, please confirm.`} //TODO: check copy and why this is known figure
          type="success"
          hasClose="true"
        />
      )}
      {get(
        props.allowToRequestReimbursement,
        'allowToRequestReimbursement.allowReimbursement'
      ) && (
        <Request onClick={handleShowRequestReimbursementAlert}>Request</Request>
      )}
      <PopupAlert
        isAlert
        contentLabel="requestReimbursementAlert"
        type="requestReimbursementAlert"
        title="Request reimbursement"
        message="Are you sure to request a reimbursement?"
        button="Continue"
        secondaryButton="Cancel"
        disableClose={false}
        handleClick={requestReimbursement}
      />
    </Wrapper>
  );
};

export default compose(
  graphql(REQUEST_REIMBURSEMENT_BOND, {
    name: 'requestReimbursement',
    options: props => ({ variables: { bookingTransactionId: props.bookingId } })
  }),
  graphql(ALLOW_TO_REQUEST_REIMBURSEMENT, {
    name: 'allowToRequestReimbursement',
    options: props => ({ variables: { bookingTransactionId: props.bookingId } })
  })
)(Reimbursement);
