import React, { Fragment } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { midBreakPoint, navy } from '../../../_shared/layout-constants';
import { ListingTicket } from '../../../admin';
import AddListingButton from '../components/addListingButton/AddListingButton';
// import { ImgixUrl } from '../../../_shared/utils';
import { AdminSubtitle } from '../../_shared/styles';

const CardSections = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin-bottom: 40px;
  margin-top: 32px;
  padding-bottom: 20px;
  @media (min-width: ${midBreakPoint}px) {
    padding-bottom: 0px;
    grid-column: 1;
    grid-row: 3;
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(442px, auto);
    grid-row-gap: 48px;
  }
`;

const ListingCount = styled.span`
  color: ${navy};
  font-size: 20px;
  font-weight: normal;
  margin-right: 4px;
`;

// const Illustration = styled.img`
//   width: 200px;
//   margin-top: 45px;
//   @media (min-width: ${midBreakPoint}px) {
//     width: 300px;
//   }
// `;

// const IllustrationText = styled.div`
//   margin-top: 30px;
//   font-size: 10px;
//   color: ${greyDark};

//   @media (min-width: ${midBreakPoint}px) {
//     font-size: 20;
//   }
// `;

// const EmptyState = ({ text }) => (
//   <div
//     style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
//   >
//     <Illustration
//       src={`${ImgixUrl}/web-image-assets/illustration/list-is-empty.png`}
//       alt="empty"
//     />
//     <IllustrationText>{text}</IllustrationText>
//   </div>
// );

const Grid = ({
  getParkingSpaces,
  hasPhoneNumber,
  hasPaymentMethod,
  hasPayoutOption
}) => {
  return (
    <div>
      <AdminSubtitle>
        <ListingCount>
          {getParkingSpaces.drafts &&
            getParkingSpaces.drafts.length > 0 &&
            getParkingSpaces.drafts.length}
        </ListingCount>{' '}
        Drafts
      </AdminSubtitle>
      <CardSections>
        <AddListingButton
          hasPhoneNumber={hasPhoneNumber}
          hasPaymentMethod={hasPaymentMethod}
          hasPayoutOption={hasPayoutOption}
        />
        {getParkingSpaces.drafts &&
          getParkingSpaces.drafts.length !== 0 &&
          getParkingSpaces.drafts.map(space => (
            <ListingTicket
              id={space.id}
              key={shortid.generate()}
              status="draft"
              title={space.title}
              parkingType={
                space.parkingTypes[0] ? space.parkingTypes[0].name : null
              }
              address={space.address}
              parkingLot={space.parkingLotNumber}
              redirectToPDP={false}
            />
          ))}
      </CardSections>
      {getParkingSpaces.active && getParkingSpaces.active.length !== 0 && (
        <Fragment>
          <AdminSubtitle>
            <ListingCount>{getParkingSpaces.active.length}</ListingCount>{' '}
            Published listings
          </AdminSubtitle>
          <CardSections>
            {getParkingSpaces.active.map(space => (
              <ListingTicket
                id={space.id}
                key={shortid.generate()}
                status="live"
                title={space.title}
                parkingType={
                  space.parkingTypes[0] ? space.parkingTypes[0].name : null
                }
                address={space.address.value}
                parkingLot={space.lot || space.parkingLotNumber}
                redirectToPDP={false}
              />
            ))}
          </CardSections>
        </Fragment>
      )}
      {getParkingSpaces.inactive && getParkingSpaces.inactive.length !== 0 && (
        <Fragment>
          <AdminSubtitle>
            <ListingCount>{getParkingSpaces.inactive.length}</ListingCount>{' '}
            History listings
          </AdminSubtitle>
          <CardSections>
            {getParkingSpaces.inactive.map(space => (
              <ListingTicket
                id={space.id}
                key={shortid.generate()}
                status="off"
                title={space.title}
                parkingType={
                  space.parkingTypes[0] ? space.parkingTypes[0].name : null
                }
                address={space.address.value}
                parkingLot={space.lot}
                redirectToPDP={false}
              />
            ))}
          </CardSections>
        </Fragment>
      )}
    </div>
  );
};

export default Grid;
