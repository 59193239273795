import React, { Component } from 'react';
import styled from 'styled-components';
import {
  graphite,
  RubikFontFamily,
  RubikSemiBold
} from '../../../_shared/layout-constants';

const Wrapper = styled.div`
  height: 20px;
  font-family: ${RubikFontFamily};
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: ${graphite};
  margin-bottom: 24px;
`;

export default class Subtitle extends Component {
  render() {
    const { title } = this.props;

    return <Wrapper>{title}</Wrapper>;
  }
}
