import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { Field } from 'redux-form';
import {
  ReduxInputField,
  ReduxToggleButtonGroup
} from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';

const ContactName = styled.div``;

const ContactNumber = styled.div``;

const Email = styled.div``;

const SelfDescription = styled.div``;

const ModifyContactModal = props => {
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const isOpen = activeModal.includes('ModifyContact');

  const {
    //redux-form props
    invalid
  } = props;

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Modify contact details"
      handleClose={() => handleHideModal('ModifyContact')}
      contentLabel="Modify Contact Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      handleSubmit={() => {
        if (invalid) return;
        props.changeContact({ isReview: true, ...props.values });
        handleHideModal('ModifyContact');
      }}
      buttonDisabled={invalid}
    >
      <ContactName>
        <Field name="contactName" label="Name" component={ReduxInputField} />
      </ContactName>

      <ContactNumber>
        <Field
          name="contactNumber"
          label="Contact number"
          component={ReduxInputField}
        />
      </ContactNumber>

      <Email>
        <Field name="email" label="Email" component={ReduxInputField} />
      </Email>

      <SelfDescription>
        <Field
          name="contactType"
          label="You..."
          options={[
            {
              label: 'Own this place',
              value: 'Owner'
            },
            {
              label: 'Manage this place',
              value: 'Manager'
            }
          ]}
          component={ReduxToggleButtonGroup}
        />
      </SelfDescription>
    </Modal>
  );
};

export default ModifyContactModal;
