import React from 'react';
import shortid from 'shortid';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactStreetview from 'react-streetview';
import styled from 'styled-components';
import { GMKEY } from '../../../_shared/layout-constants';

const ArrowRight = styled.div`
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 15%;
  width: 12px;
  height: 12px;
  background: transparent;
  border-top: 2px solid white;
  border-right: 2px solid white;
  box-shadow: 0 0 0 lightgray;
  transition: all 200ms ease;
  transform: translate3d(0, -50%, 0) rotate(45deg);

  @media (min-width: 1127px) {
    width: 20px;
    height: 20px;
  }
`;

const ArrowLeft = styled.div`
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 40%;
  width: 12px;
  height: 12px;
  background: transparent;
  border-top: 2px solid white;
  border-right: 2px solid white;
  box-shadow: 0 0 0 lightgray;
  transition: all 200ms ease;
  transform: translate3d(0, -50%, 0) rotate(-135deg);

  @media (min-width: 1127px) {
    width: 20px;
    height: 20px;
  }
`;

const ArrowBackgroundRight = styled(ButtonNext)`
  position: absolute;
  top: ${({ arrowposition }) => arrowposition};
  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.1s;
  background: #333f48b3;
  border-radius: 3px;
  z-index: 2;
  height: 40px;
  width: 22px;
  opacity: 1;
  color: #fff;
  right: 14px;

  @media (min-width: 1127px) {
    height: 70px;
    width: 40px;
    right: -20px;
  }
`;

const ArrowBackgroundLeft = styled(ButtonBack)`
  position: absolute;
  top: ${({ arrowposition }) => arrowposition};
  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.1s;
  background: #333f48b3;
  border-radius: 3px;
  z-index: 2;
  height: 40px;
  width: 22px;
  opacity: 1;
  color: #fff;
  left: 34px;

  @media (min-width: 1127px) {
    height: 70px;
    width: 40px;
    left: 20px;
  }
`;

const StyledSlider = styled(Slider)`
  height: ${props => props.dimension.height};
  width: ${props => props.dimension.width};
`;

// refer to https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
const streetViewPanoramaOptions = (lat, lng) => ({
  position: { lat: lat, lng: lng },
  pov: { heading: 100, pitch: 0 },
  zoom: 1
});

const Gallery = ({
  items,
  lat,
  lon,
  naturalSlideWidth = 100,
  naturalSlideHeight = 56,
  totalSlides,
  showMap,
  arrowPosition = '50%',
  galleryHeight,
  galleryWidth = '100%'
}) => (
  <CarouselProvider
    naturalSlideWidth={naturalSlideWidth}
    naturalSlideHeight={naturalSlideHeight}
    totalSlides={totalSlides}
    dragEnabled={false}
  >
    <ArrowBackgroundLeft arrowposition={arrowPosition}>
      <ArrowLeft />
    </ArrowBackgroundLeft>
    <StyledSlider
      dimension={{
        height: galleryHeight ? galleryHeight : '100%',
        width: galleryWidth
      }}
    >
      {items.map(item => (
        <Slide key={shortid.generate()}>{item}</Slide>
      ))}
      {showMap && (
        <Slide>
          <ReactStreetview
            apiKey={GMKEY}
            streetViewPanoramaOptions={streetViewPanoramaOptions(lat, lon)}
          />
        </Slide>
      )}
    </StyledSlider>
    <ArrowBackgroundRight arrowposition={arrowPosition}>
      <ArrowRight />
    </ArrowBackgroundRight>
  </CarouselProvider>
);

export default Gallery;
