import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import { reduxForm, Field } from 'redux-form';
import compose from 'lodash/flowRight';
import { ModalStoreContext } from '../../../common/context/modal/store';
import {
  GET_USER_INFO,
  ADD_BANK_ACCOUNT
} from '../../../common/graphql/adminSchema';
import Modal from '../../../_shared/components/ModalWrapper';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import { panSecretEncrypt } from '../../../_shared/security';

const Accountholder = styled.div``;

const BSB = styled.div`
  margin-right: 30px;
`;

const AccountNumber = styled.div``;

const AccountInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const validate = values => {
  const errors = {};
  if (!values.accountName) {
    errors.accountName = "Please enter account holder's name";
  }
  if (!values.bsb) {
    errors.bsb = 'Please enter the BSB';
  } else if (!values.bsb.match(/[0-9]{6}/)) {
    errors.bsb = 'Please enter a vaild BSB number';
  }
  if (!values.acc) {
    errors.acc = 'Please enter the Account';
  } else if (!values.acc.match(/^[0-9]*$/)) {
    errors.acc = 'please enter a vaild account number';
  }
  return errors;
};

const AddPayoutOptionModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    state: { activeModal },
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);

  const addBankAccount = async value => {
    setLoading(true);

    let payload = {
      acc: value.acc,
      bsb: value.bsb,
      accountName: value.accountName
    };
    let userInfo = props.getUserInfo;
    let filename = await panSecretEncrypt(
      userInfo && userInfo.getUserInfo.id,
      payload,
      'bank account'
    );

    //console.log('@@@ filename @@@', filename);
    const { errors } = await props.addBankAccount({
      errorPolicy: 'all',
      variables: {
        fileName: filename
      }
    });

    if (errors) {
      setLoading(false);
      setError(errors[0].message);
      return;
    }

    if (props.updatePayoutOption) {
      //console.log('@@@', result);
      props.updatePayoutOption();
    }

    setLoading(false);
    setError(null);
    handleHideModal('AddPayoutOption');

    if (props.modelDisplay && props.noOfPublishedSpace === 0) {
      handleShowModal(props.modelDisplay);
    }
  };

  const isOpen = activeModal.includes('AddPayoutOption');
  const { handleSubmit, invalid } = props;

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Add a payout option"
      handleClose={() => handleHideModal('AddPayoutOption')}
      handleSubmit={handleSubmit(addBankAccount)}
      contentLabel="Add Payout Option"
      buttonDisabled={invalid}
      buttonLabel="Save"
      loading={loading}
      isOpen={isOpen}
      notification={error}
      modalDescription="Dear customer, you provide a payout option in order for Quokka Technology to pay you. Please ensure your payout option is correct."
      showIcon={true}
    >
      <Accountholder>
        <Field
          name="accountName"
          label="Account holder"
          component={ReduxInputField}
        />
      </Accountholder>
      <AccountInfoWrapper>
        <BSB>
          <Field
            name="bsb"
            label="BSB"
            maxLength={6}
            pattern="\d*"
            component={ReduxInputField}
          />
        </BSB>

        <AccountNumber>
          <Field
            name="acc"
            label="Account number"
            maxLength={10}
            pattern="\d*"
            component={ReduxInputField}
          />
        </AccountNumber>
      </AccountInfoWrapper>
    </Modal>
  );
};

const formOptions = {
  form: 'AddPayoutOptionModal',
  validate,
  initialValues: {}
};

export default compose(
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  graphql(ADD_BANK_ACCOUNT, { name: 'addBankAccount' }),
  reduxForm(formOptions)
)(AddPayoutOptionModal);
