import React, { useContext } from 'react';
import styled from 'styled-components';
import CarparkMap from '../../../../../_shared/components/CarparkMap';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ModifyAddressModal } from '../../../../../common/modal';
import {
  defaultBorder,
  midBreakPoint,
  spacing
} from '../../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../../_shared/utils';
import './LocationSection.css';

const Wrapper = styled.div`
  width: 100%;
  min-height: 309px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 67px 218px;
  padding: ${spacing(4)} 0;
  @media (min-width: ${midBreakPoint}px) {
    padding: ${spacing(4)} ${spacing(4)} ${spacing(6)} ${spacing(4)};
    grid-template-columns: 48px 352px 1fr 51px;
    border: ${defaultBorder};
  }
`;

const ParkingSign = styled.img`
  grid-column: 1 / 2;
  grid-row: 1;
  height: 48px;
  width: 48px;
`;

const Address = styled.div`
  padding-left: ${spacing(3)};
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 4;
  }
`;

const Modify = styled.div`
  cursor: pointer;
  grid-column: 2;
  grid-row: 4;
  padding-top: 16px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 4 / 5;
    grid-row: 1;
  }
`;

const GMap = styled.div`
  grid-column: 1 / 3;
  grid-row: 2;
`;

const LocationSection = props => {
  const { actions } = useContext(ModalStoreContext);
  const { address, values, invalid, changeLocation } = props;

  return (
    <Wrapper>
      <ModifyAddressModal
        values={values}
        invalid={invalid}
        changeLocation={changeLocation}
      />

      <ParkingSign
        src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
        alt="parking sign in blue"
      />

      <Address>
        <div className="admin-section-subtitle">Address</div>
        <div className="admin-content-font">
          {address}
          {values.parkingLotNumber && ` , lot: ${values.parkingLotNumber}`}
        </div>
      </Address>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyAddress')}
        >
          Modify
        </div>
      </Modify>

      <GMap>
        <CarparkMap address={address} />
      </GMap>
    </Wrapper>
  );
};

export default LocationSection;
