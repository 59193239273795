import qs from 'qs';
import { decryptEnvVar, envVar } from './decryptEnvVar';

export const AuthZeroAudience = envVar('REACT_APP_AUTH0_AUDIENCE');
export const AuthZeroClientId = envVar('REACT_APP_AUTH0_CLIENT_ID');
export const AuthZeroDomain = envVar('REACT_APP_AUTH0_DOMAIN');
export const AwsApi = envVar('REACT_APP_AWS_API');
export const AwsPanEncryptAccessKeyId = decryptEnvVar(
  envVar('REACT_APP_AWS_PANENCRYPT_ACCESS_KEY_ID')
);
export const AwsPanEncryptSecretAccessKey = decryptEnvVar(
  envVar('REACT_APP_AWS_PANENCRYPT_SECRET_ACCESS_KEY')
);
export const BucketName = envVar('REACT_APP_BUCKET_NAME');
export const GoogleMapApiKey = decryptEnvVar(
  envVar('REACT_APP_GOOGLE_MAP_API_KEY')
);
export const ImgixApiKey = decryptEnvVar(envVar('REACT_APP_IMGIX_API_KEY'));
export const ImgixUrl = envVar('REACT_APP_IMGIX_URL');
export const ReduxSecretKey = decryptEnvVar(
  envVar('REACT_APP_REDUX_SECRET_KEY')
);
export const SentryDsn = envVar('REACT_APP_SENTRY_DSN');

export const getKeyWithTrueValue = obj =>
  Object.entries(obj)
    .filter(([key, value]) => value === true)
    .map(item => item[0]);

export const encode = data => {
  const str = data.reduce((a, b) => {
    return a + String.fromCharCode(b);
  }, '');
  return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
};

export const imageExists = image_url => {
  //console.log(image_url);
  const http = new XMLHttpRequest();
  http.open('HEAD', image_url, false);
  http.send();
  return http.status !== 404;
};

export const currency = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2
});

export const autoDetectCreditCardType = numbers => {
  var result = 'unknown';

  //https://webstandardssherpa.com/reviews/auto-detecting-credit-card-type.html
  if (/^5[1-5][0-9]{14}$/.test(numbers)) {
    result = 'MasterCard';
  } else if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(numbers)) {
    result = 'VISA';
  } else if (/^3[47][0-9]{13}$/.test(numbers)) {
    result = 'AMEX';
  } else if (/^(62[0-9]{14,17})$/.test(numbers)) {
    result = 'UnionPay';
  }
  return result;
};

export const renderCardIssuerLogo = cardIssuer => {
  let logo;
  switch (cardIssuer) {
    case 'MasterCard':
      logo = {
        src: `${ImgixUrl}/web-image-assets/icons/Mastercard-border.svg`,
        alt: 'mastercard icon for payment method'
      };
      break;
    case 'VISA':
      logo = {
        src: `${ImgixUrl}/web-image-assets/icons/visa-outline.svg`,
        alt: 'visa icon for payment method'
      };
      break;
    case 'AMEX':
      logo = {
        src: `${ImgixUrl}/web-image-assets/icons/amex-outline.svg`,
        alt: 'amex icon for payment method'
      };
      break;
    case 'UnionPay':
      logo = {
        src: `${ImgixUrl}/web-image-assets/icons/unionpay-outline.svg`,
        alt: 'unionpay icon for payment method'
      };
      break;
    default:
      logo = null;
  }
  return logo;
};

export const getQueryString = (query, options = {}) => {
  const { ignoreCase = false } = options;
  const queries = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (ignoreCase) {
    return queries[query.toLowerCase()] || queries[query.toUpperCase()];
  }
  return queries[query];
};

export const dashedAddress = (address, key) => {
  if (!address) {
    return null;
  }
  const splittedAddress = address.split(', ').reverse();
  const dashedAddress = splittedAddress[key]
    .split(' ')
    .join('-')
    .toLowerCase();
  return dashedAddress;
};

export const isDeviceMobile = () => {
  var isMobile = false;
  if (
    // eslint-disable-next-line
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    // eslint-disable-next-line
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};
