import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import {
  defaultBorder,
  greyDark,
  midBreakPoint,
  RubikSemiBold,
  spacing
} from '../layout-constants';
import { CheckBox } from './FormElements';
import InfoTip from './InfoTip';

const START_DATE = 'startDate';

const BookingLength = styled.div`
  position: relative;
  z-index: 0;
`;

const FromTo = styled.div`
  height: 56px;
  border-radius: 3px;
  border: ${defaultBorder};
  margin: 24px 0 0 0;
  padding: 0 ${spacing(3)};
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 18px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.1px;
  @media (min-width: ${midBreakPoint}px) {
    height: 24px;
    font-size: 14px;
    font-weight: ${RubikSemiBold};
    letter-spacing: -0.1px;
  }
`;

const Label = styled.span`
  color: ${greyDark};
  font-size: 16px;
`;

const SelectedDate = styled.span`
  font-size: 16px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 21px;
  }
`;

const DayPickerSection = styled.div`
  margin-bottom: -${spacing(3)};
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: ${spacing(4)};
  }
`;

const Moment = extendMoment(moment);

const dateCompose = date => {
  const year = date.format('YYYY');
  const month = parseInt(date.format('MM'), 10) - 1;
  const day = date.format('DD');
  return new Date(year, month, day);
};

const inBetween = (day, startTime, endTime, tz) => {
  const date = moment(
    day.tz(tz).format('YYYY') +
      '-' +
      day.tz(tz).format('MM') +
      '-' +
      day.tz(tz).format('DD')
  );

  const startTimeTz = moment(startTime).tz(tz);
  const startDate = dateCompose(startTimeTz);

  const endTimeTz = endTime // if endTime is null treat it as monthly booking
    ? moment(endTime)
        .add(0, 'days')
        .tz(tz)
    : moment('2999-12-31');
  const endDate = dateCompose(endTimeTz);

  // moment range.contains have to exclude either startDate or endDate, so offset endDate by 1
  const range = Moment().range(startDate, endDate);
  return range.contains(date);
};

const BookingLengthComponent = ({
  noBlockingDates,
  fromDate,
  toDate,
  monthlyOngoing,
  toggleMonthlyOngoing,
  onDatesSelection,
  handleFocusedInput,
  handleDatesChange,
  bookingPreview,
  filters,
  availableDateFrom,
  tz,
  bookingLength,
  bookingOccupiedDates,
  ...otherProps
}) => {
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const today = moment().tz(tz);
  const availableFrom = moment.tz(availableDateFrom, tz).isAfter(today)
    ? // currently only allow to book after two days, TODO: remove it after operations enhanced
      moment.tz(availableDateFrom, tz).add(2, 'days')
    : today && today.add(2, 'days');

  const filtersJSON = filters ? JSON.parse(filters) : [];

  const onFocusChange = focusedInput =>
    setFocusedInput(
      !focusedInput || monthlyOngoing ? START_DATE : focusedInput
    );

  const inBetweenBookedDates = (day, array, tz) =>
    array && array.some(t => inBetween(day, t.startTime, t.endTime, tz));

  const daysBlock = (day, tz) => {
    // return after today if not on booking page
    if (!filters) return !isInclusivelyAfterDay(day, moment()); // for srp only display future dates
    const parsedFilters = filtersJSON[0].value;

    return (
      // three cases to trigger block
      // 1. day blocked by lister (availability)
      !parsedFilters.includes(day.weekday()) ||
      // 2. already booked by others
      inBetweenBookedDates(day, bookingOccupiedDates, tz) ||
      // 3. after today
      !isInclusivelyAfterDay(day, availableFrom)
    );
  };

  return (
    <BookingLength {...otherProps}>
      <Title>Booking duration</Title>
      <FromTo>
        <Label>From</Label>
        <SelectedDate>
          {fromDate ? fromDate.format('YYYY-MM-DD') : 'Date'}
        </SelectedDate>
        {!monthlyOngoing ? (
          toDate && fromDate ? (
            <React.Fragment>
              <Label>To</Label>
              <SelectedDate>{toDate.format('YYYY-MM-DD')}</SelectedDate>
            </React.Fragment>
          ) : (
            fromDate && (
              <React.Fragment>
                <Label>To</Label>
                <SelectedDate>{fromDate.format('YYYY-MM-DD')}</SelectedDate>
              </React.Fragment>
            )
          )
        ) : null}
      </FromTo>
      <DayPickerSection>
        <DayPickerRangeController
          onDatesChange={({ startDate, endDate }) => {
            handleDatesChange(startDate, endDate, filtersJSON, bookingPreview);
          }}
          onFocusChange={onFocusChange}
          startDate={fromDate}
          endDate={monthlyOngoing ? null : toDate}
          focusedInput={focusedInput}
          enableOutsideDays={false}
          isOutsideRange={day => daysBlock(day, tz)}
          minimumNights={0}
          isDayBlocked={day => daysBlock(day, tz)}
          isFocused
        />
      </DayPickerSection>
      {toggleMonthlyOngoing && (
        <div>
          <CheckBox
            style={{ width: 'auto', fontSize: '15px' }}
            label="Monthly ongoing booking only"
            checked={monthlyOngoing}
            handleChange={() => toggleMonthlyOngoing()}
            disabled={true}
          />
          <InfoTip>
            Please choose a start date to make a monthly ongoing booking
          </InfoTip>
        </div>
      )}
    </BookingLength>
  );
};

export default BookingLengthComponent;
