import axios from 'axios';
import { all } from 'q';
import { SLACK_BOT_WEBHOOK_LISTING_UPDATE } from '../../../_shared/constants';
import { dashedAddress, AwsApi } from '../../../_shared/utils';

export const constructPriceOptions = priceOptions => {
  let parsedPriceOptions;
  if (Array.isArray(priceOptions)) {
    parsedPriceOptions = priceOptions[0] && JSON.parse(priceOptions[0]);
  } else {
    parsedPriceOptions = priceOptions;
  }
  let priceOptionsArray = [];
  let priceOptionsJSON = parsedPriceOptions;
  if (priceOptionsJSON.daily && priceOptionsJSON.daily.length > 0) {
    priceOptionsArray.push(
      JSON.stringify({
        value: priceOptionsJSON.daily,
        type: {
          short: 'D',
          description: 'daily'
        },
        unit: '$'
      })
    );
  }

  if (priceOptionsJSON.weekly && priceOptionsJSON.weekly.length > 0) {
    priceOptionsArray.push(
      JSON.stringify({
        value: priceOptionsJSON.weekly,
        type: {
          short: 'W',
          description: 'weekly'
        },
        unit: '$'
      })
    );
  }

  if (priceOptionsJSON.monthly && priceOptionsJSON.monthly.length > 0) {
    priceOptionsArray.push(
      JSON.stringify({
        value: priceOptionsJSON.monthly,
        type: {
          short: 'm',
          description: 'monthly'
        },
        unit: '$'
      })
    );
  }

  if (priceOptionsJSON.deposit && priceOptionsJSON.deposit.length > 0) {
    priceOptionsArray.push(
      JSON.stringify({
        value: priceOptionsJSON.deposit,
        type: {
          short: 'deposit',
          description: 'deposit'
        },
        unit: '$'
      })
    );
  }

  return priceOptionsArray;
};

export const submitCompletedParkingForm = async ({
  addParkingSpace,
  updateState,
  form,
  id
}) => {
  // console.log('start creating a new listing ...', {
  //   variables: {
  //     accessInstruction: form.accessInstruction,
  //     accessTypes: form.accessTypes,
  //     address: form.address,
  //     amenities: form.amenities,
  //     availableDateFrom: form.availableDateFrom,
  //     contacts: form.contacts,
  //     description: form.description,
  //     ...(id && { draftId: form.id }), // in create listing
  //     ...(!id && form.id && { id: form.id }), // in details page
  //     deliveryInstruction: form.deliveryInstruction,
  //     maxHeight: form.maxHeight,
  //     lot: form.lot,
  //     operation: form.operation,
  //     parkingTypes: form.parkingTypes,
  //     priceOptions: id
  //       ? constructPriceOptions(form.priceOptions)
  //       : form.priceOptions,
  //     suitableVehicleTypes: form.suitableVehicleTypes,
  //     title: form.title,
  //     filters: form.daysAvailable
  //   }
  // });
  try {
    const result = await addParkingSpace({
      variables: {
        accessInstruction: form.accessInstruction,
        accessTypes: form.accessTypes,
        address: form.address,
        amenities: form.amenities,
        availableDateFrom: form.availableDateFrom,
        contacts: form.contacts,
        description: form.description,
        ...(id && { draftId: form.id }), // in create listing
        ...(!id && form.id && { id: form.id }), // in details page
        deliveryInstruction: form.deliveryInstruction,
        maxHeight: form.maxHeight,
        lot: form.lot,
        operation: form.operation,
        parkingTypes: form.parkingTypes,
        priceOptions: id
          ? constructPriceOptions(form.priceOptions)
          : form.priceOptions,
        suitableVehicleTypes: form.suitableVehicleTypes,
        title: form.title,
        filters: form.daysAvailable
      }
    });

    const {
      data: {
        addParkingSpace: { parking }
      }
    } = result;
    updateState({
      loading: false,
      errorMsg: null
    });
    // console.log(parking);
    // console.log('publish', {
    //   userId: id,
    //   draftId: form.id,
    //   parkingId: parking.id
    // });
    if (id) {
      await axios
        .post(`${AwsApi}/publish-images`, {
          userId: id,
          draftId: form.id,
          parkingId: parking.id
        })
        .then(function(response) {
          // handle success
          console.log('@@@publish image', response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
      // send slack notification
      await axios
        .post(SLACK_BOT_WEBHOOK_LISTING_UPDATE, slackMessage(parking))
        .then(function(response) {
          console.log('slackbot message sent.');
          //console.log(response);
          window.location.href = '/admin/create-listing/success/'.concat(
            parking.id
          );
        })
        .catch(function(error) {
          console.log('### failed to notify QTPL. ### error details: ' + error);
          // In case the message is not sent, we still redirect the page due to
          // listing has been successfully created.
          window.location.href = '/admin/create-listing/success/'.concat(
            parking.id
          );
        });
    }
  } catch (err) {
    updateState({
      loading: false,
      errorMsg: 'listing submitting failed, please try again.'
    });
    //console.log(err);
  }
};

export const submitParkingDraft = async ({
  form,
  updateState,
  addParkingDraft,
  updateFormValue
}) => {
  //console.log('submitParkingDraft', form);
  try {
    const { data, errors } = await addParkingDraft({
      errorPolicy: all,
      variables: {
        accessInstruction: form.accessInstruction,
        accessTypes: form.accessTypes,
        address: form.address,
        amenities: form.amenities,
        availableDateFrom: form.availableDateFrom,
        contacts: form.contacts,
        description: form.description,
        id: form.id,
        keyDeliveryInstruction: form.deliveryInstruction,
        maxHeight: form.maxHeight,
        page: form.page,
        parkingLotNumber: form.lot,
        parkingTypes: form.parkingTypes,
        priceOptions: form.priceOptions,
        suitableVehicleTypes: form.suitableVehicleTypes,
        title: form.title,
        filters: form.daysAvailable
      }
    });
    const draftId = data.addParkingDraft.parking.id;
    await updateFormValue({
      id: draftId
    });
    updateState({
      loading: false,
      errorMsg: errors ? errors[0].message : null
    });
  } catch (err) {
    updateState({
      loading: false,
      errorMsg: 'Draft saving failed, please try again.'
    });
    //console.log(err);
  }
};

const slackMessage = parking => {
  let address = parking.address;
  let state = dashedAddress(address, 0);
  let city = dashedAddress(address, 1);
  let urlAddress = state && city ? `-${city}-${state}` : '';
  let url =
    window.location.origin +
    `/carpark/parking-space${urlAddress}/${parking.id}`;
  let msg = `We have a new listing! Listing URL: ${url}`;

  return JSON.stringify({ text: msg });
};
