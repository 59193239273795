import React, { Component } from 'react';
import Subtitle from '../../../components/subtitle/Subtitle';
import { InfoTable } from '../../../components/tableComponents/InfoTable';
import Message from '../../../components/tableComponents/Message';

export default class Notifications extends Component {
  render() {
    return (
      <div>
        <Subtitle title="Your messages" />

        <InfoTable height={820} padding={32} direction="column">
          <Message
            type="Booking"
            address="99 Whiteman Street"
            messageDate="17 Jun 2018"
            fromDate="03 Jul 2018"
            endDate="03 Oct 2018"
            earning="488.89"
            name="John"
            message="Hey there, I’m a computer programmer who work in Richmond. I need a parking space to commute to work on weekdays. I will look after your carpark well. Cheers. J."
          />
          <Message
            type="Payment"
            earning="195"
            renter="Oliver"
            messageDate="11:30 am 18 Jun 18"
          />
          <Message
            type="Promotion"
            percent="15"
            messageDate="13 Jun 18"
            promotion="Earn extra 15% this October"
          />
          <Message
            type="Direct Message"
            imagePath="oliver.jpg"
            name="Oliver"
            messageDate="14:30 11 Jun 18"
            messageSubject="Key handover"
            message="Hi Jane, I’d like to book a time with you to get the garage key. Can you tell me what suits you best next week?"
          />
        </InfoTable>
      </div>
    );
  }
}
