import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ListingTicket } from '../../../../../admin';
import {
  RubikSemiBold,
  midBreakPoint
} from '../../../../../_shared/layout-constants';
import { TextButton } from '../../../../../_shared/components/Buttons';
import './index.css';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 48px auto;
  height: 1096px;
  @media (min-width: ${midBreakPoint}px) {
    height: 598px;
    grid-template-rows: 24px 24px 292px 210px;
    grid-template-columns: 295px 48px 455px;
    padding: 24px;
  }
`;

const Title = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
`;

const ListingTicketWrapper = styled.div`
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 3 / 5;
  }
`;

const AccessInfo = styled.div`
  padding: 32px 0 0 0;
  display: grid;
  grid-template-rows: 24px 24px 20px 24px 20px 24px 32px auto 39px;
  @media (min-width: ${midBreakPoint}px) {
    padding: 0;
    grid-template-columns: 455px;
    grid-column: 3;
    grid-row: 3;
    grid-gap: 0;
  }
`;

const AccessInfoTitle = styled.div`
  grid-row: 1;
  grid-column: 1;
  height: 24px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
`;

const AccessType = styled.div`
  grid-row: 3;
  grid-column: 1;
`;

const MaxHeight = styled.div`
  grid-column: 1;
  grid-row: 5;
`;

const AccessInstructionTitle = styled.div`
  grid-row: 7;
  grid-column: 1;
`;

const AccessInstruction = styled.div`
  grid-row: 8;
  grid-column: 1;
`;

const BreakLine = styled.div`
  grid-row: 9;
  grid-column: 1;
`;

const BookingInfo = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 24px 17px 20px 8px 20px 24px 20px 24px 20px;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 455px;
    grid-column: 3;
    grid-row: 4;
  }
`;

const BookingInfoTitle = styled.div`
  grid-row: 1;
  grid-column: 1;
  height: 24px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
`;

const BookedThisSpace = styled.div`
  grid-row: 3;
  grid-column: 1;
`;

const FromTo = styled.div`
  grid-row: 5;
  grid-column: 1;
`;

const ParkingFeeInfo = styled.div`
  grid-row: 7;
  grid-column: 1;
`;

const BookingRefSection = styled.div`
  grid-row: 9;
  grid-column: 1;
  color: #333f48b3;
`;

const BookingRef = styled.input`
  width: 100%;
  font-size: 16px;
  border: none;
`;

const CopyBookingRefButton = styled(TextButton)`
  font-size: 12px;
`;

const copyToClipboard = (elem, refNum) => {
  // create hidden text element, if it doesn't already exist
  var targetId = '_hiddenCopyText_';
  var isInput = elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA';
  var origSelectionStart, origSelectionEnd;
  var target;
  if (isInput) {
    // can just use the original source element for the selection and copy
    target = elem;
    origSelectionStart = elem.selectionStart;
    origSelectionEnd = elem.selectionEnd;
  } else {
    // must use a temporary form element for the selection and copy
    target = document.getElementById(targetId);
    if (!target) {
      target = document.createElement('textarea');
      target.style.position = 'absolute';
      target.style.left = '-9999px';
      target.style.top = '0';
      target.id = targetId;
      document.body.appendChild(target);
    }
    target.textContent = elem.textContent;
  }
  // select the content
  var currentFocus = document.activeElement;
  target.focus();
  target.setSelectionRange(0, target.value.length);

  // copy the selection
  var succeed;
  try {
    succeed = document.execCommand('copy');
  } catch (e) {
    succeed = false;
  }
  // restore original focus
  if (currentFocus && typeof currentFocus.focus === 'function') {
    currentFocus.focus();
  }

  if (isInput) {
    // restore prior selection
    elem.setSelectionRange(origSelectionStart, origSelectionEnd);
  } else {
    // clear temporary content
    target.textContent = '';
  }

  alert(`The bookng reference number ${refNum} is copied to your clipboard.`);

  return succeed;
};

const getBookingReference = () => {
  var url = window.location.href;
  var urlArray = url.split('/');
  var bookingRefIndex = urlArray.length - 1;
  var refNum = urlArray[bookingRefIndex];

  return refNum;
};

export default class ParkingSpaceDetails extends Component {
  render() {
    const {
      id,
      title,
      address,
      parkingLot,
      parkingType,
      access,
      maxHeight,
      accessInstruction,
      listingStatus,
      startTime,
      endTime,
      parkingFee,
      isMonthlyBooking,
      isManagedBooking
    } = this.props;

    return (
      <Wrapper>
        <Title>Parking space</Title>

        <ListingTicketWrapper>
          <ListingTicket
            id={id}
            title={title}
            address={address}
            parkingLot={parkingLot}
            parkingType={parkingType}
            status={listingStatus}
            redirectToPDP={!isManagedBooking}
          />
        </ListingTicketWrapper>

        <AccessInfo>
          <AccessInfoTitle>Access Information</AccessInfoTitle>

          <AccessType>
            <span className="parking-space-details-subtitle">Access type</span>
            <span className="parking-space-details-content">{access}</span>
          </AccessType>

          <MaxHeight>
            <span className="parking-space-details-subtitle">Max height</span>
            <span className="parking-space-details-content">{maxHeight}</span>
          </MaxHeight>

          <AccessInstructionTitle>
            <span className="parking-space-details-subtitle">
              Access instruction
            </span>
          </AccessInstructionTitle>

          <AccessInstruction>
            <div className="parking-space-details-content">
              {accessInstruction}
            </div>
          </AccessInstruction>

          <BreakLine>
            <hr />
          </BreakLine>
        </AccessInfo>

        <BookingInfo>
          <BookingInfoTitle>Booking information</BookingInfoTitle>

          <BookedThisSpace className="parking-space-details-content">
            {isManagedBooking
              ? 'Your parking space has been booked'
              : 'You have booked this parking space'}
          </BookedThisSpace>

          <FromTo className="parking-space-details-content">
            <span>from</span>
            <span className="parking-space-details-booking-info">
              {moment(startTime).format('Do MMM YYYY')}
            </span>
            {endTime && <span>to</span>}
            {endTime && (
              <span className="parking-space-details-booking-info">
                {moment(endTime).format('Do MMM YYYY')}
              </span>
            )}
          </FromTo>

          <ParkingFeeInfo className="parking-space-details-content">
            <span>Parking fee is</span>
            <span className="parking-space-details-booking-info">
              $ {Math.round(parkingFee * 100) / 100}
            </span>
            {isMonthlyBooking && <span>per month</span>}
          </ParkingFeeInfo>

          <BookingRefSection>
            <div>
              <span>Booking reference number </span>
              <span>
                <CopyBookingRefButton
                  onClick={() =>
                    copyToClipboard(
                      document.getElementById('bookingRefCopyTarget'),
                      getBookingReference()
                    )
                  }
                >
                  (Copy booking reference number)
                </CopyBookingRefButton>
              </span>
              <BookingRef
                className="parking-space-details-booking-info"
                type="text"
                id="bookingRefCopyTarget"
                value={getBookingReference()}
              />
            </div>
          </BookingRefSection>
        </BookingInfo>
      </Wrapper>
    );
  }
}
