import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  midBreakPoint,
  RubikSemiBold,
  graphite,
  gutter,
  greyLight,
  defaultBorder,
  defaultBoxShadow,
  RubikFontFamily,
  spacing,
  teal,
  greyDark
} from '../layout-constants';
import Notification from './Notification';
import { RoundSecondaryButton } from './Buttons';
import { ImgixUrl } from '../utils';

if (typeof document !== 'undefined') {
  Modal.setAppElement(document.getElementById('root'));
}

const customStyles = {
  overlay: { zIndex: 10 },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '-webkit-fill-available',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    //overflow: 'unset',
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center'
  }
};

const Wrapper = styled.div`
  font-family: ${RubikFontFamily};
  position: relative;
  padding: ${gutter(2)} ${gutter(1)} ${gutter(1)} ${gutter(1)};
  background-color: #fff;
  border-radius: 3px;
  box-shadow: ${defaultBoxShadow};
  width: 100vw;
  max-height: 700px;
  border: ${defaultBorder};
  overflow-y: scroll !important;
  z-index: 98;
  @media (min-width: ${midBreakPoint}px) {
    padding: ${({ isAuth }) =>
      isAuth ? '32px 80px 64px 80px' : '32px 24px 24px 24px'};
    width: ${({ modalWidth }) => (modalWidth ? modalWidth : '500px')};
    height: auto;
    min-height: 500px;
    max-height: none;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  text-align: left;
  color: ${graphite};
`;

const Greeting = styled(Title)`
  font-size: 20px;
  margin-top: 16px;
`;

const Close = styled.img`
  display: ${({ hide }) => (hide ? 'none' : 'inline')};
  width: 31px;
  height: 31px;
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 24px;
`;

const Header = styled.div`
  margin-bottom: ${({ isAuth }) => (isAuth ? '32px' : '25px')};
  margin-right: 30px;
`;

const Button = styled(RoundSecondaryButton)`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '40px')};
`;

const ModalDescription = styled.div`
  font-size: 14px;
  letter-spacing: normal !important;
  line-height: 1.33;
  color: ${greyDark};
  margin-top: ${spacing(2)};
  padding: 10px 12px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-color: ${teal};
  background-color: white;
  @media (min-width: ${midBreakPoint}px) {
    display: ${({ hide, hideOn }) =>
      hide || hideOn === 'big' ? 'none' : 'flex'};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 8px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
  }
  ::-webkit-scrollbar-thumb {
    background: ${greyLight};
    border-radius: 2px;
  }
`;

const AuthHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const Logo = styled.img`
  width: 48px;
`;

const Icon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  object-fit: contain;
  @media (min-width: ${midBreakPoint}px) {
    height: 28px;
    width: 28px;
    margin-right: 12px;
  }
`;

const icon = type => {
  return `${ImgixUrl}/web-image-assets/icons/alert/GreenInfoIcon.png`;
};

export const ModalComponent = ({
  isAuth,
  modalWidth,
  hideCloseButton,
  handleClose,
  title,
  notification,
  modalDescription,
  children,
  buttonLabel,
  handleSubmit,
  isForm,
  buttonDisabled,
  loading,
  showIcon
}) => (
  <Wrapper isAuth={isAuth} modalWidth={modalWidth}>
    <Close
      hide={hideCloseButton}
      src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
      onClick={handleClose}
      data-dismiss="modal"
    />
    <Header isAuth={isAuth}>
      {isAuth ? (
        <AuthHeader>
          <Logo src={`${ImgixUrl}/web-image-assets/icons/qp-navy.svg`} />
          <Greeting>{title}</Greeting>
        </AuthHeader>
      ) : (
        <Title>{title}</Title>
      )}
    </Header>
    {notification && (
      <Notification type="alert1" text={notification} hasClose={false} />
    )}
    {modalDescription && (
      <ModalDescription>
        {showIcon && icon() && <Icon src={icon()} />}
        <span title={modalDescription}>{modalDescription}</span>
      </ModalDescription>
    )}
    <Content>{children}</Content>
    {buttonLabel && (
      <Button
        type="submit"
        paddingTop={0}
        fontSize={16}
        onClick={isForm ? () => {} : handleSubmit}
        disabled={buttonDisabled || loading}
      >
        {loading ? 'loading ...' : buttonLabel}
      </Button>
    )}
  </Wrapper>
);

export default ({
  title,
  isAuth = false,
  hideCloseButton = false,
  modalWidth,
  buttonLabel,
  children,
  handleClose,
  isOpen,
  contentLabel,
  handleSubmit,
  buttonDisabled,
  loading,
  isForm = true,
  notification = false,
  modalDescription,
  showIcon
}) => (
  <Modal
    isOpen={isOpen}
    style={customStyles}
    contentLabel={contentLabel}
    onRequestClose={handleClose}
  >
    {isForm ? (
      <form onSubmit={handleSubmit} noValidate>
        <ModalComponent
          isAuth={isAuth}
          modalWidth={modalWidth}
          hideCloseButton={hideCloseButton}
          handleClose={handleClose}
          title={title}
          notification={notification}
          children={children}
          buttonLabel={buttonLabel}
          handleSubmit={handleSubmit}
          buttonDisabled={buttonDisabled}
          loading={loading}
          modalDescription={modalDescription}
          showIcon={showIcon}
        />
      </form>
    ) : (
      <ModalComponent
        isAuth={isAuth}
        modalWidth={modalWidth}
        hideCloseButton={hideCloseButton}
        handleClose={handleClose}
        title={title}
        notification={notification}
        children={children}
        buttonLabel={buttonLabel}
        handleSubmit={handleSubmit}
        buttonDisabled={buttonDisabled}
        loading={loading}
        modalDescription={modalDescription}
        showIcon={showIcon}
      />
    )}
  </Modal>
);
