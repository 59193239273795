import React, { Component } from 'react';
import { Row, Col } from 'react-flexa';
import styled from 'styled-components';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/react-hoc';
import { GET_INVOICE_DOWNLOAD_URL } from '../../../../../common/graphql/bookingSchema';

const Wrapper = styled.div`
  width: 100%;
  height: 44px;
`;

class InvoiceRow extends Component {
  render() {
    const {
      amount,
      billingDate,
      //invoiceType,
      getInvoiceDownloadUrl
    } = this.props;

    return (
      <Wrapper>
        <Row justifyContent="flex-start">
          <Col xs={3.5} lg={3.5}>
            <span className="invoice-modal-content">
              {'$ ' + (Math.round(amount * 100) / 100).toString()}
            </span>
          </Col>
          <Col xs={6} lg={6}>
            <span className="invoice-modal-content">{billingDate}</span>
          </Col>
          <Col xs={2.5} lg={2.5}>
            <a
              className="invoice-modal-action"
              href={
                getInvoiceDownloadUrl.getInvoiceDownloadUrl &&
                getInvoiceDownloadUrl.getInvoiceDownloadUrl.url
              }
              download
            >
              Download
            </a>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default compose(
  graphql(GET_INVOICE_DOWNLOAD_URL, {
    name: 'getInvoiceDownloadUrl',
    handleError: false,
    options: props => ({
      variables: {
        invoiceId: props.invoiceId
      }
    })
  })
)(InvoiceRow);
