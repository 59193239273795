import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ModifyPricesModal } from '../../../../../common/modal';
import {
  defaultBorder,
  midBreakPoint,
  spacing
} from '../../../../../_shared/layout-constants';
import './PriceSection.css';

const Wrapper = styled.div`
  width: 100%;
  min-height: 146px;
  display: grid;
  grid-gap: 0;
  padding: ${spacing(4)} 0;
  grid-template-rows: repeat(4, 44px) 1fr;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 350px 268px 1fr 51px;
    grid-template-rows: 44px 20px;
    padding: ${spacing(6)} ${spacing(4)};
    border: ${defaultBorder};
  }
`;

// const Daily = styled.div`
//   grid-row: 1;
//   @media (min-width: ${midBreakPoint}px) {
//     grid-column: 1 / 2;
//     grid-row: 1;
//   }
// `;

const Weekly = styled.div`
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 3;
    grid-row: 1;
  }
`;

const Monthly = styled.div`
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 1;
  }
`;

const Modify = styled.div`
  cursor: pointer;
  grid-row: 5;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 4 / 5;
    grid-row: 1;
  }
`;

const Bond = styled.div`
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 2;
  }
`;

const PriceSection = props => {
  const { actions } = useContext(ModalStoreContext);
  const { changePricing, invalid, parkingId, change, isPublic } = props;
  const {
    //daily,
    weekly,
    monthly,
    deposit
  } = props.values;

  return (
    <Wrapper>
      <ModifyPricesModal
        invalid={invalid}
        change={change}
        isPublic={isPublic}
        changePricing={changePricing}
        values={props.values}
        parkingId={parkingId}
      />

      {/* <Daily>
        <span className="admin-section-subtitle">Per day</span>
        <span className="admin-content-font">$ {daily ? daily : null}</span>
      </Daily> */}

      {weekly && (
        <Weekly>
          <span className="admin-section-subtitle">
            Indicative weekly price
          </span>
          <span className="admin-content-font">$ {weekly}</span>
        </Weekly>
      )}

      <Monthly>
        <span className="admin-section-subtitle">Per month</span>
        <span className="admin-content-font">$ {monthly ? monthly : null}</span>
      </Monthly>

      <Bond>
        <span className="admin-section-subtitle">Security bond</span>
        <span className="admin-content-font">$ {deposit ? deposit : null}</span>
      </Bond>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyPrices')}
        >
          Modify
        </div>
      </Modify>
    </Wrapper>
  );
};

export default PriceSection;
