import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import Geocode from 'react-geocode';
import CryptoJS from 'crypto-js';
import { graphql } from '@apollo/react-hoc';
import { VERIFY_TOKEN_MUTATION } from '../../graphql/authSchema';
import { AUTH_TOKEN, AUTH0_TOKEN } from '../../../_shared/constants';
import { GMKEY } from '../../../_shared/layout-constants';
import { ReduxSecretKey } from '../../../_shared/utils';
import { useAuth0 } from '../../hooks/auth0';
import ExpressInterestModal from '../expressInterest';
import CacheBuster from '../../../CacheBuster';

const DEFAULT_CONFIG = {
  trackingId: 'UA-124028776-1',
  debug: true,
  gaOptions: {
    cookieDomain: 'none'
  }
};

const App = props => {
  useEffect(() => {
    Geocode.setApiKey(`${GMKEY}`);
    ReactGA.initialize(DEFAULT_CONFIG);
  }, []);
  const {
    isAuthenticated: isAuth0Authenticated,
    getIdTokenClaims
  } = useAuth0();

  const handleAuth0Token = async () => {
    const auth0tokenObj = await getIdTokenClaims();
    localStorage.setItem(AUTH0_TOKEN, auth0tokenObj && auth0tokenObj.__raw);
    localStorage.setItem('authenticate', 'true');
  };

  const verifyToken = async () => {
    const encryptedToken = localStorage.getItem(AUTH_TOKEN);

    try {
      const token =
        encryptedToken &&
        CryptoJS.AES.decrypt(encryptedToken, ReduxSecretKey).toString(
          CryptoJS.enc.Utf8
        );
      await props.verifyTokenMutation({
        variables: {
          token
        },
        update: (_, { data: { verifyToken } }) => {
          // stay logged in because verify token has been successful
        }
      });
    } catch (err) {
      // wait for alerts design
      // TODO, perhaps we can ask user whether he/she wants to refresh token
      // if refresh token, do...
      // if not refresh token, need to logout
      localStorage.clear();
      // TODO: ask user to log in again
    }
  };

  // if get AUTH0_TOKEN save it in local storage
  if (isAuth0Authenticated && !localStorage.getItem(AUTH0_TOKEN)) {
    handleAuth0Token();
  }

  // if get AUTH_TOKEN valid it
  if (!!localStorage.getItem(AUTH_TOKEN)) {
    verifyToken();
  }

  return (
    <div>
      <Helmet defaultTitle="Quokka Parking" titleTemplate="%s | Quokka Parking">
        <meta name="keywords" content="parking" />
      </Helmet>
      <ExpressInterestModal />
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return props.children;
        }}
      </CacheBuster>
    </div>
  );
};

export default graphql(VERIFY_TOKEN_MUTATION, { name: 'verifyTokenMutation' })(
  App
);
