import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import {
  GET_CREDIT_CARDS,
  GET_BANK_ACCOUNTS
} from '../../../common/graphql/adminSchema';
import { UserContext } from '../../../common/context/user';
import { VerifyListingModal } from '../../../common/modal';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import { GET_PARKING_SPACES } from '../../../common/graphql/adminSchema';
import './MyListingsPage.css';
import { ToggleButtonGroup } from '../../../_shared/components/FormElements';
import PopupAlert from '../../../_shared/components/PopupAlert';
import ListView from './_list';
import GridView from './_grid';
import { midBreakPoint } from '../../../_shared/layout-constants';

const Toggle = styled(ToggleButtonGroup)`
  width: 156;
  position: absolute;
  top: 34px;
  right: 56px;
  @media (min-width: ${midBreakPoint}px) {
    top: 0;
  }
`;

const MyListingsPage = ({
  getParkingSpaces,
  getBankAccounts,
  getCreditCards,
  match: {
    params: { status }
  }
}) => {
  const [view, setView] = useState('List');
  const { user } = useContext(UserContext);

  if (
    getParkingSpaces.loading ||
    getCreditCards.loading ||
    getBankAccounts.loading
  ) {
    return <SpinnerLoader />;
  }

  const hasContact = p => {
    return p && p !== null;
  };

  const hasBilling = b => {
    return b && b !== [] && b.length > 0;
  };

  const hasPhoneNumber = hasContact(user && user.phoneNumber);
  const hasPaymentMethod = hasBilling(
    getCreditCards && getCreditCards.getCreditCards
  );
  const hasPayoutOption = hasBilling(
    getBankAccounts && getBankAccounts.getBankAccounts
  );

  const View = view === 'List' ? ListView : GridView;

  return (
    <AdminWrapper title="My listings">
      <Toggle
        index={0}
        elements={[
          { name: 'List', icon: 'list.png' },
          { name: 'Grid', icon: 'grid.png' }
        ]}
        handleClick={setView}
      />
      <PopupAlert
        title="Need to know"
        type="AddBillingInfoAlert"
        message="Please complete your billing info including payment method and payout option in order for us to pay you later."
        button="Redirect to Billings"
        enableRedirect={true}
        redirectTo={'/admin/billings'}
        disableClose={true}
      />
      <VerifyListingModal redirectUrl="/admin/create-listing" />
      <View
        hasPhoneNumber={hasPhoneNumber}
        hasPaymentMethod={hasPaymentMethod}
        hasPayoutOption={hasPayoutOption}
        getParkingSpaces={getParkingSpaces}
      />
    </AdminWrapper>
  );
};

export default compose(
  graphql(GET_PARKING_SPACES, { name: 'getParkingSpaces' }),
  graphql(GET_CREDIT_CARDS, { name: 'getCreditCards' }),
  graphql(GET_BANK_ACCOUNTS, { name: 'getBankAccounts' })
)(MyListingsPage);
