import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  navy,
  greyDark,
  grey,
  graphite
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';
import './AdminNavigation.css';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: repeat(10, 52px);
  grid-template-columns: 260px;
  width: 260px;
  height: 520px;
`;

const NavItem = styled.div`
  width: 260px;
  height: 52px;
  padding: 12px 16px 12px 16px;
`;

const NavSubTitle = styled.div`
  color: ${grey};
  margin-top: 12px;
`;

const NavIcon = styled.img`
  width: 26px;
  height: 26px;
  margin: 0 10px 5px 0;
`;

const NavIcon2 = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 12px 3px 2px;
`;

const NavigationLink = styled.span`
  position: relative;
  font-size: 18px;
  color: ${graphite};
  &:hover {
    color: ${greyDark};
    cursor: pointer;
    &:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    bottom: -6px;
    left: 0;
    background-color: ${navy};
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
`;

export default class AdminNavigation extends Component {
  render() {
    return (
      <Wrapper>
        <NavItem>
          <NavSubTitle>Booking Admin</NavSubTitle>
        </NavItem>

        <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/bookings.svg`}
            alt="admin my bookings"
          />
          <NavLink
            to="/admin/bookings/my-bookings"
            activeClassName="active-nav-link"
          >
            <NavigationLink>My bookings</NavigationLink>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavIcon2
            src={`${ImgixUrl}/web-image-assets/icons/admin/manageBookings.svg`}
            alt="admin manage bookings"
          />
          <NavLink
            to="/admin/bookings/manage-bookings"
            activeClassName="active-nav-link"
          >
            <NavigationLink>Manage bookings</NavigationLink>
          </NavLink>
        </NavItem>

        {/* <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/myListings.svg`}
            alt="admin qpms"
          />
          <NavLink to="/admin/qpms" activeClassName="active-nav-link">
            <NavigationLink>QPMS</NavigationLink>
          </NavLink>
        </NavItem> */}

        <NavItem>
          <NavSubTitle>General</NavSubTitle>
        </NavItem>

        <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/myListings.svg`}
            alt="admin listings"
          />
          <NavLink to="/admin/my-listings" activeClassName="active-nav-link">
            <NavigationLink>My listings</NavigationLink>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavIcon2
            src={`${ImgixUrl}/web-image-assets/icons/admin/favParking.svg`}
            alt="admin favourite bookings"
          />
          <NavLink
            to="/admin/favourite-bookings"
            activeClassName="active-nav-link"
          >
            <NavigationLink>Favourite</NavigationLink>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/billings.svg`}
            alt="admin billings"
          />
          <NavLink to="/admin/billings" activeClassName="active-nav-link">
            <NavigationLink>Billing</NavigationLink>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
            alt="admin profile"
          />
          <NavLink to="/admin/profile" activeClassName="active-nav-link">
            <NavigationLink>Profile</NavigationLink>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavIcon
            src={`${ImgixUrl}/web-image-assets/icons/admin/settings.svg`}
            alt="admin settings"
          />
          <NavLink to="/admin/settings" activeClassName="active-nav-link">
            <NavigationLink>Settings</NavigationLink>
          </NavLink>
        </NavItem>
      </Wrapper>
    );
  }
}
