import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ServiceNotAvailablePage from '../../error/ServiceNotAvailable';

export default class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    hasError: false
  };

  componentDidCatch(error, extra) {
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      <ServiceNotAvailablePage />
    ) : (
      this.props.children
    );
  }
}
