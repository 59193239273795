export const MATCHING_VEHICLES = {
  1.8: ['Sedan', 'Coupe', 'Hatch', 'Wagon', 'Convertible', 'Ute'],
  2: [
    'Sedan',
    'Coupe',
    'Hatch',
    'Wagon',
    'Convertible',
    'Ute',
    'SUV',
    'People Mover',
    'Van'
  ],
  2.2: [
    'Sedan',
    'Coupe',
    'Hatch',
    'Wagon',
    'Convertible',
    'Ute',
    'SUV',
    'People Mover',
    'Van',
    'Cab Chassis'
  ],
  2.5: [
    'Sedan',
    'Coupe',
    'Hatch',
    'Wagon',
    'Convertible',
    'Ute',
    'SUV',
    'People Mover',
    'Van',
    'Cab Chassis',
    'Light Truck'
  ],
  4.6: [
    'Sedan',
    'Coupe',
    'Hatch',
    'Wagon',
    'Convertible',
    'Ute',
    'SUV',
    'People Mover',
    'Van',
    'Cab Chassis',
    'Light Truck',
    'Bus'
  ],
  Unrestricted: [
    'Sedan',
    'Coupe',
    'Hatch',
    'Wagon',
    'Convertible',
    'Ute',
    'SUV',
    'People Mover',
    'Van',
    'Cab Chassis',
    'Light Truck',
    'Bus'
  ]
};
