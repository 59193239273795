import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import { AdminSubtitle } from '../../../_shared/styles';
import { FullWidthBorderButton } from '../../../../_shared/components/Buttons';
import { spacing, gutter } from '../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../_shared/utils';
import { QuokkaTooltip } from '../../../../_shared/components/QuokkaTooltip';
import {
  REQUEST_REFUND_BOND,
  ALLOW_TO_REQUEST_REFUND_BOND
} from '../../../../common/graphql/adminSchema';
import Notification from '../../../../_shared/components/Notification';

const Button = styled(FullWidthBorderButton)`
  width: 200px;
  margin-top: ${spacing(3)};
`;

const Wrapper = styled.div`
  margin-bottom: ${gutter(3)};
`;

const RefundTitle = styled(AdminSubtitle)`
  margin-bottom: 20px;
  cursor: pointer;
`;

const InfoIcon = styled.img`
  width: 16px;
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
`;

const MoreInfo = styled(QuokkaTooltip)`
  display: none;

  ${RefundTitle}:hover & {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const displayBondDescription = access => {
  return 'Quokka, as a third party, will hold the bond in escrow. Provided that the Property Owner does not make a claim for loss or damage within 2 days of the completion of the booking, the bond will be returned to the Renter. If your remained bond amount is non-zero, you can claim the amount by pressing "Request Refund" button. If a Property Owner wishes to make a claim for loss and damage and draw on the security bond, the Property Owner must notify Quokka and provide all information and documentation in support of the claim within 2 days of the completion of the booking. Quokka will only be able to accept and investigate claims regarding functional damage to remote controls and will not accept “cosmetic damage” claims. If the claim is accepted, Quokka will forward this information and documentation to the Renter and invite the Renter to respond similarly. Quokka may also remove the relevant listing that is subject to the claim. After this process, Quokka may conduct its own investigation and make a final determination with respect to the release of the bond. Quokka does not take any responsibility for this and will reserve legal rights for any possible illegal activities from Renters. If you have further question, feel free to reach out to Quokka Customer Service team.';
};

const RequestRefund = ({ requestRefundBond, allowToRequestRefundBond }) => {
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(null);
  const handleClick = async () => {
    const { data, errors } = await requestRefundBond({
      errorPolicy: 'all'
    });
    if (errors) {
      setError(errors[0].message);
      return;
    }
    if (get(data, 'requestRefundBond.refundBondAmount')) {
      setAmount(get(data, 'requestRefundBond.refundBondAmount'));
    }
    setError(null);
  };

  if (requestRefundBond.loading) return null;
  return (
    <Wrapper>
      <RefundTitle>
        Refund bond
        <InfoIcon
          src={`${ImgixUrl}/web-image-assets/icons/info.png`}
          alt="info"
        />
        <MoreInfo brief={displayBondDescription()} />
      </RefundTitle>
      {!get(
        allowToRequestRefundBond,
        'allowToRequestRefundBond.allowRefund'
      ) && (
        <Notification
          text={get(
            allowToRequestRefundBond,
            'allowToRequestRefundBond.message'
          )}
          type="neutral"
          hasClose="true"
        />
      )}
      {error && <Notification text={error} type="alert1" hasClose="true" />}
      {amount && (
        <Notification
          text={`You are qualified to get $${amount} refund. We've sent a email to your inbox, please confirm.`}
          type="success"
          hasClose="true"
        />
      )}
      <Button
        onClick={handleClick}
        disabled={
          !get(allowToRequestRefundBond, 'allowToRequestRefundBond.allowRefund')
        }
      >
        Request Refund
      </Button>
    </Wrapper>
  );
};

export default compose(
  graphql(REQUEST_REFUND_BOND, { name: 'requestRefundBond' }),
  graphql(ALLOW_TO_REQUEST_REFUND_BOND, { name: 'allowToRequestRefundBond' })
)(RequestRefund);
