import React, { Component } from 'react';
import styled from 'styled-components';
import { midBreakPoint } from '../../../_shared/layout-constants';

const MaxHeightWrapper = styled.div`
  padding-bottom: 25px;
  @media (min-width: ${midBreakPoint}px) {
    padding: 24px 0 26px 0;
  }
`;

export default class MaxHeight extends Component {
  render() {
    const { maxHeight } = this.props;

    return (
      <MaxHeightWrapper>
        Max height: {maxHeight && maxHeight.value} {maxHeight && maxHeight.unit}
      </MaxHeightWrapper>
    );
  }
}
