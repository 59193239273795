import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import Notification from '../../../_shared/components/Notification';
import {
  AdminSubtitle,
  AdminSectionWrapper
  // WrapperSubtitle
} from '../../_shared/styles';
// import { RadioButton, CheckBox } from '../../../_shared/components/FormElements';
import {
  // BreakLine,
  HyperLink
} from '../../../_shared/components/BasicElements';
// import { PrimaryButton } from '../../../_shared/components/Buttons';
import ChangePasswordModal from '../../../common/modal/changePasswordModal';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import { midBreakPoint } from '../../../_shared/layout-constants';

const Content = styled.div`
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 2;
  }
`;

const SecuritySection = styled(AdminSectionWrapper)`
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    height: 68px;
    margin-bottom: 80px;
  }
`;

// const Description = styled.div`
//   font-size: 14px;
//   margin-bottom: 24px;
//   @media (min-width: ${midBreakPoint}px) {
//     font-size: 16px;
//   }
// `;

// const NotificationSection = styled(AdminSectionWrapper)`
//   height: 978px;
// `;

// const UpdateButton = styled(PrimaryButton)`
//   width: 144px;
//   height: 36px;
//   margin: 24px;
//   float: right;
// `;

// const MobileHr = styled.hr`
//   margin-bottom: 32px;
//   display: block;
//   @media (min-width: ${midBreakPoint}px) {
//     display: none;
//   }
// `;

const SettingsPage = () => {
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const toggleShowSuccessBanner = () =>
    setShowSuccessBanner(!showSuccessBanner);
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);
  return (
    <AdminWrapper title="Settings">
      {showSuccessBanner && (
        <Notification
          type="success"
          text={'Your password has been updated'}
          style={{ marginTop: 0, marginBottom: 20 }}
          hasClose={true}
        />
      )}
      <ChangePasswordModal toggleShowSuccessBanner={toggleShowSuccessBanner} />
      <Content>
        <AdminSubtitle>Security</AdminSubtitle>
        <SecuritySection>
          <HyperLink onClick={() => handleShowModal('SettingsChangePassword')}>
            Change my password
          </HyperLink>
        </SecuritySection>
      </Content>
    </AdminWrapper>
  );
};

// class SettingsPage extends Component {
// state = {
//   systemMessageEmail: true,
//   systemMessageSms: false,
//   directMessageEmail: true,
//   directMessageSms: true,
//   dashboardStatsEmail: true,
//   dashboardStatsSms: false,
//   statsFrequency: '',
//   promotionsEmail: true,
//   promotionsSms: false
// };

// render() {
//   return (
//     <AdminWrapper title="Settings">
//       <ChangePasswordModal />
//       <Content>
//         <AdminSubtitle>Security</AdminSubtitle>
//         <SecuritySection>
//           <HyperLink
//             onClick={() =>
//               this.props.handleShowModal('SettingsChangePassword')
//             }
//           >
//             Change my password
//           </HyperLink>
//         </SecuritySection>
// {
/* <MobileHr />
          <AdminSubtitle>Notifications</AdminSubtitle>
          <NotificationSection>
            <WrapperSubtitle>System messages</WrapperSubtitle>
            <Description>
              Receive system messages, including booking requests
            </Description>
            <CheckBox
              label={'Email'}
              checked={this.state.systemMessageEmail}
              handleChange={() =>
                this.setState({
                  systemMessageEmail: !this.state.systemMessageEmail
                })
              }
            />
            <CheckBox
              label={'Text message'}
              checked={this.state.systemMessageSms}
              handleChange={() =>
                this.setState({
                  systemMessageSms: !this.state.systemMessageSms
                })
              }
            />
            <BreakLine />

            <WrapperSubtitle>Direct messages</WrapperSubtitle>
            <Description>Receive messages from renters and listers</Description>
            <CheckBox
              label={'Email'}
              checked={this.state.directMessageEmail}
              handleChange={() =>
                this.setState({
                  directMessageEmail: !this.state.directMessageEmail
                })
              }
            />
            <CheckBox
              label={'Text message'}
              checked={this.state.directMessageSms}
              handleChange={() =>
                this.setState({
                  directMessageSms: !this.state.directMessageSms
                })
              }
            />
            <BreakLine />

            <WrapperSubtitle>Dashboard updates</WrapperSubtitle>
            <Description>
              Receive key statistical reports for your account
            </Description>
            <CheckBox
              label={'Email'}
              checked={this.state.dashboardStatsEmail}
              handleChange={() =>
                this.setState({
                  dashboardStatsEmail: !this.state.dashboardStatsEmail
                })
              }
            />
            <CheckBox
              label={'Text message'}
              checked={this.state.dashboardStatsSms}
              handleChange={() =>
                this.setState({
                  dashboardStatsSms: !this.state.dashboardStatsSms
                })
              }
            />
            <Description>Frequency</Description>
            <RadioButton
              label={'Never'}
              value={this.state.statsFrequency}
              name="dashboardStatsFrequency"
            />
            <RadioButton
              label={'Weekly'}
              value={this.state.statsFrequency}
              name="dashboardStatsFrequency"
            />
            <RadioButton
              label={'Monthly'}
              value={this.state.statsFrequency}
              name="dashboardStatsFrequency"
            />
            <BreakLine />

            <WrapperSubtitle>Promotions</WrapperSubtitle>
            <Description>
              Receive updates on special offers, market reports and marketing
              information
            </Description>
            <CheckBox
              label={'Email'}
              checked={this.state.promotionsEmail}
              handleChange={() =>
                this.setState({
                  promotionsEmail: !this.state.promotionsEmail
                })
              }
            />
            <CheckBox
              label={'Text messages'}
              checked={this.state.promotionsSms}
              handleChange={() =>
                this.setState({ promotionsSms: !this.state.promotionsSms })
              }
            />

            <UpdateButton>Update</UpdateButton>
            </NotificationSection> */
// }
//         </Content>
//       </AdminWrapper>
//     );
//   }
// }

export default SettingsPage;
