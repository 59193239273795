import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const VERIFY_TOKEN_MUTATION = gql`
  mutation VerifyTokenMutation($token: String!) {
    verifyToken(token: $token) {
      payload
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      payload {
        __typename
        ... on FailUserExists {
          errorMessage
        }
        ... on CreateUserFailOther {
          errorMessage
        }
        ... on CreateUserSuccess {
          user {
            id
            username
            email
            firstName
            lastName
          }
        }
        ... on CreateUserFailEmailRegistered {
          errorMessage
        }
      }
    }
  }
`;

export const RESEND_CONFIRMATION_MUTATION = gql`
  mutation ResendConfirmation($email: String!) {
    resendConfirmation(email: $email) {
      payload {
        __typename
        ... on FailUserExists {
          errorMessage
        }
        ... on FailUserNotRegistered {
          errorMessage
        }
        ... on ResendConfirmationFailOther {
          errorMessage
        }
        ... on ResendConfirmationSuccess {
          message
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($email: String!) {
    forgotPassword(email: $email) {
      payload {
        __typename
        ... on EmailNotVerified {
          errorMessage
        }
        ... on EmailNotRegistered {
          errorMessage
        }
        ... on ResetPasswordLinkSent {
          email
          message
        }
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      payload {
        __typename
        ... on IdenticalPassword {
          errorMessage
        }
        ... on TokenExpired {
          errorMessage
        }
        ... on InvalidToken {
          errorMessage
        }
        ... on ResetPasswordSuccess {
          user {
            id
            username
            email
            firstName
            lastName
          }
          message
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      changePasswordSuccess {
        user {
          id
          firstName
          lastName
          email
        }
        message
      }
    }
  }
`;
