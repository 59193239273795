import ReactGA from 'react-ga';

export const trackBookingStep = (currentStep, step, label) =>
  ReactGA.event({
    category: 'Booking form',
    action:
      currentStep < step ? `Step ${currentStep} complete` : `Step ${step} back`,
    label
  });

export const trackBookingFormAction = (action, label) =>
  ReactGA.event({
    category: 'Booking form',
    action,
    label
  });

export const trackCreateListingStep = step => {
  ReactGA.event({
    category: 'Create Listing form',
    action: step === 'Review' ? 'submitted' : `Step ${step} complete`
  });
};

export const trackHomePage = (action, value) => {
  ReactGA.event({
    category: 'Home Page',
    action: action,
    label: value
  });
};

export const trackSRPFilter = (subaction, value) => {
  ReactGA.event({
    category: 'SRP',
    action: `filter-${subaction}`,
    label: value
  });
};

export const trackPDP = (action, value) => {
  ReactGA.event({
    category: 'PDP',
    action: action,
    label: value
  });
};

export const trackShowMap = toggle => {
  ReactGA.event({
    category: 'SRP',
    action: 'mobile-showMap',
    label: toggle ? 'show' : 'hide'
  });
};

export const trackGallery = (page, label) => {
  ReactGA.event({
    category: page,
    action: 'gallery-buttonClick',
    label: label
  });
};
