import { lazy } from 'react';

export const Sitemap = lazy(() =>
  import(/* webpackChunkName: "sitemap" */ '../home/components/sitemap')
);

export const HowItWorks = lazy(() =>
  import(
    /* webpackChunkName: "how-it-works" */ '../home/components/howItWorks/HowItWorks'
  )
);

export const AboutUs = lazy(() =>
  import(
    /* webpackChunkName: "about-us" */ '../home/components/aboutUs/AboutUs'
  )
);

export const HomeLayout = lazy(() =>
  import(
    /* webpackChunkName: "home" */ '../home/components/homeLayout/HomeLayout'
  )
);

export const ContactUs = lazy(() =>
  import(
    /* webpackChunkName: "contact-us" */ '../home/components/contactUs/contactUs'
  )
);

export const PrivacyPolicy = lazy(() =>
  import(
    /* webpackChunkName: "privacy-policy" */ '../home/components/privacyPolicy'
  )
);

export const TermsAndConditions = lazy(() =>
  import(
    /* webpackChunkName: "terms-and-conditions" */ '../home/components/TermsAndConditions'
  )
);

export const SearchLayout = lazy(() =>
  import(
    /* webpackChunkName: "search-page" */ '../search/components/searchLayout'
  )
);

export const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: "reset-password" */ '../auth/components/resetPasswordModal/ResetPasswordModal'
  )
);

export const CarparkDetailsLayout = lazy(() =>
  import(
    /* webpackChunkName: "carpark-details" */ '../carpark/components/carparkDetailsLayout/CarparkDetailsLayout'
  )
);

export const BookingRequestSent = lazy(() =>
  import(
    /* webpackChunkName: "booking-request-sent" */ '../bookingProcess/feedbacks/requestSent'
  )
);

export const BookingSuccess = lazy(() =>
  import(
    /* webpackChunkName: "booking-success" */ '../bookingProcess/feedbacks/success'
  )
);

export const BookingDeny = lazy(() =>
  import(
    /* webpackChunkName: "booking-deny" */ '../bookingProcess/feedbacks/deny'
  )
);
export const MyListingsPage = lazy(() =>
  import(
    /* webpackChunkName: "my-listing-page" */ '../admin/myListings/myListingsPage/MyListingsPage'
  )
);

export const MyBookingsPage = lazy(() =>
  import(
    /* webpackChunkName: "my-booking-page" */ '../admin/bookings/myBookings'
  )
);

export const ManageBookingsPage = lazy(() =>
  import(
    /* webpackChunkName: "manage-booking-page" */ '../admin/bookings/manageBookings'
  )
);

export const QPMSListPage = lazy(() =>
  import(/* webpackChunkName: "qpms-list-page" */ '../admin/qpms/list')
);

export const QPMSGeneralPage = lazy(() =>
  import(/* webpackChunkName: "qpms-general-page" */ '../admin/qpms/general')
);

export const QPMSHoursPage = lazy(() =>
  import(/* webpackChunkName: "qpms-hours-page" */ '../admin/qpms/hours')
);

export const QPMSRatesPage = lazy(() =>
  import(/* webpackChunkName: "qpms-rates-page" */ '../admin/qpms/rates')
);

export const QPMSFacilitiesPage = lazy(() =>
  import(
    /* webpackChunkName: "qpms-facilities-page" */ '../admin/qpms/facilities'
  )
);

export const QPMSImagesPage = lazy(() =>
  import(/* webpackChunkName: "qpms-images-page" */ '../admin/qpms/images')
);

export const QPMSCarparkDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "qpms-carpark-details-page" */ '../admin/qpms/carparkDetails'
  )
);

export const CreateListingPage = lazy(() =>
  import(
    /* webpackChunkName: "create-listing-page" */ '../admin/createListing/createListingPage/CreateListingPage'
  )
);

export const CreateListingSuccessPage = lazy(() =>
  import(
    /* webpackChunkName: "create-listing-success-page" */ '../admin/createListing/success/CreateListingSuccessPage'
  )
);

export const ProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "profile-page" */ '../admin/profile/profilePage/ProfilePage'
  )
);

export const FavouriteBookingsPage = lazy(() =>
  import(
    /* webpackChunkName: "favourite-booking-page" */ '../admin/favouriteBookings/favouriteBookingsPage/FavouriteBookingsPage'
  )
);

export const BillingsPage = lazy(() =>
  import(/* webpackChunkName: "billings-page" */ '../admin/billings')
);

export const BookingDetailPage = lazy(() =>
  import(
    /* webpackChunkName: "booking-detail-page" */ '../admin/bookings/bookingDetailPage'
  )
);

export const ListingDetailPage = lazy(() =>
  import(
    /* webpackChunkName: "listing-detail-page" */ '../admin/myListings/listingDetailPage/ListingDetailPage'
  )
);
export const DashboardPage = lazy(() =>
  import(
    /* webpackChunkName: "dash-board-page" */ '../admin/dashboard/dashboardPage/DashboardPage'
  )
);
export const SettingsPage = lazy(() =>
  import(
    /* webpackChunkName: "settings-page" */ '../admin/settings/settingsPage/SettingsPage'
  )
);
export const ServiceNotAvailable = lazy(() =>
  import(
    /* webpackChunkName: "service-not-available" */ '../error/ServiceNotAvailable'
  )
);

export const UnderMaintenance = lazy(() =>
  import(
    /* webpackChunkName: "under-maintenance" */ '../error/UnderMaintenance'
  )
);

export const PageNotFound = lazy(() =>
  import(/* webpackChunkName: "404-page-not-found" */ '../error/PageNotFound')
);

export const RetryPayment = lazy(() =>
  import(
    /* webpackChunkName: "retry-payment" */ './components/payment/retryPayment'
  )
);

export const PaymentExpired = lazy(() =>
  import(
    /* webpackChunkName: "payment-expired" */ './components/payment/paymentExpired'
  )
);

export const ContactInfo = lazy(() =>
  import(
    /* webpackChunkName: "contact-info" */ '../common/components/handleEmailButtons'
  )
);

export const HandleEmailButton = lazy(() =>
  import(
    /* webpackChunkName: "handle-email-button" */ '../common/components/handleEmailButtons'
  )
);

export const HandleBookingRequest = lazy(() =>
  import(
    /* webpackChunkName: "handle-booking-request" */ '../common/components/handleEmailButtons'
  )
);

export const HandleBondRefund = lazy(() =>
  import(
    /* webpackChunkName: "handle-bond-refund" */ '../common/components/handleEmailButtons'
  )
);

export const HandleBookingCompletion = lazy(() =>
  import(
    /* webpackChunkName: "handle-booking-completion" */ '../common/components/handleEmailButtons'
  )
);

export const HandleReimbursementConfirmation = lazy(() =>
  import(
    /* webpackChunkName: "handle-reimbursement-confirmation" */ '../common/components/handleEmailButtons'
  )
);

export const HandleCancellation = lazy(() =>
  import(
    /* webpackChunkName: "handle-cancellation" */ '../common/components/handleEmailButtons'
  )
);

export const VehiclesInfo = lazy(() =>
  import(/* webpackChunkName: "vehicles-info" */ './components/vehiclesInfo')
);
