import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { FacebookShareButton } from 'react-share';
import Modal from '../../../_shared/components/ModalWrapper';
import Clipboard from 'react-clipboard.js';
import { trackPDP } from '../../../_shared/tracker';
import { ImgixUrl } from '../../../_shared/utils';
import './index.css';

const SocialLink = styled.a`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '32px'};
  text-decoration: none;
  padding: 0 7px;
`;

const FacebookShare = styled(FacebookShareButton)`
  margin-bottom: 32px;
`;

const CopyLink = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '32px'};
  text-decoration: none;
`;

const Icon = styled.img`
  margin-right: 12px;
`;

const LinkText = styled.span`
  line-height: 1.11;
  height: 20px;
  font-size: 19px;
`;
const ShareModal = props => {
  const [url, setUrl] = useState(null);
  const { state, actions } = useContext(ModalStoreContext);

  useEffect(() => {
    const { id } = props;
    if (id) {
      setUrl(
        `${window.location.origin}/carpark/parking-space/${id}/?priceUnit=D`
      );
    } else {
      setUrl(window.location.href);
    }
  }, [props]);
  const onSuccessCopy = () => {
    trackPDP('share', 'copyPath');
    alert('URL copied!');
  };
  const emailClick = () => {
    trackPDP('share', 'email');
    window.open(
      `mailto:?subject=Quokka Parking | Check out this parking space!&body=Hi! \n\nCheck out this awesome parking space I found on Quokka Parking ${this.state.url}`
    );
  };
  const { invalid } = props;
  const isOpen = state.activeModal.includes('ShareModal');

  return (
    <Modal
      title="Share this parking space"
      handleClose={() => actions.handleHideModal('ShareModal')}
      contentLabel="Share this parking space"
      isOpen={isOpen}
      buttonDisabled={invalid}
      modalWidth="400px"
    >
      <FacebookShare url={url}>
        <SocialLink
          target="_blank"
          onClick={() => trackPDP('share', 'facebook')}
        >
          <Icon
            src={`${ImgixUrl}/web-image-assets/icons/socialMedia/facebook-black.svg`}
          />
          <LinkText>Facebook</LinkText>
        </SocialLink>
      </FacebookShare>
      <CopyLink>
        <Clipboard
          data-clipboard-text={url}
          onSuccess={onSuccessCopy}
          style={{ background: 'white' }}
        >
          <Icon
            src={`${ImgixUrl}/web-image-assets/icons/socialMedia/link.svg`}
          />
          <LinkText>Copy link</LinkText>
        </Clipboard>
      </CopyLink>
      <SocialLink marginBottom="0" onClick={emailClick}>
        <Icon
          src={`${ImgixUrl}/web-image-assets/icons/socialMedia/email.svg`}
        />
        <LinkText>Email</LinkText>
      </SocialLink>
    </Modal>
  );
};

export default ShareModal;
