import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { UserContext } from '../../../common/context/user';
import { reduxForm, Field } from 'redux-form';
import './index.css';
import { ReduxTextArea } from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';

const Message = styled.div``;

const ContactListerModal = ({ getUserInfo, email, person, handleSubmit }) => {
  const { user } = useContext(UserContext);
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);
  const isOpen = activeModal.includes('ContactListerModal');

  const emailLister = value => {
    const subject = `Quokka Parking | a message from ${user.firstName} ${user.lastName}`;
    const body = `${value.message}\n\nThis email is sent by ${user.firstName} ${user.lastName}. \n\nYou can contact the renter by replying to this email address: ${user.email}`;
    window.open(
      `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
    );
    handleHideModal('ContactListerModal');
  };

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title={`Contact ${person}`}
      handleClose={() => handleHideModal('ContactListerModal')}
      handleSubmit={handleSubmit(emailLister)}
      contentLabel="Contact Lister Modal"
      buttonLabel="Send message to space manager"
      isOpen={isOpen}
    >
      <Message>
        <Field
          name="message"
          component={ReduxTextArea}
          height={300}
          tooltip={'Contact space manager to address your enquiry'}
        />
      </Message>
    </Modal>
  );
};

const formOptions = {
  form: 'ContactListerModal'
};

export default reduxForm(formOptions)(ContactListerModal);
