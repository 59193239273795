import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import NavHeader from '../../../_shared/components/navHeader/NavHeader';
import GridStatus from './_gridStatus';
import Notification from '../../../_shared/components/Notification';
import { ImgixUrl } from '../../../_shared/utils';

const Content = styled.div`
  width: 100%;
  margin-top: 64px;
`;

const GridView = () => {
  const { status, subStatus } = useParams();
  const [tab, setTab] = useState(status || 'currentbookings');
  return (
    <div>
      <NavHeader
        toggleNav={setTab}
        active={tab}
        toggleItems={['Current bookings', 'History bookings']}
        switchView="Switch to Manager view"
        switchViewUrl="/admin/bookings/manage-bookings"
        switchViewIcon={`${ImgixUrl}/web-image-assets/icons/admin/lister-icon.svg`}
      />
      <Content>
        {subStatus === 'cancelled' && (
          <Notification
            style={{ marginBottom: 24 }}
            type="success"
            text={
              'Booking cancellation has been submitted, please check your email to confirm.'
            }
          />
        )}
        <GridStatus status={tab} />
      </Content>
    </div>
  );
};

export default GridView;
