import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import { ModalStoreContext } from '../../../common/context/modal/store';
import {
  GET_USER_INFO,
  ADD_CREDIT_CARD
} from '../../../common/graphql/adminSchema';
import Modal from '../../../_shared/components/ModalWrapper';
import { ExpiryInput } from '../../../_shared/components/FormElements';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import {
  autoDetectCreditCardType,
  renderCardIssuerLogo
} from '../../../_shared/utils';
import { panSecretEncrypt } from '../../../_shared/security';

const Cardholder = styled.div``;

const CardNumber = styled.div``;

const CardInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Expiry = styled.div`
  min-width: 175px;
  flex: 0 1 175px;
  margin-right: 20px;
`;

const CVV = styled.div`
  max-width: 175px;
  flex: 0 1 175px;
`;

const validate = values => {
  const numbers = /[0-9]/g;
  const errors = {};
  if (!values.cardHolder) {
    errors.cardHolder = "Please enter card holder's name";
  }
  if (!values.cardNumber) {
    errors.cardNumber = 'Please enter card number';
  } else if (!values.cardNumber.replace(/ /g, '').match(/[0-9]{16}/)) {
    errors.cardNumber = 'Please enter a vaild card number';
  }
  if (!values.cvv) {
    errors.cvv = "Please enter card's CVV number";
  } else if (!values.cvv.match(/[0-9]{3}/)) {
    errors.cvv = 'Please enter vaild CVV number';
  }

  const thisYear = moment().year() - 2000;
  if (!values.month || !values.year) {
    errors.month = 'Please enter card expire date';
    errors.year = 'Please enter card expire date';
  } else if (
    !values.month.match(numbers) ||
    !values.year.match(numbers) ||
    values.month > 12 ||
    Number(values.year) < thisYear ||
    (Number(values.year) === thisYear &&
      Number(values.month) <= moment().month())
  ) {
    errors.month = 'Please enter a vaild expire date';
    errors.year = 'Please enter a vaild expire date';
  }
  return errors;
};

const AddPaymentMethodModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardIssuerLogo, setCardIssuerLogo] = useState(null);
  const { state, actions } = useContext(ModalStoreContext);

  useEffect(() => {
    props.initialize({});
  }, []); //eslint-disable-line

  const addPaymentMethod = async value => {
    setLoading(true);
    // for now, '20' prefix for the year entered is fine
    var expiryDate = moment(['20' + value.year, parseInt(value.month, 10) - 1])
      .endOf('month')
      .format('YYYY-MM-DD');
    let payload = {
      cardHolder: value.cardHolder,
      cardNumber: value.cardNumber.replace(/ /g, ''),
      expiryDate: expiryDate,
      cvv: value.cvv
    };
    let userInfo = props.getUserInfo;
    let filename = await panSecretEncrypt(
      userInfo && userInfo.getUserInfo.id,
      payload,
      'credit card'
    );
    //console.log('@@@ filename @@@', filename);
    const { errors } = await props.addCreditCard({
      errorPolicy: 'all',
      variables: {
        fileName: filename
      }
    });
    if (errors) {
      setLoading(false);
      //console.log(errors);
      setError(errors[0].message);
      return;
    }
    // update credit cards dropdown list in booking process step 4
    if (props.updatePaymentMethod) {
      props.updatePaymentMethod();
    }
    setLoading(false);
    setError(null);
    actions.handleHideModal('AddPaymentMethod');
  };

  const displayIssuerLogo = value => {
    const parsedCardnumber = value.replace(/ /g, '');
    const getCardIssuer = autoDetectCreditCardType(parsedCardnumber);
    const getCardIssuerLogo = renderCardIssuerLogo(getCardIssuer);
    //console.log('parsedCardnumber', parsedCardnumber);
    //console.log('getCardIssuer', getCardIssuer);
    //console.log('getCardIssuerLogo', getCardIssuerLogo);
    setCardIssuerLogo(getCardIssuerLogo);
  };

  const { handleSubmit, invalid } = props;
  const isOpen = state.activeModal.includes('AddPaymentMethod');
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Add a new credit card"
      handleClose={() => actions.handleHideModal('AddPaymentMethod')}
      handleSubmit={handleSubmit(addPaymentMethod.bind(this))}
      contentLabel="Add Payment Method Card"
      buttonLabel="Save"
      isOpen={isOpen}
      loading={loading}
      buttonDisabled={invalid}
      notification={error}
      modalDescription="Dear customer, we do not store your credit card information on our servers. All payments that are made through this site are processed securely and externally by one or more third party payment gateway providers, such as Stripe. Unless you expressly consent otherwise, we do not see or have access to any personal information that you may provide to such third party payment gateway providers, other than information that is required in order to process your payment. For more info, please refer to our Privacy Policy."
      showIcon={true}
    >
      <Cardholder>
        <Field
          name="cardHolder"
          label="Card holder"
          component={ReduxInputField}
        />
      </Cardholder>

      <CardNumber>
        <Field
          name="cardNumber"
          label="Card number"
          normalize={value =>
            value
              .replace(/\W/gi, '')
              .replace(/(.{4})/g, '$1 ')
              .trim()
          }
          maxLength={19}
          icon={cardIssuerLogo}
          onChange={e => displayIssuerLogo(e.target.value)}
          component={ReduxInputField}
        />
      </CardNumber>

      <CardInfoWrapper>
        <Expiry>
          <ExpiryInput label="Expiry" />
        </Expiry>

        <CVV>
          <Field
            name="cvv"
            label="CVV"
            maxLength={3}
            component={ReduxInputField}
          />
        </CVV>
      </CardInfoWrapper>
    </Modal>
  );
};

const formOptions = {
  form: 'AddPaymentMethodModal',
  validate,
  initialValues: {}
};

export default compose(
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  graphql(ADD_CREDIT_CARD, { name: 'addCreditCard' }),
  reduxForm(formOptions)
)(AddPaymentMethodModal);
