import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  RubikSemiBold,
  graphite,
  greyLight,
  transparentGrey,
  greyDark,
  greyExtraLight,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const Hr = styled.hr`
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  text-align: left;
  color: ${graphite};
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: ${RubikSemiBold};
  text-align: left;
  color: ${graphite};
  margin-bottom: 18px;
  margin-top: 40px;
`;

const Section = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 90px;
  }
`;
const P = styled.p`
  color: ${transparentGrey};
  margin-bottom: 12px;
`;
const VehicleWithLabelBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 167px;
`;

const Wrapper = styled.div`
  padding: 24px;
  overflow-y: scroll;
  height: 95vh;
  @media (min-width: ${midBreakPoint}px) {
    width: 900px;
    margin: 0 auto;
  }
`;

const Img = styled.img`
  width: 167px;
  height: 63px;
  margin-bottom: 12px;
`;

const FlexWrapRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const VehicleWithLabelWrapper = styled(FlexWrapRow)`
  justify-content: space-between;
`;

const FeatureBox = styled.div`
  padding: 12px;
  border: 1px solid ${greyExtraLight};
  width: 155px;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  margin-left: 13px;
`;

const FeatureBoxRow = styled.div`
  display: flex;
`;

const FeatureBoxCell = styled.div`
  &:first-child {
    flex: 1.2;
    color: ${greyDark};
  }
  &:nth-child(2) {
    flex: 0.8;
  }
`;

const VehicleWithLabelAndFeaturesBox = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;
`;

const VehicleWithLabelAndFeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
`;

const LeftArrow = styled.img`
  width: 22.4px;
  /* height: 22.4px; */
`;

const BackBar = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${greyLight};
  height: 48px;
  padding: 0 20px;
  margin: 56px 0 0 0;

  @media (min-width: ${midBreakPoint}px) {
    padding: 0 36px;
    height: 72px;
  }
`;

const Container = styled.div`
  display: ${({ isShow }) => (isShow ? 'inline' : 'none')};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  background: white;
`;

const VehicleWithLabel = ({ label, icon }) => (
  <VehicleWithLabelBox>
    <Img
      alt={label}
      src={`${ImgixUrl}/web-image-assets/icons/vehicles_big/${icon}.png`}
    />
    <div>{label}</div>
  </VehicleWithLabelBox>
);

const VehicleFeatures = ({ width, length, clearance }) => (
  <FeatureBox>
    <FeatureBoxRow>
      <FeatureBoxCell>width:</FeatureBoxCell>
      <FeatureBoxCell>{width}</FeatureBoxCell>
    </FeatureBoxRow>
    <FeatureBoxRow>
      <FeatureBoxCell>length:</FeatureBoxCell>
      <FeatureBoxCell>{length}</FeatureBoxCell>
    </FeatureBoxRow>
    <FeatureBoxRow>
      <FeatureBoxCell>clearance:</FeatureBoxCell>
      <FeatureBoxCell>{clearance}</FeatureBoxCell>
    </FeatureBoxRow>
  </FeatureBox>
);

const VehicleWithLabelAndFeatures = ({
  label,
  icon,
  width,
  length,
  clearance
}) => (
  <VehicleWithLabelAndFeaturesBox>
    <VehicleWithLabel label={label} icon={icon} />
    <VehicleFeatures width={width} length={length} clearance={clearance} />
  </VehicleWithLabelAndFeaturesBox>
);
const VehiclesInfo = ({ isShow = true, hide }) => {
  return (
    <Container isShow={isShow}>
      <BackBar>
        <LeftArrow
          onClick={hide}
          src={`${ImgixUrl}/web-image-assets/icons/Left.png`}
          alt="arrow left"
        />
      </BackBar>
      <Wrapper>
        <Title>What do the vehicle types mean?</Title>
        <Section>
          <Subtitle>Passenger cars</Subtitle>
          <P>Most parking spaces work well for common passenger cars.</P>
          <VehicleWithLabelWrapper>
            <VehicleWithLabel label="Hatchback" icon="hatchback" />
            <VehicleWithLabel label="Sedan" icon="sedan" />
            <VehicleWithLabel label="Convertible/Coupe" icon="convertible" />
            <VehicleWithLabel label="Wagon" icon="suv" />
          </VehicleWithLabelWrapper>
        </Section>
        <Hr />
        <Section>
          <Subtitle>Commercial cars</Subtitle>
          <P>
            Some commercial cars fit well with our most parking spaces. However,
            you should always check specifications before determine the parking
            space suitability for your vehicle.
          </P>
          <VehicleWithLabelAndFeaturesWrapper>
            <VehicleWithLabelAndFeatures
              label="Pickup / Ute"
              icon="pickup-ute"
              width="2.23m"
              length="5.3m"
              clearance="1.84m"
            />
            <VehicleWithLabelAndFeatures
              label="Cab-chasis"
              icon="pickup-cab-chasis"
              width="2.385m"
              length="6.283m"
              clearance="2.25m"
            />
            <VehicleWithLabelAndFeatures
              label="Van / People Mover"
              icon="van-people-mover"
              width="1.98m"
              length="5.168m"
              clearance="1.928m"
            />
            <VehicleWithLabelAndFeatures
              label="Light-truck"
              icon="mini-truck"
              width="2.18m"
              length="5.27m"
              clearance="2.356m"
            />
            <VehicleWithLabelAndFeatures
              label="Mini-bus"
              icon="mini-bus"
              width="2.55m"
              length="12.93m"
              clearance="3.68m"
            />
            <VehicleWithLabelAndFeatures
              label="Camper"
              icon="camper"
              width="2.1m"
              length="3.08m"
              clearance="1.99m"
            />
          </VehicleWithLabelAndFeaturesWrapper>
        </Section>
        <Hr />
        <Section>
          <Subtitle>Got extra space?</Subtitle>
          <P>
            Your parking space may suit vehicles with a trailor attached if it’s
            extra long.
          </P>
          <FlexWrapRow>
            <VehicleWithLabel label="Trailer (canopy type)" icon="trailor" />
            <VehicleWithLabel label="Trailer (tray type)" icon="trailor-b" />
          </FlexWrapRow>
        </Section>
      </Wrapper>
    </Container>
  );
};

export default withRouter(VehiclesInfo);
