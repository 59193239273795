import React, { Component, Fragment, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import parseAddress from 'australia-address-parser';
//
import { Review } from '../../../admin/createListing/review';
import Notification from '../../../_shared/components/Notification';
import MoreOption from './components/moreOption/MoreOption';
import { GET_PARKING_SPACE } from '../../../common/graphql/carparkSchema';
import { ADD_PARKING_SPACE } from '../../../common/graphql/adminSchema';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { AdminSubtitle } from '../../_shared/styles';
import { midBreakPoint } from '../../../_shared/layout-constants';
import PopupAlert from '../../../_shared/components/PopupAlert';
import { FullWidthBorderButton } from '../../../_shared/components/Buttons';
import { GET_PARKING_SPACE_CONFIRMED_BOOKINGS } from '../../../common/graphql/bookingSchema';
import {
  // submitCompletedParkingForm,
  constructPriceOptions
} from '../../createListing/createListingPage/_submit';
import validator from '../../createListing/createListingPage/_validation';
import {
  ALL_VEHICLE_TYPES,
  ALL_AMENITIES,
  ALL_HEIGHT_OPTIONS,
  ALL_ACCESS_TYPES,
  ALL_PARKING_TYPES,
  UPDATE_PARKING_AVAILABILITY,
  UPDATE_PARKING_PRICING,
  UPDATE_PARKING_CONTACTS,
  UPDATE_PARKING_DETAILS,
  UPDATE_PARKING_FEATURES,
  UPDATE_PARKING_ADDRESS,
  UPDATE_PARKING_STATUS
} from '../../../common/graphql/carparkSchema';
import './ListingDetailPage.css';

const Section = styled.div`
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 50px;
  }
`;

const Subtitle = styled(AdminSubtitle)`
  margin-bottom: 12px;
  cursor: pointer;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 26px;
  }
`;

const MoreOptionButton = styled(FullWidthBorderButton)`
  width: 200px;
`;

const MobileHr = styled.hr`
  display: block;
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

class ListingDetailPage extends Component {
  state = {
    form: {
      accessInstruction: null,
      accessTypes: null,
      address: null,
      amenities: null,
      contacts: null,
      description: null,
      id: null, //true listing id
      maxHeight: null,
      page: null, // only in draft
      parkingTypes: null,
      priceOptions: null,
      suitableVehicleTypes: null,
      title: null,
      geolocation: null,
      daysAvailable: null,
      availableDateFrom: moment().toISOString(), // availableFromDate in draft
      deliveryInstruction: null, // keyDeliveryInstruction in draft
      lot: null, // parkingLotNumber in draft
      status: this.props.match.path.includes('live') ? 'A' : 'I'
    },
    errorMsg: null,
    infoMsg: null,
    loading: false,
    showMoreOption: false
  };

  componentDidUpdate(prevProps) {
    if (
      !this.props.getParkingSpace.loading &&
      prevProps.getParkingSpace.loading
    ) {
      const parking = this.props.getParkingSpace.getParkingSpace;
      //console.log('@@@parking', parking);
      this.restoreFormValues(parking);
    }
  }

  restoreFormValues = parking => {
    const parsedContacts =
      JSON.parse(parking.contacts) && JSON.parse(parking.contacts);
    const parsedPriceOptions = JSON.parse(parking.priceOptions);
    const parsedFilters = JSON.parse(parking.filters);
    this.updateFormValue({
      ...parking,
      accessTypes: parking.accessTypes.map(s => s.id),
      amenities: parking.amenities.map(s => s.id),
      status: parking.status,
      maxHeight: parking.maxHeight && parking.maxHeight.id,
      parkingTypes: parking.parkingTypes.map(s => s.id),
      suitableVehicleTypes: parking.suitableVehicleTypes.map(s => s.id),
      address: parking.address.value,
      ...(parking.id && { id: parking.id }),

      ...(parsedContacts && { contacts: [JSON.stringify(parsedContacts[0])] }), // to avoid weird [[[]]]
      ...(parsedPriceOptions && {
        priceOptions: parsedPriceOptions.map(p => JSON.stringify(p))
      }),
      ...(parsedFilters && {
        daysAvailable: [JSON.stringify(parsedFilters[0])]
      })
    });
    const parsedAddr = parseAddress.parseLocation(parking.address.value);
    const prices = parsedPriceOptions.reduce((obj, item) => {
      obj[item.type.description] = item.value;
      return obj;
    }, {});

    this.props.initialize({
      ...(parsedAddr && {
        streetNumber: parsedAddr.streetNumber,
        streetName: parsedAddr.streetName,
        streetType: parsedAddr.streetType,
        suburb: parsedAddr.suburb,
        postcode: parsedAddr.postcode,
        state: {
          value: parsedAddr.state,
          label: parsedAddr.state
        },
        country: 'Australia'
      }),
      // because select field comes in object format
      ...(parking.accessTypes &&
        parking.accessTypes.length > 0 && {
          accessTypes: {
            label: parking.accessTypes[0].name,
            value: parking.accessTypes[0].name
          }
        }),
      ...(parking.parkingTypes &&
        parking.parkingTypes.length > 0 && {
          parkingTypes: {
            label: parking.parkingTypes[0].name,
            value: parking.parkingTypes[0].name
          }
        }),
      status: parking.status,
      amenities: parking.amenities,
      ...(parking.maxHeight && {
        maxHeight: {
          label: parking.maxHeight.value,
          value: parking.maxHeight.value
        }
      }),
      suitableVehicles: parking.suitableVehicleTypes,

      listingTitle: parking.title,
      description: parking.description,
      parkingLotNumber: parking.lot,
      accessInstruction: parking.accessInstruction,
      deliveryInstruction: parking.deliveryInstruction,

      ...(parsedContacts[0] && {
        contactName: parsedContacts[0].contactName,
        contactNumber: parsedContacts[0].contactNumber,
        contactType: parsedContacts[0].contactType,
        email: parsedContacts[0].email
      }),

      ...(prices && {
        daily: prices.daily,
        weekly: prices.weekly,
        monthly: prices.monthly,
        deposit: prices.deposit
      }),

      availableDateFrom:
        parking.availableDateFrom && moment(parking.availableDateFrom),
      ...(parsedFilters[0] && {
        daysAvailable: parsedFilters[0].value.map(day => ({ id: day }))
      })
      // TODO: filters
    });
  };

  toggleListingStatus = async () => {
    let { getParkingSpaceConfirmedBookings } = this.props;

    if (
      getParkingSpaceConfirmedBookings &&
      getParkingSpaceConfirmedBookings.getParkingSpaceConfirmedBookings &&
      getParkingSpaceConfirmedBookings.getParkingSpaceConfirmedBookings.length >
        0
    ) {
      this.props.handleShowModal('DeactivateListingAlert');
    } else {
      await this.updateFormValue({
        status: this.state.form.status === 'A' ? 'I' : 'A'
      });
      if (this.state.form.status === 'I') {
        let { getParkingSpace } = this.props;
        getParkingSpace &&
          this.updateInfoMsg(
            `Your parking space at ${getParkingSpace.getParkingSpace.address.value} has been deactivated.`
          );
      } else {
        this.updateInfoMsg(null);
      }
      await this.props.updateParkingStatus({
        variables: {
          id: this.state.form.id,
          status: this.state.form.status === 'A' ? 'ACTIVE' : 'INACTIVE'
        }
      });
    }
  };

  toggleMoreOptions = e => {
    this.setState({ showMoreOption: !this.state.showMoreOption });
  };

  updateInfoMsg = e => {
    this.setState({ infoMsg: e });
  };

  // General use
  updateState = state => {
    // this.setState(state, () => {
    //   //console.log('@@@updated state', this.state);
    // });
    this.setState(state);
  };

  updateFormValue = values => {
    return new Promise(resolve => {
      this.setState(
        {
          form: {
            ...this.state.form,
            ...values
          }
        },
        () => {
          resolve();
          //console.log('@@form state updated', this.state);
        }
      );
    });
  };

  composeLocationFormValues = async ({
    streetNumber,
    streetName,
    streetType,
    suburb,
    parkingLotNumber,
    state: { value: state },
    postcode,
    country
  }) => {
    const address = `${streetNumber} ${streetName} ${streetType}. ${suburb} ${state} ${postcode}, Australia`; // TODO: remove hardcode
    await this.updateFormValue({
      address,
      title: address,
      page: 'Location',
      lot: parkingLotNumber
    });
    this.props.change(
      'listingTitle',
      `An affordable parking lot on ${streetName} ${streetType}`
    );
    this.props.change(
      'description',
      `A parking lot on ${streetName} ${streetType} is available.`
    );
    // submitCompletedParkingForm({
    //   addParkingSpace: this.props.addParkingSpace,
    //   updateState: this.updateState,
    //   form: this.state.form
    // });

    await this.props.updateParkingAddress({
      variables: {
        id: this.state.form.id,
        address: this.state.form.address,
        lot: this.state.form.lot
      }
    });
  };

  // features
  composeFeaturesFormValues = async values => {
    const { allAccessTypes, allParkingTypes, allHeightOptions } = this.props;
    if (
      allHeightOptions.loading ||
      allAccessTypes.loading ||
      allParkingTypes.loading
    )
      return null;
    const {
      streetName,
      parkingTypes,
      streetType,
      accessTypes,
      maxHeight,
      amenities,
      status,
      suitableVehicles
    } = values;

    // const { streetName, streetType } = this.state.locationState;
    await this.updateFormValue({
      page: 'Features',
      accessTypes: allAccessTypes.allAccessTypes
        .filter(a => accessTypes.value === a.name)
        .map(a => a.id),
      parkingTypes: allParkingTypes.allParkingTypes
        .filter(a => parkingTypes.value === a.name)
        .map(a => a.id),
      maxHeight: allHeightOptions.allHeightOptions
        .filter(a => maxHeight.value === a.value)
        .map(a => a.id)[0],
      amenities: amenities.map(a => a.id),
      status: status,
      suitableVehicleTypes: suitableVehicles.map(s => s.id)
    });
    this.props.change(
      'description',
      `A parking lot on ${streetName} ${streetType} is available. This space comes with ${amenities
        .map(a => a.name)
        .join(', ')}. It is suitable for ${suitableVehicles
        .map(a => a.name)
        .join(', ')}. Book it today.`
    );

    await this.props.updateParkingFeatures({
      variables: {
        id: this.state.form.id,
        maxHeight: this.state.form.maxHeight,
        accessTypes: this.state.form.accessTypes,
        amenities: this.state.form.amenities,
        parkingTypes: this.state.form.parkingTypes,
        suitableVehicleTypes: this.state.form.suitableVehicleTypes
      }
    });
  };

  // details
  composeDetailsFormValues = async values => {
    const {
      listingTitle,
      accessInstruction,
      deliveryInstruction,
      description
    } = values;
    // this.updateState({
    //   detailsState: e
    // });
    await this.updateFormValue({
      page: 'Details',
      title: listingTitle,
      accessInstruction,
      description,
      deliveryInstruction: deliveryInstruction
    });
    await this.props.updateParkingDetails({
      variables: {
        id: this.state.form.id,
        title: this.state.form.title,
        description: this.state.form.description,
        deliveryInstruction: this.state.form.deliveryInstruction,
        accessInstruction: this.state.form.accessInstruction
      }
    });
  };

  // contacts
  composeContactFormValues = async ({
    contactName,
    contactNumber,
    email,
    contactType
  }) => {
    this.props.change('contactName', contactName);
    this.props.change('contactNumber', contactNumber);
    this.props.change('contactType', contactType);
    this.props.change('email', email);
    await this.updateFormValue({
      page: 'Contact',
      contacts: [
        JSON.stringify({
          contactName: contactName,
          contactNumber: contactNumber,
          email: email,
          contactType: contactType
        })
      ]
    });
    await this.props.updateParkingContacts({
      variables: {
        id: this.state.form.id,
        contacts: this.state.form.contacts
      }
    });
  };

  // pricing
  composePricingFormValues = async ({ daily, weekly, monthly, deposit }) => {
    await this.updateFormValue({
      page: 'Pricing',
      priceOptions: constructPriceOptions({ daily, weekly, monthly, deposit })
    });
    await this.props.updateParkingPricing({
      variables: {
        id: this.state.form.id,
        priceOptions: this.state.form.priceOptions
      }
    });
  };

  composeAvailabilityFormValues = async ({
    availableDateFrom = moment(),
    daysAvailable
  }) => {
    await this.updateFormValue({
      page: 'Availability',
      availableDateFrom: availableDateFrom.toISOString(),
      daysAvailable: [
        JSON.stringify({
          key: 'dates',
          type: 'within',
          value: daysAvailable.map(d => d.id)
        })
      ]
    });
    this.props.change('availableDateFrom', availableDateFrom);
    await this.props.updateParkingAvailability({
      variables: {
        id: this.state.form.id,
        availableDateFrom: this.state.form.availableDateFrom,
        filters: this.state.form.daysAvailable
      }
    });
  };

  checkMissingProps = formSyncErrors => {
    const errorsArray = Object.values(formSyncErrors);
    const missingPropsErrors =
      errorsArray && errorsArray.length > 0 && errorsArray.join(', ');
    return missingPropsErrors;
  };

  render() {
    const { errorMsg, infoMsg, loading, showMoreOption } = this.state;
    // if (this.props.addParkingSpace.loading) return null;

    const { handleSubmit, change, formSyncErrors } = this.props;
    return (
      <AdminWrapper title="Listing details" loading={loading}>
        <PopupAlert
          isAlert
          contentLabel="DeactivateListingAlert"
          type="DeactivateListingAlert"
          title="Need to know"
          message="Are you sure to take this listing offline? Once deactivated, renters will not find your listing in their search results."
          button="Deactivate"
          secondaryButton="Cancel"
          disableClose={false}
        />
        {errorMsg && (
          <Notification
            type="alert1"
            text={errorMsg || this.checkMissingProps(formSyncErrors)}
            hasClose={true}
            style={{ marginBottom: 20 }}
          />
        )}
        {infoMsg && (
          <Notification
            type="norm1"
            text={infoMsg}
            hasClose={true}
            style={{ marginBottom: 20 }}
          />
        )}
        <Fragment>
          <form onSubmit={handleSubmit(() => {})} noValidate>
            <Review
              composedValues={this.state.form}
              composeLocationFormValues={this.composeLocationFormValues}
              composeFeaturesFormValues={this.composeFeaturesFormValues}
              composeDetailsFormValues={this.composeDetailsFormValues}
              composeContactFormValues={this.composeContactFormValues}
              composePricingFormValues={this.composePricingFormValues}
              composeAvailabilityFormValues={this.composeAvailabilityFormValues}
              updateFormValue={this.updateFormValue}
              change={change}
              parkingId={this.state.form.id}
              isPublic={true}
              name="listingDetailsPage"
              updateState={this.updateState}
            />
            <MobileHr />
            {showMoreOption ? (
              <Section>
                <Subtitle onClick={() => this.toggleMoreOptions()}>
                  Active listing
                </Subtitle>
                <MoreOption
                  status={this.state.form.status === 'A' ? true : false}
                  toggleListingStatus={this.toggleListingStatus}
                  toggleEnabled={true}
                />
              </Section>
            ) : (
              <Section>
                <MoreOptionButton
                  type="submit"
                  onClick={() => this.toggleMoreOptions()}
                >
                  More options
                </MoreOptionButton>
              </Section>
            )}
          </form>
        </Fragment>
      </AdminWrapper>
    );
  }
}

const ListingDetailPageWithModal = props => {
  const {
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);
  const isAuthenticated = localStorage.getItem('authenticate') === 'true';
  return (
    <ListingDetailPage
      handleHideModal={handleHideModal}
      handleShowModal={handleShowModal}
      isAuthenticated={isAuthenticated}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PARKING_SPACE, {
    name: 'getParkingSpace',
    options: props => ({
      variables: {
        id: props.match.params.id
      },
      errorPolicy: 'all'
    }),
    handleError: false
  }),
  graphql(ADD_PARKING_SPACE, { name: 'addParkingSpace' }),
  //
  graphql(UPDATE_PARKING_AVAILABILITY, { name: 'updateParkingAvailability' }),
  graphql(UPDATE_PARKING_PRICING, { name: 'updateParkingPricing' }),
  graphql(UPDATE_PARKING_CONTACTS, { name: 'updateParkingContacts' }),
  graphql(UPDATE_PARKING_DETAILS, { name: 'updateParkingDetails' }),
  graphql(UPDATE_PARKING_FEATURES, { name: 'updateParkingFeatures' }),
  graphql(UPDATE_PARKING_ADDRESS, { name: 'updateParkingAddress' }),
  graphql(UPDATE_PARKING_STATUS, { name: 'updateParkingStatus' }),
  //
  graphql(GET_PARKING_SPACE_CONFIRMED_BOOKINGS, {
    name: 'getParkingSpaceConfirmedBookings',
    options: props => ({
      variables: {
        parkingId:
          props.getParkingSpace &&
          props.getParkingSpace.getParkingSpace &&
          props.getParkingSpace.getParkingSpace.id
      }
    }),
    handleError: false
  }),
  graphql(ALL_PARKING_TYPES, { name: 'allParkingTypes' }),
  graphql(ALL_ACCESS_TYPES, { name: 'allAccessTypes' }),
  graphql(ALL_HEIGHT_OPTIONS, { name: 'allHeightOptions' }),
  graphql(ALL_AMENITIES, { name: 'allAmenities' }),
  graphql(ALL_VEHICLE_TYPES, { name: 'allVehicleTypes' }),
  connect(state => ({
    formSyncErrors: getFormSyncErrors('listingDetailsPage')(state)
  })),
  reduxForm({ form: 'listingDetailsPage', validate: validator })
)(ListingDetailPageWithModal);
