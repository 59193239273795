import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import moment from 'moment-timezone';
import shortid from 'shortid';
import { Row, Col } from 'react-flexa';
import InvoiceRow from './components/invoiceRow';
import Modal from '../../../_shared/components/ModalWrapper';

const Header = styled.div`
  grid-column: 2 / 12;
  grid-row: 2;
  padding: 24px 24px 0 24px;
`;

const InvoicesSection = styled.div`
  grid-column: 2 / 12;
  grid-row: 3;
  padding: 24px 24px 0 24px;
  overflow: auto;
`;

const InvoiceModal = props => {
  const { invoices, invoiceNum } = props;
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);
  const isOpen = activeModal.includes('InvoiceModal');

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title={'Invoices (' + invoiceNum + ')'}
      handleClose={() => handleHideModal('InvoiceModal')}
      contentLabel="Invoice Modal"
      isOpen={isOpen}
    >
      <Header>
        <Row justifyContent="flex-start">
          <Col xs={3.5} lg={3.5}>
            <span className="invoice-modal-header">Amount</span>
          </Col>
          <Col xs={8.5} lg={8.5}>
            <span className="invoice-modal-header">Billing date</span>
          </Col>
        </Row>
      </Header>

      <InvoicesSection>
        {invoices &&
          invoices.map(i => (
            <InvoiceRow
              key={shortid.generate()}
              amount={i.amount}
              billingDate={moment(i.createDate).format('DD MMM YYYY')}
              invoiceId={i.id}
              invoiceType={i.invoiceType}
            />
          ))}
      </InvoicesSection>
    </Modal>
  );
};

export default InvoiceModal;
