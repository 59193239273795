import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { graphql } from '@apollo/react-hoc';
import { reduxForm, Field } from 'redux-form';
import { useAuth0 } from '../../../common/hooks/auth0';
import compose from 'lodash/flowRight';
import { LOGIN_MUTATION } from '../../../common/graphql/authSchema';
import { AUTH_TOKEN } from '../../../_shared/constants';
import './LoginModal.css';
import {
  teal,
  RubikSemiBold,
  spacing
} from '../../../_shared/layout-constants';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  FullWidthSecondaryButton,
  FullWidthPrimaryButton,
  FullWidthBorderButton
} from '../../../_shared/components/Buttons';
import CryptoJS from 'crypto-js';
import { ReduxInputFieldWithIcon } from '../../../_shared/components/FormElements';
import { ImgixUrl, ReduxSecretKey } from '../../../_shared/utils';

const EmailFieldWrapper = styled.div`
  grid-column: 3 / -3;
  grid-row: 2 / 3;
`;

const PasswordFieldWrapper = styled.div``;

const LoginButton = styled(FullWidthPrimaryButton)`
  margin-bottom: 16px;
  font-weight: ${RubikSemiBold};
`;

const ForgotPasswordLink = styled.div`
  justify-self: center;
  text-align: center;
  a {
    color: ${teal};
    font-size: 16px;
  }
`;

const Or = styled.div`
  margin-top: ${spacing(2)};
  text-align: center;
`;

const SignupLabel = styled.div`
  justify-self: center;
  text-align: center;
  padding-top: ${spacing(4)};
  padding-bottom: ${spacing(2)};
`;

const SignupButton = styled(FullWidthSecondaryButton)`
  margin-top: 12px;
  font-weight: ${RubikSemiBold};
`;

const BreakLineWrapper = styled.div``;

const validate = values => {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Email format is not valid';
  }

  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;

  if (!values.password) {
    errors.password = 'Please enter your password';
  } else if (values.password.length < 8) {
    errors.password =
      'Password length needs to be greater than 8. Please try again.';
  } else if (
    !values.password.match(numbers) ||
    !values.password.match(lowerCaseLetters) ||
    !values.password.match(upperCaseLetters)
  ) {
    errors.password =
      'Password needs to include more than 1 uppercase alphabetic letters and 1 number. Please try again.';
  }

  return errors;
};

const LoginModal = props => {
  const [notification, setNotification] = useState(null);
  const {
    state: { activeModal, msg },
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);
  // show the error msg from auth0
  useEffect(() => {
    setNotification(msg);
  }, [msg]);
  const authLogin = async value => {
    await props
      .loginMutation({
        variables: {
          email: value.email,
          password: value.password
        },
        update: (_, { data: { login } }) => {
          // by default, enable "Remember Me"
          const encryptedToken = CryptoJS.AES.encrypt(
            login.token,
            ReduxSecretKey
          );
          localStorage.setItem(AUTH_TOKEN, encryptedToken);
          localStorage.setItem('authenticate', 'true');
          handleHideModal('Login');
          setNotification(null);
        }
      })
      .catch(err => {
        // TODO: login not successful, need to handle
        // David said will handle this later, should should ask him to make
        // back-end ready for this.
        setNotification(
          'Login not successful, please enter valid credentials.'
        );
        //console.log(err);
      });
  };
  const isOpen = activeModal.includes('Login');
  // const responseGoogle = response => {
  //   console.log(response);
  // };
  const { loginWithRedirect } = useAuth0();
  const {
    //redux-form props
    handleSubmit
  } = props;
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      // isAuth={true}
      title="Welcome back"
      handleClose={() => handleHideModal('Login')}
      contentLabel="Login Modal"
      isOpen={isOpen}
      notification={notification}
    >
      <EmailFieldWrapper>
        <Field
          name="email"
          label="Email"
          component={ReduxInputFieldWithIcon}
          icon={`${ImgixUrl}/web-image-assets/icons/Email.svg`}
        />
      </EmailFieldWrapper>

      <PasswordFieldWrapper>
        <Field
          name="password"
          label="Password"
          type="password"
          component={ReduxInputFieldWithIcon}
          icon={`${ImgixUrl}/web-image-assets/icons/Password.svg`}
        />
      </PasswordFieldWrapper>

      <LoginButton type="submit" onClick={handleSubmit(authLogin)}>
        Log in
      </LoginButton>

      <ForgotPasswordLink>
        <div
          className="forgot_password"
          onClick={() => {
            handleHideModal('Login');
            handleShowModal('ForgotPassword');
          }}
        >
          Forgot password?
        </div>
      </ForgotPasswordLink>

      <BreakLineWrapper>
        <hr />
      </BreakLineWrapper>

      <SignupLabel>Don't have an account?</SignupLabel>
      <FullWidthBorderButton type="button" onClick={() => loginWithRedirect()}>
        Login with{' '}
        <img
          alt="google"
          width="23px"
          src="http://pluspng.com/img-png/google-logo-png-open-2000.png"
        />{' '}
        <img
          width="23px"
          alt="facebook"
          src="https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/facebook_circle-512.png"
        />
      </FullWidthBorderButton>
      <Or>or</Or>
      <SignupButton
        type="button"
        onClick={() => {
          handleHideModal('Login');
          handleShowModal('Signup');
        }}
      >
        Sign up
      </SignupButton>
    </Modal>
    /* eslint-enable */
  );
};

const formOptions = {
  form: 'LoginModal',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(LOGIN_MUTATION, { name: 'loginMutation' })
)(LoginModal);
