import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import get from 'lodash/get';
import Notification from '../../../_shared/components/Notification';
import ListerDetails from './components/listerDetails';
import ManageBooking from './components/manageBooking';
import Reimebursement from './components/reimbursement';
import ParkingSpaceDetails from './components/parkingSpaceDetails';
import PaymentDetails from './components/paymentDetails';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import {
  midBreakPoint,
  defaultBorder
} from '../../../_shared/layout-constants';
import { GET_BOOKING_DETAILS } from '../../../common/graphql/bookingSchema';
import { BOOKING_STATUS } from '../../../_shared/constants';
import './index.css';

const Content = styled.div`
  display: grid;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 3fr 36px 2fr;
    grid-template-rows: auto 36px auto 36px auto;
  }
`;

const ParkingSpaceSection = styled.div`
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 4;
    border: ${defaultBorder};
  }
`;

const PaymentSection = styled.div`
  grid-column: 1;
  grid-row: 3;
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    border: ${defaultBorder};
  }
`;

const ListerSection = styled.div`
  grid-column: 1;
  grid-row: 5;
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 3;
    grid-column: 3;
    border: ${defaultBorder};
  }
`;

const ManageBookingSection = styled.div`
  grid-column: 1;
  grid-row: ${({ gridRow }) => gridRow + 2};
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: ${({ gridRow }) => gridRow};
    grid-column: 3;
    border: ${defaultBorder};
  }
`;

const ReimbursementSection = styled.div`
  grid-column: 1;
  grid-row: 7;
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    border: ${defaultBorder};
  }
`;

const MobileHr = styled.hr`
  margin: 24px 0;
  display: block;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const displayWhen = currentStatus => allowStatus => {
  const allowedStatus = allowStatus.map(a => BOOKING_STATUS[a]);
  return allowedStatus.includes(currentStatus);
};

const isManagedBooking = url => {
  return url.includes('/admin/bookings/manage-bookings');
};

const BookingDetailPage = ({ data, errors, loading, match }) => {
  const [error, setError] = useState(null);

  if (data.loading) return <SpinnerLoader />;

  const { getBookingDetails } = data;
  const { url } = match;

  const displayWhenStatusIs = displayWhen(
    get(getBookingDetails, 'bookingStatus')
  );

  return (
    <AdminWrapper title="Booking details">
      {(error || errors) && (
        <Notification
          type="alert1"
          text={error || (errors && errors[0].message)}
          hasClose={false}
          style={{ marginBottom: 20 }}
        />
      )}
      <Content>
        <ParkingSpaceSection>
          <ParkingSpaceDetails
            id={get(getBookingDetails, 'parking.id')}
            title={get(getBookingDetails, 'parking.title')}
            address={get(getBookingDetails, 'parking.address.value')}
            parkingLot={get(getBookingDetails, 'parking.lot')}
            parkingType={get(getBookingDetails, 'parking.parkingTypes[0].name')}
            access={get(getBookingDetails, 'parking.accessTypes[0].name')}
            maxHeight={`${get(
              getBookingDetails,
              'parking.maxHeight.value'
            )}${get(getBookingDetails, 'parking.maxHeight.unit')}`}
            accessInstruction={get(
              getBookingDetails,
              'parking.accessInstruction'
            )}
            listingStatus={
              get(getBookingDetails, 'parking.status') === 'A' ? 'live' : 'off'
            }
            startTime={get(getBookingDetails, 'bookings[0].startTime')}
            endTime={get(getBookingDetails, 'bookings[0].endTime')}
            parkingFee={get(getBookingDetails, 'bookings[0].total')}
            isMonthlyBooking={get(
              getBookingDetails,
              'bookings[0].isMonthlyBooking'
            )}
            isManagedBooking={isManagedBooking(url)}
          />
        </ParkingSpaceSection>

        <MobileHr />

        <PaymentSection>
          <PaymentDetails
            startTime={get(getBookingDetails, 'bookings[0].startTime')}
            bookingId={get(getBookingDetails, 'id')}
            isMonthlyBooking={get(
              getBookingDetails,
              'bookings[0].isMonthlyBooking'
            )}
            bookingType={match.params.type}
            paymentStatus={get(getBookingDetails, 'paymentStatus')}
            nextPaymentDate={get(getBookingDetails, 'nextPaymentDate')}
            tz={get(getBookingDetails, 'parking.address.tz')}
            isManagedBooking={isManagedBooking(url)}
          />
        </PaymentSection>

        <MobileHr />

        {!isManagedBooking(url) && (
          <ListerSection>
            <ListerDetails
              listingOwner={get(getBookingDetails, 'parking.owner')}
            />
          </ListerSection>
        )}

        {displayWhenStatusIs(['PendingComplete']) && (
          <ReimbursementSection>
            {get(getBookingDetails, 'id') && (
              <Reimebursement
                bookingId={get(getBookingDetails, 'id')}
                setError={setError}
              />
            )}
          </ReimbursementSection>
        )}

        <MobileHr />

        {displayWhenStatusIs(['Requested', 'Confirmed']) && (
          <ManageBookingSection gridRow={isManagedBooking(url) ? 3 : 7}>
            {get(getBookingDetails, 'id') && (
              <ManageBooking
                bookingId={get(getBookingDetails, 'id')}
                setError={setError}
              />
            )}
          </ManageBookingSection>
        )}
      </Content>
    </AdminWrapper>
  );
};

export default graphql(GET_BOOKING_DETAILS, {
  options: props => ({
    variables: {
      bookingTransactionId: props.match.params.id
    },
    fetchPolicy: 'no-cache'
  }),
  handleError: false
})(BookingDetailPage);
