import styled from 'styled-components';
import { createSkeletonElement } from '@trainline/react-skeletor';
import Imgix from 'react-imgix';
import {
  greyExtraLight,
  navy,
  midBreakPoint,
  RubikSemiBold,
  transparentGrey
} from '../../../_shared/layout-constants';

export const LayoutWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  height: calc(100vh - 65px);
  background-color: ${greyExtraLight};
  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 1175px 1fr;
    grid-template-rows: auto 1fr 380px;
  }
`;

export const LayoutWrapperLoader = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  height: 100vh;
  background-color: ${'#fff'};
  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 1155px 1fr;
    overflow-x: -moz-hidden-unscrollable;
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 64px;
  z-index: 3;
  @media (min-width: ${midBreakPoint}px) {
    height: 81px;
    z-index: 4;
  }
`;
export const Notification = styled.div`
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 1;
    grid-column: 1 / 4;
  }
`;
export const PageContent = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  /* margin-top: 64px; */
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 140px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 0;
    grid-row: 2;
    grid-column: 2 / 3;
    grid-template-columns: 694px 34px 447px;
    grid-template-rows: auto;
    margin-bottom: 200px;
  }
`;

export const StickyMobileFooter = styled.div`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: block;
    z-index: 1;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 16px 1px rgba(157, 157, 157, 0.5);
    background: rgba(255, 255, 255, 0.8);
    padding: 12px 24px 24px 24px;
  }
`;

export const CarparkDetailsWrapper = styled.div`
  z-index: 0;
  display: ${({ mobileFullScreenDisplay }) =>
    mobileFullScreenDisplay ? 'none' : 'grid'};
  grid-row: 1 / 4;
  grid-column: 1;
  background-color: #ffffff;
  width: 100%;
  overflow-y: ${({ bookingStepOpened }) =>
    bookingStepOpened ? 'hidden' : 'auto'};
  -webkit-overflow-scrolling: touch;
  grid-gap: 10px;
  grid-template-rows: auto 423px 300px auto auto auto;
  grid-template-columns: 1.3fr 1fr;
  padding-bottom: 80px;
  margin-bottom: 112px;

  @media (min-width: ${midBreakPoint}px) {
    overflow-y: visible;
    padding-bottom: 0px;
    display: grid;
    /* min-height: 2414px; */
    overflow-y: auto;
    grid-template-columns: 539px 145px;
    grid-template-rows: auto 430px 423px auto auto auto;
    grid-column: 1 / 2;
    width: 694px;
  }
`;

export const GalleryWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  position: relative;
  z-index: 0;
`;

export const GalleryWrapperLoader = createSkeletonElement(styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  z-index: 0;
  height: 340px;
`);

export const ListerInfoWrapper = createSkeletonElement(styled.div`
  z-index: 0;
  position: absolute;
  right: 200px;
  top: -15px;
  @media (min-width: ${midBreakPoint}px) {
    right: 0;
  }
`);

export const CarparkInfo = createSkeletonElement(styled.div`
  grid-column: 1 / 3;
  grid-row: 2;
  position: relative;
  padding: 33px 24px 16px 24px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
  }
`);

export const ButtonWrapper = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 2;
    margin-top: 90px;
    width: 95px;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  /* padding: 78px 23px 0 54px; */
  /* padding: 78px 0 0 0; */
`;

export const MobileButtonWrapper = createSkeletonElement(styled.div`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    width: 80px;
    padding: 5px 9px;
    top: 18px;
    right: 24px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: ${transparentGrey};
  }
  /* padding: 78px 23px 0 54px; */
  /* padding: 78px 0 0 0; */
`);

export const MapWrapper = createSkeletonElement(styled.div`
  grid-column: 1 / 3;
  grid-row: 3;
  height: 300px;
  @media (min-width: ${midBreakPoint}px) {
    height: 423px;
  }
`);

export const SurroundingWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 4;
  min-height: 212px;
`;

export const AmenitiesWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 6;
  margin-bottom: 150px;

  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 0;
  }
`;

export const VehicleTypesWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 5;
`;

export const AccessMaxHeightWrapper = createSkeletonElement(styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${midBreakPoint}px) {
    flex-direction: row;
    height: 70px;
  }
`);

export const ResponsiveWrapper = styled.div`
  opacity: 0.99;
  z-index: 2;
  grid-row: 1 / 4;
  grid-column: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* display: ${({ show }) => (show ? 'block' : 'none')}; */
  margin-top: ${({ show, isStepOne }) =>
    isStepOne ? (show ? 0 : '1500px') : 0};
  transition: margin-top 0.8s;
  @media (min-width: ${midBreakPoint}px) {
    overflow-y: visible;
    top: 0;
    display: block;
    position: relative;
    grid-column: 3 / 4;
    grid-row: 1;
    padding-top: 24px;
    padding-right: 4px;
    margin-top: 0;
  }
`;

export const Footer = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / -1;
    grid-row: 3;
  }
`;

export const Hr = styled.div`
  border: 0;
  border-top: 1px solid #cccccc;
  border-bottom: 0;
  height: 1px;
  margin: 0 24px;
  @media (min-width: ${midBreakPoint}px) {
    marigin: 0 auto;
    width: 635px;
  }
`;

export const VerticleLine = styled.div`
  @media (min-width: ${midBreakPoint}px) {
    border-right: 3px solid ${navy};
    height: 20px;
    margin: 24px 16px 26px 16px;
  }
`;

export const Price = styled.span`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
`;

export const PriceNote = styled.p`
  margin-bottom: 5px;
`;

export const SideNote = styled.p`
  font-size: 14px;
`;

export const GalleryItem = styled(Imgix)`
  width: 100%;
`;
