import React from 'react';
import styled from 'styled-components';
import {
  yellow,
  grey,
  defaultBoxShadow,
  RubikSemiBold
} from '../layout-constants';
import { ImgixUrl } from '../utils';

const ToolTipContainer = styled.div`
  width: ${({ width }) => width};
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

const ToolTipHeader = styled.div`
  background-color: ${yellow};
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: ${({ isDetailed }) => (isDetailed ? 0 : '3px')};
  border-bottom-right-radius: ${({ isDetailed }) => (isDetailed ? 0 : '3px')};
`;

const ToolTipBox = styled.div`
  position: relative;
  box-shadow: ${defaultBoxShadow};
  border-radius: 3px;
  width: 100%;
`;

const ToolTipContent = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: solid 1px ${grey};
  border-top: none;
  padding: 8px;
  font-size: 12px;
`;

const ToolTipArrow = styled.span`
  &::before,
  &::after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border: 12px solid transparent;
    box-shadow: none;
    left: -24px;
    bottom: 17px;
  }
  &::after {
    border-right-color: ${yellow};
  }
`;

const Quokka = styled.img`
  width: 25px;
  margin-bottom: -6px;
`;

const Title = styled.div`
  flex: 1;
  font-weight: ${RubikSemiBold};
  font-size: 12px;
`;

const Close = styled.img`
  width: 14px;
  margin-left: 8px;
  cursor: pointer;
`;

const Pin = styled.img`
  width: 24px;
  margin-right: 8px;
`;

export class QuokkaTooltip extends React.Component {
  state = {
    isShow: true
  };

  hideToolTip = () => {
    this.setState({
      isShow: false
    });
  };

  render() {
    const { brief, width, isDetailed, children, ...otherProps } = this.props;
    const { isShow } = this.state;
    return (
      <ToolTipContainer width={width} isShow={isShow} {...otherProps}>
        <Quokka
          src={`${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder.svg`}
        />
        <ToolTipBox>
          <ToolTipHeader isDetailed={isDetailed}>
            <Pin src={`${ImgixUrl}/web-image-assets/icons/Pin.svg`} />
            <Title>{brief}</Title>
            <Close
              src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
              onClick={this.hideToolTip}
            />
          </ToolTipHeader>
          {isDetailed && <ToolTipContent>{children}</ToolTipContent>}
          {!isDetailed && <ToolTipArrow />}
        </ToolTipBox>
      </ToolTipContainer>
    );
  }
}
