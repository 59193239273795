import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { Field } from 'redux-form';
import { debounce, get } from 'lodash';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import { midBreakPoint } from '../../../_shared/layout-constants';
import ToolTip from '../../../_shared/components/Tooltips';
import InfoTip from '../../../_shared/components/InfoTip';
import { DRAFT_PARKING_WEEKLY_PRICE } from '../../../common/graphql/adminSchema';
import './Pricing.css';

const DailyPricingSection = styled.div`
  height: 0;
`;

const PricingSection = styled.div`
  display: flex;
  align-items: start;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: -18px;
  }
`;

const PerRateUnit = styled.div`
  width: 100px;
  margin: 45px 0 0 12px;
`;

const _updateFields = (draftId, localData, names, values) => {
  if (!!draftId) {
    const obj = {};
    for (let idx in names) {
      obj[names[idx]] = values[idx];
    }
    localData.addItem(draftId, obj);
  }
};

const updateFields = debounce(_updateFields, 300);

const Pricing = ({ change, draftId, localData }) => {
  const [getDraftWeeklyValue, { loading, data }] = useLazyQuery(
    DRAFT_PARKING_WEEKLY_PRICE
  );

  const changeDailyWeeklyPrice = (event, value) => {
    _updateFields(draftId, localData, [event.target.name], [value]);

    return getDraftWeeklyValue({
      variables: { dailyPrice: value, draftParkingId: draftId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache' // TODO: follow up this issue
    });
  };
  const debounceChangeDailyWeeklyPrice = debounce(changeDailyWeeklyPrice, 300);

  const debounceChangeMonthlyWeeklyPrice = (event, value) => {
    updateFields(draftId, localData, [event.target.name], [value]);

    debounceChangeDailyWeeklyPrice(
      { target: { name: 'daily' } },
      (value * 12) / 365
    );
  };

  const weeklyTotal = get(data, 'draftParkingWeeklyPrice.total');

  useEffect(() => {
    if (!loading && weeklyTotal) {
      change('weekly', `${weeklyTotal}`);
      change('daily', `${weeklyTotal / 7 / 0.9 / 1.2}`);
      _updateFields(draftId, localData, ['weekly'], [weeklyTotal.toString()]);
      _updateFields(
        draftId,
        localData,
        ['daily'],
        [(weeklyTotal / 7 / 0.9 / 1.2).toString()]
      );
    }
  }, [weeklyTotal, change, loading, draftId, localData]);

  return (
    <div>
      {/* TODO: tooltip copy update */}
      <ToolTip style={{ marginBottom: 21 }}>
        Tip: you can compare prices of similar parking space in the market. In
        order for your parking space to be searchable and affordable, please set
        a competitive monthly price.{' '}
      </ToolTip>
      <DailyPricingSection>
        <Field
          style={{ maxWidth: 190 }}
          name="daily"
          label="Daily rate ($)"
          component={ReduxInputField}
          disabled
          type="hidden"
        />
        {/* <PerRateUnit>per day</PerRateUnit> */}
      </DailyPricingSection>

      <PricingSection>
        <Field
          style={{ maxWidth: 190 }}
          name="monthly"
          label="Monthly rate ($)"
          component={ReduxInputField}
          onChange={(event, value) => {
            debounceChangeMonthlyWeeklyPrice(event, value);
          }}
        />
        <PerRateUnit>per month</PerRateUnit>
      </PricingSection>

      <PricingSection>
        <Field
          style={{ maxWidth: 190 }}
          name="weekly"
          label="Weekly rate ($)"
          disabled
          type="hidden"
          component={ReduxInputField}
        />
        {/* <PerRateUnit>per week</PerRateUnit> */}
        {!loading && weeklyTotal && (
          <InfoTip>Indicative weekly price is ${weeklyTotal} per week</InfoTip>
        )}
      </PricingSection>

      <div className="breakline-margin">
        <hr className="break-line" />
      </div>
      {/* TODO: tooltip copy update */}
      <ToolTip style={{ marginTop: 21 }}>
        Tip: set a bond so that your asset such as remote controller and swipe
        card are protected and insured. You can also set an one month payment
        refundable bond deposit. Quokka strongly suggests you to set a minimum
        $100 refundable bond deposit.
      </ToolTip>
      <PricingSection>
        <Field
          style={{ maxWidth: 190, marginTop: 20 }}
          name="deposit"
          label="Security bond"
          component={ReduxInputField}
          onChange={(event, value) => {
            updateFields(draftId, localData, [event.target.name], [value]);
          }}
        />
      </PricingSection>
    </div>
  );
};

export default Pricing;
