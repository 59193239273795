import React, { Component } from 'react';
import Subtitle from '../../../components/subtitle/Subtitle';
import { InfoTable } from '../../../components/tableComponents/InfoTable';
import { InfoCell } from '../../../components/tableComponents/InfoCell';
import { Divider } from '../../../components/tableComponents/Divider';
import { Info } from '../../../components/tableComponents/Info';
import { Description } from '../../../components/tableComponents/Description';
import { Link } from '../../../components/tableComponents/Link';
import { Icon } from '../../../components/tableComponents/Icon';
import { ImgixUrl } from '../../../../_shared/utils';

export default class BookingOverview extends Component {
  render() {
    return (
      <div>
        <Subtitle title="Booking overview" />

        <InfoTable padding={24}>
          <InfoCell>
            <Info fontSize="36">1</Info>
            <Description>active booking(s)</Description>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="16" extraMargin={13}>
              <span>
                <Icon
                  size={28}
                  src={`${ImgixUrl}/web-image-assets/icons/request-sent.svg`}
                  alt="payment paid"
                />
              </span>
              <span>Paid for May 18</span>
            </Info>
            <Link desktopAlign="center">Invoice</Link>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="24" extraMargin={7}>
              3 Jun 2018
            </Info>
            <Description>Next direct debit scheduled</Description>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="36">$ 214</Info>
            <Description>Saved since Feb 2018</Description>
            <Link href="/admin/bookings/my-bookings">View bookings</Link>
          </InfoCell>
        </InfoTable>
      </div>
    );
  }
}
