import React, { Component, useContext } from 'react';
import { ModalStoreContext } from '../../../common/context/modal/store';
import moment from 'moment';
import styled from 'styled-components';
import { SingleDatePicker } from 'react-dates';
// import {
//   ReduxCheckbox,
//   Checkboxes
// } from '../../../_shared/components/CheckBoxes';
// import { ReduxCheckBoxComponent } from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';
// import { Field } from 'redux-form';
// import { DAYS_MAPPING } from '../../../_shared/constants';

const FromDate = styled.div`
  min-height: 640px;
`;

// const WeekdaysSelection = styled.div`
//   margin-top: 24px;
// `;

class ModifyAvailabilityModal extends Component {
  state = {
    focused: true
  };

  onDateChange = date => {
    this.props.updateFormValue({
      availableDateFrom: date.toISOString(),
      page: 'Availability'
    });
    this.props.change('availableDateFrom', date);
  };

  render() {
    const { availableDateFrom = moment().add(2, 'days') } = this.props.values; //TODO: remote add 2 days
    const {
      isOpen,
      handleHideModal,
      values,
      invalid,
      changeAvailability
    } = this.props;

    return (
      /* eslint-disable jsx-a11y/anchor-has-content */
      <Modal
        title="Change availability"
        handleClose={() => handleHideModal('ModifyAvailability')}
        contentLabel="Modify Availability Modal"
        buttonLabel="Save"
        isOpen={isOpen}
        isForm={false}
        buttonDisabled={invalid}
        handleSubmit={() => {
          if (invalid) return;
          changeAvailability({ isReview: true, ...values });
          handleHideModal('ModifyAvailability');
        }}
      >
        <FromDate>
          <span className="contact_modal_subtitle">
            This space will be available from
          </span>
          <div className="create-listing-from-date">
            <SingleDatePicker
              id="create-listing-from-date"
              date={availableDateFrom}
              onDateChange={this.onDateChange}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              numberOfMonths={1}
              enableOutsideDays
              showDefaultInputIcon
            />
          </div>
        </FromDate>

        {/* <WeekdaysSelection>
          <span className="create-listing-subtitle">
            If your space is only available on certain days, select the
            weekday(s) that you’d like to take bookings.
          </span>
          <div className="tick-boxes-section" style={{ marginTop: 12 }}>
            <Field
              name="daysAvailable"
              itemComponent={ReduxCheckBoxComponent}
              component={ReduxCheckbox(Checkboxes)}
              data={DAYS_MAPPING}
              textField="name"
              valueField="id"
            />
          </div>
        </WeekdaysSelection> */}
      </Modal>
    );
  }
}
const WrappedModifyAvailabilityModal = props => {
  const {
    actions: { handleHideModal },
    state: { activeModal }
  } = useContext(ModalStoreContext);
  return (
    <ModifyAvailabilityModal
      handleHideModal={handleHideModal}
      isOpen={activeModal.includes('ModifyAvailability')}
      {...props}
    />
  );
};

export default WrappedModifyAvailabilityModal;
