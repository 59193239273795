import React from 'react';
import styled from 'styled-components';
import {
  RubikSemiBold,
  graphite,
  RubikFontFamily,
  spacing,
  teal,
  midBreakPoint,
  greyDark,
  RubikBold,
  navyDark
} from '../../../_shared/layout-constants';
import InfoTip from '../../../_shared/components/InfoTip';
//import { ImgixUrl } from '../../../_shared/utils';

const SummaryValue = styled.div`
  margin: 12px 0 0 0;
  font-size: 16px;
  font-family: ${RubikFontFamily};
  color: ${graphite};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
`;

const SummarySubtitle = styled.div`
  height: 24px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  color: ${graphite};
  letter-spacing: -0.1px;
`;

const PaymentSummary = styled.div`
  grid-column: 1;
  grid-row: 7;
  padding-bottom: 28px;
`;

const PaymentSummaryData = styled.div`
  margin-left: auto;
`;

const Discount = styled.span`
  text-align: right;
  color: #01bac7;
`;

const FirstPayment = styled.span`
  font-size: 16px;
  color: ${navyDark};
  font-weight: ${RubikBold};
`;

// const Icon = styled.img`
//   height: 18px;
//   width: 18px;
//   margin-right: 10px;
//   object-fit: contain;
//   @media (min-width: ${midBreakPoint}px) {
//     height: 28px;
//     width: 28px;
//     margin-right: 12px;
//   }
// `;

const DiscountBanner = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${greyDark};
  margin: ${spacing(3)} 0 ${spacing(4)} 0;
  z-index: 2;
  padding: 10px 12px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-color: ${teal};
  background-color: white;
  @media (min-width: ${midBreakPoint}px) {
    display: ${({ hide, hideOn }) =>
      hide || hideOn === 'big' ? 'none' : 'flex'};
  }
`;

// const icon = type => {
//   return `${ImgixUrl}/web-image-assets/icons/alert/GreenInfoIcon.png`;
// };

const sumBookingPrices = (booking, type) => {
  //console.log(booking);
  return booking.map(b => b[type]).reduce((a, b) => a + b, 0);
};

const Summary = ({
  bookingLength,
  preview,
  monthlyOngoing,
  depositValue,
  depositUnit
}) => (
  <PaymentSummary>
    <SummarySubtitle>Payment summary</SummarySubtitle>
    <SummaryValue>
      Booking duration
      <PaymentSummaryData>
        {bookingLength.toString()}
        {bookingLength.toString() !== 'monthly ongoing basis' ? ' days' : null}
      </PaymentSummaryData>
    </SummaryValue>
    <SummaryValue>
      Total parking fee
      <PaymentSummaryData>
        {'$' + Math.round(sumBookingPrices(preview, 'total') * 100) / 100}
      </PaymentSummaryData>
    </SummaryValue>
    {sumBookingPrices(preview, 'totalDiscount') > 0 ? (
      <SummaryValue>
        Discount
        <PaymentSummaryData>
          <Discount>
            {'-$' +
              Math.round(sumBookingPrices(preview, 'totalDiscount') * 100) /
                100}
          </Discount>
        </PaymentSummaryData>
      </SummaryValue>
    ) : null}
    {monthlyOngoing && depositValue && depositValue > 0 && (
      <SummaryValue>
        Security bond
        <PaymentSummaryData>{depositUnit + depositValue}</PaymentSummaryData>
      </SummaryValue>
    )}
    <hr />
    <SummaryValue>
      Total
      <PaymentSummaryData>
        {'$' + Math.round(sumBookingPrices(preview, 'total') * 100) / 100}
      </PaymentSummaryData>
    </SummaryValue>
    {bookingLength >= 5 ? (
      <DiscountBanner>
        {/* {icon() && <Icon src={icon()} />} */}
        <span>
          Since you've booked more than 5 days, a discount will be applied !{' '}
        </span>
      </DiscountBanner>
    ) : null}
    <InfoTip style={{ marginTop: 12 }}>
      {monthlyOngoing && (
        <span>
          Your first payment is
          <FirstPayment>
            {'    $ '}
            {Math.round(
              (sumBookingPrices(preview, 'total') + parseFloat(depositValue)) *
                100
            ) / 100}
          </FirstPayment>
          , which includes security bond if security bond applies.{' '}
        </span>
      )}
      You will be billed once the owner of the property owner has confirmed your
      booking. Default notice period 30 days will apply unless property owner
      resets it otherwise.
    </InfoTip>
  </PaymentSummary>
);

export default Summary;
