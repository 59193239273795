import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import CryptoJS from 'crypto-js';
import { AUTH_TOKEN, AUTH0_TOKEN } from './_shared/constants';
import { ReduxSecretKey } from './_shared/utils';

// apollo client
// refer to https://www.apollographql.com/docs/react/recipes/authentication.html
// TODO: replace with environment variable for packaging
const httpLink = new HttpLink({
  uri:
    process.env.GRAPHQL_ENDPOINT ||
    (window && window.config && window.config.graphql.url)
  // credentials: 'include' this should be 'same-origin' if backend server is the same domain
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const encryptedToken = localStorage.getItem(AUTH_TOKEN);
  let token;
  if (encryptedToken) {
    token =
      encryptedToken &&
      CryptoJS.AES.decrypt(encryptedToken, ReduxSecretKey).toString(
        CryptoJS.enc.Utf8
      );
  } else {
    // get from auth0
    token = localStorage.getItem(AUTH0_TOKEN);
  }

  const authorizationHeader = token ? `JWT ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader
    }
  });
  return forward(operation);
});

// Error handling: https://www.apollographql.com/docs/react/data/error-handling/
const ErrorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Signature has expired') {
        localStorage.clear(); // if auth0 login token expired, clear the localStorage
      }
      return null;
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Composing Links https://www.apollographql.com/docs/link/composition/
const composedLink = ApolloLink.from([
  middlewareAuthLink,
  ErrorHandler,
  httpLink
]);

export const client = new ApolloClient({
  link: composedLink,
  cache: new InMemoryCache()
});
