import React from 'react';
import styled from 'styled-components';
import { SimplifiedFooter, StandardFooter } from '../../../common/components';
import { AdminNavigation } from '../../../admin';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import {
  RubikSemiBold,
  graphite,
  midBreakPoint,
  greyDark
} from '../../../_shared/layout-constants';

const Layout = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 200px 96px 960px 1fr;
    grid-template-rows: 36px auto 380px;
  }
`;

const DashboardNav = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    height: 364px;
    margin-bottom: 937px;
  }
`;

const MobileFooter = styled.div`
  display: block;
  margin-top: 50px;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / 6;
    grid-row: 3;
  }
`;

const Title = styled.div`
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 36px;
  }
`;

const MainTitle = styled.span`
  font-weight: ${RubikSemiBold};
  color: ${graphite};
  font-size: 24px;

  @media (min-width: ${midBreakPoint}px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.span`
  margin: 0 0 0 10px;
  position: relative;
  top: -14px;
  color: ${greyDark};
  font-size: 12px;

  @media (min-width: ${midBreakPoint}px) {
    font-size: 14px;
  }
`;

const PageContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 32px 24px 0 24px;
  @media (min-width: ${midBreakPoint}px) {
    overflow-y: visible;
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    min-height: 1300px;
    padding: 0 36px 80px 36px;
    margin: 0 0 80px 0;
  }
`;

const AdminWrapper = ({ title, subtitle, children, loading }) => (
  <Layout>
    {loading && <SpinnerLoader />}
    <DashboardNav>
      <AdminNavigation />
    </DashboardNav>

    <PageContent>
      <div>
        <Title>
          {title && <MainTitle>{title}</MainTitle>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Title>
        {children}
      </div>
      <MobileFooter>
        <SimplifiedFooter />
      </MobileFooter>
    </PageContent>
    <DesktopFooter>
      <StandardFooter />
    </DesktopFooter>
  </Layout>
);

export default AdminWrapper;
