import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/react-hooks';
import { Auth0Provider } from './common/hooks/auth0';
import Root from './common/Root';
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import history from './_shared/history';
import {
  AuthZeroAudience,
  AuthZeroClientId,
  AuthZeroDomain
} from './_shared/utils';
import { client } from './apollo.config';
import './_shared/global-styles';

const rootElement = document.getElementById('root');

const onRedirectCallback = appState => {
  const pathname =
    window.localStorage.getItem('auth0-redirect-pathname') ||
    window.location.pathname;
  const url = appState && appState.targetUrl ? appState.targetUrl : pathname;

  history.push(url);
  window.location.href = url;
};

const auth0_config = {
  domain: AuthZeroDomain,
  clientId: AuthZeroClientId,
  audience: AuthZeroAudience
};

function renderDOM(Component) {
  ReactDOM.render(
    <Auth0Provider
      domain={auth0_config.domain}
      client_id={auth0_config.clientId}
      audience={auth0_config.audience}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <ApolloProvider client={client}>
          <IntlProvider locale="en-AU">
            <Component />
          </IntlProvider>
        </ApolloProvider>
      </Provider>
    </Auth0Provider>,
    rootElement
  );
}
renderDOM(Root);

serviceWorker.register();
