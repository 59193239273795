import React, { useContext } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import PopupAlert from '../../../../_shared/components/PopupAlert';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import {
  teal,
  greyDark,
  midBreakPoint
} from '../../../../_shared/layout-constants';
import {
  DELETE_BANK_ACCOUNT,
  SET_DEFAULT_RESOURCE
} from '../../../../common/graphql/adminSchema';

const Wrapper = styled.div`
  padding: 24px 16px;
  @media (min-width: ${midBreakPoint}px) {
    padding: 0;
    width: 100%;
    height: 148px;
  }
`;

const BankAccount = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 36px 36px 36px 53px auto;
  grid-template-columns: 1fr auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 154px 270px auto 61px;
    grid-template-rows: 20px 16px 20px 24px 20px;
  }
`;

const BankAccountTitle = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: 20px;
  text-align: left;
`;

const BSB = styled.div`
  grid-column: 1 / 3;
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
  }
`;

const Account = styled.div`
  grid-column: 1;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 3;
  }
`;

const AccountName = styled.div`
  grid-row: 2;
  grid-column: 1 / 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 3;
  }
`;

const MakeDefault = styled.a`
  grid-column: 1;
  grid-row: 5;
  width: 96px;
  height: 20px;
  color: ${teal};
  cursor: pointer;
`;

const Default = styled.div`
  grid-column: 1;
  grid-row: 5;
  width: 55px;
  height: 20px;
`;

const Remove = styled.a`
  grid-column: 2;
  grid-row: 5;
  width: 61px;
  height: 20px;
  color: ${teal};
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 4;
  }
  cursor: pointer;
`;

const PayoutOptionSubtitle = styled.span`
  height: 20px;
  text-align: left;
`;

const PayoutOptionContent = styled.span`
  height: 20px;
  margin-left: 16px;
  color: ${greyDark};
`;

const PayoutOption = props => {
  const {
    actions: { handleShowModal, handleHideModal }
  } = useContext(ModalStoreContext);

  const makeBankAccountDefault = async () => {
    try {
      await props.setDefaultResource({
        variables: {
          id: props.bankAccountId,
          type: 'PAYOUT_OPTION'
        }
      });
      props.setErrorMsg(null);
      if (props.updatePayoutOption) {
        props.updatePayoutOption();
      }
    } catch (e) {
      props.setErrorMsg(
        'Failed to make this payout method default. Please try again.'
      );
    }
  };

  const encrypt = number => {
    let middle_index = number.toString().length / 2;
    let prefix = '';

    for (let i = 0; i < middle_index; i++) {
      prefix = prefix.concat('x');
    }

    return prefix.concat(number.toString().slice(middle_index, number.length));
  };

  const deleteBankAccount = async () => {
    try {
      await props.deleteBankAccount({
        variables: {
          id: props.bankAccountId
        }
      });
      props.setErrorMsg(null);
      props.updatePayoutOption && props.updatePayoutOption();
    } catch (e) {
      props.setErrorMsg(
        'Failed to delete this payout method. Please try again.'
      );
    }
  };

  const handleShowDeleteDefaultBankAccountAlert = () => {
    handleShowModal('deleteDefaultBankAccountAlert');
  };

  const handleHideDeleteDefaultBankAccountAlert = () => {
    handleHideModal('deleteDefaultBankAccountAlert');
  };

  const handleShowDeleteAlert = numberOfBankAccounts => {
    if (numberOfBankAccounts <= 0) {
      handleShowDeleteDefaultBankAccountAlert();
    } else {
      handleShowModal('deleteBankAccountAlert');
    }
  };

  const handleContinueDeletion = () => {
    deleteBankAccount();
    handleHideDeleteDefaultBankAccountAlert();
  };

  const { defaultStatus, acc, bsb, accName, numberOfBankAccounts } = props;

  return (
    <Wrapper>
      <PopupAlert
        isAlert
        contentLabel="deleteBankAccountAlert"
        type="deleteBankAccountAlert"
        title="Delete bank account"
        message="Are you sure to delete this bank account?"
        button="Delete"
        secondaryButton="Cancel"
        disableClose={false}
        handleClick={deleteBankAccount}
      />
      <PopupAlert
        isAlert
        contentLabel="deleteDefaultBankAccountAlert"
        type="deleteDefaultBankAccountAlert"
        title="Delete default bank account"
        message="Sorry, you cannot remove default bank account as there will be payout to your nominated bank account."
        button="Delete"
        secondaryButton="Understood"
        disableClose={false}
        handleClick={handleContinueDeletion}
      />
      <BankAccount>
        <BankAccountTitle>Bank Account</BankAccountTitle>
        <BSB>
          <PayoutOptionSubtitle>BSB:</PayoutOptionSubtitle>
          <PayoutOptionContent>{encrypt(bsb)}</PayoutOptionContent>
        </BSB>
        <Account>
          <PayoutOptionSubtitle>Account number:</PayoutOptionSubtitle>
          <PayoutOptionContent>{encrypt(acc)}</PayoutOptionContent>
        </Account>
        <AccountName>
          <PayoutOptionSubtitle>Account name:</PayoutOptionSubtitle>
          <PayoutOptionContent>{accName}</PayoutOptionContent>
        </AccountName>

        {defaultStatus ? (
          <Default>Default</Default>
        ) : (
          <MakeDefault onClick={makeBankAccountDefault}>
            Make default
          </MakeDefault>
        )}

        <Remove onClick={() => handleShowDeleteAlert(numberOfBankAccounts)}>
          Remove
        </Remove>
      </BankAccount>
    </Wrapper>
  );
};

export default compose(
  graphql(DELETE_BANK_ACCOUNT, { name: 'deleteBankAccount' }),
  graphql(SET_DEFAULT_RESOURCE, { name: 'setDefaultResource' })
)(PayoutOption);
