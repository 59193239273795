import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import './index.css';
import { Field } from 'redux-form';
import {
  ReduxInputField,
  ReduxTextArea
} from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';

const ListingTitle = styled.div``;

const ParkingDescription = styled.div``;

const AccessInstruction = styled.div``;

const keyDeliveryInstruction = styled.div``;

const ModifyDetailsModal = props => {
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const isOpen = activeModal.includes('ModifyDetails');
  const {
    //redux-form props
    invalid
  } = props;
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Modify details"
      handleClose={() => handleHideModal('ModifyDetails')}
      contentLabel="Modify Details Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      handleSubmit={() => {
        if (invalid) return;
        props.changeDetails({ isReview: true, ...props.values });
        handleHideModal('ModifyDetails');
      }}
      buttonDisabled={invalid}
      isForm={false}
    >
      <ListingTitle>
        <Field name="listingTitle" label="Title" component={ReduxInputField} />
      </ListingTitle>

      <ParkingDescription>
        <Field
          name="description"
          label="Description"
          component={ReduxTextArea}
        />
      </ParkingDescription>

      <AccessInstruction>
        <Field
          name="accessInstruction"
          label="Access instructions"
          component={ReduxTextArea}
          maxLength={150}
        />
      </AccessInstruction>

      <keyDeliveryInstruction>
        <Field
          name="deliveryInstruction"
          label="key delivery instructions"
          component={ReduxTextArea}
          maxLength={150}
        />
      </keyDeliveryInstruction>
    </Modal>
  );
};

export default ModifyDetailsModal;
