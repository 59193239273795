import React, { Component } from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { ReduxSelectField } from './FormElements';
import { spacing, teal, midBreakPoint, greyDark } from '../layout-constants';
//import { ImgixUrl } from '../utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 156px auto;
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-rows: 128px auto;
  }
`;

const Title = styled.div`
  grid-column: 1 / 13;
  grid-row: 1;
`;

const DOBNote = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${greyDark};
  margin-top: ${spacing(2)};
  z-index: 2;
  padding: 10px 12px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-color: ${teal};
  background-color: white;
  @media (min-width: ${midBreakPoint}px) {
    display: ${({ hide, hideOn }) =>
      hide || hideOn === 'big' ? 'none' : 'flex'};
  }
`;

const DateOfBirthDD = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;
  margin-left: 2px;
`;

const DateOfBirthMM = styled.div`
  grid-column: 4 / 8;
  grid-row: 2;
  padding: 0 10px;
`;

const DateOfBirthYYYY = styled.div`
  grid-column: 8 / 13;
  grid-row: 2;
  margin-right: 2px;
`;

// const Icon = styled.img`
//   height: 18px;
//   width: 18px;
//   margin-right: 10px;
//   object-fit: contain;
//   @media (min-width: ${midBreakPoint}px) {
//     height: 28px;
//     width: 28px;
//     margin-right: 12px;
//   }
// `;

// const icon = type => {
//   return `${ImgixUrl}/web-image-assets/icons/alert/GreenInfoIcon.png`;
// };

class DateOfBirthField extends Component {
  render() {
    const today = new Date();
    const year = today.getFullYear();
    const year_from = year - 100;
    const year_to = year - 16;

    let MMOptions = [];
    let DDOptions = [];
    let YYYYOptions = [];

    const minTwoDigits = n => (n < 10 ? '0' : '') + n;

    let i;
    for (i = 1; i <= 12; i++) {
      MMOptions.push({
        value: minTwoDigits(i),
        label: minTwoDigits(i)
      });
    }

    let j;
    for (j = 1; j <= 31; j++) {
      DDOptions.push({
        value: minTwoDigits(j),
        label: minTwoDigits(j)
      });
    }

    let k;
    for (k = year_to; k >= year_from; k--) {
      YYYYOptions.push({
        value: k.toString(),
        label: k.toString()
      });
    }

    return (
      <Wrapper>
        <Title>
          <label>Date of birth</label>
          <DOBNote>
            {/* {icon() && <Icon src={icon()} />} */}
            <span>
              Your date of birth provides us the evidence that you are an
              legitimate driver and can use our platform for renting parking
              space. It also helps us to send you special gift, e.g. coupon and
              discount, on your birthday.
            </span>
          </DOBNote>
        </Title>

        <DateOfBirthDD>
          <Field
            name="day"
            maxLength={2}
            placeholder="DD"
            disableError
            component={ReduxSelectField}
            options={DDOptions}
          />
        </DateOfBirthDD>

        <DateOfBirthMM>
          <Field
            name="month"
            placeholder="MM"
            disableError
            component={ReduxSelectField}
            options={MMOptions}
          />
        </DateOfBirthMM>

        <DateOfBirthYYYY>
          <Field
            name="year"
            placeholder="YYYY"
            disableError
            component={ReduxSelectField}
            options={YYYYOptions}
          />
        </DateOfBirthYYYY>
      </Wrapper>
    );
  }
}

export default DateOfBirthField;
