import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { GMKEY, DEFAULT_ZOOM, MAP_STYLE } from '../layout-constants';

const CarparkMap = ({ address, coordinate }) => {
  const [center, setCenter] = useState(null);
  const renderMarkers = (map, maps) => {
    return new maps.Marker({
      position: center,
      map
    });
  };

  useEffect(() => {
    //console.log('@@address', address, coordinate);
    if (center) {
      return;
    }
    // if coordinate is passed, do not use address
    if (coordinate && coordinate.lat && coordinate.lon) {
      setCenter({ lat: coordinate.lat, lng: coordinate.lon });
    } else {
      Geocode.setApiKey(`${GMKEY}`);
      Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          setCenter({ lat, lng });
        },
        error => {
          console.error(error);
        }
      );
    }
  }, []); // eslint-disable-line

  if (!center) {
    return null; // TODO: replace with skeleton loader
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GMKEY }}
      defaultZoom={DEFAULT_ZOOM}
      defaultCenter={{
        lat: center.lat,
        lng: center.lng
      }}
      options={MAP_STYLE}
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  );
};

export default CarparkMap;
