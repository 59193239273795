import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import { reduxForm, Field } from 'redux-form';
import compose from 'lodash/flowRight';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { RESET_PASSWORD_MUTATION } from '../../../common/graphql/authSchema';
import PopupAlert from '../../../_shared/components/PopupAlert';
import { RubikSemiBold } from '../../../_shared/layout-constants';
import Modal from '../../../_shared/components/ModalWrapper';
import { FullWidthPrimaryButton } from '../../../_shared/components/Buttons';
import { ReduxInputFieldWithIcon } from '../../../_shared/components/FormElements';
import { getQueryString, ImgixUrl } from '../../../_shared/utils';
import './ResetPasswordModal.css';

const PasswordFieldWrapper = styled.div``;

const PasswordConfirmationFieldWrapper = styled.div`
  margin-bottom: 24px;
`;

const Button = styled(FullWidthPrimaryButton)`
  font-weight: ${RubikSemiBold};
`;

const validate = values => {
  const errors = {};

  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;

  if (!values.password) {
    errors.password = 'Please enter new password';
  } else if (values.password.length < 8) {
    errors.password =
      'Password length needs to be greater than 8. Please try again.';
  } else if (
    !values.password.match(numbers) ||
    !values.password.match(lowerCaseLetters) ||
    !values.password.match(upperCaseLetters)
  ) {
    errors.password =
      'Password needs to include more than 1 uppercase alphabetic letters and 1 number. Please try again.';
  }

  if (!values.passwordConfirmed) {
    errors.passwordConfirmed = 'Please re-enter new password';
  } else if (values.password !== values.passwordConfirmed) {
    errors.passwordConfirmed =
      "The re-entered password doesn't match your new password. Please try again.";
  }

  return errors;
};

const ResetPasswordModal = props => {
  const {
    state,
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);
  const token = getQueryString('token');
  const [notification, setNotification] = useState(null);
  useEffect(() => {
    props.initialize({});
  }, []); // eslint-disable-line

  const resetPassword = async value => {
    await props
      .resetPasswordMutation({
        variables: {
          password: value.password,
          token: token
        },
        update: (_, { data: { resetPassword } }) => {
          if (resetPassword.payload.__typename === 'ResetPasswordSuccess') {
            handleHideModal('ResetPassword');
            handleShowModal('ResetPasswordAlert');
            setNotification(null);
          } else if (resetPassword.payload.__typename === 'IdenticalPassword') {
            setNotification('Reset password failed as passwords do not match');
          } else if (resetPassword.payload.__typename === 'TokenExpired') {
            setNotification('Reset password failed as your token is expired');
          } else if (resetPassword.payload.__typename === 'InvalidToken') {
            setNotification('Reset password failed as your token is invalid');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const {
    //redux-form props
    handleSubmit,
    invalid
  } = props;

  const isOpen = state.activeModal.includes('ResetPassword');

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <div>
      <PopupAlert
        contentLabel="ResetPasswordAlert"
        type="ResetPasswordAlert"
        title="Reset password"
        message="Your new password has been set successfully."
        button="OK"
        enableRedirect={true}
        redirectTo="/search"
      />
      <Modal
        isAuth={true}
        title="Almost there..."
        handleClose={() => handleHideModal('ResetPassword')}
        contentLabel="ResetPassword Modal"
        isOpen={isOpen}
        notification={notification}
      >
        <PasswordFieldWrapper>
          <Field
            name="password"
            label="Password"
            type="password"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Password.svg`}
          />
        </PasswordFieldWrapper>

        <PasswordConfirmationFieldWrapper>
          <Field
            name="passwordConfirmed"
            label="Confirm Password"
            type="password"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Password.svg`}
          />
        </PasswordConfirmationFieldWrapper>

        <Button
          type="submit"
          onClick={handleSubmit(resetPassword)}
          disabled={invalid}
        >
          Update password
        </Button>
      </Modal>
    </div>
  );
};

const formOptions = {
  form: 'ResetPasswordModal',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(RESET_PASSWORD_MUTATION, { name: 'resetPasswordMutation' })
)(ResetPasswordModal);
