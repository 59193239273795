import gql from 'graphql-tag';

export const REQUEST_BOOKING = gql`
  mutation RequestBooking(
    $bookings: [TimeRangeInput]!
    $contacts: [String]
    $creditCardId: ID
    $id: ID
    $parkingId: ID!
    $preview: Boolean
    $vehicleIds: [ID]
    $isMonthlyBooking: Boolean
  ) {
    requestBooking(
      bookings: $bookings
      id: $id
      parkingId: $parkingId
      vehicleIds: $vehicleIds
      contacts: $contacts
      creditCardId: $creditCardId
      preview: $preview
      isMonthlyBooking: $isMonthlyBooking
    ) {
      bookingRequest {
        id
        bookings {
          startTime
          endTime
          cost
          costDiscount
          commission
          commissionDiscount
          total
          totalDiscount
        }
        parking {
          address {
            value
            subpremise
            streetNumber
            route
            locality
            postalCode
            state
            stateCode
            country
            countryCode
            tz
          }
          priceOptions
          owner {
            id
            username
          }
          id
          title
          description
          status
          lot
          accessInstruction
          deliveryInstruction
          createdDate
          contacts
          modifiedDate
          amenities {
            id
            name
            description
          }
          parkingTypes {
            id
            name
            description
          }
          accessTypes {
            id
            name
            description
          }
          suitableVehicleTypes {
            id
            name
            description
          }
          maxHeight {
            id
            value
            unit
            normalized
          }
          filters
        }
      }
    }
  }
`;

export const DELETE_REQUESTED_BOOKING = gql`
  mutation DeleteRequestedBooking($id: ID!) {
    deleteRequestedBooking(id: $id) {
      bookingRequest {
        id
        bookings {
          costDiscount
          commissionDiscount
          endTime
          startTime
          cost
          commission
          total
          totalDiscount
        }
        parking {
          address {
            value
            subpremise
            streetNumber
            route
            locality
            postalCode
            state
            stateCode
            country
            countryCode
            tz
          }
          priceOptions
          owner {
            id
            username
          }
          id
          title
          description
          status
          lot
          accessInstruction
          deliveryInstruction
          createdDate
          contacts
          modifiedDate
          amenities {
            id
            name
            description
          }
          parkingTypes {
            id
            name
            description
          }
          accessTypes {
            id
            name
            description
          }
          suitableVehicleTypes {
            id
            name
            description
          }
          maxHeight {
            id
            value
            unit
            normalized
          }
          filters
        }
      }
    }
  }
`;

export const CONFIRM_BOOKING_REQUEST = gql`
  mutation ConfirmBookingRequest($id: ID!) {
    confirmBookingRequest(id: $id) {
      bookingTransaction {
        id
      }
    }
  }
`;

export const GET_PARKING_SPACE_REQUESTED_BOOKINGS = gql`
  query GetParkingSpaceRequestedBookings($limit: Int, $parkingId: ID!) {
    getParkingSpaceRequestedBookings(limit: $limit, parkingId: $parkingId) {
      id
      bookings {
        costDiscount
        commissionDiscount
        endTime
        startTime
        cost
        commission
        total
        totalDiscount
        isMonthlyBooking
      }
      parking {
        address {
          value
          tz
        }
        priceOptions
        owner {
          id
          username
          firstName
          lastName
          contacts
          isActive
          email
          phoneNumber
        }
        id
        title
        description
        status
        lot
        accessInstruction
        deliveryInstruction
        createdDate
        contacts
        modifiedDate
        amenities {
          id
          name
          description
        }
        parkingTypes {
          id
          name
          description
        }
        accessTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        maxHeight {
          id
          value
          unit
          normalized
        }
        filters
      }
      paymentStatus
      nextPaymentDate
    }
  }
`;

export const GET_PARKING_SPACE_CONFIRMED_BOOKINGS = gql`
  query GetParkingSpaceConfirmedBookings($limit: Int, $parkingId: ID!) {
    getParkingSpaceConfirmedBookings(limit: $limit, parkingId: $parkingId) {
      id
      bookings {
        startTime
        endTime
        cost
        costDiscount
        commission
        commissionDiscount
        totalDiscount
        total
        isMonthlyBooking
      }
      parking {
        address {
          value
          tz
        }
        priceOptions
        owner {
          id
          username
          firstName
          lastName
          contacts
          isActive
          email
          phoneNumber
        }
        id
        title
        description
        status
        lot
        accessInstruction
        deliveryInstruction
        createdDate
        contacts
        modifiedDate
        amenities {
          id
          name
          description
        }
        parkingTypes {
          id
          name
          description
        }
        accessTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        maxHeight {
          id
          value
          unit
          normalized
        }
        filters
      }
      paymentStatus
      nextPaymentDate
    }
  }
`;

export const GET_PARKING_SPACE_COMPLETED_BOOKINGS = gql`
  query GetParkingSpaceCompletedBookings($limit: Int, $parkingId: ID!) {
    getParkingSpaceCompletedBookings(limit: $limit, parkingId: $parkingId) {
      id
      bookings {
        startTime
        endTime
        cost
        costDiscount
        commission
        commissionDiscount
        totalDiscount
        total
        isMonthlyBooking
      }
      parking {
        address {
          value
          tz
        }
        priceOptions
        owner {
          id
          username
          firstName
          lastName
          contacts
          isActive
          email
          phoneNumber
        }
        id
        title
        description
        status
        lot
        accessInstruction
        deliveryInstruction
        createdDate
        contacts
        modifiedDate
        amenities {
          id
          name
          description
        }
        parkingTypes {
          id
          name
          description
        }
        accessTypes {
          id
          name
          description
        }
        suitableVehicleTypes {
          id
          name
          description
        }
        maxHeight {
          id
          value
          unit
          normalized
        }
        filters
      }
    }
  }
`;

const BookingTransaction = `
    id
    isMonthlyBooking
    contacts
    bookings {
      costDiscount
      commissionDiscount
      endTime
      startTime
      cost
      commission
      total
      totalDiscount
      isMonthlyBooking
    }
    parking {
      address {
        value
        tz
      }
      priceOptions
      owner {
        id
        username
      }
      id
      title
      description
      status
      lot
      accessInstruction
      deliveryInstruction
      createdDate
      contacts
      modifiedDate
      amenities {
        id
        name
        description
      }
      parkingTypes {
        id
        name
        description
      }
      accessTypes {
        id
        name
        description
      }
      suitableVehicleTypes {
        id
        name
        description
      }
      maxHeight {
        id
        value
        unit
        normalized
      }
      filters
    }
`;

export const GET_REQUESTED_BOOKINGS = gql`
  query GetRequestedBookings($limit: Int) {
    getRequestedBookings(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_BOOKING_REQUESTS = gql`
  query GetBookingRequests($limit: Int) {
    getBookingRequests(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_CONFIRMED_BOOKINGS = gql`
  query GetConfirmedBookings($limit: Int) {
    getConfirmedBookings(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_BOOKING_CONFIRMATIONS = gql`
  query GetBookingConfirmations($limit: Int) {
    getBookingConfirmations(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_PENDING_BOOKINGS = gql`
  query GetPendingBookings($limit: Int) {
    getPendingCompletedBookings(limit: $limit) {
      ${BookingTransaction}
    }
    getRequestToCancelBookings(limit: $limit) {
      ${BookingTransaction}
    }
    getUnderInvestigationBookings(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_BOOKING_PENDINGS = gql`
  query GetBookingPendings($limit: Int) {
    getBookingPendingCompletions(limit: $limit) {
      ${BookingTransaction}
    }
    getBookingRequestToCancellations(limit: $limit) {
      ${BookingTransaction}
    }
    getBookingUnderInvestigations(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_COMPLETED_BOOKINGS = gql`
  query GetCompletedBookings($limit: Int) {
    getCompletedBookings(limit: $limit) {
      ${BookingTransaction}
    }
    getCancelledBookings(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_BOOKING_COMPLETIONS = gql`
  query GetBookingCompletions($limit: Int) {
    getBookingCompletions(limit: $limit) {
      ${BookingTransaction}
    }
    getBookingCancellations(limit: $limit) {
      ${BookingTransaction}
    }
  }
`;

export const GET_INVOICE_HISTORY = gql`
  query getInvoiceHistory($pageFrom: Int, $pageSize: Int) {
    getInvoiceHistory(pageFrom: $pageFrom, pageSize: $pageSize) {
      total
      invoices {
        id
        createDate
        invoiceType
        amount
      }
    }
  }
`;

export const GET_BOOKING_INVOICE_HISTORY = gql`
  query getBookingInvoiceHistory(
    $bookingTransactionId: UUID
    $pageFrom: Int
    $pageSize: Int
  ) {
    getBookingInvoiceHistory(
      bookingTransactionId: $bookingTransactionId
      pageFrom: $pageFrom
      pageSize: $pageSize
    ) {
      total
      invoices {
        id
        createDate
        invoiceType
        amount
      }
    }
  }
`;

export const GET_INVOICE_DOWNLOAD_URL = gql`
  query getInvoiceDownloadUrl($invoiceId: UUID) {
    getInvoiceDownloadUrl(invoiceId: $invoiceId) {
      url
    }
  }
`;

export const SUBMIT_DENY_BOOKING_REQUEST_REASON = gql`
  mutation submitDenyBookingRequestReason($token: ID!, $denyReason: String!) {
    submitDenyBookingRequestReason(token: $token, denyReason: $denyReason) {
      success
    }
  }
`;
export const DENY_BOOKING = gql`
  mutation denyBookingRequest($id: ID!) {
    denyBookingRequest(id: $id) {
      bookingRequest {
        id
        parking {
          id
        }
      }
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation requestToCancelBooking($bookingTransactionId: ID!) {
    requestToCancelBooking(bookingTransactionId: $bookingTransactionId) {
      bookingTransaction {
        id
      }
    }
  }
`;

export const GET_BOOKING_DETAILS = gql`
  query getBookingDetails($bookingTransactionId: UUID) {
    getBookingDetails(bookingTransactionId: $bookingTransactionId) {
      id
      contacts
      isMonthlyBooking
      paymentStatus
      nextPaymentDate
      bookingStatus
      bookings {
        startTime
        cost
        costDiscount
        commission
        commissionDiscount
        totalDiscount
        total
        isMonthlyBooking
      }
      parking {
        id
        owner {
          email
          id
          firstName
          lastName
        }
        title
        status
        accessInstruction
        deliveryInstruction
        description
        maxHeight {
          value
          unit
        }
        parkingTypes {
          id
          name
        }
        accessTypes {
          id
          name
        }
        address {
          coordinate {
            lat
            lon
          }
          value
          subpremise
          streetNumber
          route
          locality
          postalCode
          state
          stateCode
          country
          countryCode
          tz
        }
        priceOptions
      }
    }
  }
`;

export const GET_RENTER_PARKING_PRICE_OPTIONS = gql`
  query renterParkingPriceOptions($parkingId: ID!) {
    renterParkingPriceOptions(id: $parkingId) {
      id
      dailyPrice
      weeklyPrice
      monthlyPrice
    }
  }
`;
