import React, { Component } from 'react';
import styled from 'styled-components';
import {
  graphite,
  greyLight,
  midBreakPoint
} from '../../../_shared/layout-constants';
import MessageContent from './MessageContent';
import { ImgixUrl } from '../../../_shared/utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  color: ${graphite};
  margin-bottom: 35px;
  grid-template-rows: 48px auto 2px;
  grid-template-columns: 68px 1fr;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 76px 1fr 23px;
    grid-template-rows: auto 2px;
  }
`;

const MessageDate = styled.div`
  grid-row: 1;
  grid-column: 1 / 3;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const Icon = styled.div`
  grid-row: 2;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
    margin-right: 25px;
    grid-row: 1;
  }
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  padding: ${({ quokka }) => (quokka ? '5px' : '0')};
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: solid 1px ${greyLight};
  border-radius: 50%;
`;

const Info = styled.div`
  grid-row: 2;
  grid-column: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 1;
  }
`;

const Expander = styled.div`
  grid-row: 2;
  grid-column: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 1;
  }
`;

const Divider = styled.hr`
  grid-row: 3;
  grid-column: 1 / 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 2;
  }
`;

export default class Message extends Component {
  render() {
    const { name, imagePath, messageDate } = this.props;

    return (
      <Wrapper>
        <MessageDate>{messageDate}</MessageDate>
        <Icon>
          {imagePath ? (
            <div>
              <Image
                src={`${ImgixUrl}/web-image-assets/placeholders/${imagePath}`}
                alt="profile"
              />
              {name}
            </div>
          ) : (
            <div>
              <Image
                quokka={true}
                src={`${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder-greysvg`}
                alt="profile placeholder"
              />
              {'Quokka'}
            </div>
          )}
        </Icon>
        <Info>
          <MessageContent {...this.props} />
        </Info>
        <Expander />
        <Divider />
      </Wrapper>
    );
  }
}
