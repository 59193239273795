import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { Button } from '../../../carpark';
import { ShareModal } from '../../../common/components';
import {
  greyExtraLight,
  yellowLight,
  grey,
  teal,
  greyDark,
  RubikSemiBold,
  defaultBoxShadow
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const TicketContainer = styled.div`
  min-height: 360px;
  width: 240px;
  height: 360px;
  border-radius: 3px;
  box-shadow: 0 4px 0 0 ${greyExtraLight};
  background-color: #fff;
  border: solid 1px ${greyExtraLight};
  display: grid;
  grid-gap: 0;
  grid-template-columns: 24px 187px 12px 17px;
  grid-template-rows: 88px 28px auto 36px 59px auto 49px;
  box-shadow: ${defaultBoxShadow};
  margin: 0 24px 48px 0;
`;

const ActiveBookingYellowDecoration = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 8;
  width: 15px;
  height: 358px;
  background-color: ${yellowLight};
`;

const HistoryBookingGreyDecoration = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 8;
  width: 15px;
  height: 358px;
  background-color: ${grey};
`;

const PLogo = styled.img`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin: 24px 126px 24px 0;
`;

const ShareIcon = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin: 24px 12px 24px 155px;
`;

const TicketID = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: 20px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
  margin-bottom: 8px;
`;

const ParkingInfo = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  font-size: 14px;
  padding-top: 6px;
`;

const ParkingName = styled.span`
  margin-bottom: 8px;
  display: block;
  color: ${greyDark};
`;

const Address = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${greyDark};
`;

const PricingOption = styled.div`
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  height: 20px;
  margin-bottom: 16px;
`;

const TimeRange = styled.div`
  grid-column: 2 / 3;
  grid-row: 5 / 6;
`;

const TimeOn = styled.span`
  font-size: 14px;
  text-align: left;
  margin-right: 15px;
  display: inline;
`;

const Time = styled.span`
  font-size: 16px;
  font-weight: ${RubikSemiBold};
  line-height: 18px;
  text-align: center;
  display: inline;
`;

const Instruction = styled.div`
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  height: 29px;
  opacity: 0.7;
  font-size: 12px;
`;

const ViewDetailsButton = styled(Link)`
  grid-column: 1 / 3;
  grid-row: 7 / 8;
  width: 224px;
  height: 16px;
  font-family: Arial;
  font-size: 14px;
  text-align: center;
  color: ${teal};
  padding: 12px 0 12px 0;
`;

const Ticket = props => {
  const { actions } = useContext(ModalStoreContext);
  const {
    status,
    ticketId,
    parkingName,
    address,
    isMonthlyOngoing,
    startTime,
    endTime,
    accessInstruction,
    parkingId,
    ...otherProps
  } = props;

  const type =
    status === 'confirmations' || status === 'requests' ? 'live' : 'off';

  return (
    <TicketContainer {...otherProps}>
      {type === 'live' ? (
        <ActiveBookingYellowDecoration />
      ) : (
        <HistoryBookingGreyDecoration />
      )}
      <PLogo
        src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
        alt="p icon"
        width="40"
        height="40"
      />
      <ShareIcon>
        <Button
          iconPath="Share-button.svg"
          onClick={() => actions.handleShowModal('ShareModal')}
        />
      </ShareIcon>

      <TicketID>
        Space{' '}
        {ticketId &&
          ticketId
            .toString()
            .substring(0, 5)
            .concat('...')}
      </TicketID>

      <ParkingInfo>
        <ParkingName>
          {parkingName && parkingName.substring(0, 25).concat('...')}
        </ParkingName>
        <Address>{address && address.substring(0, 25).concat('...')}</Address>
      </ParkingInfo>

      <PricingOption>
        {isMonthlyOngoing ? 'monthly ongoing' : 'one time booking'}
      </PricingOption>

      <TimeRange>
        {startTime && (
          <div>
            <TimeOn>Start on</TimeOn>
            <Time>{startTime}</Time>
          </div>
        )}
        {endTime && (
          <div>
            <TimeOn>End on</TimeOn>
            <Time>{endTime === 'Invalid date' ? '-' : endTime}</Time>
          </div>
        )}
      </TimeRange>

      <Instruction>
        Note:{' '}
        {accessInstruction && accessInstruction.substring(0, 25).concat('...')}
      </Instruction>

      {status !== 'requests' && (
        <ViewDetailsButton
          to={
            '/admin/bookings/my-bookings/booking-detail/' +
            type +
            '/' +
            ticketId
          }
        >
          Learn more
        </ViewDetailsButton>
      )}
      <ShareModal id={ticketId} />
    </TicketContainer>
  );
};

export default Ticket;
