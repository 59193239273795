import React from 'react';
import styled from 'styled-components';
import { greyLight, midBreakPoint } from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';
import { loadGoogleMaps } from '../../../_shared/load-google-map';

const MobileNav = styled.div`
  z-index: 3;
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: flex;
    transition: top 0.3s ease-in-out;
    position: fixed;
    top: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 0 : '-2000px')};
    height: 100%;
    width: 100%;
    background-color: white;
    left: 0;
    right: 0;
    padding: 48px 24px 0 24px;
    flex-direction: column;
  }
`;

const NavItem = styled.a`
  display: block;
  margin-top: 26px;
  cursor: pointer;
`;

const BreakLine = styled.div`
  border-bottom: solid 1px ${greyLight};
  margin-top: 24px;
  height: 0;
`;

const NavIcon = styled.img`
  width: 26px;
  height: 26px;
  margin: 0 10px 5px 0;
`;

const NavIcon2 = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 12px 3px 2px;
`;

const Close = styled.img`
  grid-row: 1;
  grid-column: 2;
  width: 28px;
  height: 24px;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 24px;

  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

export default class MobileNavComponent extends React.Component {
  render() {
    const {
      isAuthenticated,
      handleShowModal,
      handleLogout,
      hideMobileNav,
      isMobileNavOpen
    } = this.props;
    return (
      <MobileNav isMobileNavOpen={isMobileNavOpen}>
        <Close
          src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
          alt="close"
          onClick={hideMobileNav}
        />
        <div style={{ overflowY: 'auto' }}>
          <NavItem href="/search">
            <NavIcon
              src={`${ImgixUrl}/web-image-assets/icons/Search.svg`}
              alt="search"
            />
            Search
          </NavItem>
          <BreakLine />
          <NavItem
            onClick={() => {
              loadGoogleMaps();
              hideMobileNav();
              handleShowModal('ExpressInterest');
            }}
          >
            <NavIcon
              src={`${ImgixUrl}/web-image-assets/icons/admin/bookings.svg`}
              alt="express interest"
            />
            Express interest
          </NavItem>
          <NavItem href="/admin/my-listings">
            <NavIcon
              src={`${ImgixUrl}/web-image-assets/icons/cash.svg`}
              alt="search"
            />
            List your space
          </NavItem>
          <NavItem href="/contact-us">
            <NavIcon
              src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
              alt="contact us"
            />
            Contact us
          </NavItem>
          <BreakLine />
          {!isAuthenticated ? (
            <React.Fragment>
              <NavItem
                onClick={() => {
                  handleShowModal('Login');
                  hideMobileNav();
                }}
              >
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
                  alt="login"
                />
                Login
              </NavItem>
              <NavItem
                onClick={() => {
                  handleShowModal('Signup');
                  hideMobileNav();
                }}
              >
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
                  alt="signup"
                />
                Sign up
              </NavItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavItem href="/admin/bookings/my-bookings">
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/bookings.svg`}
                  alt="admin my bookings"
                />
                My bookings
              </NavItem>
              <NavItem href="/admin/bookings/manage-bookings">
                <NavIcon2
                  src={`${ImgixUrl}/web-image-assets/icons/admin/manageBookings.svg`}
                  alt="admin manage bookings"
                />
                Manage bookings
              </NavItem>
              <NavItem href="/admin/favourite-bookings">
                <NavIcon2
                  src={`${ImgixUrl}/web-image-assets/icons/admin/favParking.svg`}
                  alt="admin favourite parking"
                />
                Favourite parking
              </NavItem>
              <NavItem href="/admin/my-listings">
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/myListings.svg`}
                  alt="admin my listings"
                />
                My listings
              </NavItem>
              <NavItem href="/admin/billings">
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/billings.svg`}
                  alt="admin billings"
                />
                Billing
              </NavItem>
              <NavItem href="/admin/profile">
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/profile.svg`}
                  alt="admin profile"
                />
                Profile
              </NavItem>
              <NavItem href="/admin/settings">
                <NavIcon
                  src={`${ImgixUrl}/web-image-assets/icons/admin/settings.svg`}
                  alt="admin settings"
                />
                Settings
              </NavItem>
            </React.Fragment>
          )}
          <BreakLine />
          {isAuthenticated && (
            <NavItem
              style={{ marginBottom: 80 }}
              onClick={e => {
                handleLogout(e);
                hideMobileNav();
              }}
            >
              <NavIcon
                src={`${ImgixUrl}/web-image-assets/icons/admin/logout.svg`}
                alt="search"
              />
              Log out
            </NavItem>
          )}
        </div>
      </MobileNav>
    );
  }
}
