import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  SimplifiedFooter,
  StandardFooter
} from '../../../../common/components';
import {
  midBreakPoint,
  RubikSemiBold,
  spacing
} from '../../../../_shared/layout-constants';
import { FullWidthPrimaryButton } from '../../../../_shared/components/Buttons';
import { ImgixUrl } from '../../../../_shared/utils';

const Layout = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 900px 1fr;
    grid-template-rows: 64px auto 380px;
  }
`;

const MobileFooter = styled.div`
  display: block;
  margin: 50px 24px 0 24px;
  grid-column: 1 / 6;
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / 6;
    grid-row: 3;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${spacing(5)} ${spacing(4)} 0 ${spacing(4)};
  @media (min-width: ${midBreakPoint}px) {
    overflow-y: visible;
    grid-column: 2;
    grid-row: 2 / 3;
    min-height: 600px;
    padding: 0 36px 80px 36px;
    margin: 0 0 80px 0;
  }
`;

const LogoAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${midBreakPoint}px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

const Description = styled.p`
  margin-right: auto;
  margin-left: auto;
`;

const Button = styled(FullWidthPrimaryButton)`
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${midBreakPoint}px) {
    width: 265px;
  }
`;

const Logo = styled.img`
  width: 45px;
  margin-bottom: 18px;
  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 0;
  }
`;

export default class PaymentExpired extends Component {
  render() {
    return (
      <Layout>
        <PageContent>
          <LogoAndTitle>
            <Logo
              src={`${ImgixUrl}/web-image-assets/icons/quokka-faint.svg`}
              alt="maintenance"
            />
            <Title>Your payment has expired</Title>
          </LogoAndTitle>
          <Description>
            We are glad that you come back, don't worry, you can make an another
            booking
          </Description>
          <Button>
            <Link to="/search">Make another booking</Link>
          </Button>
        </PageContent>
        <MobileFooter>
          <SimplifiedFooter />
        </MobileFooter>
        <DesktopFooter>
          <StandardFooter />
        </DesktopFooter>
      </Layout>
    );
  }
}
