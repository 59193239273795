import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import { UpdateMyDetailsModal } from '../../../../common/modal';
import {
  teal,
  greyDark,
  midBreakPoint
} from '../../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 100%;
  min-height: 150px;
  display: grid;
  grid-gap: 0;
  grid-template-rows: repeat(4, 44px) 52px 44px;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 214px 1fr 30px;
    grid-template-rows: 40px 40px 40px 20px;
  }
`;

const Firstname = styled.div`
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 1;
  }
`;

const Lastname = styled.div`
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 1;
  }
`;

const Edit = styled.a`
  grid-row: 6;
  color: ${teal};
  cursor: pointer;
  @media (min-width: ${midBreakPoint}px) {
    text-align: right;
    grid-column: 3;
    grid-row: 1;
  }
`;

const DateOfBirth = styled.div`
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 3;
    grid-row: 2;
  }
`;

const Email = styled.div`
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 3;
    grid-row: 3;
  }
`;

const PhoneNumber = styled.div`
  grid-row: 5;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 3;
    grid-row: 4;
  }
`;

const Subtitle = styled.span`
  margin-right: 14px;
`;

const Info = styled.span`
  color: ${greyDark};
`;

const MyDetails = props => {
  const { actions } = useContext(ModalStoreContext);
  const { firstName, lastName, dateOfBirth, email, phoneNumber } = props.user;
  return (
    <Wrapper>
      <UpdateMyDetailsModal />

      <Edit onClick={() => actions.handleShowModal('UpdatePersonalDetails')}>
        Edit
      </Edit>

      <Firstname>
        <Subtitle>First name:</Subtitle>
        <Info>{firstName}</Info>
      </Firstname>

      <Lastname>
        <Subtitle>Last name:</Subtitle>
        <Info>{lastName}</Info>
      </Lastname>

      <DateOfBirth>
        <Subtitle>Date of birth:</Subtitle>
        <Info>{dateOfBirth}</Info>
      </DateOfBirth>

      <Email>
        <Subtitle>Email:</Subtitle>
        <Info>{email}</Info>
      </Email>

      <PhoneNumber>
        <Subtitle>Phone number:</Subtitle>
        <Info>{phoneNumber}</Info>
      </PhoneNumber>
    </Wrapper>
  );
};

export default MyDetails;
