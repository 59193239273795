import React, { Component } from 'react';
import styled from 'styled-components';
import MessageButtons, { FirstButton, SecondaryButton } from './MessageButtons';
import {
  greyLight,
  greyDark,
  graphite,
  RubikSemiBold,
  bodyFontSize,
  yellow,
  midBreakPoint
} from '../../../_shared/layout-constants';

const Title = styled.div`
  display: flex;
  color: ${graphite};
  margin-bottom: 12px;
  justify-content: space-between;
  font-size: 16px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 20px;
  }
`;

const MessageType = styled.div`
  border-radius: 3px;
  height: 24px;
  margin: 2px;
  padding 3px 15px;
  font-size: 14px;
  text-align: center;
  margin-left: 16px;
  border: solid 1px ${greyLight};
  background: ${({ type }) => (type === 'Promotion' ? `${yellow}` : '#fff')};
`;

const MessageDate = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: inline;
    margin-left: auto;
    font-size: ${bodyFontSize}px;
    margin-top: 6px;
    height: 20px;
  }
`;

const Description = styled.div`
  width: 100%;
  line-height: 1.25;
  margin-bottom: 15px;
  color: ${greyDark};
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

const MessageBox = styled.div`
  border: solid 1px ${greyLight};
  padding: 15px;
  width: 100%;
  font-style: italic;
  margin-bottom: 24px;
  color: ${greyDark};
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

const Requester = styled.div`
  color: ${greyDark};
  margin-bottom: 8px;
`;

const Strong = styled.span`
  font-weight: ${RubikSemiBold};
`;

export default class MessageContent extends Component {
  render() {
    const {
      name,
      type,
      address,
      fromDate,
      endDate,
      message,
      messageSubject,
      earning,
      promotion,
      messageDate,
      renter,
      percent
    } = this.props;

    return (
      <div>
        <Title>
          <span>
            {type === 'Booking'
              ? 'New booking request'
              : type === 'Payment'
              ? 'Payment received'
              : type === 'Promotion'
              ? promotion
              : messageSubject}
          </span>
          <MessageType type={type}>{type}</MessageType>
          <MessageDate>{messageDate}</MessageDate>
        </Title>
        {type === 'Booking' ? (
          <div>
            <Description>
              {name} would like to book your parking space at {address} from{' '}
              <Strong>{fromDate}</Strong> to <Strong>{endDate}</Strong>.
            </Description>
            <Description>
              You will earn <Strong>${earning}</Strong> from this booking.
            </Description>
            <MessageBox>
              <Requester>{name} says:</Requester>
              <div>{message}</div>
            </MessageBox>
            <MessageButtons
              approveText="Approve request"
              denyText="Nope, deny it"
            />
          </div>
        ) : type === 'Payment' ? (
          <div>
            <Description>
              You have received a monthly payment of ${earning} for your parking
              space at {address} from {renter}.
            </Description>
            <SecondaryButton>View transaction record</SecondaryButton>
          </div>
        ) : type === 'Promotion' ? (
          <div>
            <Description>
              Quokka Parking is giving away {percent}% back to parking space
              managers in the coming October. List more spaces on Quokka Parking
              and earn more!
            </Description>
            <FirstButton>Learn more</FirstButton>
          </div>
        ) : (
          <div>
            <Description>{message}</Description>
            <MessageButtons approveText="Reply" person={name} />
          </div>
        )}
      </div>
    );
  }
}
