import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SimplifiedFooter, StandardFooter } from '../common/components';
import {
  midBreakPoint,
  RubikSemiBold,
  spacing
} from '../_shared/layout-constants';
import { FullWidthPrimaryButton } from '../_shared/components/Buttons';
import { ImgixUrl } from '../_shared/utils';

const Layout = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    height: 100%;
    grid-template-columns: 1fr 900px 1fr;
    grid-template-rows: 64px auto 380px;
  }
`;

const MobileFooter = styled.div`
  display: block;
  margin: 50px 24px 0 24px;
  grid-column: 1 / 6;
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / 6;
    grid-row: 3;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${spacing(5)} ${spacing(4)} 0 ${spacing(4)};
  @media (min-width: ${midBreakPoint}px) {
    overflow-y: visible;
    grid-column: 2;
    grid-row: 2 / 3;
    min-height: 600px;
    padding: 0 36px 80px 36px;
    margin: 0 0 80px 0;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  margin-bottom: 20px;
  text-align: center;
`;

const Description = styled.p`
  margin-right: auto;
  margin-left: auto;
`;

const Button = styled(FullWidthPrimaryButton)`
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${midBreakPoint}px) {
    width: 265px;
  }
`;

const Illustration = styled.img`
  width: 200px;
  margin: 0 auto 50px auto;
  @media (min-width: ${midBreakPoint}px) {
    width: 360px;
  }
`;

export default class ServiceNotAvailablePage extends Component {
  render() {
    return (
      <Layout>
        <PageContent>
          <Illustration
            src={`${ImgixUrl}/web-image-assets/illustration/bad-gateaway.png`}
          />
          <Title>Oops, Quokka Parking is not available at the moment.</Title>
          <Description>
            We are glad that you come back, don't worry, you can make an another
            booking
          </Description>
          <Button>
            <Link to="/search">Back to search</Link>
          </Button>
        </PageContent>
        <MobileFooter>
          <SimplifiedFooter />
        </MobileFooter>
        <DesktopFooter>
          <StandardFooter />
        </DesktopFooter>
      </Layout>
    );
  }
}
