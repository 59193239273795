import React, { Component } from 'react';
import moment from 'moment';
import parseAddress from 'australia-address-parser';
import {
  Location,
  Features,
  Details,
  Contact,
  Pricing,
  Availability,
  Review
} from '../../../admin';
import { trackCreateListingStep } from '../../../_shared/tracker';
import { submitParkingDraft, submitCompletedParkingForm } from './_submit';
import CreateListingPageWrapper from './_wrapper';
import {
  CREATE_LISTING_STEPS,
  PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY
} from './_constants';

class CreateListingForm extends Component {
  state = {
    currentStepIndex: 0,
    lastStepIndex: 0,
    invalid: false,
    loading: false,
    errorMsg: null,

    geolocation: null,
    agree: true,
    detailPageTouched: false,

    form: {
      accessInstruction: null,
      accessTypes: null,
      address: null,
      amenities: null,
      contacts: null,
      description: null,
      id: null, //true listing id
      maxHeight: null,
      page: null, // only in draft
      parkingTypes: null,
      priceOptions: null,
      suitableVehicleTypes: null,
      title: null,
      geolocation: null,
      daysAvailable: null,
      availableDateFrom: moment()
        .add(2, 'days')
        .toISOString(), // availableFromDate in draft  //TODO: remote add 2 days
      deliveryInstruction: null, // keyDeliveryInstruction in draft
      lot: null // parkingLotNumber in draft
    }
  };

  componentDidMount() {
    this.updateState({
      loading: true,
      errorMsg: null
    });

    const { firstName, lastName, phoneNumber, email } = this.props.user;
    const default_values = {
      contactName: `${firstName} ${lastName}`,
      contactNumber: phoneNumber,
      contactType: 'Owner',
      accessInstruction: 'Access from the basement',
      deliveryInstruction: 'In person',
      email,
      country: 'Australia',
      daysAvailable: [0, 1, 2, 3, 4, 5, 6].map(d => ({ id: d }))
    };
    this.props.initialize(default_values);

    // if has draft, restore draft, other wise start new
    if (this.props.match.params && this.props.match.params.id) {
      const drafts = this.props.getParkingSpaces.drafts;
      const draft = drafts.filter(d => d.id === this.props.match.params.id)[0];
      //console.log(draft);
      this.restoreDraft(draft, default_values);
      // jump to last stored step
    } else if (this.state.form.page === null) {
      this.props.initialize({
        ...default_values,
        ...this.props.localData.getItem(PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY)
      });
      this.setState({
        loading: false,
        errorMsg: null,
        form: {
          ...this.state.form,
          page: 'Location'
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      this.props.setLoading(this.state.loading); // sync with admin wrapper loader
    }
  }

  restoreDraft = async (draft, defaults) => {
    const stepIndex = CREATE_LISTING_STEPS.find(s => draft.page === s.section)
      .step;
    const contacts =
      JSON.parse(draft.contacts) && JSON.parse(draft.contacts)[0];
    const prices =
      JSON.parse(draft.priceOptions) && JSON.parse(draft.priceOptions)[0];
    const filters =
      typeof draft.filters === 'string' ? JSON.parse(draft.filters) : undefined;
    await this.updateFormValue({
      ...draft,
      deliveryInstruction: draft.keyDeliveryInstruction,
      lot: draft.parkingLotNumber,
      accessTypes: draft.accessTypes.map(s => s.id),
      amenities: draft.amenities.map(s => s.id),
      maxHeight: draft.maxHeight && draft.maxHeight.id,
      parkingTypes: draft.parkingTypes.map(s => s.id),
      suitableVehicleTypes: draft.suitableVehicleTypes.map(s => s.id),
      ...(contacts && { contacts: [JSON.stringify(contacts)] }), // to avoid weird [[[]]]
      ...(prices && { priceOptions: [JSON.stringify(prices)] }),
      ...(filters && {
        daysAvailable: [JSON.stringify(filters[0])]
      })
    });

    // console.log(CREATE_LISTING_STEPS[stepIndex].pageProperties);
    // console.log(
    //   this.props.localData.getItem(
    //     draft.id,
    //     CREATE_LISTING_STEPS[stepIndex].pageProperties
    //   )
    // );

    const parsedAddr = parseAddress.parseLocation(draft.address);
    const data = this.props.localData.getItem(draft.id);

    if (
      data === Object(data) &&
      typeof data['availableDateFrom'] === 'string'
    ) {
      data['availableDateFrom'] = moment(data['availableDateFrom']);
    }

    this.props.initialize({
      ...defaults,
      ...(parsedAddr && {
        streetNumber: parsedAddr.streetNumber,
        streetName: parsedAddr.streetName,
        streetType: parsedAddr.streetType,
        suburb: parsedAddr.suburb,
        postcode: parsedAddr.postcode,
        state: {
          value: parsedAddr.state,
          label: parsedAddr.state
        },
        country: 'Australia'
      }),
      // because select field comes in object format
      ...(draft.accessTypes &&
        draft.accessTypes.length > 0 && {
          accessTypes: {
            label: draft.accessTypes[0].name,
            value: draft.accessTypes[0].name
          }
        }),
      ...(draft.parkingTypes &&
        draft.parkingTypes.length > 0 && {
          parkingTypes: {
            label: draft.parkingTypes[0].name,
            value: draft.parkingTypes[0].name
          }
        }),
      amenities: draft.amenities,
      ...(draft.maxHeight && {
        maxHeight: {
          label: draft.maxHeight.value,
          value: draft.maxHeight.value
        }
      }),
      suitableVehicles: draft.suitableVehicleTypes,

      ...((draft.title && {
        listingTitle: draft.title
      }) || {
        listingTitle: this.autoFieldTexts('listingTitle', {
          streetType: parsedAddr.streetType,
          streetName: parsedAddr.streetName
        })
      }),
      ...((draft.description && {
        description: draft.description
      }) || {
        description: this.autoFieldTexts('description', {
          streetName: parsedAddr.streetName,
          streetType: parsedAddr.streetType,
          amenities: draft.amenities,
          suitableVehicles: draft.suitableVehicleTypes
        })
      }),
      parkingLotNumber: draft.parkingLotNumber,
      ...(draft.accessInstruction && {
        accessInstruction: draft.accessInstruction
      }),
      ...(draft.keyDeliveryInstruction && {
        deliveryInstruction: draft.keyDeliveryInstruction
      }),
      ...(contacts && {
        contactName: contacts.contactName,
        contactNumber: contacts.contactNumber,
        contactType: contacts.contactType,
        email: contacts.email
      }),

      ...(prices && {
        daily: prices.daily,
        weekly: prices.weekly,
        monthly: prices.monthly,
        deposit: prices.deposit
      }),

      availableDateFrom:
        draft.availableDateFrom && moment(draft.availableDateFrom),
      ...(filters && {
        daysAvailable: filters.value.map(day => ({ id: day }))
      }),
      ...data
      // TODO: filters
    });

    this.jumpToPage(stepIndex);
    this.setState({
      lastStepIndex: stepIndex
    });

    this.updateState({
      loading: false,
      errorMsg: null
    });
  };

  // General use
  updateState = state => {
    this.setState(state, () => {
      console.log('@@@updated state', this.state);
    });
  };

  updateFormValue = values => {
    return new Promise(resolve => {
      this.setState(
        {
          form: {
            ...this.state.form,
            ...values
          }
        },
        () => {
          resolve();
          //console.log('@@form state updated', this.state);
        }
      );
    });
  };

  updateDraft = shouldUpdate => {
    if (shouldUpdate) {
      submitParkingDraft({
        addParkingDraft: this.props.addParkingDraft,
        updateState: this.updateState,
        form: this.state.form,
        updateFormValue: this.updateFormValue
      });
    }
  };
  //TODO: update availability

  // location
  // this function should also incharge of update variable
  composeLocationFormValues = async ({
    streetNumber,
    streetName,
    streetType,
    suburb,
    state: { value: state },
    postcode,
    parkingLotNumber,
    isReview = false
  }) => {
    const address = `${streetNumber} ${streetName} ${streetType}. ${suburb} ${state} ${postcode}, Australia`; // TODO: remove hardcode
    await this.updateFormValue({
      address,
      title: this.autoFieldTexts('listingTitle', { streetName, streetType }),
      lot: parkingLotNumber,
      page: isReview ? 'Review' : 'Features'
    });
    this.props.change(
      'listingTitle',
      this.autoFieldTexts('listingTitle', { streetName, streetType })
    );
    this.props.change(
      'description',
      `A parking lot on ${streetName} ${streetType} is available.`
    );
    this.updateDraft(isReview);
  };

  // features
  composeFeaturesFormValues = async values => {
    const {
      allAccessTypes,
      allParkingTypes,
      allHeightOptions,
      isReview = false
    } = this.props;
    if (
      allHeightOptions.loading ||
      allAccessTypes.loading ||
      allParkingTypes.loading
    )
      return null;
    const {
      streetName,
      parkingTypes,
      streetType,
      accessTypes,
      maxHeight,
      amenities,
      suitableVehicles
    } = values;

    // const { streetName, streetType } = this.state.locationState;
    await this.updateFormValue({
      page: isReview ? 'Review' : 'Details',
      accessTypes: allAccessTypes.allAccessTypes
        .filter(a => accessTypes.value === a.name)
        .map(a => a.id),
      parkingTypes: allParkingTypes.allParkingTypes
        .filter(a => parkingTypes.value === a.name)
        .map(a => a.id),
      maxHeight: allHeightOptions.allHeightOptions
        .filter(a => maxHeight.value === a.value)
        .map(a => a.id)[0],
      amenities: amenities.map(a => a.id),
      suitableVehicleTypes: suitableVehicles.map(s => s.id)
    });
    this.props.change(
      'description',
      this.autoFieldTexts('description', {
        streetType,
        streetName,
        amenities,
        suitableVehicles
      })
    );
    this.updateDraft(isReview);
  };

  autoFieldTexts = (field, data) => {
    switch (field) {
      case 'listingTitle':
        return `An affordable parking lot on ${data.streetName} ${data.streetType}`;
      case 'description':
        return `A parking lot on ${data.streetName} ${
          data.streetType
        } is available. This space comes with ${data.amenities
          .map(a => a.name)
          .join(', ')}. It is suitable for ${data.suitableVehicles
          .map(a => a.name)
          .join(', ')}. Book it today.`;
      default:
        return '';
    }
  };

  // details
  composeDetailsFormValues = async values => {
    const {
      listingTitle,
      accessInstruction,
      deliveryInstruction,
      description,
      isReview = false
    } = values;

    await this.updateFormValue({
      page: isReview ? 'Review' : 'Contact',
      title: listingTitle,
      accessInstruction,
      description,
      deliveryInstruction
    });

    this.updateDraft(isReview);
  };

  // contacts
  composeContactFormValues = async ({
    contactName,
    contactNumber,
    email,
    contactType,
    isReview = false,
    isUpdate = false
  }) => {
    await this.updateFormValue({
      page: isReview ? 'Review' : 'Contact',
      contacts: [
        JSON.stringify({
          contactName: contactName,
          contactNumber: contactNumber,
          email: email,
          contactType: contactType
        })
      ]
    });
    await this.updateUserInfo({ contactName, contactNumber, email });
    this.updateDraft(isReview || isUpdate);
  };

  updateUserInfo = async values => {
    try {
      const { user } = this.props;
      const { firstName, lastName, phoneNumber, email } = user;
      // not calling this api if only contact type changes
      const contactName = `${firstName} ${lastName}`;
      if (
        contactName === values.contactName &&
        phoneNumber === values.contactNumber &&
        email === values.email
      ) {
        return;
      }
      await this.props.updateUserInformation({
        variables: {
          firstName: values.contactName.split(/\s+/)[0],
          lastName: values.contactName.split(/\s+/)[1]
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  // pricing
  composePricingFormValues = async ({
    daily,
    weekly,
    monthly,
    deposit,
    isReview = false
  }) => {
    await this.updateFormValue({
      page: isReview ? 'Review' : 'Availability',
      priceOptions: [JSON.stringify({ daily, weekly, monthly, deposit })]
    });
    this.updateDraft(isReview);
  };

  // availability
  composeAvailabilityFormValues = async ({
    availableDateFrom = moment().add(2, 'days'),
    daysAvailable,
    isReview = false
  }) => {
    await this.updateFormValue({
      page: 'Review',
      availableDateFrom: availableDateFrom.toISOString(),
      daysAvailable: [
        JSON.stringify({
          key: 'dates',
          type: 'within',
          value: daysAvailable.map(d => d.id)
        })
      ]
    });
    this.props.change('availableDateFrom', availableDateFrom);
    this.updateDraft(isReview);
  };

  updateValidatedStep = () => {
    const { currentStepIndex, lastStepIndex } = this.state;
    if (currentStepIndex > lastStepIndex) {
      this.setState({ lastStepIndex: currentStepIndex });
    }
  };

  setInvalid = invalid => {
    this.setState({
      invalid
    });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentStepIndex: prevState.currentStepIndex + 1
    }));
  };

  handleNextButtonClick = async values => {
    this.updateState({
      loading: true,
      errorMsg: null
    });
    await this.updateValues(values);
    const id = this.props.user.id;
    const { addParkingDraft, addParkingSpace, formSyncErrors } = this.props;
    const { invalid, currentStepIndex } = this.state;

    this.updateValidatedStep();

    if (this.state.form.id === null) {
      this.props.localData.deleteItem(PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY);
    }

    // if detail page next button not clicked, don't show error msg for photo uploading
    if (currentStepIndex === 2) {
      this.setState({
        detailPageTouched: true
      });
    }

    // if field invalid on the page, disable proceeding to next page
    if (
      invalid ||
      (currentStepIndex === 2 && formSyncErrors.enoughImageUploaded) || // photo not uploaded on details page
      (currentStepIndex === 3 && formSyncErrors.contactType)
    ) {
      this.setState({
        loading: false,
        errorMsg: null
      });
      return;
    }
    trackCreateListingStep(currentStepIndex);

    if (
      currentStepIndex === 6 &&
      !this.checkMissingProps(formSyncErrors) &&
      this.state.agree
    ) {
      this.props.localData.deleteItem(this.state.form.id);
      await submitCompletedParkingForm({
        addParkingSpace,
        updateState: this.updateState,
        form: this.state.form,
        id
      });
    } else {
      await submitParkingDraft({
        addParkingDraft,
        updateState: this.updateState,
        form: this.state.form,
        updateFormValue: this.updateFormValue
      });
      this.goToNextPage();
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  jumpToPage = stepIndex => {
    this.setState({
      currentStepIndex: stepIndex
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  checkMissingProps = formSyncErrors => {
    const errorsArray = Object.values(formSyncErrors);
    const missingPropsErrors =
      errorsArray && errorsArray.length > 0 && errorsArray.join(', ');
    return missingPropsErrors;
  };

  updateValues = values => {
    const section = CREATE_LISTING_STEPS[this.state.currentStepIndex].section;
    switch (section) {
      case 'Location':
        this.composeLocationFormValues(values);
        break;
      case 'Features':
        this.composeFeaturesFormValues(values);
        break;
      case 'Details':
        this.composeDetailsFormValues(values);
        break;
      case 'Contact':
        this.composeContactFormValues(values);
        break;
      case 'Pricing':
        this.composePricingFormValues(values);
        break;
      case 'Availability':
        this.composeAvailabilityFormValues(values);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      currentStepIndex,
      lastStepIndex,
      errorMsg,
      loading,
      form,
      agree
    } = this.state;
    const {
      handleSubmit,
      change,
      invalid,
      formSyncErrors,
      localData,
      allParkingTypes,
      allAccessTypes,
      allAmenities,
      allHeightOptions,
      allVehicleTypes
    } = this.props;

    const missingPropsErrors =
      currentStepIndex === 6 && this.checkMissingProps(formSyncErrors);

    return (
      <form onSubmit={handleSubmit(() => {})} noValidate>
        <CreateListingPageWrapper
          updateState={this.updateState}
          currentStepIndex={currentStepIndex}
          lastStepIndex={lastStepIndex}
          errorMsg={errorMsg || missingPropsErrors}
          loading={loading}
          jumpToPage={this.jumpToPage}
          disabled={!agree && currentStepIndex === 6} //only show disable when hasn't agree on T&C on review page
          onNextButtonClick={handleSubmit(
            this.handleNextButtonClick.bind(this)
          )}
          invalid={invalid}
        >
          {currentStepIndex === 0 && (
            <Location change={change} draftId={form.id} localData={localData} />
          )}
          {currentStepIndex === 1 && (
            <Features
              draftId={form.id}
              allParkingTypes={allParkingTypes}
              allAccessTypes={allAccessTypes}
              allAmenities={allAmenities}
              allHeightOptions={allHeightOptions}
              allVehicleTypes={allVehicleTypes}
              form={form}
              change={change}
              localData={localData}
            />
          )}
          {currentStepIndex === 2 && (
            <Details
              parkingId={form.id}
              change={change}
              draftId={form.id}
              localData={localData}
              isDirty={this.state.detailPageTouched}
            />
          )}
          {currentStepIndex === 3 && (
            <Contact
              draftId={form.id}
              composeContactFormValues={this.composeContactFormValues}
              updateState={this.updateState}
              updateFormValue={this.updateFormValue}
              change={change}
              localData={localData}
            />
          )}
          {currentStepIndex === 4 && (
            <Availability
              draftId={form.id}
              change={change}
              updateFormValue={this.updateFormValue}
              localData={localData}
            />
          )}
          {currentStepIndex === 5 && (
            <Pricing change={change} draftId={form.id} localData={localData} />
          )}
          {currentStepIndex === 6 && (
            <Review
              draftId={form.id}
              composedValues={form}
              composeLocationFormValues={this.composeLocationFormValues}
              composeFeaturesFormValues={this.composeFeaturesFormValues}
              composeDetailsFormValues={this.composeDetailsFormValues}
              composeContactFormValues={this.composeContactFormValues}
              composePricingFormValues={this.composePricingFormValues}
              composeAvailabilityFormValues={this.composeAvailabilityFormValues}
              updateFormValue={this.updateFormValue}
              change={change}
              name="createListingForm"
              isPublic={false}
              parkingId={form.id}
              updateState={this.updateState}
              agree={agree}
              localData={localData}
            />
          )}
        </CreateListingPageWrapper>
      </form>
    );
  }
}

export default CreateListingForm;
