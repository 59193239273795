import { isValidPhoneNumber } from 'react-phone-number-input';
export default values => {
  const errors = {};
  if (!values.postcode) {
    errors.postcode = 'Postcode is required';
  } else if (!values.postcode.match(/^((0[289])|([1-9]\d))(\d{2})$/i)) {
    errors.postcode = 'Please enter a valid Austrlian postcode';
  }
  if (!values.streetNumber) {
    errors.streetNumber = 'Street number is required';
  }
  if (!values.streetName) {
    errors.streetName = 'Street name is required';
  } else if (!values.streetName.match(/[A-Za-z]/g)) {
    errors.streetName = 'Please enter a valid street name';
  }
  if (!values.streetType) {
    errors.streetType = 'Street type is required';
  } else if (!values.streetType.match(/^[A-Za-z]*$/g)) {
    errors.streetType = 'Please enter a valid street type';
  }

  if (!values.suburb) {
    errors.suburb = 'Suburb is required';
  } else if (!values.suburb.match(/^[A-Za-z ]*$/g)) {
    errors.suburb = 'Please enter valid suburb name';
  }

  if (!values.state || (values.state && !values.state.value)) {
    errors.state = 'State is required';
  }
  if (!values.parkingTypes) {
    errors.parkingTypes = 'Please select a parking type';
  }
  if (!values.accessTypes) {
    errors.accessTypes = 'Please select an access type';
  }
  if (!values.maxHeight) {
    errors.maxHeight = 'Please tell us about max height';
  }
  if (
    !values.amenities ||
    (values.amenities && values.amenities.length === 0)
  ) {
    errors.amenities =
      'Please tell us about amenity your parking space provides';
  }
  if (
    !values.suitableVehicles ||
    (values.suitableVehicles && values.suitableVehicles.length === 0)
  ) {
    errors.suitableVehicles =
      'Please tell us vehicle types can park at this parking space';
  }
  if (values.enoughImageUploaded < 2) {
    errors.enoughImageUploaded =
      'Please upload at lease three images. Quokka recommends you to upload 2 photos for the parking space and 1 photo for building or surrounding environment';
  }
  if (!values.listingTitle) {
    errors.listingTitle = 'Title is required';
  }
  if (!values.description) {
    errors.description =
      'Please describe your listing (eg. the type of parking space, amenities, landmarks around you)';
  }
  if (values.parkingLotNumber < 0) {
    errors.parkingLotNumber = 'Please provide a valid parking lot number. ';
  }
  if (!values.accessInstruction) {
    errors.accessInstruction = 'Access Instruction is required';
  }
  if (!values.deliveryInstruction) {
    errors.deliveryInstruction = 'Please provide key delivery instruction';
  }

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.email) {
    errors.email = 'Please enter the email address';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (!values.contactNumber) {
    errors.contactNumber = 'Please enter the contact phone number';
  } else if (!isValidPhoneNumber(values.contactNumber)) {
    errors.contactNumber = 'Please enter a vaild phone number';
  }

  if (!values.contactName) {
    errors.contactName = 'Please enter the contact name';
  }

  if (!values.contactType) {
    errors.contactType = 'Please describe the contact';
  }

  const price = /^(\d*\.{0,1}\d{1,2})$/g;
  if (!values.daily) {
    //errors.daily = 'Please tell us about daily rate of your listing';
  } else if (values.daily < 5) {
    //errors.daily =
    //  'The daily price needs to be a valid price; it suggests the daily price needs to be greater than $5';
  }
  // else if (!values.daily.match(price)) {
  //   errors.daily = 'Please enter a vaild price';
  // }

  if (!values.weekly) {
    //errors.weekly = 'Please tell us about weekly rate of your listing';
  } else if (!values.weekly.match(price)) {
    //errors.weekly = 'Please enter a vaild price';
  }

  if (!values.monthly) {
    errors.monthly = 'Please tell us about monthly rate of your listing';
  } else if (!values.monthly.match(price)) {
    errors.monthly = 'Please enter a vaild price';
  }
  // else if (Number(values.monthly) > Number(values.daily) * 31 * 0.9 * 1.2) {
  //   // 0.9 is our discount rate, 20% is our profit rate
  //   errors.monthly = 'Monthly price cannot be greater than daily times 31';
  // }

  if (!values.deposit) {
    errors.deposit = 'Please tell us about security bond amount';
  } else if (!values.deposit.match(price)) {
    errors.deposit = 'Please enter a vaild price';
  } else if (values.deposit < 100) {
    errors.deposit = 'The security bond needs to be greater or equal to $100';
  }

  if (values.daysAvailable && values.daysAvailable.length === 0) {
    errors.daysAvailable = 'Please at least pick one available day';
  }
  return errors;
};
