import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import { Link } from 'react-router-dom';
import { StandardFooter } from '../../../common/components';
import { ListingTicket } from '../../../admin';
import { GET_PARKING_SPACE } from '../../../common/graphql/carparkSchema';
import { RubikSemiBold } from '../../../_shared/layout-constants';
import {
  PrimaryButton,
  BorderButton
} from '../../../_shared/components/Buttons';
import { midBreakPoint } from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';
import './CreateListingSuccessPage.css';

const LayoutWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  width: 100%;
  background-color: #ffffff;
  grid-template-rows: 1fr auto;
  @media (min-width: ${midBreakPoint}px) {
    background-color: #f3f3f3;
    height: 100%;
    grid-template-columns: 1fr 900px 1fr;
    grid-template-rows: auto 380px;
  }
`;

const Content = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: #ffffff;
  margin-bottom: 48px;
  @media (min-width: ${midBreakPoint}px) {
    min-height: 952px;
    margin-bottom: 324px;
    padding: 124px 150px 324px 150px;
  }
`;

const Message = styled.div`
  display: grid;
  grid-gap: 0;
  margin: 0 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin: 0 auto 48px auto;
    grid-template-columns: 98px auto;
    grid-template-rows: 1fr 1fr;
  }
`;

const MessageIcon = styled.img`
  height: 40px;
  width: 40px;
  margin: -16px auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 64px;
    width: 64px;
    margin-right: 32px;
  }
`;

const MessageTitle = styled.div`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  line-height: 36px;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 28px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
`;

const MessageContent = styled.div`
  margin-top: 12px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
`;

const Instruction = styled.div`
  height: 20px;
  font-size: 18px;
  margin: 48px auto 48px auto;
  text-align: center;
  vertical-align: middle;
`;

const TicketSection = styled.div`
  height: 442px;
  width: 273px;
`;

const ButtonSection = styled.div`
  margin: 0 auto auto auto;
  justify-self: center;
`;

const Footer = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;
`;

const AddButton = styled(PrimaryButton)`
  width: 273px;
  height: 48px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
`;

const ViewMyListingButton = styled(BorderButton)`
  width: 273px;
  height: 48px;
  display: block;
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 24px;
`;

class CreateListingSuccessPage extends Component {
  state = {
    parking: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.getParkingSpace.loading &&
      prevProps.getParkingSpace.loading
    ) {
      this.setState({ parking: this.props.getParkingSpace.getParkingSpace });
    }
  }

  render() {
    const { parking } = this.state;

    return (
      <LayoutWrapper>
        <Content>
          <Message>
            <MessageIcon
              src={`${ImgixUrl}/web-image-assets/icons/request-sent.svg`}
              alt="request sent icon"
            />
            <MessageTitle>Congratulations on your new listing!</MessageTitle>
            <MessageContent>
              <div>It's time to sit back, and enjoy earning money.</div>
              <div className="payout-option-row">
                <a className="payout-option-text" href="/admin/billings">
                  Add a payment option
                </a>
                <span>
                  , so we can get your cash to you quicker. You can also do it
                  in ‘Admin > Billing'.
                </span>
              </div>
            </MessageContent>
          </Message>

          <hr />

          <Instruction className="center-block">
            Below is your a snapshot of your listing.
          </Instruction>

          {parking ? (
            <TicketSection className="center-block">
              <ListingTicket
                id={parking.id}
                status="live"
                address={parking.address.value}
                title={parking.title}
                parkingType={
                  parking.parkingTypes[0] ? parking.parkingTypes[0].name : null
                }
                parkingLot={parking.lot}
                redirectToPDP={true}
              />
            </TicketSection>
          ) : null}

          <ButtonSection>
            <AddButton>
              <Link
                to="/admin/create-listing"
                style={{ textDecoration: 'none' }}
              >
                Add another listing
              </Link>
            </AddButton>
            <ViewMyListingButton>
              <Link to="/admin/my-listings/" style={{ textDecoration: 'none' }}>
                View my listings
              </Link>
            </ViewMyListingButton>
          </ButtonSection>
        </Content>

        <Footer>
          <StandardFooter />
        </Footer>
      </LayoutWrapper>
    );
  }
}

export default compose(
  graphql(GET_PARKING_SPACE, {
    name: 'getParkingSpace',
    options: props => ({
      variables: {
        id: props.match.params.id
      }
    }),
    handleError: false
  })
)(CreateListingSuccessPage);
