import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { BeatLoader } from 'react-spinners';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Jimp from 'jimp';
import {
  teal,
  midBreakPoint,
  greyExtraLight,
  greyDark
} from '../../../_shared/layout-constants';
import { UserContext } from '../../../common/context/user';
import { ImgixUrl, ImgixApiKey, AwsApi } from '../../../_shared/utils';

const ImageUploaderPlaceholder = styled.a`
  position: relative;
  text-align: center;
  border-radius: 5px;
  border-style: dashed;
  border-width: 1px;
  border-color: ${({ isDragActive }) => (isDragActive ? teal : '#c7c7c7')};
  color: #fff;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 108px;
  cursor: pointer;
  @media (min-width: ${midBreakPoint}px) {
    width: 212px;
    height: 145px;
    margin-top: 0;
    cursor: pointer;
  }
`;

const Uploading = styled.div`
  color: ${greyDark};
`;

const Progress = styled.div`
  position: absolute;
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  z-index: -1;
  left: 0;
  background-color: ${greyExtraLight};
`;

const AddIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const Prview = styled.img`
  height: 100%;
  flex: 1;
  object-fit: cover;
  max-width: 100%;
`;

const AddPhotoText = styled.span`
  width: 114px;
  height: 24px;
  font-size: 20px;
  color: ${teal};
  margin: 4px 0 4px 8px;
`;

const Remove = styled.div`
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
`;

export const MAX_IMAGES = 1;
export const ACCEPTED_TYPES = ['.jpeg', '.png', '.jpg'];
export const MAX_SIZE = 5242880; // 5MB

export const ImageUploader = ({
  imgPosition,
  parkingId,
  isPublic,
  changeUploadStatus,
  getUserInfo
}) => {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const { user } = useContext(UserContext);

  const onDrop = useCallback(
    acceptedFiles => {
      if (!acceptedFiles || acceptedFiles.length === 0) return;
      //console.log(acceptedFiles[0]); // you should have a look at the format, not simply assume it's a binary file

      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async readerEvt => {
        try {
          const bufferString = readerEvt.target.result;
          const jimpFile = await Jimp.read(bufferString);
          const base64PngDataURI = await jimpFile.getBase64Async(Jimp.MIME_PNG);
          // Very important!: generate VALID base64String for img; otherwise potentially lead to CORS issue
          const base64String = base64PngDataURI.slice(22); // convert data URI to base64string
          if (isPublic) {
            setProgress(1); // start counting progress
            await axios.post(
              'https://api.imgix.com/v2/image/purger',
              {
                url: `${ImgixUrl}/${parkingId}/image${imgPosition}.png`
              },
              {
                auth: {
                  username: ImgixApiKey,
                  password: ''
                }
              }
            );
          }
          await axios.post(
            `${AwsApi}/create-update-images`,
            {
              userId: user && user.id, // define valid logged in user id (not user token!); note replace placeholder userId 1 to the real userId
              directory: isPublic ? 'public' : 'private',
              resourceId: parkingId,
              filename: `image${imgPosition}`,
              fileExtension: '.png', // ext starts with a dot, you should double check what you've uploaded/created in s3
              base64String: base64String
            },
            {
              onUploadProgress: progressEvent => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              }
            }
          );
          setProgress(0);
          setPreview(URL.createObjectURL(acceptedFiles[0]));
        } catch (e) {
          console.log(e);
        }
      };

      reader.readAsArrayBuffer(acceptedFiles[0]);
    },
    [] // eslint-disable-line
  );

  const onDropRejected = () => {
    alert('Maximum file upload size is 5MB'); // TODO: replace it with our alert component
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${AwsApi}/delete-image`, {
        data: {
          userId: user && user.id,
          directory: isPublic ? 'public' : 'private',
          resourceId: parkingId,
          filename: `image${imgPosition}`,
          fileExtension: '.png'
        }
      });
      if (isPublic) {
        await axios.post(
          'https://api.imgix.com/v2/image/purger',
          {
            url: `${ImgixUrl}/${parkingId}/image${imgPosition}.png`
          },
          {
            auth: {
              username: ImgixApiKey,
              password: ''
            }
          }
        );
      }
      setPreview(null);
      setProgress(0);
    } catch (e) {
      console.log(e);
    }
  };

  const fetch_retry_list_image = async (imgPosition, n) => {
    if (!user) return null;
    setLoading(true);
    axios
      .get(
        `${AwsApi}/list-images?userId=${
          user.id
        }&resourceId=${parkingId}&directory=${isPublic ? 'public' : 'private'}`
      )
      .then(res => {
        let data = res.data.body;
        if (data && !_.isEmpty(data.imagesPath)) {
          let imagePath = data.imagesPath[`${imgPosition}`];
          const base64String = imagePath && imagePath['base64String'];
          if (!base64String) {
            setLoading(false);
            return;
          } // if empty initially, return
          setPreview(`data:image/png;base64,${base64String}`);
        }
        setLoading(false);
      })
      .catch(async err => {
        setLoading(false);
        if (n === 1) console.log(err);
        return await fetch_retry_list_image(imgPosition, n - 1);
      });
  };

  useEffect(() => {
    if (changeUploadStatus) {
      if (preview) {
        changeUploadStatus(true);
      } else {
        changeUploadStatus(false);
      }
    }
    if (!load) {
      // Need to fetch a few times again if a failure to fetch image for one time
      // Persistent image fetching
      fetch_retry_list_image(imgPosition, 5);
    }
    setLoad(true);
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(preview);
  }, [preview]); // eslint-disable-line

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: MAX_SIZE,
    accept: ACCEPTED_TYPES
  });

  return (
    <Fragment>
      <ImageUploaderPlaceholder isDragActive={isDragActive} {...getRootProps()}>
        <Progress progress={progress} />
        <input {...getInputProps()} />
        {loading ? (
          <BeatLoader size={15} margin={'2px'} color={greyDark} />
        ) : progress > 0 ? (
          <Uploading>uploading...{progress}%</Uploading>
        ) : preview ? (
          <Prview src={preview} alt={`parking-space${imgPosition}`} />
        ) : (
          <Fragment>
            <AddIcon
              src={`${ImgixUrl}/web-image-assets/icons/create-new-listing.svg`}
              alt="upload a photo"
            />
            <AddPhotoText>Add a photo</AddPhotoText>
          </Fragment>
        )}
      </ImageUploaderPlaceholder>
      {!isPublic && preview && <Remove onClick={handleDelete}>remove</Remove>}
    </Fragment>
  );
};

export default ImageUploader;
