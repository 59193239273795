import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import {
  yellow,
  red,
  navy,
  greyLight,
  teal,
  greyExtraLight,
  RubikFontFamily,
  midBreakPoint
} from '../layout-constants';
import {
  DISMISS_SYSTEM_NOTIFICATION,
  GET_SYSTEM_NOTIFICATION
} from '../../common/graphql/adminSchema';
import { ImgixUrl } from '../utils';

export const NOTIFICATION_TYPE = {
  SRP: 'SRP',
  PDP: 'PDP',
  ADMIN: 'ADMIN'
};

/**
 * Notification type ref:
 * default: grey border / white bg / black text
 * neutral: grey border / grey bg / black text
 * highlight: yellow border / yellow bg / black text
 * norm: blue border / white bg / blue text
 *
 * alert1: red border / white bg / red text
 * alert2: red border / red bg / white text
 *
 * success: green border / white bg / black text
 */

const renderStyle = type => {
  switch (type) {
    case 'highlight':
      return `
        border-color: ${yellow};
        background-color: ${yellow};
      `;
    case 'norm':
      return `
        border-color: ${navy};
        background-color: white;
        color: ${navy};
      `;
    case 'alert1':
      return `
        border-color: ${red};
        background-color: white;
        color: ${red};
      `;
    case 'alert2':
      return `
        border-color: ${red};
        background-color: ${red};
        color: white;
      `;
    case 'success':
      return `
        border-color: ${teal};
        background-color: white;
      `;
    case 'neutral':
      return `
        background-color: ${greyExtraLight};
        border-color: ${greyExtraLight};
      `;
    default:
      return `
        border-color: ${greyLight};
        background-color: white;
      `;
  }
};

const iconType = type => {
  const baseUrl = `${ImgixUrl}/web-image-assets/icons/alert/`;
  switch (type) {
    case 'alert1':
      return baseUrl + 'AlertRed.svg';
    case 'alert2':
      return baseUrl + 'Alert.svg';
    case 'success':
      return baseUrl + 'Success.png';
    default:
      return null;
  }
};

const Wrapper = styled.div`
  font-family: ${RubikFontFamily};
  display: ${({ hide, hideOn }) =>
    hide || hideOn === 'small' ? 'none' : 'flex'};
  position: ${({ sticky }) => (sticky ? 'absolute' : 'relative')};
  padding: 10px 8px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  z-index: 2;
  ${({ type }) => renderStyle(type)}
  @media (min-width: ${midBreakPoint}px) {
    display: ${({ hide, hideOn }) =>
      hide || hideOn === 'big' ? 'none' : 'flex'};
  }
`;

const Close = styled.img`
  height: 20px;
  width: 20px;
  margin-left: auto;
  cursor: pointer;
`;

const Icon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  object-fit: contain;
  @media (min-width: ${midBreakPoint}px) {
    height: 28px;
    width: 28px;
    margin-right: 12px;
  }
`;

const NotificationComponent = ({
  text,
  type,
  hideOn,
  notificationType,
  hasClose = true,
  hide,
  handleClick,
  ...otherProps
}) => (
  <Wrapper hide={hide} hideOn={hideOn} type={type} {...otherProps}>
    {iconType(type) && <Icon src={iconType(type)} />}
    <span title={text}>{text}</span>
    {hasClose && (
      <Close
        src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
        alt="close"
        onClick={handleClick}
      />
    )}
  </Wrapper>
);

// Notification from system
const DismissableNotification = props => {
  const { loading, data } = useQuery(GET_SYSTEM_NOTIFICATION, {
    variables: { notificationType: props.notificationType }
  });
  const [dismissSystemNotification] = useMutation(DISMISS_SYSTEM_NOTIFICATION);
  const [hide, setHide] = useState(false);
  const handleClick = async () => {
    setHide(!hide);
    // if notificationType prop passed in, call endpoint to mutate dismiss state
    try {
      await dismissSystemNotification({
        variables: {
          notificationType: props.notificationType
        }
      });
    } catch (e) {
      //
    }
  };
  if (loading) return null;

  // check dismiss state, if true, don't show
  if (
    data &&
    data.getSystemNotifications &&
    data.getSystemNotifications.dismissed === true
  )
    return null;

  return (
    <NotificationComponent handleClick={handleClick} hide={hide} {...props} />
  );
};

const NormalNotification = props => {
  const [hide, setHide] = useState(false);
  const handleClick = () => {
    setHide(!hide);
  };

  return (
    <NotificationComponent handleClick={handleClick} hide={hide} {...props} />
  );
};

const Notification = props => {
  if (!props.notificationType) return <NormalNotification {...props} />;
  return <DismissableNotification {...props} />;
};

export default Notification;
