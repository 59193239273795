import React, { Component, useContext } from 'react';
import { ModalStoreContext } from '../../../common/context/modal/store';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { reduxForm, Field, getFormValues, getFormSyncErrors } from 'redux-form';
import {
  ReduxInputField,
  PhoneInputField
} from '../../../_shared/components/FormElements';
import { graphql } from '@apollo/react-hoc';
import moment from 'moment';
import {
  GET_PHONE_NUMBER_VERIFICATION,
  VERIFY_USER_INFORMATION
} from '../../../common/graphql/adminSchema';
import DateOfBirthField from '../../../_shared/components/DateOfBirthField';
import { UserContext } from '../../../common/context/user';
import { SecondaryButton } from '../../../_shared/components/Buttons';
import './index.css';
import Modal from '../../../_shared/components/ModalWrapper';
import { graphite, midBreakPoint } from '../../../_shared/layout-constants';

const MobileNumber = styled.div`
  max-width: 240px;
`;

const GetCodeButton = styled(SecondaryButton)`
  position: relative;
  margin: 40px 0 6px 20px;
  height: 34px;
  width: 106px;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  padding: 7px 0 8px 0;

  span {
    position: absolute;
    bottom: 0;
    font-size: 10px;
    color: ${graphite};
    bottom: -16px;
    left: 4px;
  }

  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

const VerificationCode = styled.div`
  max-width: 240px;
`;

const DateOfBirth = styled.div`
  margin-bottom: 0px;
`;

const validate = values => {
  const errors = {};

  if (!values.verificationCode) {
    errors.verificationCode = 'Please enter the verification code';
  }
  if (!values.year) {
    errors.year = 'Please provide valid dob';
  }
  if (!values.month) {
    errors.month = 'Please provide valid dob';
  }
  if (!values.day) {
    errors.day = 'Please provide valid dob';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Please enter the contact phone number';
  } else if (!isValidPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'Please enter a vaild phone number';
  }
  return errors;
};

class VerifyListingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileCodeStatus: false,
      phoneNumber: '',
      verificationCode: '',
      token: null,
      countdown: 0,
      notification: null
    };
  }

  componentDidMount() {
    const { user } = this.props;
    // should hide the info collecting modal if already provided
    if (user.phoneNumber && user.dateOfBirth) {
      this.props.handleHideModal('VerifyListingModal');
    }
    this.props.initialize({
      day: user.dateOfBirth ? user.dateOfBirth.split('-')[2] : null,
      month: user.dateOfBirth ? user.dateOfBirth.split('-')[1] : null,
      year: user.dateOfBirth ? user.dateOfBirth.split('-')[0] : null,
      phoneNumber: user.phoneNumber ? user.phoneNumber : ''
    });
  }

  onVerificationCodeChange = e => {
    this.setState({ verificationCode: e.target.value });
  };

  setNotification = e => {
    this.setState({ notification: e });
  };

  startTimer = () => {
    if (this.state.countdown < 0) return null;
    this.interval = setInterval(
      () =>
        this.setState({
          countdown: this.state.countdown - 1
        }),
      1000
    );
  };

  verifyPhoneNumber = async () => {
    await this.props
      .getPhoneNumberVerification({
        variables: {
          phoneNumber: this.props.currentValues.phoneNumber
        },
        update: (_, { data: { getPhoneNumberVerification } }) => {
          if (getPhoneNumberVerification) {
            this.setState(
              {
                token: getPhoneNumberVerification.token,
                countdown: 60
              },
              () => {
                this.startTimer();
              }
            );
          } else {
            console.log('Your number has been verified.');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  verifyUserInformation = async value => {
    var dob = moment(
      (value.year.value || value.year) +
        '-' +
        (value.month.value || value.month) +
        '-' +
        (value.day.value || value.day)
    ).format('YYYY-MM-DD');

    try {
      await this.props.verifyUserInformation({
        variables: {
          dob: dob,
          phoneNumber: value.phoneNumber,
          token: this.state.token,
          verificationCode: value.verificationCode
        }
      });
      this.setNotification(null);
      this.props.handleHideModal('VerifyListingModal');
      if (this.props.redirectUrl) {
        window.location.href = this.props.redirectUrl;
      }

      if (this.props.handleCallback) {
        this.props.handleCallback();
      }
    } catch (err) {
      let error = err.message.replace('GraphQL error: ', '');
      this.setNotification(error);
    }
  };

  UNSAFE_componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleCloseModal = () => {
    this.setNotification(null);
    this.props.handleHideModal('VerifyListingModal');
    // TO-DO don't force it temporarily, will uncomment this line when mobile messaging is enabled.
    //window.location.href = '/admin/my-listings';
  };

  setMobileCodeStatus = mobileCodeStatus => {
    this.setState({
      mobileCodeStatus
    });
  };

  render() {
    const {
      isOpen,
      invalid,
      handleSubmit,
      currentValues,
      change,
      formSyncErrors
    } = this.props;
    const { notification } = this.state;

    return (
      /* eslint-disable jsx-a11y/anchor-has-content */
      <Modal
        title="Verify your details"
        handleClose={() => {
          this.handleCloseModal();
          // this.props.history.push('/admin/my-listings');
        }}
        contentLabel="Verify Listing Modal"
        buttonLabel="Verify"
        buttonDisabled={invalid || formSyncErrors.phoneNumber}
        isOpen={isOpen}
        handleSubmit={handleSubmit(this.verifyUserInformation.bind(this))}
        notification={notification}
        modalDescription={'Once you are verified, you can move to next step'}
        showIcon={false}
      >
        <DateOfBirth>
          <DateOfBirthField />
        </DateOfBirth>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <MobileNumber>
            <PhoneInputField
              name="phoneNumber"
              label="Contact number"
              value={currentValues && currentValues.phoneNumber}
              error={
                currentValues &&
                currentValues.phoneNumber &&
                formSyncErrors.phoneNumber
              }
              onChange={phone => change('phoneNumber', phone)}
            />
          </MobileNumber>

          <GetCodeButton
            onClick={this.verifyPhoneNumber}
            type="button"
            disabled={formSyncErrors.phoneNumber || this.state.countdown > 0}
          >
            Get code
            {this.state.countdown > 0 && (
              <span>resend after {this.state.countdown} sec</span>
            )}
          </GetCodeButton>
        </div>

        <VerificationCode>
          <Field
            name="verificationCode"
            label="Verification code"
            component={ReduxInputField}
          />
        </VerificationCode>
      </Modal>
    );
  }
}

const WrappedVerifyListingModal = props => {
  const {
    actions: { handleHideModal },
    state: { activeModal }
  } = useContext(ModalStoreContext);
  const { user } = useContext(UserContext);
  if (!user) {
    return null;
  }
  return (
    <VerifyListingModal
      user={user}
      handleHideModal={handleHideModal}
      isOpen={activeModal.includes('VerifyListingModal')}
      {...props}
    />
  );
};

const formOptions = {
  form: 'verifyListingModal',
  validate
};

export default compose(
  withRouter,
  connect(state => ({
    formSyncErrors: getFormSyncErrors('verifyListingModal')(state),
    currentValues: getFormValues('verifyListingModal')(state)
  })),
  reduxForm(formOptions),
  graphql(GET_PHONE_NUMBER_VERIFICATION, {
    name: 'getPhoneNumberVerification'
  }),
  graphql(VERIFY_USER_INFORMATION, { name: 'verifyUserInformation' })
)(WrappedVerifyListingModal);
