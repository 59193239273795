import AWS from 'aws-sdk';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';
import aesjs from 'aes-js';
import randomstring from 'randomstring';
import {
  autoDetectCreditCardType,
  AwsPanEncryptAccessKeyId,
  AwsPanEncryptSecretAccessKey
} from './utils';

const REGION = 'ap-southeast-2';
const KMS_ALIAS = 'alias/pan-security';
const KMS_KEY_SPEC = 'AES_256';
const S3_BUCKET = 'quokka-parking-pan-security';

AWS.config.apiVersions = {
  kms: '2014-11-01',
  s3: '2006-03-01'
};
AWS.config.update({
  region: REGION,
  accessKeyId: AwsPanEncryptAccessKeyId,
  secretAccessKey: AwsPanEncryptSecretAccessKey
});

const kms = new AWS.KMS();
const kms_params = {
  KeyId: KMS_ALIAS,
  KeySpec: KMS_KEY_SPEC
};

const s3 = new AWS.S3();
const s3_params = {
  Bucket: S3_BUCKET,
  Tagging: 'env=prod'
};

const IV = CryptoJS.lib.WordArray.random(16);

// const decrypt = (ciphertextStr, key) => {
//   var ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

//   // split IV and ciphertext
//   var iv = ciphertext.clone();
//   iv.sigBytes = 16;
//   iv.clamp();
//   ciphertext.words.splice(0, 4);
//   ciphertext.sigBytes -= 16;

//   // decryption
//   var decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
//     iv: iv
//   });
//   return decrypted.toString(CryptoJS.enc.Utf8);
// };

const filenameGenerator = () => {
  return randomstring.generate({
    length: 40,
    charset: 'alphabetic',
    readable: 'false'
  });
};

const aesEncrypt = (key_256, text) => {
  // return hex string; so later it needs to be converted to bytes before decryption
  let iv = IV.clone();
  let encrypted = CryptoJS.AES.encrypt(text, key_256, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: iv,
    asBytes: true
  });
  //console.log('iv', iv);
  //console.log('iv string', iv.toString());
  //console.log('encrypted iv string', encrypted.iv.toString());
  //console.log('encrypted ciphertest string', encrypted.ciphertext.toString());

  let res = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  // console.log(res);
  // console.log(key_256);
  // console.log(decrypt(res, key_256));

  return res;
};

const toHex = text => {
  return aesjs.utils.hex.fromBytes(aesjs.utils.utf8.toBytes(text.toString()));
};

const detectCreditCardType = value => {
  const parsedCardnumber = value.replace(/ /g, '');
  const cardIssuer = autoDetectCreditCardType(parsedCardnumber);
  return cardIssuer;
};

export const uploadToS3 = (content, filename) => {
  s3_params['Body'] = JSON.stringify(content);
  s3_params['Key'] = filename;

  s3.putObject(s3_params, function(err, data) {
    if (err) console.log('err', err, err.stack);
    else console.log('data', data);
  });
};

export const encryptToCiphertext = (
  payload,
  encryptionContext,
  ciphertextBlob,
  plaintext,
  filename,
  encryptionType
) => {
  let key_256 = plaintext;

  let encryptedFilename = aesEncrypt(key_256, filename);

  if (encryptionType === 'credit card') {
    payload['cardType'] = aesEncrypt(
      key_256,
      detectCreditCardType(payload['cardNumber'])
    );
    payload['cardHolder'] = aesEncrypt(key_256, payload['cardHolder']);
    payload['cardNumber'] = aesEncrypt(key_256, payload['cardNumber']);
    payload['cvv'] = aesEncrypt(key_256, payload['cvv']);
    payload['expiryDate'] = aesEncrypt(key_256, payload['expiryDate']);
  } else if (encryptionType === 'bank account') {
    payload['acc'] = aesEncrypt(key_256, payload['acc']);
    payload['bsb'] = aesEncrypt(key_256, payload['bsb']);
    payload['accountName'] = aesEncrypt(key_256, payload['accountName']);
  }
  //console.log('@@@ credit card @@@', payload);

  encryptionContext['Bucket'] = toHex(encryptionContext['Bucket']);
  encryptionContext['Date'] = toHex(encryptionContext['Date']);
  encryptionContext['UserName'] = toHex(encryptionContext['UserName']);
  //console.log('@@@ encryption context @@@', encryptionContext);

  let result = {
    encryptedFilename: encryptedFilename,
    encryptedCDK: ciphertextBlob,
    encryptionContext: encryptionContext,
    payload: payload
  };
  //console.log('@@@ final payload @@@', result);

  uploadToS3(result, filename);

  return filename;
};

export const createEncryptionKeysAndEncrypt = async (
  uuid,
  payload,
  filename,
  encryptionType
) => {
  let date = moment().format('DD-MM-YYYY');
  let encryptionContext = {
    Bucket: 'quokka-parking-pan-security',
    UserName: uuid,
    Date: date
  };

  kms_params['EncryptionContext'] = encryptionContext;

  const data = await kms.generateDataKey(kms_params, function(err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      let encryptionData = data;
      let CiphertextBlob = encryptionData.CiphertextBlob;
      let Plaintext = encryptionData.Plaintext;
      return encryptToCiphertext(
        payload,
        encryptionContext,
        CiphertextBlob,
        Plaintext,
        filename,
        encryptionType
      );
    }
  });

  return data;
};

// output ciphertext filename which is uploaded in s3
export const panSecretEncrypt = async (uuid, payload, encryptionType) => {
  let filename = filenameGenerator();

  await createEncryptionKeysAndEncrypt(uuid, payload, filename, encryptionType);

  return filename;
};
