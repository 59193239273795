import React, { Component } from 'react';
import styled from 'styled-components';
import { StandardFooter } from '../../../common/components';
import { BookingTicket } from '../../../admin';
import { RubikSemiBold } from '../../../_shared/layout-constants';
import { PrimaryButton } from '../../../_shared/components/Buttons';
import { ImgixUrl } from '../../../_shared/utils';

const LayoutWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr 1155px 1fr;
  grid-template-rows: auto 380px;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
`;

const Content = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: #ffffff;
  width: 1155px;
  height: 952px;
  padding: 124px 150px 200px 200px;
  margin-bottom: 200px;
`;

const Message = styled.div`
  height: 64px;
  width: 855px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 98px auto;
  grid-template-rows: 1fr 1fr;
  margin: 0 auto 48px auto;
`;

const MessageIcon = styled.img`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  height: 64px;
  width: 64px;
  margin-right: 32px;
`;

const MessageTitle = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  width: 100%;
  height: 20px;
  font-size: 28px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
`;

const MessageContent = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: 20px;
  margin-top: 12px;
`;

const Instruction = styled.div`
  width: 100%;
  font-size: 18px;
  margin: 48px auto 48px auto;
`;

const TicketSection = styled.div`
  height: 360px;
  width: 240px;
`;

const ButtonSection = styled.div`
  margin: 80px auto auto auto;
  justify-self: center;
`;

const Footer = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;
`;

const CTAButton = styled(PrimaryButton)`
  width: 219px;
  height: 48px;
  display: block;
  margin-right: auto;
  margin-left: auto;
`;

class BookingSuccess extends Component {
  render() {
    return (
      <LayoutWrapper>
        <Content>
          <Message>
            <MessageIcon
              src={`${ImgixUrl}/web-image-assets/icons/request-sent.svg`}
              alt="request sent icon"
            />
            <MessageTitle>
              Your request has been confirmed. Congrats!
            </MessageTitle>
            <MessageContent>
              park n go? Not 100% about if/when we want to use this term
              throughout the site
            </MessageContent>
          </Message>

          <hr className="break-line" />

          <Instruction className="center-block">
            Here's a review of your booking - to help you remember the important
            stuff.
          </Instruction>

          <TicketSection className="center-block">
            <BookingTicket />
          </TicketSection>

          <ButtonSection>
            <CTAButton type="submit" value="Submit">
              CTA
            </CTAButton>
          </ButtonSection>
        </Content>

        <Footer>
          <StandardFooter />
        </Footer>
      </LayoutWrapper>
    );
  }
}

export default BookingSuccess;
