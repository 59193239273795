import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import groupBy from 'lodash/groupBy';
import { ListingTicket } from '../../../admin';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import { AdminSubtitle } from '../../_shared/styles';
import { GET_FAVOURITES } from '../../../common/graphql/adminSchema';
import { midBreakPoint, greyDark } from '../../../_shared/layout-constants';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import { ImgixUrl } from '../../../_shared/utils';

const LiveListingsTitle = styled(AdminSubtitle)`
  margin-bottom: 22px;
`;

const Illustration = styled.img`
  width: 200px;
  margin-top: 45px;
  @media (min-width: ${midBreakPoint}px) {
    width: 300px;
  }
`;

const Suburb = styled.div``;

const SuburbListings = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin-bottom: 40px;
  margin-top: 32px;
  padding-bottom: 20px;
  @media (min-width: ${midBreakPoint}px) {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(442px, auto);
    grid-row-gap: 48px;
  }
`;

const SuburbName = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
`;

const IllustrationText = styled.div`
  margin-top: 30px;
  font-size: 10px;
  color: ${greyDark};

  @media (min-width: ${midBreakPoint}px) {
    font-size: 20;
  }
`;

const EmptyState = ({ text }) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Illustration
      src={`${ImgixUrl}/web-image-assets/illustration/shopping.png`}
      alt="empty"
    />
    <IllustrationText>{text}</IllustrationText>
  </div>
);

const FavouriteBookingsPage = ({ getFavourites }) => {
  if (getFavourites.loading) {
    return <SpinnerLoader />;
  }

  return (
    <AdminWrapper title="Favourites">
      <LiveListingsTitle />
      {getFavourites.getFavourites && getFavourites.getFavourites.length > 0 ? (
        <div>
          {Object.entries(
            groupBy(getFavourites.getFavourites, 'parking.address.locality')
          ).map(([k, v]) => (
            <Suburb>
              {k & (k !== 'null') && k !== '' ? (
                <SuburbName>{k}</SuburbName>
              ) : null}
              <SuburbListings>
                {v.map(a => (
                  <ListingTicket
                    key={shortid.generate()}
                    favourite={true}
                    id={a.parking.id}
                    parkingType={
                      a.parking.parkingTypes[0]
                        ? a.parking.parkingTypes[0].name
                        : null
                    }
                    title={a.parking.title}
                    address={a.parking.address.value}
                    parkingLot={a.parking.lot}
                    redirectToPDP={true}
                  />
                ))}
              </SuburbListings>
            </Suburb>
          ))}
        </div>
      ) : (
        <EmptyState text="You favourite booking list is empty" />
      )}
    </AdminWrapper>
  );
};

export default compose(graphql(GET_FAVOURITES, { name: 'getFavourites' }))(
  FavouriteBookingsPage
);
