import React, { Component } from 'react';
import moment from 'moment';
import shortid from 'shortid';
import Subtitle from '../subtitle/Subtitle';
import { InfoTable } from '../tableComponents/InfoTable';
import { Title } from '../tableComponents/Title';
import Transaction from '../tableComponents/Transaction';
import { Link } from '../tableComponents/Link';

export default class Transactions extends Component {
  render() {
    const { subtitle, title, invoices, transactions } = this.props;

    return (
      <div>
        {subtitle && <Subtitle title={subtitle} />}

        <InfoTable height={500} padding={32} direction="column">
          {title && invoices && <Title>{title}</Title>}
          {invoices &&
            invoices.map(t => (
              <Transaction
                key={shortid.generate()}
                date={moment(t.createDate).format('DD MMM YYYY')}
                amount={t.amount}
                type={t.invoiceType === 'receipt' ? 'in' : 'out'}
                description={t.invoiceType + ' number: ' + t.id}
              />
            ))}
          {title && transactions && <Title>{title}</Title>}
          {transactions &&
            transactions.map(t => (
              <Transaction
                key={shortid.generate()}
                //date={moment(t.createDate).format('DD MMM YYYY')}
                //amount={t.amount}
                //type={t.invoiceType === "invoice" ? "out" : "in"}
                //description={t.invoiceType + ': ' + t.id}
              />
            ))}
          {(invoices || transactions) && <Link>Show more</Link>}
        </InfoTable>
      </div>
    );
  }
}
