import React, { Component } from 'react';
import styled from 'styled-components';
import {
  graphite,
  greyLight,
  greyExtraLight,
  yellowLight,
  midBreakPoint
} from '../../../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../../../_shared/utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  grid-template-columns: ${({ direction }) =>
    direction === 'left' ? '0 80px auto 24px 1fr' : '1fr 12px auto 80px 0'};
  color: ${graphite};
  margin-bottom: 24px;
  font-size: 14px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;

const Icon = styled.div`
  grid-row: 1;
  grid-column: ${({ direction }) => (direction === 'left' ? 2 : 4)};
  display: flex;
  flex-direction: column;
  ${({ direction }) =>
    direction === 'left' ? 'margin-right: 25px' : 'margin-left: 25px'};
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  padding: ${({ quokka }) => (quokka ? '5px' : '0')};
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: solid 1px ${greyLight};
  border-radius: 50%;
`;

const InfoBox = styled.div`
  grid-row: 1;
  grid-column: 3;
  height: auto;
  position: relative;
`;

const InfoArrow = styled.span`
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ direction }) =>
      direction === 'left' ? '8px 16px 8px 0' : '8px 0 8px 16px'};
    border-color: ${({ direction }) =>
      direction === 'left'
        ? `transparent ${greyExtraLight} transparent transparent`
        : `transparent transparent transparent ${yellowLight}`};
    box-shadow: none;
    ${({ direction }) =>
      direction === 'left' ? 'left: -16px' : 'right: -16px'};
    top: 12px;
  }
`;

const Info = styled.div`
  background-color: ${({ direction }) =>
    direction === 'left' ? `${greyExtraLight}` : `${yellowLight}`};
  padding: 16px;
`;

export default class Message extends Component {
  render() {
    const { direction, person, imagePath, message } = this.props;

    return (
      <Wrapper direction={direction}>
        <Icon direction={direction}>
          {imagePath ? (
            <div>
              <Image
                src={`${ImgixUrl}/web-image-assets/placeholders/${imagePath}`}
                alt="profile"
              />
              {person}
            </div>
          ) : (
            <div>
              <Image
                quokka={true}
                src={`${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder-grey.svg`}
                alt="profile placeholder"
              />
              {'Quokka'}
            </div>
          )}
        </Icon>

        <InfoBox>
          <InfoArrow direction={direction} />
          <Info direction={direction}>{message}</Info>
        </InfoBox>
      </Wrapper>
    );
  }
}
