import React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { RoundPrimaryButton } from '../../../_shared/components/Buttons';
import {
  RubikSemiBold,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { StandardFooter, SimplifiedFooter } from '../../../common/components';
import { ImgixUrl } from '../../../_shared/utils';

const LayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  @media (min-width: ${midBreakPoint}px) {
    display: grid;
    grid-gap: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 900px 1fr;
    grid-template-rows: 1fr 380px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: ${RubikSemiBold};
  margin-bottom: 24px;
  text-align: center;
`;

// const Indication = styled.p`
//   font-weight: ${RubikSemiBold};
//   margin-top: 22px;
//   margin-bottom: 22px;
//   text-align: center;
// `;

const Button = styled(RoundPrimaryButton)`
  margin-top: 22px;
  @media (min-width: ${midBreakPoint}px) {
    margin-left: calc(50% - 132px);
    width: 265px;
    margin-top: 64px;
  }
`;

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }
`;

const MobileFooter = styled.div`
  display: block;
  margin: 50px 24px 0 24px;
  /* grid-column: 1 / 6; */
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    display: none;
  }
`;

const PageContent = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-top: 0;
    display: flex;
    padding: 64px 0 165px 0;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
`;

// const CardsLayout = styled.div`
//   display: flex;
//   flex-direction: column;
//   @media (min-width: ${midBreakPoint}px) {
//     display: grid;
//     grid-column-gap: 24px;
//     grid-row-gap: 16px;
//     grid-template-columns: 438px 438px;
//     grid-template-rows: auto;
//   }
// `;

const Illustration = styled.img`
  width: 200px;
  margin: 0 auto 50px auto;
  @media (min-width: ${midBreakPoint}px) {
    width: 360px;
  }
`;

const urlInSuburb = suburb =>
  `/find-parking-space/address-${suburb}/search?startDate=${moment().format(
    'DD-MM-YYYY'
  )}&endDate=${moment()
    .add(1, 'months')
    .format(
      'DD-MM-YYYY'
    )}&parkingTypes=&priceUnit={%22value%22:%22m%22,%22label%22:%22Monthly%22}&priceRange={%22min%22:0,%22max%22:1500}`;

export default class CarParkNotFound extends React.Component {
  render() {
    return (
      <LayoutWrapper>
        <PageContent>
          <Illustration
            src={`${ImgixUrl}/web-image-assets/illustration/page-not-found.png`}
          />
          <Title>Oops, we can’t find this parking space.</Title>
          {/* <Indication>
            Here are some similar parking spaces we found in this area.
          </Indication> */}
          {/* <CardsLayout>
            <SearchResultCard
              parkingType="Undercover parking space"
              id="12345"
              address="283 City Clarendon Road, Southbank, VIC 3006"
              price={260}
              priceUnit="W"
              distance={1.8}
            />
            <SearchResultCard
              parkingType="Undercover parking space"
              id="12345"
              address="283 City Clarendon Road, Southbank, VIC 3006"
              price={260}
              priceUnit="W"
              distance={1.8}
            />
            <SearchResultCard
              parkingType="Undercover parking space"
              id="12345"
              address="283 City Clarendon Road, Southbank, VIC 3006"
              price={260}
              priceUnit="W"
              distance={1.8}
            />
            <SearchResultCard
              parkingType="Undercover parking space"
              id="12345"
              address="283 City Clarendon Road, Southbank, VIC 3006"
              price={260}
              priceUnit="W"
              distance={1.8}
            />
          </CardsLayout> */}
          <a
            href={urlInSuburb('cbd-melbourne-vic-australia')} // TODO: remove hardcode
          >
            <Button>More parking spaces</Button>
          </a>
        </PageContent>
        <MobileFooter>
          <SimplifiedFooter />
        </MobileFooter>
        <DesktopFooter>
          <StandardFooter />
        </DesktopFooter>
      </LayoutWrapper>
    );
  }
}
