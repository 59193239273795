import React, { Component } from 'react';
import styled from 'styled-components';
import { ImgixUrl } from '../../../_shared/utils';

const IconWrapper = styled.div`
  height: 29px;
  margin-bottom: 24px;
`;

const IconImg = styled.img`
  height: 30px;
  width: 20px;
  padding-bottom: 6px;
`;

const IconName = styled.span`
  font-size: 18px;
  text-align: left;
  margin: 0 0 0 16px;
`;

const mapping = {
  CabChassis: 'Cab chassis',
  LightTruck: 'Light truck',
  PeopleMover: 'People mover',
  'Arranged transfers': 'Arranged transfer'
};

export default class Icon extends Component {
  render() {
    const { iconName, pathType } = this.props;

    const imgPath =
      pathType + '/' + iconName.toLowerCase().replace('/', ' ') + '.svg';
    var name = iconName.charAt(0).toUpperCase() + iconName.substr(1);

    if (name.toLowerCase() === 'cctv') {
      name = name.toUpperCase();
    }

    return (
      <IconWrapper>
        <IconImg
          src={`${ImgixUrl}/web-image-assets/icons/${imgPath}`}
          alt="icon"
        />
        <IconName>{name in mapping ? mapping[name] : name}</IconName>
      </IconWrapper>
    );
  }
}
