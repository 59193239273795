import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import shortid from 'shortid';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  TextButton,
  SecondaryButton
} from '../../../_shared/components/Buttons';
import {
  midBreakPoint,
  greyDark,
  greyExtraLight,
  navy,
  RubikSemiBold
} from '../../../_shared/layout-constants';
import { ImgixUrl, isDeviceMobile } from '../../../_shared/utils';
import SpinnerLoader from '../../../_shared/components/SpinnerLoader';
import { BOOKING_STATUS } from '../query';
import {
  DENY_BOOKING,
  CONFIRM_BOOKING_REQUEST
} from '../../../common/graphql/bookingSchema';

const PAGINATION_MAX_ITEMS = 100;

const BookingsSection = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  @media (min-width: ${midBreakPoint}px) {
    flex-wrap: wrap;
  }
`;

const Illustration = styled.img`
  width: 200px;
  margin-top: 120px;
  @media (min-width: ${midBreakPoint}px) {
    width: 300px;
  }
`;

const IllustrationText = styled.div`
  margin-top: 30px;
  font-size: 10px;
  color: ${greyDark};

  @media (min-width: ${midBreakPoint}px) {
    font-size: 20;
  }
`;

const EmptyState = ({ text }) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Illustration
      src={`${ImgixUrl}/web-image-assets/illustration/waiting.png`}
      alt="empty"
    />
    <IllustrationText>{text}</IllustrationText>
  </div>
);

const Table = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;
  font-size: 14px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${greyExtraLight};
  padding: 18px;
  align-items: center;
`;

const TableHeader = styled(TableRow)`
  color: grey;
  padding-bottom: 8px;
`;

const TableCell = styled.div`
    /* flex-basis: ${({ flex }) => flex}; */
    width: ${({ width }) => width};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: ${({ align }) => (align ? align : 'left')};
`;

const NavyTextButton = styled(TextButton)`
  font-size: 14px;
  color: ${navy};
`;

const AcceptButton = styled(SecondaryButton)`
  padding: 6px 10px;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 12px;
`;

const BookingSegmentTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: ${RubikSemiBold};
`;

const ListingTableRow = ({ booking, status, role }) => {
  const type =
    status === 'confirmations' || status === 'requests' ? 'live' : 'off';

  const [denyBooking] = useMutation(DENY_BOOKING, {
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: role.query,
        variables: { awaitRefetchQueries: true }
      }
    ]
  });

  const [confirmBooking] = useMutation(CONFIRM_BOOKING_REQUEST, {
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: role.query,
        variables: { awaitRefetchQueries: true }
      }
    ]
  });

  const handleDenyBooking = async () => {
    try {
      await denyBooking({
        variables: { id: get(booking, 'id') }
      });
      setTimeout(() => window.location.reload(), 2000);
    } catch (e) {}
  };

  const handleConfirmBooking = async () => {
    try {
      await confirmBooking({
        variables: { id: get(booking, 'id') }
      });
      setTimeout(() => window.location.reload(), 2000);
    } catch (e) {}
  };

  const priceParser = (priceOptions, type) => {
    try {
      const result = JSON.parse(priceOptions).filter(
        c => c.type.short === type
      )[0];
      return `${result.unit}${result.value}`;
    } catch (e) {
      return '';
    }
  };

  const startTime = moment
    .tz(
      get(booking, 'bookings[0].startTime'),
      get(booking, 'parking.address.tz')
    )
    .format('D MMM YYYY');

  const endTime = moment
    .tz(get(booking, 'bookings[0].endTime'), get(booking, 'parking.address.tz'))
    .format('D MMM YYYY');
  const displayedEndTime = endTime === 'Invalid date' ? '' : endTime;

  const fee = get(booking, 'bookings[0].isMonthlyBooking')
    ? `${priceParser(get(booking, 'parking.priceOptions'), 'm')} / monthly`
    : `${priceParser(get(booking, 'parking.priceOptions'), 'D')} / daily`;
  return (
    <TableRow key={booking.id}>
      {/* <TableCell width="10%">
        {contactParser(get(booking, 'parking.contacts'))}
      </TableCell> */}
      <TableCell width={!isDeviceMobile() ? `35%` : `45%`}>
        {get(booking, 'parking.address.value')}
      </TableCell>
      <TableCell align="center" width={!isDeviceMobile() ? `10%` : `20%`}>
        {get(booking, 'parking.lot')}
      </TableCell>
      {!isDeviceMobile() && (
        <TableCell width="25%">{`${startTime}-${displayedEndTime}`}</TableCell>
      )}
      {!isDeviceMobile() && <TableCell width="15%">{fee}</TableCell>}
      <TableCell width={!isDeviceMobile() ? `20%` : `30%`}>
        {status !== 'requests' ? (
          <Link
            to={`/admin/bookings/manage-bookings/booking-detail/${type}/${get(
              booking,
              'id'
            )}`}
          >
            <NavyTextButton>View listing</NavyTextButton>
          </Link>
        ) : (
          <div>
            {/* <ListingButton>Deny</ListingButton> */}
            <AcceptButton onClick={handleConfirmBooking}>Accept</AcceptButton>
            <NavyTextButton onClick={handleDenyBooking}>Deny</NavyTextButton>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

const ListingTable = ({ bookings, status, role }) => {
  return (
    <Table>
      <TableHeader>
        {/* <TableCell width="10%">Renter</TableCell> */}
        <TableCell width={!isDeviceMobile() ? `35%` : `45%`}>
          Parking Space
        </TableCell>
        <TableCell align="center" width={!isDeviceMobile() ? `10%` : `20%`}>
          Lot#
        </TableCell>
        {!isDeviceMobile() && <TableCell width="25%">Duration</TableCell>}
        {!isDeviceMobile() && <TableCell width="15%">Fee</TableCell>}
        <TableCell width={!isDeviceMobile() ? `20%` : `30%`}>Action</TableCell>
      </TableHeader>
      {bookings.map(booking => (
        <ListingTableRow
          key={shortid.generate()}
          booking={booking}
          status={status}
          role={role}
        />
      ))}
    </Table>
  );
};

const BookingSegment = ({ setEmpty, setLoading, status, title }) => {
  const role = BOOKING_STATUS[status].lister;
  const { data, loading } = useQuery(role.query, {
    handleError: false,
    variables: { limit: PAGINATION_MAX_ITEMS },
    fetchPolicy: 'cache-and-network'
  });
  const listEmpty = role.name.every(s => {
    return get(data, `${s}.length`) === 0;
  });
  useEffect(() => {
    setLoading(loading);
    setEmpty(listEmpty);
  }, [loading, listEmpty, setEmpty, setLoading]);
  if (loading || listEmpty) return null;
  return (
    <Fragment>
      {title && <BookingSegmentTitle>{title}</BookingSegmentTitle>}
      <BookingsSection>
        <ListingTable
          bookings={role.name.flatMap(s => get(data, `${s}`, []))}
          status={status}
          role={role}
        />
      </BookingsSection>
    </Fragment>
  );
};

const Status = ({ status }) => {
  const [requestsEmpty, setRequestsEmpty] = useState(false);
  const [pendingEmpty, setPendingEmpty] = useState(false);
  const [confirmationsEmpty, setConfirmationsEmpty] = useState(false);
  const [historyEmpty, setHistoryEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      {loading && <SpinnerLoader />}
      {status === 'currentbookings'
        ? requestsEmpty &&
          pendingEmpty &&
          confirmationsEmpty && (
            <EmptyState text={'You have no current booking requests yet'} />
          )
        : historyEmpty && (
            <EmptyState text={'You have no history booking requests yet'} />
          )}
      {status === 'currentbookings' ? (
        <div>
          <BookingSegment
            setEmpty={setRequestsEmpty}
            setLoading={setLoading}
            status={'requests'}
            title="Requested bookings"
          />
          <BookingSegment
            setEmpty={setPendingEmpty}
            setLoading={setLoading}
            status={'pending'}
            title="Pending bookings"
          />
          <BookingSegment
            setEmpty={setConfirmationsEmpty}
            setLoading={setLoading}
            status={'confirmations'}
            title="Confirmed bookings"
          />
        </div>
      ) : (
        <BookingSegment
          setEmpty={setHistoryEmpty}
          setLoading={setLoading}
          status={'history'}
        />
      )}
    </Fragment>
  );
};

export default Status;
