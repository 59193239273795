import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  RubikFontFamily,
  RubikSemiBold,
  RubikNormal,
  greyExtraLight,
  greyDark,
  graphite,
  greyLight,
  yellowDark,
  RubikBold
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';
import { RoundPrimaryButton } from '../../../_shared/components/Buttons';

const Popup = styled.div`
  font-family: ${RubikFontFamily};
  display: grid;
  position: absolute;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 119px 35px 49px 27px 60px 10px;
  width: 240px;
  min-height: 300px;
  margin-left: -120px;
  margin-top: -300px;
  vertical-align: middle;
  border: 0;
  border-radius: 0px;
  z-index: 99;
  cursor: pointer;
  overflow: hidden;
`;

const CarparkPhoto = styled.img`
  grid-row: 1;
  grid-column: 1 / 13;
  width: ${({ small }) => (small ? '96px' : '240px')};
  height: ${({ small }) => (small ? '96px' : '120px')};
  flex-shrink: 0;
  object-fit: cover;
  margin: 0 14px 0 0;
`;

const Label = styled.span`
  grid-row: 1;
  grid-column: 1 / 4;
  background-color: ${yellowDark};
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  padding: 1px 8px;
  font-style: italic;
  font-weight: 500;
  z-index: 1;
  width: 74px;
  height: 21px;
`;

const ClusterItemLabel = styled.span`
  background-color: ${yellowDark};
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  padding: 1px 8px;
  font-style: italic;
  font-weight: 500;
  z-index: 1;
  width: 74px;
  height: 21px;
  position: absolute;
  float: left;
`;

const ParkingType = styled.div`
  background: #fff;
  grid-column: 1 / 13;
  grid-row: 2;
  padding-left: 10px;
  padding-top: 3px;
  width: 100%;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  padding: 9px 12px;
`;

const ParkingIcon = styled.div`
  grid-column: 1 / 4;
  grid-row: 3;
  background: #fff;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
  margin: 0 8px 0 12px;
`;

const Address = styled.div`
  background: #fff;
  grid-column: 4 / 13;
  grid-row: 3;
  padding: 0 12px 0 0;
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: rgba(51, 63, 72, 0.7);
`;

const Price = styled.div`
  background: #fff;
  grid-column: 1 / 8;
  grid-row: 4;
  padding: 10px 0 0 10px;
  font-size: 13px;
`;

const Availability = styled.div`
  background: #fff;
  grid-column: 8 / 13;
  grid-row: 4;
  padding: 10px 0 2px 3px;
  width: 100%;
  font-size: 13px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  grid-column: 1 / 13;
  grid-row: 5;
  padding: 10px 10px;
  background: #fff;
  border: 0;
  box-shadow: 2px 8px 4px -2px rgba(157, 157, 157, 0.5);
`;

const DetailsButton = styled(RoundPrimaryButton)`
  width: 100%;
  height: 36px;
  margin-left: -6px;
`;

const Arrow = styled.span`
  grid-column: 1 / 13;
  grid-row: 6;
  align-self: center;
  justify-self: center;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0 11px;
  border-color: #fff transparent transparent transparent;
`;

const ClusterItem = styled(Link)`
  height: 112px;
  border-top: 1px solid ${greyExtraLight};
  display: flex;
  padding: 8px;
  position: relative;

  &:hover {
    background-color: ${greyExtraLight};
    cursor: pointer;
  }
`;

const ClusterCard = styled.div`
  cursor: default;
  background-color: white;
  border-radius: 3px;
  width: 348px;
  z-index: 99;
  font-family: ${RubikFontFamily};
  margin-left: -175px;
  margin-top: -257px;
  margin-top: ${({ isFullHeight }) => (isFullHeight ? '-369px' : '-257px;')};
  position: absolute;
  box-shadow: 2px 8px 4px -2px rgba(157, 157, 157, 0.5);
  &:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 180px;
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`;

const CarparkInfo = styled.div`
  padding-left: 8px;
`;

const CarparkTitle = styled.div`
  font-weight: ${RubikSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${graphite};
`;

const CarparkAddress = styled.div`
  font-weight: ${RubikNormal};
  font-size: 14px;
  color: ${greyDark};
`;

const CarparkPrice = styled.div`
  & > b {
    font-size: 18px;
    font-weight: ${RubikSemiBold};
  }
  font-size: 15px;
  color: ${graphite};
  font-weight: ${RubikNormal};
`;

const ClusterTitle = styled.div`
  & > b {
    font-weight: ${RubikBold};
  }
  font-weight: ${RubikNormal};
  font-size: 18px;
  padding: 10px;
`;

const ParkingSign = styled.img`
  height: 36px;
  width: 36px;
  margin-right: 16px;
`;

const CarparkAddressWrapper = styled.div`
  display: flex;
  margin: 5px 0;
`;

const ClusterItems = styled.div`
  height: ${({ isFullHeight }) => (isFullHeight ? '336px' : '224px')};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent; /* optional: just make scrollbar invisible */
  }
  ::-webkit-scrollbar-thumb {
    background: ${greyLight};
    border-radius: 2px;
  }
`;

const units = {
  H: 'hour',
  D: 'day',
  W: 'week',
  m: 'month'
};

const dashedAddress = (address, key) => {
  if (!address) {
    return null;
  }
  const splittedAddress = address.split(', ').reverse();
  const dashedAddress = splittedAddress[key]
    .split(' ')
    .join('-')
    .toLowerCase();
  return dashedAddress;
};

const composeUrl = (address, id, priceUnit) => {
  const state = dashedAddress(address, 0);
  const city = dashedAddress(address, 1);
  const urlAddress = state && city ? `-${city}-${state}` : '';
  const viewDetailsLink = `/carpark/parking-space${urlAddress}/${id}/?priceUnit=${priceUnit}`;

  return viewDetailsLink;
};

const getPrice = chosenPrice => priceOptions =>
  priceOptions.filter(a => a.type === chosenPrice)[0];

const MapPopup = ({ result, onToggleChange, chosenPrice }) => {
  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    onToggleChange(null);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside); // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const node = useRef();

  const getChosenPrice = getPrice(chosenPrice);

  return result.length > 1 ? (
    <MultiMapPopup
      result={result}
      node={node}
      getChosenPrice={getChosenPrice}
    />
  ) : (
    <SingleMapPopup
      node={node}
      result={result[0]}
      getChosenPrice={getChosenPrice}
    />
  );
};

const SingleMapPopup = ({ node, result, getChosenPrice }) => {
  return (
    <Popup ref={node}>
      <CarparkPhoto
        src={`${ImgixUrl}/${result.objectID}/image0.png`}
        onError={e => {
          e.target.onerror = null;
          e.target.src = `${ImgixUrl}/carparkPlaceholder.png`;
        }}
        alt="Carpark Photo"
      />
      {result && result.status === 'I' && <Label>Booked</Label>}
      <ParkingType>
        {result.parking_types[0] ? result.parking_types[0] : 'Parking'}
      </ParkingType>

      <ParkingIcon>
        <Icon
          src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
          alt="parking sign in blue"
        />
      </ParkingIcon>

      <Address>
        {result.address && result.address.replace('australia', 'Australia')}
      </Address>

      <Price>
        <span className="map-popup-price">
          $ {getChosenPrice(result.parking_options).price_normalized}
        </span>
        <span className="map-popup-unit">
          {' '}
          / {units[getChosenPrice(result.parking_options).type]}
        </span>
      </Price>

      <Availability>
        {result && result.status === 'A' ? 'Available' : 'Booked'}
      </Availability>

      <ButtonWrapper>
        <Link
          to={composeUrl(
            result.address,
            result.objectID,
            getChosenPrice(result.parking_options).type
          )}
          style={{ textDecoration: 'none' }}
        >
          <DetailsButton paddingTop={8} fontSize={16}>
            Learn more
          </DetailsButton>
        </Link>
      </ButtonWrapper>

      <Arrow />
    </Popup>
  );
};

export const MultiMapPopup = ({ node, result, getChosenPrice }) => {
  const isFullHeight = result.length >= 3;
  return (
    <ClusterCard isFullHeight={isFullHeight} ref={node}>
      <ClusterTitle>
        <b>{result.length}</b> parking spaces in this building
      </ClusterTitle>
      <ClusterItems isFullHeight={isFullHeight}>
        {result.map((r, i) => (
          <ClusterItem
            to={composeUrl(
              r.address,
              r.objectID,
              getChosenPrice(r.parking_options).type
            )}
            key={i}
          >
            <CarparkPhoto
              small
              src={`${ImgixUrl}/${r.objectID}/image0.png`}
              onError={e => {
                e.target.onerror = null;
                e.target.src = `${ImgixUrl}/carparkPlaceholder.png`;
              }}
              alt="Carpark Photo"
            />
            {r && r.status === 'I' && (
              <ClusterItemLabel>Booked</ClusterItemLabel>
            )}
            <CarparkInfo>
              <CarparkTitle>
                {r.title.length > 45 ? `${r.title.substr(0, 45)}...` : r.title}
              </CarparkTitle>
              <CarparkAddressWrapper>
                <ParkingSign
                  src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
                  alt="parking sign in blue"
                />
                <CarparkAddress>
                  {r.address.length > 40
                    ? `${r.address.substr(0, 40)}...`
                    : r.address}
                </CarparkAddress>
              </CarparkAddressWrapper>
              <CarparkPrice>
                <b>$ {getChosenPrice(r.parking_options).price_normalized} </b>
                per {units[getChosenPrice(r.parking_options).type]}
              </CarparkPrice>
            </CarparkInfo>
          </ClusterItem>
        ))}
      </ClusterItems>
    </ClusterCard>
  );
};

export default MapPopup;
