import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getFormSyncErrors, Field } from 'redux-form';
import debounce from 'lodash/debounce';
import { ImageUploader } from '../../../admin';
import {
  ReduxInputField,
  ReduxTextArea
} from '../../../_shared/components/FormElements';
import { midBreakPoint, spacing } from '../../../_shared/layout-constants';
import ToolTip from '../../../_shared/components/Tooltips';
import Notification from '../../../_shared/components/Notification';
import './Details.css';

const Photos = styled.div`
  grid-column: 1 / 4;
  grid-row: 5;
  width: 100%;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 44px auto 193px;
  }
`;

const PhotosTitle = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
`;

const PhotoToolTip = styled(ToolTip)`
  grid-column: 1 / 4;
  grid-row: 2;
  margin-bottom: 24px;
`;

const PhotoOne = styled.div`
  grid-column: 1;
  padding: ${spacing(3)} 0 0 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 3;
    padding: 0 ${spacing(5)};
  }
`;

const PhotoTwo = styled.div`
  grid-column: 1;
  padding: ${spacing(3)} 0 0 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 3;
    padding: 0 ${spacing(5)};
  }
`;

const PhotoThree = styled.div`
  grid-column: 1;
  padding: ${spacing(3)} 0 0 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 3;
    padding: 0 ${spacing(5)};
  }
`;

const DetailSection = styled.div`
  margin-top: 30px;
`;

const photoNumbers = status => {
  const result = Object.entries(status).filter(([k, v]) => v === true).length;
  return result;
};

class Details extends Component {
  state = {
    imageStatus: {
      image1: false,
      image2: false,
      image3: false
    }
  };

  changeUploadStatus = imageIndex => uploadStatus => {
    this.setState(
      {
        imageStatus: {
          ...this.state.imageStatus,
          [`image${imageIndex}`]: uploadStatus
        }
      },
      () => {
        //console.log('ddd', photoNumbers(this.state.imageStatus));
        this.props.change(
          'enoughImageUploaded',
          photoNumbers(this.state.imageStatus)
        );
      }
    );
  };

  componentDidMount() {
    // this.props.initialize(this.props.detailsState);
    this.updateField = debounce(e => {
      const obj = {};
      obj[e.target.name] = e.target.value;
      this.updateLocalData(obj);
    }, 300);

    this.updateFieldRaw = debounce(e => {
      const obj = {};
      obj[e.key] = e.value;
      this.updateLocalData(obj);
    }, 300);
  }

  updateLocalData(obj) {
    if (!!this.props.draftId) {
      this.props.localData.addItem(this.props.draftId, obj);
    }
  }

  render() {
    const {
      formSyncErrors: { enoughImageUploaded },
      isDirty,
      parkingId
    } = this.props;
    return (
      <div>
        {enoughImageUploaded && isDirty && (
          <Notification
            type="alert1"
            text={enoughImageUploaded}
            style={{ marginBottom: 20 }}
            hasClose={false}
          />
        )}

        <Photos>
          <PhotosTitle className="admin-section-subtitle">Photos</PhotosTitle>

          {/* TODO: tooltip copy update */}
          <PhotoToolTip hasClose={false}>
            Please upload at least 2 photos, 3 photos are encouraged. The first
            photo can be a parking space photo, and the second photo can be
            carpark building and surroundings.
          </PhotoToolTip>

          <PhotoOne>
            <ImageUploader
              imgPosition={0}
              parkingId={parkingId}
              changeUploadStatus={this.changeUploadStatus(1)}
            />
          </PhotoOne>

          <PhotoTwo>
            <ImageUploader
              imgPosition={1}
              parkingId={parkingId}
              changeUploadStatus={this.changeUploadStatus(2)}
            />
          </PhotoTwo>

          <PhotoThree>
            <ImageUploader
              imgPosition={2}
              parkingId={parkingId}
              changeUploadStatus={this.changeUploadStatus(3)}
            />
          </PhotoThree>
        </Photos>

        <DetailSection>
          <Field
            name="listingTitle"
            label="Listing title"
            component={ReduxInputField}
            onChange={e => {
              this.updateField(e);
            }}
          />
        </DetailSection>

        <DetailSection>
          <Field
            name="description"
            label="Parking space description"
            component={ReduxTextArea}
            onChange={e => {
              this.updateField(e);
            }}
          />
        </DetailSection>

        {/* TODO: tooltip copy update */}
        <ToolTip style={{ marginBottom: 21 }}>
          * Note: the following information will only be available once your
          space has been booked.
        </ToolTip>

        {/* TODO: tooltip copy update */}
        <DetailSection>
          <Field
            name="accessInstruction"
            label="Access instructions"
            tooltip="Tip: your access instruction enables renters to navigate to
          your parking space. Please provide details such as access method such
          as swipe card for indoor lot and remote controller for garage."
            component={ReduxTextArea}
            maxLength={150}
            onChange={e => {
              this.updateField(e);
            }}
          />
        </DetailSection>

        {/* TODO: tooltip copy update */}
        <DetailSection>
          <Field
            name="deliveryInstruction"
            label="Key delivery instructions"
            tooltip="Tip: please provide your key delivery instruction, such as provide
          key in person or through an our keys escrow management system."
            maxLength={150}
            component={ReduxTextArea}
            onChange={e => {
              this.updateField(e);
            }}
          />
        </DetailSection>
      </div>
    );
  }
}

export default connect(state => ({
  formSyncErrors: getFormSyncErrors('createListingForm')(state)
}))(Details);
