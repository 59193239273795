import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  greyExtraLight,
  aqua,
  grey,
  greyDark,
  graphite,
  navy
} from '../../../_shared/layout-constants';
import { AdminSubtitle } from '../../_shared/styles';
import { TextButton } from '../../../_shared/components/Buttons';
import { isDeviceMobile } from '../../../_shared/utils';
import AddListingButton from '../components/addListingButton/AddListingButton';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  font-size: 14px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${greyExtraLight};
  padding: 18px;
  align-items: center;
`;

const TableHeader = styled(TableRow)`
  color: grey;
  padding-bottom: 8px;
`;

const TableCell = styled.div`
    /* flex-basis: ${({ flex }) => flex}; */
    width: ${({ width }) => width};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: ${({ align }) => (align ? align : 'left')};
`;

const ListingCount = styled.span`
  color: ${navy};
  font-size: 20px;
  font-weight: normal;
  margin-right: 4px;
`;

const Pill = styled.div`
  padding: 0 16px;
  background-color: ${({ color }) => color};
  display: inline-flex;
  border-radius: 3px;
  color: #ffffff;
`;

const StatusPill = ({ status }) => {
  switch (status) {
    case 'D':
      return <Pill color={grey}>Draft</Pill>;
    case 'I':
      return <Pill color={greyDark}>Offline</Pill>;
    case 'A':
      return <Pill color={aqua}>Live</Pill>;
    default:
      return;
  }
};

const ViewListingButton = styled(TextButton)`
  font-size: 14px;
`;

const ListingButton = styled.div`
  font-size: 14px;
  border: 1px solid ${graphite};
  padding: 4px 16px;
  border-radius: 3px;
  background-color: white;
  display: inline-flex;
`;

const StyledAddListingButton = styled(AddListingButton)`
  margin-bottom: 24px;
`;

const ListingTable = ({ spaces, status }) => {
  const urlStatus = status => {
    switch (status) {
      case 'D':
        return 'draft';
      case 'I':
        return 'off';
      case 'A':
        return 'live';
      default:
        return;
    }
  };
  const priceParser = (priceOptions, type) => {
    try {
      const result = JSON.parse(priceOptions).filter(
        c => c.type.short === type
      )[0];
      return `${result.unit}${result.value}`;
    } catch (e) {
      return '';
    }
  };
  return (
    <Table>
      <TableHeader>
        <TableCell width={!isDeviceMobile() ? `35%` : `60%`}>
          Parking space
        </TableCell>
        <TableCell width={!isDeviceMobile() ? `5%` : `15%`}>Lot#</TableCell>
        {!isDeviceMobile() && <TableCell width="15%">Parking type</TableCell>}
        {!isDeviceMobile() && <TableCell width="10%">Status</TableCell>}
        {/* {!isDeviceMobile() && status !== 'D' && (
          <TableCell width="5%">Daily</TableCell>
        )} */}
        {!isDeviceMobile() && status !== 'D' && (
          <TableCell width="7%">Monthly</TableCell>
        )}
        <TableCell align="center" width={!isDeviceMobile() ? `20%` : `25%`}>
          Action
        </TableCell>
      </TableHeader>
      {spaces.map(space => (
        <TableRow key={space.id}>
          <TableCell width={!isDeviceMobile() ? `35%` : `60%`}>
            {status === 'D' ? space.address : space.address.value}
          </TableCell>
          <TableCell width={!isDeviceMobile() ? `5%` : `15%`}>
            {space.lot || space.parkingLotNumber}
          </TableCell>
          {!isDeviceMobile() && (
            <TableCell width="15%">
              {space.parkingTypes[0] ? space.parkingTypes[0].name : null}
            </TableCell>
          )}
          {!isDeviceMobile() && (
            <TableCell width="10%">
              <StatusPill status={status || space.status} />
            </TableCell>
          )}
          {/* {!isDeviceMobile() && status !== 'D' && (
            <TableCell width="5%">
              {priceParser(space.priceOptions, 'D')}
            </TableCell>
          )} */}
          {!isDeviceMobile() && status !== 'D' && (
            <TableCell width="7%">
              {priceParser(space.priceOptions, 'm')}
            </TableCell>
          )}
          <TableCell align="center" width={!isDeviceMobile() ? `20%` : `25%`}>
            <Link
              to={`/admin/my-listings/listing-detail/${urlStatus(
                status || space.status
              )}/${space.id}`}
            >
              {status === 'D' ? (
                <ListingButton>Edit</ListingButton>
              ) : (
                <ViewListingButton>View</ViewListingButton>
              )}
            </Link>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

const sortByAddress = array => {
  array &&
    array.sort(function(a, b) {
      let c = a.address && a.address.value && a.address.value.split(' ', 2)[1];
      let d = b.address && b.address.value && b.address.value.split(' ', 2)[1];
      return c && d && c.localeCompare(d);
    });

  return array;
};

const ListView = ({
  getParkingSpaces,
  hasPhoneNumber,
  hasPaymentMethod,
  hasPayoutOption
}) => (
  <div>
    <StyledAddListingButton
      hasPhoneNumber={hasPhoneNumber}
      hasPaymentMethod={hasPaymentMethod}
      hasPayoutOption={hasPayoutOption}
      inline
    />
    {getParkingSpaces.drafts && getParkingSpaces.drafts.length !== 0 && (
      <Fragment>
        <AdminSubtitle>
          <ListingCount>{getParkingSpaces.drafts.length}</ListingCount> Drafts
        </AdminSubtitle>
        {getParkingSpaces.drafts && (
          <ListingTable
            spaces={sortByAddress(getParkingSpaces.drafts)}
            status="D"
          />
        )}
      </Fragment>
    )}
    {getParkingSpaces.active && getParkingSpaces.active.length !== 0 && (
      <Fragment>
        <AdminSubtitle>
          <ListingCount>{getParkingSpaces.active.length}</ListingCount>{' '}
          Published Listings
        </AdminSubtitle>
        {getParkingSpaces.active && (
          <ListingTable spaces={sortByAddress(getParkingSpaces.active)} />
        )}
      </Fragment>
    )}
    {getParkingSpaces.inactive && getParkingSpaces.inactive.length !== 0 && (
      <Fragment>
        <AdminSubtitle>
          <ListingCount>{getParkingSpaces.inactive.length}</ListingCount>{' '}
          History Listings
        </AdminSubtitle>
        {getParkingSpaces.inactive && (
          <ListingTable spaces={sortByAddress(getParkingSpaces.inactive)} />
        )}
      </Fragment>
    )}
  </div>
);

export default ListView;
