import React, { Component } from 'react';
import styled from 'styled-components';
import { grey, RubikSemiBold } from '../../../../../_shared/layout-constants';

const Wrapper = styled.div`
  width: 74px;
  height: 24px;
  border-radius: 3px;
  background-color: ${grey};
  padding: 3px 26px 4px 27px;
`;

const Status = styled.div`
  width: 21px;
  height: 17px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  text-align: center;
  color: #fff;
`;

export default class OffStatus extends Component {
  render() {
    return (
      <Wrapper>
        <Status>Off</Status>
      </Wrapper>
    );
  }
}
