import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ModifyAvailabilityModal } from '../../../../../common/modal';
import {
  defaultBorder,
  midBreakPoint,
  greyDark
} from '../../../../../_shared/layout-constants';
import './AvailabilitySection.css';

const DaysMapping = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday'
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 140px;
  display: grid;
  grid-gap: 0;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 130px auto;
  padding: 24px 0;
  @media (min-width: ${midBreakPoint}px) {
    border: ${defaultBorder};
    padding: 40px 24px 40px 24px;
    grid-template-rows: 44px auto;
    grid-template-columns: 130px 1fr auto;
  }
`;

const AvailableFromTitle = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

const AvailableFrom = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 1;
`;

const Modify = styled.div`
  cursor: pointer;
  grid-column: 2;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 1;
  }
`;

const DaysAvailableTitle = styled.div`
  grid-column: 1;
  grid-row: 2;
`;

const DaysAvailable = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 2;
  margin-bottom: 24px;

  @media (min-width: ${midBreakPoint}px) {
    margin-bottom: 0;
  }
`;

const AvailabilitySection = props => {
  const { actions } = useContext(ModalStoreContext);
  const {
    changeAvailability,
    change,
    updateFormValue,
    invalid,
    values,
    values: { availableDateFrom, daysAvailable }
  } = props;

  return (
    <Wrapper>
      <ModifyAvailabilityModal
        updateFormValue={updateFormValue}
        change={change}
        invalid={invalid}
        changeAvailability={changeAvailability}
        values={values}
      />

      <AvailableFromTitle>Available from</AvailableFromTitle>
      <AvailableFrom>
        {availableDateFrom ? availableDateFrom.format('DD/MM/YYYY') : null}
      </AvailableFrom>

      <DaysAvailableTitle>Days available</DaysAvailableTitle>
      <DaysAvailable>
        {daysAvailable && daysAvailable.length > 0
          ? daysAvailable.map(day => DaysMapping[day.id]).join(' ')
          : null}
      </DaysAvailable>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyAvailability')}
        >
          Modify
        </div>
      </Modify>
    </Wrapper>
  );
};

export default AvailabilitySection;
