export {
  default as LocationSection
} from './components/locationSection/LocationSection';
export {
  default as FeaturesSection
} from './components/featuresSection/FeaturesSection';
export {
  default as DetailsSection
} from './components/detailsSection/DetailsSection';
export {
  default as ContactSection
} from './components/contactSection/ContactSection';
export {
  default as PriceSection
} from './components/priceSection/PriceSection';
export {
  default as AvailabilitySection
} from './components/availabilitySection/AvailabilitySection';
export { default as Review } from './Review';
