import React, { Component } from 'react';
import styled from 'styled-components';
import { greyDark } from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';

const DesriptionWrapper = createSkeletonElement(styled.div`
  color: ${greyDark};
  text-align: left;
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`);

export default class Description extends Component {
  render() {
    const { content } = this.props;

    return <DesriptionWrapper>{content}</DesriptionWrapper>;
  }
}
