import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import PopupAlert from '../../../../../_shared/components/PopupAlert';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import {
  RubikSemiBold,
  teal,
  midBreakPoint
} from '../../../../../_shared/layout-constants';
import { CANCEL_BOOKING } from '../../../../../common/graphql/bookingSchema';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 48px auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-rows: 20px 1fr 20px;
    grid-template-columns: 155px auto 115px;
    padding: 24px;
    height: 100%;
  }
`;

const Title = styled.div`
  grid-column: 1;
  grid-row: 1;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
`;

// const ModifyBooking = styled.a`
//   color: ${teal};
//   @media (min-width: ${midBreakPoint}px) {
//     grid-column: 1;
//     grid-row: 3;
//   }
// `;

const CancelBooking = styled.a`
  color: ${teal};
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 3;
  }
`;

const ManageBooking = props => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);

  const [cancelBooking, { data, error }] = useMutation(CANCEL_BOOKING, {
    errorPolicy: 'all'
  });
  if (data) {
    window.location.href = '/admin/bookings/my-bookings/pending/cancelled';
  }
  if (error) {
    props.setError('Failed to cancel your booking. Please try again.');
  }
  const handleCancelBooking = async () => {
    try {
      cancelBooking({
        variables: { bookingTransactionId: props.bookingId }
      });
    } catch (e) {}
  };

  const handleShowCancelAlert = () => {
    handleShowModal('cancelBookingAlert');
  };

  return (
    <Wrapper>
      <Title>Manage booking</Title>

      {/* <ModifyBooking>Modify booking</ModifyBooking> */}

      <CancelBooking onClick={handleShowCancelAlert}>
        Cancel booking
      </CancelBooking>
      <PopupAlert
        isAlert
        contentLabel="cancelBookingAlert"
        type="cancelBookingAlert"
        title="Cancel Booking"
        message="Are you sure to cancel this booking?"
        button="Continue"
        secondaryButton="Cancel"
        disableClose={false}
        handleClick={handleCancelBooking}
      />
    </Wrapper>
  );
};

export default ManageBooking;
