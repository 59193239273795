// @flow
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
// import GoogleLogin from 'react-google-login';
import { graphql } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import compose from 'lodash/flowRight';
//
import { ModalStoreContext } from '../../../common/context/modal/store';
import { useAuth0 } from '../../../common/hooks/auth0';
import { SIGNUP_MUTATION } from '../../../common/graphql/authSchema';
import PopupAlert from '../../../_shared/components/PopupAlert';
import './SignupModal.css';
import { RubikSemiBold, teal } from '../../../_shared/layout-constants';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  FullWidthSecondaryButton,
  FullWidthPrimaryButton,
  FullWidthBorderButton
} from '../../../_shared/components/Buttons';
import { ReduxInputFieldWithIcon } from '../../../_shared/components/FormElements';
import { ImgixUrl } from '../../../_shared/utils';

const ArrowIcon = styled.img`
  margin-left: 8px;
  width: 14px;
  cursor: pointer;
  transform: ${({ show }) => (show ? 'rotate(0)' : 'rotate(180deg)')};
`;

const EmailFieldWrapper = styled.div`
  padding: 0;
`;

const FirstNameWrapper = styled.div``;

const LastNameWrapper = styled.div``;

const PasswordFieldWrapper = styled.div``;

const SignupButton = styled(FullWidthPrimaryButton)`
  margin: 24px 0 12px 0;
  font-weight: ${RubikSemiBold};
`;

const SigninLabel = styled.div`
  justify-self: center;
  text-align: center;
  padding-top: 12px;
`;

const SigninButton = styled(FullWidthSecondaryButton)`
  margin-top: 6px;
  font-weight: ${RubikSemiBold};
`;

const TCTitle = styled.div`
  font-weight: ${RubikSemiBold};
  font-size: 14px;
`;

const TCDescription = styled.div`
  font-size: 14px;
  a,
  a:hover {
    text-decoration: underline;
    color: ${teal};
  }
`;

const TCDetails = styled.div`
  font-size: 12px;
  a,
  a:hover {
    text-decoration: underline;
    color: ${teal};
  }
  margin-top: 14px;
`;

const Or = styled.div`
  margin-top: 4px 0;
  text-align: center;
`;

const SocialLoginButton = styled(FullWidthBorderButton)`
  margin: 12px 0 6px 0;
`;

const BreakLineWrapper = styled.div``;

// const GoogleLoginButton = styled(GoogleLogin)`
//   margin-top: 12px;
//   justify-content: center;
//   box-shadow: none !important;
//   border: 1px solid ${grey} !important;
//   &:focus {
//     outline: none;
//   }
// `;

const validate = values => {
  const errors = {};

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Email format is invalid';
  }

  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const regName = /[a-zA-Z]/g;

  if (!values.password) {
    errors.password = 'Please enter your password';
  } else if (values.password.length < 8) {
    errors.password =
      'Password length needs to be greater than 8. Please try again.';
  } else if (
    !values.password.match(numbers) ||
    !values.password.match(lowerCaseLetters) ||
    !values.password.match(upperCaseLetters)
  ) {
    errors.password =
      'Password needs to include more than 1 uppercase alphabetic letters and 1 number. Please try again.';
  }

  if (!values.firstName || !regName.test(values.firstName)) {
    errors.firstName = 'Please enter your valid first name';
  }

  if (!values.lastName || !regName.test(values.lastName)) {
    errors.lastName = 'Please enter your valid last name';
  }

  return errors;
};

const SignupModal = props => {
  const { loginWithRedirect } = useAuth0();
  // const responseGoogle = response => {
  //   console.log(response);
  // };
  const [showTC, setTC] = useState(false);
  const toggleTC = () => setTC(!showTC);
  const [notification, setNotification] = useState(null);
  const {
    state,
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);

  const authSignup = async value => {
    await props
      .signupMutation({
        variables: {
          email: value.email,
          firstName: value.firstName,
          lastName: value.lastName,
          password: value.password
        },
        update: (_, { data: { signup } }) => {
          if (signup.payload.__typename === 'CreateUserSuccess') {
            handleHideModal('Signup');
            handleShowModal('WelcomeAlert');
            setNotification(null);
          } else if (signup.payload.__typename === 'FailUserExists') {
            setNotification('Signup failed as user exists, try another one?');
          } else if (
            signup.payload.__typename === 'CreateUserFailEmailRegistered'
          ) {
            setNotification('Signup failed as email has been registered');
          } else {
            setNotification('Signup failed for some reason, please try again');
          }
        }
      })
      .catch(err => {
        // TODO: login not successful, need to handle
        setNotification(`error is: ${err}`);
      });
  };

  const isOpen = state.activeModal.includes('Signup');

  const {
    //redux-form props
    invalid,
    submitting,
    pristine,
    handleSubmit
  } = props;

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <div>
      <PopupAlert
        title="Welcome to Quokka"
        type="WelcomeAlert"
        message="Thanks for signing up with Quokka, your sign up activation link has been sent to your email address. Check it out."
        button="Great!"
      />
      <Modal
        // isAuth={true}
        title="Welcome to Quokka"
        handleClose={() => handleHideModal('Signup')}
        contentLabel="Signup Modal"
        isOpen={isOpen}
        notification={notification}
        isForm={true}
      >
        <EmailFieldWrapper>
          <Field
            name="email"
            type="email"
            label="Email"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Email.svg`}
          />
        </EmailFieldWrapper>

        <FirstNameWrapper>
          <Field
            name="firstName"
            type="text"
            label="First Name"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Name.svg`}
          />
        </FirstNameWrapper>

        <LastNameWrapper>
          <Field
            name="lastName"
            type="text"
            label="Last Name"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Name.svg`}
          />
        </LastNameWrapper>

        <PasswordFieldWrapper>
          <Field
            name="password"
            label="Password"
            type="password"
            component={ReduxInputFieldWithIcon}
            icon={`${ImgixUrl}/web-image-assets/icons/Password.svg`}
          />
        </PasswordFieldWrapper>

        <TCTitle>Terms & Conditons</TCTitle>
        <TCDescription>
          By continuing, you agree with Quokka Parking's{' '}
          <Link
            to="/terms-and-conditions"
            onClick={() => {
              handleHideModal('Signup');
            }}
          >
            Terms and Conditions
          </Link>
          <ArrowIcon
            show={showTC}
            onClick={toggleTC}
            src={`${ImgixUrl}/web-image-assets/icons/collapse_arrow.svg`}
          />
        </TCDescription>
        {showTC && (
          <TCDetails>
            By continuing, you agree to the Quokka Parking (QP)’s{' '}
            <Link
              to="/terms-and-conditions"
              onClick={() => {
                handleHideModal('Signup');
              }}
            >
              Terms and Conditions{' '}
            </Link>
            . We will collect, use and disclose your personal information to
            provide you services as a Space Manager and to promote the services
            of QP and third parties. We may also contact you to get your
            feedback on our products and services. Our{' '}
            <Link
              to="/privacy-policy"
              onClick={() => {
                handleHideModal('Signup');
              }}
            >
              Privacy Policy
            </Link>{' '}
            explains how we collect, use and disclose personal information and
            how you may access, correct or complain about the handling of
            personal information.
          </TCDetails>
        )}

        <SignupButton
          disabled={pristine || invalid || submitting}
          onClick={handleSubmit(authSignup)}
        >
          Sign up
        </SignupButton>

        <BreakLineWrapper>
          <hr />
        </BreakLineWrapper>

        <SigninLabel>Already on Quokka Parking?</SigninLabel>

        <SocialLoginButton onClick={() => loginWithRedirect()}>
          Login with{' '}
          <img
            alt="google"
            width="23px"
            src="http://pluspng.com/img-png/google-logo-png-open-2000.png"
          />{' '}
          <img
            width="23px"
            alt="facebook"
            src="https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/facebook_circle-512.png"
          />
        </SocialLoginButton>
        <Or>or</Or>
        <SigninButton
          type="submit"
          onClick={() => {
            handleHideModal('Signup');
            handleShowModal('Login');
          }}
        >
          Log in
        </SigninButton>
      </Modal>
    </div>
    /* eslint-enable */
  );
};

const formOptions = {
  form: 'Signup',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(SIGNUP_MUTATION, { name: 'signupMutation' })
)(SignupModal);
