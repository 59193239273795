import React, { Component } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { createSkeletonElement } from '@trainline/react-skeletor';
import { Icon } from '../../../carpark';
import {
  RubikSemiBold,
  midBreakPoint,
  spacing
} from '../../../_shared/layout-constants';

const AmenityWrapper = styled.div`
  padding: ${spacing(6)} ${spacing(4)};
  @media (min-width: ${midBreakPoint}px) {
    padding: 40px 24px 24px 24px;
  }
`;

const Title = createSkeletonElement(styled.h2`
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
  text-align: left;
  margin: 0 0 40px 0;
`);

const IconsWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default class Amenity extends Component {
  render() {
    const { amenities } = this.props;

    return (
      <AmenityWrapper>
        <Title>Amenities</Title>
        <IconsWrapper>
          {amenities &&
            amenities.map(amenity => {
              return (
                <Icon
                  key={shortid.generate()}
                  iconName={amenity.name}
                  pathType="amenities"
                />
              );
            })}
        </IconsWrapper>
      </AmenityWrapper>
    );
  }
}
