import { createGlobalStyle } from 'styled-components';
import {
  RubikFontFamily,
  defaultFontWeight,
  bodyLineHeight,
  bodyFontSize,
  graphite,
  greyLight,
  grey,
  greyDark,
  RubikSemiBold
} from './layout-constants';

export const GlobalStyle = createGlobalStyle`
  body {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-family: ${RubikFontFamily} !important;
    font-weight: ${defaultFontWeight} !important;
    line-height: ${bodyLineHeight} !important;
    font-size: ${bodyFontSize}px !important;
    color: ${graphite} !important;
    font-style: normal !important;
    font-stretch: normal !important;
    letter-spacing: normal !important;
  }

  body.ReactModal__Body--open {
    overflow-y: hidden !important;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: ${grey} !important;
  }

  :-ms-input-placeholder { /* Internet Explorer */
    color: ${grey} !important;
  }

  ::placeholder {
    color: ${grey} !important;
  }

  a {
    color: ${graphite} !important;
    
    &:hover, &:focus  {
      color: ${greyDark} !important;
      text-decoration: none !important;
    }
  }

  label {
    font-weight: ${defaultFontWeight} !important;
  }

  input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
    border: 0 solid transparent;
  }

  hr {
    margin-bottom: 12px;
    margin-top: 15px;
    width: 100%;
    border: 0 !important;
    height: 0 !important;
    border-top: 1px solid ${greyLight} !important;
  }

  b, strong {
    font-weight: ${RubikSemiBold} !important;
  }

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .gm-style .gm-style-cc span, .gm-style .gm-style-cc a, .gm-style .gm-style-mtc div {
    font-size: 10px !important;
    height: 30px !important;
  }

  /* react-date customised styles */
  @media (max-width: 1127px) {
    .DayPicker_transitionContainer {
      height: 340px !important;
    }
  }

  @media (min-width: 1127px) {
    .DateRangePicker_picker,
    .DateRangePicker_picker_1,
    .DateRangePicker_picker__directionLeft,
    .DateRangePicker_picker__directionLeft_2,
    .DateRangePicker_picker__openDown,
    .DateRangePicker_picker__openDown_3 {
      width: 443px !important;
    }

    .DayPicker,
    .DayPicker_1,
    .DayPicker__horizontal,
    .DayPicker__horizontal_2
    .DayPicker_transitionContainer,
    .DayPicker_transitionContainer_1 {
      width: 397px !important;
    }

    .CalendarDay_container,
    .CalendarDay_container_1 {
      width: 56px !important;
      height: 56px !important;
    }

    .DayPickerNavigation_container,
    .DayPickerNavigation_container_1,
    .DayPickerNavigation_container__horizontal,
    .DayPickerNavigation_container__horizontal_2 {
      width: 435px !important;
      margin-left: -20px !important;
    }

    .DayPickerNavigation_button,
    .DayPickerNavigation_button_1,
    .DayPickerNavigation_button__default,
    .DayPickerNavigation_button__default_2,
    .DayPickerNavigation_button__horizontal,
    .DayPickerNavigation_button__horizontal_3,
    .DayPickerNavigation_leftButton__horizontal,
    .DayPickerNavigation_leftButton__horizontal_4 {
      width: 58px !important;
      height: 58px !important;
    }

    .CalendarMonth_caption,
    .CalendarMonth_caption_1 {
      padding: 35px 0 80px 0 !important;
      font-family: Rubik !important;
      font-size: 20px !important;
      font-weight: 500 !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.2 !important;
      letter-spacing: -0.25px !important;
      color: #333f48 !important;
    }

    .CalendarMonthGrid,
    .CalendarMonthGrid_1,
    .CalendarMonthGrid__horizontal,
    .CalendarMonthGrid__horizontal_2 {
      margin: 0 0 0 -20px !important;
    }

    .DayPicker_weekHeaders,
    .DayPicker_weekHeaders_1,
    .DayPicker_weekHeaders__horizontal,
    .DayPicker_weekHeaders__horizontal_2 {
      margin: 0 0 12px -20px !important;
    }

    .DayPicker_weekHeader_li,
    .DayPicker_weekHeader_li_1 {
      width: 56px !important;
      font-family: Rubik !important;
      font-size: 16px !important;
      line-height: 1.25 !important;
      letter-spacing: normal !important;
      color: #70787e !important;
    }

    .DayPicker_weekHeader,
    .DayPicker_weekHeader_1 {
      padding: 0 !important;
      margin: 58px 0 0 22px !important;
    }

    .DayPicker_weekHeader_ul,
    .DayPicker_weekHeader_ul_1 {
      height: 42px !important;
    }

    .CalendarMonth_table,
    .CalendarMonth_table_1 {
      margin: 16px 0 0 0 !important;
    }
  }

  .DayPicker__horizontal {
    /* margin: 0 auto; */
  }

  .DayPicker_transitionContainer,
  .DayPicker_transitionContainer_1 {
    width: 100% !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected_2,
  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background-color: #d0d9e9 !important;
    color: #144392 !important;
    border: #e4e7e7 !important;
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar_2,
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range_4 {
    color: #cacccd !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: #144392 !important;
    color: #ffffff !important;
    border: 1px solid #144392 !important;
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .DateRangePicker .DateRangePickerInput {
    border: none;
    background-color: transparent;
  }

  .DateInput {
    background-color: transparent !important;
    width: auto !important;
    padding: 6px 0 !important;
    font-size: 16px !important;
    color: #70787e !important;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: #263037 !important;
  }

  .DayPickerNavigation_button__default {
    border: solid 1px #979797 !important;
  }

  .DateInput_displayText__focused {
    background: #d0d9e9 !important;
    border: solid 1px #144392 !important;
    color: #000 !important;
  }

  .DayPicker__horizontal {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin: 0 auto !important;
  }

  .react-phone-number-input.disabled{
    background-color: #eaebec !important;
  }

  .react-phone-number-input__row {
    padding: 11px 16px !important;
    border-radius: 3px !important;
    height: 48px !important;
    border: 1px solid #d6d9da !important;
  }

  .react-phone-number-input__input.react-phone-number-input__phone {
    border-bottom: none !important;
    color: #70787e !important;
    line-height: 24px !important;
  }

  .react-phone-number-input__input.react-phone-number-input__phone.react-phone-number-input__input--disabled {
    background-color: #eaebec !important;
  }

  .react-phone-number-input__icon {
    height: 21px !important;
    border: none !important;
  }
`;
