import React, { Component } from 'react';
import styled from 'styled-components';
import NavHeader from '../../../_shared/components/navHeader/NavHeader';
import BookingOverview from '../components/bookingOverview/BookingOverview';
import ListingOverview from '../components/listingOverview/ListingOverview';
import Notifications from '../components/notifications/Notifications';
import Transactions from '../../components/transactions/Transactions';
import AdminWrapper from '../../_shared/components/AdminWrapper';

const Content = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export default class DashboardPage extends Component {
  state = {
    booking: true,
    listing: false,
    notifications: false,
    transactions: false,
    active: 'booking'
  };

  toggleNav = goTo => {
    ['booking', 'listing', 'notifications', 'transactions']
      .filter(i => i !== goTo)
      .map(item => this.setState({ [item]: false }));
    this.setState({ [goTo]: true });
    this.setState({ active: goTo });
  };

  render() {
    return (
      <AdminWrapper title="Dashboard">
        <NavHeader
          toggleNav={this.toggleNav}
          active={this.state.active}
          toggleItems={['Booking', 'Listing', 'Notifications', 'Transactions']}
        />
        <Content>
          {this.state.booking && <BookingOverview />}
          {this.state.listing && <ListingOverview />}
          {this.state.notifications && <Notifications />}
          {this.state.transactions && <Transactions />}
        </Content>
      </AdminWrapper>
    );
  }
}
