import React, { Fragment } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import shortid from 'shortid';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Field } from 'redux-form';
import {
  greyDark,
  graphite,
  red,
  navy,
  defaultBorder,
  greyExtraLight,
  defaultBoxShadow,
  greyLight,
  gutter,
  spacing,
  RubikFontFamily,
  midBreakPoint,
  RubikSemiBold
} from '../layout-constants';
import { ImgixUrl } from '../utils';
import ToolTip from './Tooltips';

/**
 * This files export following components:
 * InputField, SelectField, ToggleButtonGroup, CheckBox, TextArea, ExpiryInput
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gutter(1)};
  font-family: ${RubikFontFamily};
`;

const Label = styled.span`
  margin-bottom: 12px;
  font-weight: ${RubikSemiBold};
`;

const Input = styled.input`
  padding: ${({ hasIcon }) => (hasIcon ? '11px 16px 11px 56px' : '11px 16px')};
  line-height: 24px;
  background-color: #fff;
  border: 1px solid ${({ error }) => (error ? red : greyLight)};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  color: ${greyDark};
  &:focus {
    border-color: ${navy};
  }
  &:disabled {
    border: ${defaultBorder};
    background-color: ${greyExtraLight};
  }
`;

const ErrorMsg = styled.span`
  color: ${red};
  margin: ${spacing(2)} 0 0 0;
`;

export const InputField = ({
  label,
  type,
  className,
  value,
  handleChange,
  placeholder,
  maxLength,
  pattern,
  disabled,
  name,
  hideInputField,
  error,
  errorMsg,
  ...otherProps
}) => (
  <Wrapper {...otherProps}>
    <Label>{label}</Label>
    {hideInputField !== true ? (
      <Input
        {...otherProps}
        className={className}
        name={name}
        type={type ? type : 'text'}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        pattern={pattern}
        onChange={handleChange}
        disabled={disabled}
        error={error}
        errorMsg={errorMsg}
      />
    ) : null}
    {error && <ErrorMsg>{errorMsg}</ErrorMsg>}
  </Wrapper>
);

const InputWrapper = styled.div`
  position: relative;
`;

const InputIcon = styled.img`
  position: absolute;
  left: 16px;
  top: 13px;
  height: 20px;
`;

export const ReduxInputField = ({
  input,
  label,
  tooltip,
  type,
  style,
  icon,
  meta: { touched, error, warning },
  ...otherProps
}) => (
  <Wrapper style={style}>
    {label && type !== 'hidden' && <Label>{label}</Label>}
    {tooltip && type !== 'hidden' && <ToolTip>{tooltip}</ToolTip>}
    <InputWrapper>
      {icon && <InputIcon {...icon} />}
      <Input
        hasIcon={icon}
        {...otherProps}
        {...input}
        error={error && touched}
        type={type ? type : 'text'}
        formNoValidate
      />
    </InputWrapper>
    {touched && error && <ErrorMsg>{error}</ErrorMsg>}
  </Wrapper>
);

const InputBoxIcon = styled.img`
  width: 20px;
  height: 16.2px;
  top: 16px;
  right: 16px;
  bottom: 16px;
  left: auto;
  position: absolute;
`;

// redux-form doc https://redux-form.com/8.2.2/examples/syncvalidation/
export const ReduxInputFieldWithIcon = ({
  input,
  label,
  type,
  icon,
  meta: { touched, error, warning },
  ...otherProps
}) => (
  <Wrapper style={{ marginBottom: 16, position: 'relative' }}>
    <Input
      {...otherProps}
      {...input}
      placeholder={label}
      type={type ? type : 'text'}
      formNoValidate
    />
    <InputBoxIcon src={icon} alt="" />
    {touched && error && <ErrorMsg>{error}</ErrorMsg>}
  </Wrapper>
);

const selectFieldStyles = ({
  borderColor,
  boxShadow,
  withIcon = false,
  error,
  touched
}) => {
  return {
    control: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        boxShadow: 'none',
        height: withIcon ? 44 : 48,
        backgroundColor: '#fff',
        borderColor: error && touched ? red : greyLight,
        paddingLeft: withIcon ? 189 : 0,
        '&:hover': {
          borderColor: withIcon ? greyLight : navy,
          cursor: 'pointer'
        },
        span: {
          display: 'none'
        }
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: withIcon ? 60 : 40,
        color: graphite,
        backgroundColor: isSelected
          ? greyExtraLight
          : isFocused
          ? greyExtraLight
          : '#fff',
        '&:active': {
          backgroundColor: greyExtraLight
        }
      };
    },
    singleValue: styles => ({ ...styles, color: greyDark }),
    placeholder: styles => ({ ...styles, color: greyDark })
  };
};

export const SelectField = ({
  label,
  name,
  value,
  handleChange,
  placeholder,
  options,
  borderColor = greyLight,
  boxShadow,
  maxMenuHeight,
  ...otherProps
}) => (
  <Wrapper {...otherProps}>
    {label && <Label>{label}</Label>}
    <Select
      name={name}
      value={typeof value === 'object' ? value : { label: value, value: value }}
      onChange={handleChange}
      placeholder={placeholder}
      styles={selectFieldStyles({ borderColor, boxShadow })}
      options={options}
      maxMenuHeight={maxMenuHeight ? maxMenuHeight : '280px'}
    />
  </Wrapper>
);

export const ReduxSelectField = ({
  label,
  name,
  value,
  placeholder,
  options,
  borderColor = graphite,
  boxShadow,
  maxMenuHeight,
  input,
  meta: { touched, error, warning },
  disableError = false,
  ...otherProps
}) => (
  <Wrapper {...otherProps}>
    {label && <Label>{label}</Label>}
    <Select
      {...input}
      placeholder={placeholder}
      styles={selectFieldStyles({ borderColor, boxShadow, error, touched })}
      options={options}
      maxMenuHeight={maxMenuHeight ? maxMenuHeight : '200px'}
      onBlur={event => event.preventDefault()} // ref: https://github.com/JedWatson/react-select/issues/2692
      value={
        typeof input.value === 'object'
          ? input.value
          : { label: input.value, value: input.value }
      }
    />
    {!disableError && touched && error && <ErrorMsg>{error}</ErrorMsg>}
  </Wrapper>
);

const Icon = styled.img`
  height: 20.54px;
  width: 21px;
  line-height: 59px;
  top: 11.5px;
  left: 29px;
  position: absolute;
  z-index: 1;
`;

const SelectFieldWithIconWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const SelectIndicator = styled.span`
  color: ${greyDark};
  position: absolute;
  top: 11.5px;
  left: 74px;
  z-index: 1;
`;

export const SelectFieldWithIcon = ({
  icon,
  label,
  iconAlt,
  value,
  defaultValue,
  handleChange,
  placeholder,
  options
}) => (
  <SelectFieldWithIconWrapper>
    <Icon src={icon} alt={iconAlt} />
    <SelectIndicator>{label}</SelectIndicator>
    <Select
      className="sort-by-selector"
      name="sort by name"
      defaultValue={defaultValue}
      styles={selectFieldStyles({ withIcon: true })}
      onChange={handleChange}
      placeholder={placeholder}
      options={options}
    />
  </SelectFieldWithIconWrapper>
);

const ToggleButtons = styled.div`
  display: flex;
  border: ${defaultBorder};
  border-radius: 3px;
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: #fff;
  color: ${graphite};
  font-size: 16px;
  padding: ${({ small }) => (small ? '5px 12px' : '13px 13px')};
  cursor: pointer;
  float: center;
  /* height: 48px; */
  text-align: center;
  outline: 0;
  border: none;

  &:active,
  &:focus {
    background-color: ${greyExtraLight};
  }

  &:last-child:not(:nth-child(2)) {
    border-left: 1px solid ${greyLight};
  }

  &:first-child {
    border-right: 1px solid ${greyLight};
  }

  @media (max-width: ${midBreakPoint}px) {
    font-size: 12px;
  }
`;

const ToggleButtonIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export class ToggleButtonGroup extends React.Component {
  state = {
    toggled: null
  };

  componentDidMount() {
    this.setState({
      toggled: this.props && this.props.index === 0 ? this.props.index : null
    });
  }

  toggleState = i => {
    this.setState({
      toggled: i
    });
  };

  render() {
    const { label, elements, handleClick, ...otherProps } = this.props;
    const { toggled } = this.state;

    return (
      <Wrapper {...otherProps}>
        {label && <Label>{label}</Label>}
        <ToggleButtons>
          {elements.map(({ name, icon }, i) => (
            <ToggleButton
              small
              key={shortid.generate()}
              style={{
                backgroundColor: toggled === i ? greyExtraLight : 'white'
              }}
              value={name}
              onClick={e => {
                handleClick && handleClick(name);
                this.toggleState(i);
              }}
            >
              <ToggleButtonIcon
                alt="icon"
                src={`${ImgixUrl}/web-image-assets/icons/${icon}`}
              />
              {name}
            </ToggleButton>
          ))}
        </ToggleButtons>
      </Wrapper>
    );
  }
}

export class ReduxToggleButtonGroup extends React.Component {
  state = {
    toggled: null
  };

  componentDidMount() {
    const index =
      this.props &&
      this.props.options
        .map(o => o.value === this.props.input.value)
        .indexOf(true); //TODO: replace it with findIndex when IE start to support

    this.setState({
      toggled: index !== -1 ? index : null
    });
  }

  toggleState = i => {
    this.setState({
      toggled: i
    });
  };

  render() {
    const {
      label,
      options,
      name,
      input: { onChange },
      meta: { touched, error },
      ...otherProps
    } = this.props;
    const { toggled } = this.state;

    return (
      <Wrapper {...otherProps}>
        <Label>{label}</Label>
        <ToggleButtons>
          {options.map(({ label, value }, i) => (
            <ToggleButton
              key={shortid.generate()}
              style={{
                backgroundColor: toggled === i ? greyExtraLight : 'white'
              }}
              value={value}
              onClick={e => {
                onChange && onChange(e.target.value);
                this.toggleState(i);
              }}
            >
              {label}
            </ToggleButton>
          ))}
        </ToggleButtons>
        {touched && error && <ErrorMsg>{error}</ErrorMsg>}
      </Wrapper>
    );
  }
}

// common style used by checkbox and radio button
const BulletContainer = styled.label`
  display: block;
  position: relative;
  padding-left: ${spacing(5)};
  padding-bottom: 5px;
  margin-left: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CheckboxContainer = styled(BulletContainer)`
  height: 25px;
  width: 196px;
`;

const InvisibleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CheckboxMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: ${defaultBorder};
  border-radius: 3px;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 8px;
    height: 15px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ${CheckboxContainer}:hover & {
    background-color: #ccc;
  }
  ${CheckboxContainer} input:checked ~ & {
    background-color: ${graphite};
    &:after {
      display: block;
    }
  }
`;

const IconImg = styled.img`
  height: 30px;
  width: 20px;
  padding-bottom: 6px;
  margin-right: 10px;
`;

export const CheckBox = ({
  label,
  value,
  checked,
  handleChange,
  iconType,
  disabled,
  ...otherProps
}) => {
  let imgPath;
  if (iconType) {
    imgPath = iconType + '/' + label.toLowerCase().replace('/', ' ') + '.svg';
  }
  return (
    <CheckboxContainer {...otherProps}>
      {iconType && (
        <IconImg
          src={`${ImgixUrl}/web-image-assets/icons/${imgPath}`}
          alt="icon"
        />
      )}
      {label}
      <InvisibleInput
        type="checkbox"
        value={value || label}
        checked={checked}
        onChange={e => handleChange(e.target.value)}
        disabled={disabled}
      />
      <CheckboxMark />
    </CheckboxContainer>
  );
};

const AmenitiesVehiclesMapping = {
  CabChassis: 'Cab chassis',
  LightTruck: 'Light truck',
  PeopleMover: 'People mover',
  Cctv: 'CCTV',
  Wc: 'WC',
  'Arranged transfers': 'Arranged transfer'
};

export const ReduxCheckBoxComponent = ({
  input,
  label,
  pathType,
  ...otherProps
}) => {
  let imgPath;
  if (pathType) {
    imgPath = pathType + '/' + label.toLowerCase().replace('/', ' ') + '.svg';
  }

  return (
    <CheckboxContainer {...otherProps}>
      {pathType && (
        <IconImg
          src={`${ImgixUrl}/web-image-assets/icons/${imgPath}`}
          alt="icon"
        />
      )}
      {label.charAt(0).toUpperCase() + label.substr(1) in
      AmenitiesVehiclesMapping
        ? AmenitiesVehiclesMapping[
            label.charAt(0).toUpperCase() + label.substr(1)
          ]
        : label.charAt(0).toUpperCase() + label.substr(1)}
      <InvisibleInput type="checkbox" {...input} />
      <CheckboxMark />
    </CheckboxContainer>
  );
};

const RadioContainer = styled(BulletContainer)`
  margin-right: 10px;
`;

const RadioCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: ${defaultBorder};
  border-radius: 100%;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${graphite};
  }
  ${RadioContainer}:hover & {
    background-color: #ccc;
  }
  ${RadioContainer} input:checked ~ & {
    &:after {
      display: block;
    }
  }
`;

export const RadioButton = ({
  label,
  value,
  name,
  checked,
  handleChange,
  ...otherProps
}) => (
  <RadioContainer {...otherProps}>
    {label}
    <InvisibleInput
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={e => handleChange(e.target.value)}
    />
    <RadioCheckMark />
  </RadioContainer>
);

const InputArea = styled.textarea`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '132px')};
  border-radius: 3px;
  border-color: ${({ withBoxShadow, error, touched }) =>
    touched && error ? red : withBoxShadow ? greyExtraLight : greyLight};
  border-width: 1px;
  padding: 14px 16px 14px 16px;
  color: ${greyDark};
  box-shadow: ${({ withBoxShadow }) =>
    withBoxShadow ? defaultBoxShadow : 'none'};
  &:focus {
    border-color: ${navy};
  }
`;

export const TextArea = ({
  label,
  name,
  value,
  handleChange,
  height,
  withBoxShadow = false,
  ...otherProps
}) => (
  <Wrapper {...otherProps}>
    {label && <Label>{label}</Label>}
    <InputArea
      type="text"
      name={name}
      value={value}
      onChange={value => handleChange(value)}
      height={height}
    />
  </Wrapper>
);

export const ReduxTextArea = ({
  input,
  label,
  tooltip,
  height,
  big = false,
  withBoxShadow = false,
  meta: { touched, error, warning },
  ...otherProps
}) => (
  <Wrapper>
    {label && <Label>{label}</Label>}
    {tooltip && <ToolTip hasClose={false}>{tooltip}</ToolTip>}
    <InputArea
      {...otherProps}
      {...input}
      touched={touched}
      error={error}
      type="text"
      big={big}
      height={height}
    />
    {touched && error && <ErrorMsg>{error}</ErrorMsg>}
  </Wrapper>
);

const OuterField = styled.div`
  padding: 11px 10px 11px 10px;
  background-color: #ffffff;
  border: ${defaultBorder};
  border-radius: 3px;
  width: 100%;
  height: 48px;
  display: block;
`;

const InnerField = styled.input`
  border: 0;
  text-align: center;
  width: 72px;
  height: 24px;
  padding: 0 ${spacing(3)};
  outline: 0;
  color: ${greyDark};
  display: inline;
  float: left;
`;

const Slash = styled.div`
  padding: 5px 1px 0 1px;
  float: left;
  font-size: 14px;
  vertical-align: middle;
`;

export const InnerInputField = ({
  input,
  label,
  type,
  setErrorMsg,
  meta: { touched, error, warning },
  ...otherProps
}) => {
  touched && setErrorMsg(error);
  return (
    <InnerField
      {...otherProps}
      {...input}
      type={type ? type : 'text'}
      formNoValidate
    />
  );
};

export class ExpiryInput extends React.Component {
  state = {
    error: ''
  };
  setErrorMsg = i => {
    this.setState({
      error: i
    });
  };

  render() {
    const { label } = this.props;
    const { error } = this.state;
    return (
      <Wrapper>
        <Label>{label}</Label>
        <OuterField>
          <Field
            name="month"
            maxLength={2}
            setErrorMsg={this.setErrorMsg}
            component={InnerInputField}
          />
          <Slash>/</Slash>
          <Field
            name="year"
            maxLength={2}
            setErrorMsg={this.setErrorMsg}
            component={InnerInputField}
          />
        </OuterField>
        <ErrorMsg>{error}</ErrorMsg>
      </Wrapper>
    );
  }
}

export const BreakLine = styled.hr`
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  border: 0;
  height: 0;
  border-top: 1px solid #cccccc;
`;

export const MobileInput = ({
  setPhoneNumber,
  setMobileCodeStatus,
  phoneNumber
}) => {
  const onMobileNumberChange = (status, value, countryData, number, id) => {
    setPhoneNumber(number.replace(/\s/g, ''));
    if (setMobileCodeStatus) {
      setMobileCodeStatus(status);
    }
  };
  const loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url +
      (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  const lookup = callback => {
    loadJSONP('http://ipinfo.io', 'sendBack');
    window.sendBack = resp => {
      const countryCode = resp && resp.country ? resp.country : '';
      callback(countryCode);
    };
  };
  return (
    <Fragment>
      <div style={{ marginBottom: 12 }}>Mobile number</div>
      <IntlTelInput
        defaultCountry={'au'}
        geoIpLookup={lookup}
        css={['intl-tel-input', 'form-control']}
        utilsScript={'libphonenumber.js'}
        placeholder=""
        value={phoneNumber}
        onPhoneNumberChange={onMobileNumberChange}
      />
    </Fragment>
  );
};

export const PhoneInputField = ({
  handleChange,
  label,
  error,
  disabled,
  ...props
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <PhoneInput
        className={disabled ? 'disabled' : ''}
        country="AU"
        {...props}
        disabled={disabled}
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </Wrapper>
  );
};

// export const ReduxInputField = ({
//   input,
//   label,
//   type,
//   style,
//   icon,
//   meta: { touched, error, warning },
//   ...otherProps
// }) => (
//   <Wrapper style={style}>
//     <Label>{label}</Label>
//     <InputWrapper>
//       {icon && <InputIcon {...icon} />}
//       <Input
//         hasIcon={icon}
//         {...otherProps}
//         {...input}
//         error={error && touched}
//         type={type ? type : 'text'}
//         formNoValidate
//       />
//     </InputWrapper>
//     {touched && (error && <ErrorMsg>{error}</ErrorMsg>)}
//   </Wrapper>
// );
