import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import PlacesAutocomplete from 'react-places-autocomplete';
import autoFindStyles from './searchBarStyle';
import * as moment from 'moment';
import { ImgixUrl } from '../../../_shared/utils';
import { loadGoogleMaps } from '../../../_shared/load-google-map';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import { greyDark } from '../../../_shared/layout-constants';

const formName = 'Search';

const formOptions = {
  form: formName
};

const addrAutocompleteOptions = {
  componentRestrictions: {
    country: 'au'
  }
};

const SearchIcon = styled.img`
  line-height: 50px;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  position: absolute;
  z-index: 1;
  margin-left: 24px;
`;

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

const InputField = styled(ReduxInputField)`
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? paddingLeft + 'px' : '64px'};
  padding-right: 16px;
  height: 20px !important;
  width: 100%;
  position: relative;
  font-size: 16px;
  color: ${greyDark};
  border-radius: 28px;
  border: none;
  outline: 0;
  text-overflow: ellipsis;
  margin: 24px 0 0 14px;
`;

const SearchQuery = modifiedSearchLocation => {
  return (window.location.href =
    '/find-parking-space' +
    '/address-' +
    modifiedSearchLocation +
    '/search?startDate=' +
    moment().format('DD-MM-YYYY') +
    '&endDate=' +
    moment()
      .add(1, 'months')
      .format('DD-MM-YYYY') +
    '&priceUnit={"value":"m","label":"Monthly"}' +
    '&priceRange={"min":0,"max":1500}');
};

class SearchBar extends React.Component {
  state = {
    tempSearchLocation: '',
    googleMapsReady: false
  };

  componentDidMount() {
    loadGoogleMaps(() => {
      this.setState({ googleMapsReady: true });
    });
  }

  recordSearchLocation = location => {
    this.setState({ tempSearchLocation: location });
  };

  updateSearchLocation = () => {
    const { tempSearchLocation } = this.state;
    this.setState(
      {
        searchLocation: tempSearchLocation
      },
      () => {
        const modifiedSearchLocation = this.state.searchLocation
          .replace(/\s+/g, '-')
          .replace(/,/g, '')
          .toLowerCase();

        SearchQuery(modifiedSearchLocation);
      }
    );
  };

  handleSubmit = () => {
    if (this.props.updateSearchLocation) {
      this.props.updateSearchLocation(this.state.tempSearchLocation);
    } else {
      // if no props passed in, trigger the default update (redirect to specified url)
      this.updateSearchLocation();
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleSubmit();
    }
  };

  render() {
    const {
      address,
      height,
      placeholder,
      autocompletionOptions,
      paddingLeft,
      ...otherprops
    } = this.props;

    const { googleMapsReady, tempSearchLocation } = this.state;

    const inputProps = placeholder => {
      return {
        className: 'search-bar',
        value: tempSearchLocation,
        onChange: location => this.recordSearchLocation(location),
        placeholder: placeholder
      };
    };

    return (
      <Wrapper
        onSubmit={this.handleSubmit}
        onKeyDown={e => {
          this.handleKeyDown(e);
        }}
        {...otherprops}
      >
        <SearchIcon
          src={`${ImgixUrl}/web-image-assets/icons/Search.svg`}
          alt="search icon"
          height={height}
        />
        {googleMapsReady ? (
          <Field
            name="search-bar"
            inputProps={inputProps(placeholder)}
            component={PlacesAutocomplete}
            styles={autoFindStyles}
            options={
              autocompletionOptions
                ? autocompletionOptions
                : addrAutocompleteOptions
            }
          />
        ) : (
          <Field
            name="search-bar"
            component={InputField}
            paddingLeft={paddingLeft ? paddingLeft : '64'}
            placeholder="Where are you parking?"
            onChange={e => {
              this.recordSearchLocation(e.target.value);
            }}
            style={{ width: '95%' }}
          />
        )}
      </Wrapper>
    );
  }
}

export default reduxForm(formOptions)(SearchBar);
