import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../common/context/modal/store';
import {
  ReduxInputField,
  ReduxSelectField
} from '../../../_shared/components/FormElements';
import { Field } from 'redux-form';
import Modal from '../../../_shared/components/ModalWrapper';

const AusStates = require('../../../_shared/AusStates.json');

const StreetNumber = styled.div``;

const StreetName = styled.div``;

const StreetType = styled.div``;

const Suburb = styled.div``;

const State = styled.div``;

const Postcode = styled.div``;

const Country = styled.div``;

const ParkingLot = styled.div``;

const ModifyAddressModal = props => {
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const isOpen = activeModal.includes('ModifyAddress');
  const positiveNumber = value => (value > 0 ? value : 0);
  const {
    //redux-form props
    invalid
    // handleSubmit
  } = props;
  //console.log('invalid', invalid);
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Modify location"
      handleClose={() => handleHideModal('ModifyAddress')}
      contentLabel="Modify Carpark Address Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      handleSubmit={() => {
        if (invalid) return;
        props.changeLocation({ isReview: true, ...props.values });
        handleHideModal('ModifyAddress');
      }}
      buttonDisabled={invalid}
      isForm={false}
    >
      <StreetNumber>
        <Field
          name="streetNumber"
          label="Street number"
          component={ReduxInputField}
        />
      </StreetNumber>

      <StreetName>
        <Field
          name="streetName"
          label="Street name"
          component={ReduxInputField}
        />
      </StreetName>

      <StreetType>
        <Field
          name="streetType"
          label="Street type"
          component={ReduxInputField}
        />
      </StreetType>

      <Suburb>
        <Field name="suburb" label="Suburb" component={ReduxInputField} />
      </Suburb>

      <State>
        <Field
          name="state"
          label="State"
          component={ReduxSelectField}
          options={AusStates}
        />
      </State>

      <Postcode>
        <Field name="postcode" label="Postcode" component={ReduxInputField} />
      </Postcode>

      <Country>
        <Field
          name="country"
          label="Country"
          component={ReduxInputField}
          disabled
        />
      </Country>

      <ParkingLot>
        <Field
          name="parkingLotNumber"
          label="Lot number"
          type="number"
          normalize={value => positiveNumber(value)}
          component={ReduxInputField}
        />
      </ParkingLot>
    </Modal>
  );
};

// const formOptions = {
//   form: 'ModifyAddressModal',
//   validate
// };

export default ModifyAddressModal;
