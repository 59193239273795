import React, { createContext } from 'react';
import useMatchMedia from './_hook';
import { midBreakPoint } from '../../../_shared/layout-constants';

// Our default media queries
// sm, md, lg to match our css values
export const DEFAULT_MEDIA_QUERIES = {
  mobile: `screen and (max-width: ${midBreakPoint - 1}px)`, // less than our lowest breakpoint
  desktop: `screen and (min-width: ${midBreakPoint}px)`
};

const defaultInitialValues = {
  type: 'screen',
  width: 320
};

const MediaContext = createContext();

const MediaContextProvider = ({
  children,
  queries = DEFAULT_MEDIA_QUERIES,
  initialValues = defaultInitialValues
}) => {
  const { Provider } = MediaContext;
  const media = useMatchMedia(queries, initialValues);

  return <Provider value={media}>{children}</Provider>;
};

export { MediaContext, MediaContextProvider };

export default MediaContext;
