import React, { Component } from 'react';
import styled from 'styled-components';
import { ParkingIcon } from '../../../carpark';
import {
  RubikSemiBold,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';

const TitleContentWrapper = createSkeletonElement(styled.div`
  padding: 20px 12px 22px 0;
  display: flex;
  align-items: flex-start;
`);

const TitleWrapper = styled.h1`
  font-size: 18px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.28px;
  text-align: left;
  padding: 0 0 0 12px;
  display: inline-block;
  margin: 2px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 28px;
    padding: 0 0 0 12px;
    margin: 2x;
    letter-spacing: -0.98px;
  }
`;

export default class Title extends Component {
  render() {
    const { title } = this.props;
    return (
      <TitleContentWrapper>
        <ParkingIcon />
        <TitleWrapper>{title}</TitleWrapper>
      </TitleContentWrapper>
    );
  }
}
