import React, { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducers';
import { useActions } from './actions';

const ModalStoreContext = createContext(initialState);

const ModalStoreProvider = ({ children }) => {
  // Set up reducer with useReducer and our defined reducer, initialState from reducers.js
  const [state, dispatch] = useReducer(reducer, initialState);
  // Create an object of all our actions, handling special cases where a simple dispatch is too primitive
  const actions = useActions(state, dispatch);

  // Render state, dispatch and special case actions
  return (
    <ModalStoreContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </ModalStoreContext.Provider>
  );
};

export { ModalStoreContext, ModalStoreProvider };
