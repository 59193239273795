import styled from 'styled-components';
import { greyLight, midBreakPoint } from '../../../_shared/layout-constants';

export const InfoTable = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  overflow-x: hidden;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  @media (min-width: ${midBreakPoint}px) {
    padding ${({ padding }) => `${padding}px`};
    border: solid 1px ${greyLight};
    flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  }
`;
