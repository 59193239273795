import gql from 'graphql-tag';

/* queries */
export const GET_ACTIVE_CREDIT_CARDS = gql`
  query GetActiveCreditCards($token: UUID!) {
    getActiveCreditCards(token: $token) {
      id
      cardNumber
      cardType
    }
  }
`;

export const GET_BOOKING_TRANSACTION = gql`
  query GetBookingTransaction($token: UUID!) {
    getBookingTransaction(token: $token) {
      id
      bookings {
        startTime
        endTime
        cost
        costDiscount
        commission
        commissionDiscount
        totalDiscount
        total
      }
      parking {
        id
        title
        status
        accessInstruction
        deliveryInstruction
        description
        parkingTypes {
          id
          name
        }
        accessTypes {
          id
          name
        }
        address {
          coordinate {
            lat
            lon
          }
          value
          subpremise
          streetNumber
          route
          locality
          postalCode
          state
          stateCode
          country
          countryCode
          tz
        }
        priceOptions
      }
    }
  }
`;

export const RETRY_PAYMENT = gql`
  mutation RetryPayment($token: UUID, $fileName: String, $creditCardId: UUID) {
    retryPayment(
      token: $token
      fileName: $fileName
      creditCardId: $creditCardId
    ) {
      retryPaymentRequest {
        bookingTransactionId
      }
    }
  }
`;

export const AVERAGE_SUBURB_PRICE = gql`
  query AverageSuburbPrice($suburb: String, $state: String, $country: String) {
    averageSuburbPrice(suburb: $suburb, state: $state, country: $country) {
      suburbFound
      alternativeSuburb
      price
      currency
    }
  }
`;
