// @flow
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
//import shortid from 'shortid';
import get from 'lodash/get';
//import * as moment from 'moment-timezone';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SelectField } from '../../../_shared/components/FormElements';
import { TextButton } from '../../../_shared/components/Buttons';
import {
  //graphite,
  //RubikFontFamily,
  RubikSemiBold,
  midBreakPoint
} from '../../../_shared/layout-constants';
import BookingStepWrapper from '../_shared/BookingStepWrapper';
//import { teal, greyDark } from '../../../_shared/layout-constants';
import { REQUEST_BOOKING } from '../../../common/graphql/bookingSchema';
import { GET_CREDIT_CARDS } from '../../../common/graphql/adminSchema';
import { AddPaymentMethodModal } from '../../../common/modal';
import { trackBookingFormAction } from '../../../_shared/tracker';
import Summary from '../_shared/Summary';

// const ConfirmBlockWrapper = styled.div`
//   margin-bottom: 28px;
//   display: flex;
//   flex-direction: column;
// `;

// const EditButton = styled.a`
//   font-size: 20px;
//   text-align: right;
//   color: ${teal};
//   padding: 33px 5px 20px 0;
//   flex: 1;
//   cursor: pointer;
// `;

// const FormGroup = styled.div`
//   display: flex;
// `;

// const Form = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 2;
// `;

// const SummaryValue = styled.div`
//   margin: 12px 0 0 0;
//   font-size: 16px;
//   font-family: ${RubikFontFamily};
//   color: ${graphite};
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   display: flex;
// `;

const SummarySubtitle = styled.label`
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.1px;
  @media (min-width: ${midBreakPoint}px) {
    height: 24px;
    font-size: 14px;
    font-weight: ${RubikSemiBold};
    letter-spacing: -0.1px;
  }
`;

const AddButton = styled(TextButton)`
  font-size: 14px;
  margin-top: 28px;
  display: block;
`;

// const ValueCategory = styled.span`
//   font-size: 14px;
//   color: ${greyDark};
//   margin-right: 12px;
// `;

const BreakLine = styled.hr`
  margin: 16px 0 32px 0;
`;

const SelectedContactNote = styled.span`
  height: 14px;
  opacity: 0.7;
  font-size: 12px;
  padding: 0 0 0 2px;
`;

const CreditCardSection = styled.div``;

// const ConfirmBlock = ({ attributes, category, edit }) => (
//   <ConfirmBlockWrapper>
//     <FormGroup>
//       {attributes.map(attribute =>
//         attribute && attribute.length >= 2 && attribute[1] ? (
//           <Form key={shortid.generate()}>
//             <SummarySubtitle>{attribute[0]}</SummarySubtitle>
//             <SummaryValue>
//               {category && <ValueCategory>{category}</ValueCategory>}
//               {attribute[1]}
//             </SummaryValue>
//           </Form>
//         ) : null
//       )}
//       <EditButton onClick={edit}>Edit</EditButton>
//     </FormGroup>
//     <BreakLine />
//   </ConfirmBlockWrapper>
// );

const StepThree = ({
  handlePaymentChange,
  toggleHandler,
  duration,
  vehicles,
  contacts,
  priceOptions,
  tz,
  preview,
  handleShowModal,
  parkingId,
  payment,
  selectedPaymentOption
}) => {
  const [creditCardOptions, setCreditCardOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data: creditCards, refetch: refetchCreditCards } = useQuery(
    GET_CREDIT_CARDS
  );
  const history = useHistory();
  const [requestBooking, { data, error: requestBookingError }] = useMutation(
    REQUEST_BOOKING,
    {
      errorPolicy: 'all'
    }
  );

  useEffect(() => {
    const loadCreditCardOptions = get(creditCards, 'getCreditCards', [])
      .sort((a, b) => {
        const bb = get(b, 'defaultresourceSet[0].creditCard.id') === b.id;
        const aa = get(a, 'defaultresourceSet[0].creditCard.id') === a.id;
        return bb - aa;
      })
      .map(creditCard => ({
        value: creditCard.id,
        label: creditCard.cardNumber
      }));
    // map all the vehicles to options
    setCreditCardOptions(loadCreditCardOptions);
  }, [creditCards]);

  useEffect(() => {
    handlePaymentChange(selectedPaymentOption || creditCardOptions[0]); // default the first option
  }, [creditCardOptions, selectedPaymentOption, handlePaymentChange]);

  useEffect(() => {
    if (get(data, 'requestBooking.bookingRequest.id')) {
      history.push(
        '/carpark/booking/' + get(data, 'requestBooking.bookingRequest.id')
      );
      setLoading(false);
      setError(null);
    }
  }, [data, history]);

  useEffect(() => {
    if (requestBookingError) {
      setLoading(false);
      setError(requestBookingError.message);
    }
  }, [requestBookingError]);

  // const displayDate = m => {
  //   if (m && moment.isMoment(m)) {
  //     return m.format('DD/MM/YYYY');
  //   }
  //   return '';
  // };

  // const displayOption = o => {
  //   if (o && o.selectedOption) {
  //     return o.selectedOption.label;
  //   }
  //   return '';
  // };

  const handleRequestBooking = async () => {
    let requestPayload = {
      preview: false,
      bookings: duration.rangeArray,
      contacts: [contacts.selectedOption.value],
      creditCardId: payment.selectedOption.value,
      parkingId: parkingId,
      vehicleIds: vehicles.selectedOption && [vehicles.selectedOption.value],
      isMonthlyBooking: duration.monthlyOngoing
    };

    setLoading(true);

    try {
      await requestBooking({
        variables: requestPayload,
        errorPolicy: 'all'
      });
    } catch (e) {
      setError(e);
      setLoading(false);
      console.log(e);
    }
  };

  const deposit =
    priceOptions && priceOptions.find(p => get(p, 'type.short') === 'deposit');
  const depositValue = get(deposit, 'value', 0);
  const depositUnit = get(deposit, 'unit', '$');

  const handleSubmit = () => {
    handleRequestBooking(false);
    trackBookingFormAction('submitted', parkingId);
  };

  // const determineToDate = (toDate, fromDate, tz, monthlyOngoing) => {
  //   if (toDate) {
  //     // TODO
  //     return ['To', displayDate(toDate.tz(tz))];
  //   } else if (!toDate && !monthlyOngoing && fromDate) {
  //     return ['To', displayDate(fromDate.tz(tz))];
  //   }
  // };

  return (
    <BookingStepWrapper
      back={() => toggleHandler(2)}
      next={handleSubmit}
      current={2}
      error={error}
      isLoading={loading}
    >
      <SummarySubtitle>Confirm payment method</SummarySubtitle>
      <AddPaymentMethodModal updatePaymentMethod={refetchCreditCards} />
      <CreditCardSection>
        <SelectField
          value={selectedPaymentOption}
          handleChange={handlePaymentChange}
          placeholder="VISA"
          options={creditCardOptions}
        />
      </CreditCardSection>
      <SelectedContactNote>
        Please provide a valid credit card to pay for the parking space booking.
        If the pre-filled credit card is invalid or outdated, please update the
        credit card via "Add a new credit card" button below.
      </SelectedContactNote>
      <AddButton
        type="submit"
        onClick={() => handleShowModal('AddPaymentMethod')}
      >
        Add a new credit card
      </AddButton>
      <BreakLine />

      {/* <ConfirmBlock
        attributes={[
          // display localtime on step on since utc time are stored in state
          duration.fromDate && [
            'Booking from',
            displayDate(duration.fromDate.tz(tz)) //TODO
          ],
          determineToDate(
            duration.toDate,
            duration.fromDate,
            tz,
            duration.monthlyOngoing
          )
        ]}
        edit={() => toggleHandler(1)}
      />

      {vehicles && vehicles.selectedOption && (
        <ConfirmBlock
          attributes={[['Vehicle', displayOption(vehicles)]]}
          category="Registration number"
          edit={() => toggleHandler(2)}
        />
      )}

      <ConfirmBlock
        attributes={[['Contact information', displayOption(contacts)]]}
        category="Me"
        edit={() => toggleHandler(2)}
      /> */}

      {preview && (
        <Summary
          bookingLength={get(duration, 'bookingLength')}
          preview={preview}
          monthlyOngoing={get(duration, 'monthlyOngoing')}
          depositValue={depositValue}
          depositUnit={depositUnit}
        />
      )}
    </BookingStepWrapper>
  );
};

export default StepThree;
