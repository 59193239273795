import React from 'react';
import styled from 'styled-components';
import { yellowLight, RubikFontFamily } from '../layout-constants';
import { ImgixUrl } from '../utils';

const ToolTipWrapper = styled.div`
  font-family: ${RubikFontFamily};
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${yellowLight};
  padding: 13px 28px 16px 14px;
  position: relative;
`;

const Close = styled.img`
  width: 14px;
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
`;

export default class ToolTip extends React.Component {
  state = {
    isShow: true
  };

  hideToolTip = () => {
    this.setState({
      isShow: false
    });
  };

  render() {
    const { hasClose, children, ...props } = this.props;
    const { isShow } = this.state;
    return (
      <ToolTipWrapper isShow={isShow} {...props}>
        {children}
        {hasClose && (
          <Close
            src={`${ImgixUrl}/web-image-assets/icons/Escape.svg`}
            onClick={this.hideToolTip}
          />
        )}
      </ToolTipWrapper>
    );
  }
}
