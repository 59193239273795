import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ModifyFeaturesModal } from '../../../../../common/modal';
import {
  defaultBorder,
  midBreakPoint,
  greyDark,
  spacing
} from '../../../../../_shared/layout-constants';
import './FeaturesSection.css';

const Wrapper = styled.div`
  width: 100%;
  min-height: 150px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 160px 1fr;
  grid-template-rows: repeat(3, 40px) minmax(40px, auto) minmax(40px, auto);
  padding: ${spacing(4)} 0;
  @media (min-width: ${midBreakPoint}px) {
    padding: ${spacing(4)} ${spacing(4)} ${spacing(6)} ${spacing(4)};
    border: ${defaultBorder};
    grid-template-columns: 150px 1fr 110px 1fr auto;
    grid-template-rows: 44px 44px minmax(44px, auto) auto;
  }
`;

const SpaceTypeTitle = styled.div`
  grid-column: 1;
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 1;
  }
`;

const SpaceType = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 1;
  }
`;

const Access = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 4;
    grid-row: 1;
  }
`;

const AccessTitle = styled.div`
  grid-column: 1;
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 1;
  }
`;

const MaxHeight = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 2;
  }
`;

const MaxHeightTitle = styled.div`
  grid-column: 1;
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 2;
  }
`;

const Modify = styled.div`
  cursor: pointer;
  grid-row: 7;
  grid-column: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 7;
    grid-row: 1;
  }
`;

const AmenitiesTitle = styled.div`
  grid-column: 1;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 3;
  }
`;

const Amenities = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 7;
    grid-row: 3;
  }
`;

const SuitableVehiclesTitle = styled.div`
  grid-column: 1;
  grid-row: 5;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 4;
  }
`;

const SuitableVehicles = styled.div`
  color: ${greyDark};
  grid-column: 2;
  grid-row: 5;
  margin-bottom: 24px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 7;
    grid-row: 4;
    margin-bottom: 0;
  }
`;

const FeaturesSection = props => {
  const { actions } = useContext(ModalStoreContext);

  const displayArrItems = item => {
    return item
      .map(i => i.name)
      .toString()
      .replace(/['"]+/g, '')
      .replace(/[,]/g, ', ');
  };

  const {
    parkingTypes,
    accessTypes,
    maxHeight,
    amenities,
    suitableVehicles
  } = props.values;

  const { changeFeatures, invalid } = props;
  return (
    <Wrapper>
      <ModifyFeaturesModal
        changeFeatures={changeFeatures}
        values={props.values}
        invalid={invalid}
      />

      <SpaceTypeTitle>Type</SpaceTypeTitle>
      <SpaceType>{parkingTypes && parkingTypes.value}</SpaceType>

      <AccessTitle>Access</AccessTitle>
      <Access>{accessTypes && accessTypes.value}</Access>

      <MaxHeightTitle>Max Height</MaxHeightTitle>
      <MaxHeight>{maxHeight && maxHeight.value}m</MaxHeight>

      <AmenitiesTitle>Amenities</AmenitiesTitle>
      <Amenities>{amenities ? displayArrItems(amenities) : null}</Amenities>

      <SuitableVehiclesTitle>Suitable Vehicles</SuitableVehiclesTitle>
      <SuitableVehicles>
        {suitableVehicles ? displayArrItems(suitableVehicles) : null}
      </SuitableVehicles>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyFeatures')}
        >
          Modify
        </div>
      </Modify>
    </Wrapper>
  );
};

export default FeaturesSection;
