import React from 'react';
import styled from 'styled-components';
import PopupAlert from '../../../../../_shared/components/PopupAlert';
import {
  RubikSemiBold,
  //teal,
  defaultBorder,
  midBreakPoint,
  spacing
} from '../../../../../_shared/layout-constants';
import './MoreOption.css';

const Wrapper = styled.div`
  height: 102px;
  display: grid;
  grid-gap: 0;
  grid-template-rows: 45px 45px 24px;
  grid-template-columns: 99px 27px 72px 1fr;
  width: 100%;
  height: 100%;
  @media (min-width: ${midBreakPoint}px) {
    padding: ${spacing(6)} ${spacing(4)};
    grid-template-columns: 99px 27px 72px 1fr 130px;
    border: ${defaultBorder};
  }
`;

const LiveListingTitle = styled.div`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  font-weight: ${RubikSemiBold};
  padding: 2px 0;
`;

const Description = styled.div`
  font-size: 16px;
  grid-row: 2;
  grid-column: 1 / 5;
`;

const Toggle = styled.div`
  grid-row: 3;
  grid-column: 1;
`;

const MoreOption = props => {
  const { status, toggleListingStatus, toggleEnabled } = props;
  return (
    <Wrapper>
      <PopupAlert
        title="Delete this listing"
        message="Are you sure you need to delete this listing? It is not retractable once deleted."
        button="Yes, delete it"
        type="DeleteListingAlert"
      />

      <LiveListingTitle>Live listings</LiveListingTitle>

      <Description>
        Once deactivated, your listing will be offline and no longer available
        for bookings
      </Description>

      <Toggle>
        <label className="switch">
          <input
            type="checkbox"
            className="listing-detail-more-option-value"
            checked={status}
            onChange={toggleListingStatus}
            disabled={!toggleEnabled}
          />
          <span className="listing-detail-more-option-toggle" />
        </label>
      </Toggle>

      {/* <DeleteListing
        onClick={() => this.props.handleShowModal('DeleteListingAlert')}
      >
        Delete this listing
      </DeleteListing> */}
    </Wrapper>
  );
};

export default MoreOption;
