import React from 'react';
import { createSkeletonProvider } from '@trainline/react-skeletor';
import * as Components from './_styles';
import { StepOne } from '../../../bookingProcess';
import { greyExtraLight } from '../../../_shared/layout-constants';
import { StandardFooter } from '../../../common/components';

const PageBodyLoader = createSkeletonProvider(
  {
    data: {
      title: '_____',
      status: '_________',
      description: '_________',
      maxHeight: '_________',
      priceOptions: '_________',
      accessTypes: '_________',
      parkingTypes: '_________',
      amenities: '_________',
      address: '_________',
      owner: '_________',
      favouritesSet: '_________'
    }
  },
  ({
    title,
    status,
    description,
    maxHeight,
    priceOptions,
    accessTypes,
    parkingTypes,
    amenities,
    address,
    owner,
    SuitableVehicleTypes,
    favouritesSet,
    toggleHandler,
    toggleBookingStep
  }) =>
    title === undefined &&
    status === undefined &&
    description === undefined &&
    maxHeight === undefined &&
    priceOptions === undefined &&
    accessTypes === undefined &&
    parkingTypes === undefined &&
    amenities === undefined &&
    address === undefined &&
    owner === undefined &&
    SuitableVehicleTypes === undefined &&
    favouritesSet === undefined &&
    toggleHandler === undefined &&
    toggleBookingStep === undefined,
  () => ({
    color: greyExtraLight,
    backgroundColor: greyExtraLight
  })
)(({ status, mobileFullScreenDisplay, toggleHandler }) => (
  <Components.PageContent>
    <Components.CarparkDetailsWrapper
      mobileFullScreenDisplay={mobileFullScreenDisplay}
      // innerRef={node => {
      //   this.layoutNode = node;
      // }}
      bookingStepOpened={false}
    >
      <Components.GalleryWrapperLoader />

      <Components.CarparkInfo />

      <Components.ButtonWrapper />

      <Components.MapWrapper />
    </Components.CarparkDetailsWrapper>

    <Components.ResponsiveWrapper
      show={false}
      isStepOne={true}
      // ref={node => {
      //   this.layoutStyledNode = node;
      // }}
      // innerRef={node => {
      //   this.responsiveWrapperNode = node;
      // }}
    >
      <StepOne
        toggleHandler={toggleHandler}
        status={status}
        toggleBookingStep={false}
        showBookingStep={false}
        loader={true}
      />
    </Components.ResponsiveWrapper>
  </Components.PageContent>
));

const CarparkDetailsLoader = () => (
  <Components.LayoutWrapperLoader>
    <PageBodyLoader />
    <Components.Footer>
      <StandardFooter />
    </Components.Footer>
  </Components.LayoutWrapperLoader>
);

export default CarparkDetailsLoader;
