import { greyDark, defaultBorder } from '../../../_shared/layout-constants';

const autoFindStylesWithoutSearchIcon = {
  root: {
    position: 'relative',
    paddingBottom: '0px',
    height: '100%',
    width: '100%'
  },
  input: {
    padding: '0 12px',
    fontSize: '16px',
    color: greyDark,
    width: '100%',
    borderRadius: '28px',
    border: 'none',
    outline: '0',
    textOverflow: 'ellipsis',
    height: '100%'
  },
  autocompleteContainer: {
    backgroundColor: '#FFFFFF',
    marginTop: '10px',
    borderRadius: '24px',
    border: defaultBorder,
    color: greyDark,
    position: 'absolute',
    zIndex: '1',
    padding: '0',
    margin: '24px 0 0 0',
    width: '260px'
  },
  autocompleteItem: {
    height: '54px',
    backgroundColor: '#ffffff',
    padding: '18px 24px 8px 24px',
    color: '#555555',
    cursor: 'pointer',
    borderRadius: '32px'
  },
  autocompleteItemActive: {
    backgroundColor: '#fafafa',
    borderRadius: '28px'
  }
};

export default autoFindStylesWithoutSearchIcon;
