// @flow
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import { ModalStoreContext } from '../../../common/context/modal/store';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/react-hoc';
import { FORGOT_PASSWORD_MUTATION } from '../../../common/graphql/authSchema';
import PopupAlert from '../../../_shared/components/PopupAlert';
import { RubikSemiBold } from '../../../_shared/layout-constants';
import Modal from '../../../_shared/components/ModalWrapper';
import {
  FullWidthSecondaryButton,
  FullWidthPrimaryButton
} from '../../../_shared/components/Buttons';
import { ReduxInputFieldWithIcon } from '../../../_shared/components/FormElements';
import { ImgixUrl } from '../../../_shared/utils';

const ResetPasswordButton = styled(FullWidthPrimaryButton)`
  margin: 24px 0;
  font-weight: ${RubikSemiBold};
`;

const SignupLabel = styled.div`
  justify-self: center;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 12px;
`;

const SignupButton = styled(FullWidthSecondaryButton)`
  font-weight: ${RubikSemiBold};
`;

const BreakLineWrapper = styled.div``;

const validate = values => {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Email format is not valid';
  }

  return errors;
};

const ForgotPasswordModal = props => {
  const [notification, setNotification] = useState(null);
  const {
    state: { activeModal },
    actions: { handleHideModal, handleShowModal }
  } = useContext(ModalStoreContext);

  const forgotPassword = async value => {
    await props
      .forgotPasswordMutation({
        variables: {
          email: value.email
        },
        update: (_, { data: { forgotPassword } }) => {
          if (forgotPassword.payload.__typename === 'ResetPasswordLinkSent') {
            handleHideModal('ForgotPassword');
            handleShowModal('UpdatePasswordAlert');
            setNotification(null);
          } else if (forgotPassword.payload.__typename === 'EmailNotVerified') {
            setNotification(
              'Reset password failed as your email is not verified'
            );
          } else if (
            forgotPassword.payload.__typename === 'EmailNotRegistered'
          ) {
            setNotification(
              'Reset password failed as your email is not registered'
            );
          }
        }
      })
      .catch(err => {
        setNotification(null);
        console.log(err);
      });
  };
  const isOpen = activeModal.includes('ForgotPassword');
  const { handleSubmit } = props;
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <div>
      <PopupAlert
        contentLabel="UpdatePasswordAlert"
        type="UpdatePasswordAlert"
        title="Update password"
        message="Please check your email for a link to reset your new password."
        button="OK"
      />
      <Modal
        isAuth={true}
        title="Let's get you back in!"
        handleSubmit={handleSubmit(forgotPassword)}
        handleClose={() => handleHideModal('ForgotPassword')}
        contentLabel="Login Modal"
        isOpen={isOpen}
        notification={notification}
      >
        <Field
          name="email"
          label="Email"
          component={ReduxInputFieldWithIcon}
          icon={`${ImgixUrl}/web-image-assets/icons/Email.svg`}
        />

        <ResetPasswordButton type="submit" onClick={forgotPassword}>
          Reset password
        </ResetPasswordButton>

        <BreakLineWrapper>
          <hr />
        </BreakLineWrapper>

        <SignupLabel>Don't have an account?</SignupLabel>

        <SignupButton
          type="submit"
          onClick={() => {
            handleHideModal('ForgotPassword');
            handleShowModal('Signup');
          }}
        >
          Sign up
        </SignupButton>
      </Modal>
    </div>
    /* eslint-enable */
  );
};

const formOptions = {
  form: 'SignupModal',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(FORGOT_PASSWORD_MUTATION, { name: 'forgotPasswordMutation' })
)(ForgotPasswordModal);
