import React, { createContext, useState } from 'react';

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const { Provider } = UserContext;
  const [user, setUser] = useState(null);

  return <Provider value={{ user, setUser }}>{children}</Provider>;
};

export { UserContext, UserContextProvider };
