import React from 'react';
import styled from 'styled-components';
import { midBreakPoint, greyLight } from '../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../_shared/utils';
import './index.css';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 12px 400px 123px 144px 198px 50px auto 24px;
  grid-template-rows: 80px;
  min-height: 80px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 24px 400px 144px 123px 198px 50px auto 24px;
  }
`;

const CompanyLogo = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin-top: 30px;
`;

const Career = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 4;
    grid-row: 1;
    margin-top: 30px;
  }
`;

const ContactUs = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 3;
    grid-row: 1;
    margin-top: 30px;
  }
`;

const TermsConditions = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 5;
    grid-row: 1;
    margin-top: 30px;
  }
`;

const Privacy = styled.div`
  display: none;
  @media (min-width: ${midBreakPoint}px) {
    display: block;
    grid-column: 6;
    grid-row: 1;
    margin-top: 30px;
  }
`;

const Hr = styled.hr`
  width: 100%;
  border: 0;
  height: 1;
  grid-row: 1;
  border-top: 1px solid ${greyLight};
  margin: 0;
  grid-column: 1 / 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 8;
  }
`;

export default ({ ...props }) => (
  <Wrapper {...props}>
    <Hr />
    <CompanyLogo>
      <a className="simplified-footer-link" href="/">
        <img
          className="simplified-footer-quokka-icon"
          src={`${ImgixUrl}/web-image-assets/icons/Quokka-icon-footer-black.svg`}
          alt="quokka placeholder"
        />
        <div className="simplified-footer-quokka-technology-company">
          All rights reserved © Quokka Parking 2022
        </div>
      </a>
    </CompanyLogo>

    <ContactUs>
      <a className="simplified-footer-link" href="/contact-us">
        Contact us
      </a>
    </ContactUs>

    <Career>
      <a
        className="simplified-footer-link"
        href="https://quokkatechnology.zohorecruit.com/recruit/Portal.na?digest=RY9VVYVIaZ6q1gvIcSfaeHGAUnkE7bVo.a85bxdBEmQ-&iframe=false&mode=register&embedsource=CareerSite"
        target="_blank"
        rel="noopener noreferrer"
      >
        Career
      </a>
    </Career>

    <TermsConditions>
      <a className="simplified-footer-link" href="/terms-and-conditions">
        Terms and conditions
      </a>
    </TermsConditions>

    <Privacy>
      <a className="simplified-footer-link" href="/privacy-policy">
        Privacy
      </a>
    </Privacy>
  </Wrapper>
);
