import CryptoJS from 'crypto-js';

const KEY = '63K2AfAos6NMNvEHddpjbTPSk7KBcZHrj';

export const decryptEnvVar = r => {
  if (typeof r !== 'undefined') {
    return CryptoJS.AES.decrypt(r.toString(), KEY).toString(CryptoJS.enc.Utf8);
  }
};

export const envVar = name => {
  if (typeof window !== 'undefined') {
    return `${process.env[name] ||
      (window && window.config && window.config[name])}`;
  }
};
