import { types } from './reducers';

export const useActions = (state, dispatch) => {
  const handleShowModal = (modal, msg, interestedAddress) => {
    dispatch({ type: types.SHOW_MODAL, modal, msg, interestedAddress });
  };
  const handleHideModal = (modal, msg, interestedAddress) => {
    dispatch({ type: types.HIDE_MODAL, modal, msg, interestedAddress });
  };
  const handleInterestedAddress = interestedAddress => {
    dispatch({ type: types.INTERESTED_ADDRESS, interestedAddress });
  };
  return {
    handleShowModal,
    handleHideModal,
    handleInterestedAddress
  };
};
