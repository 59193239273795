import React, { Component } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import {
  greyDark,
  greyExtraLight,
  grey,
  midBreakPoint,
  teal
} from '../../layout-constants';
import { isDeviceMobile } from '../../utils';
import './NavHeader.css';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 27px 2px;
  grid-template-columns: auto;
  height: 30px;
  margin-bottom: 24px;
`;

const Navs = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 12px 0;

  @media (min-width: ${midBreakPoint}px) {
    height: 44px;
  }
`;

const NavLinks = styled.div``;

const NavLink = styled.a`
  display: inline;
  margin-right: 18px;
  height: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${greyDark};
  text-decoration: none;
  font-size: 12px;

  &:hover {
    color: ${grey};
    cursor: pointer;
  }

  @media (min-width: ${midBreakPoint}px) {
    margin-right: 48px;
    height: 20px;
    font-size: 16px !important;
  }
`;

const Switch = styled.div``;

const SwitchButton = styled.a`
  cursor: pointer;
  color: ${teal};

  &:hover {
    color: ${grey};
    cursor: pointer;
  }
`;

const SwitchIcon = styled.img`
  width: 30px;
  height: 20px;
  filter: invert(0.4) sepia(0.6) saturate(5) hue-rotate(175deg);
  margin: 0 6px 4px 0;
`;

const Breakline = styled.hr`
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  border: 0;
  height: 0;
  border-top: 2px solid ${greyExtraLight};
  box-shadow: inset 0 -2px 0 0 #d6d9da;
  z-index: -1;
`;

export default class NavHeader extends Component {
  render() {
    const {
      toggleNav,
      active,
      toggleItems,
      switchView,
      switchViewUrl,
      switchViewIcon
    } = this.props;

    return (
      <Wrapper>
        <Navs>
          <NavLinks>
            {toggleItems &&
              toggleItems.map(t => (
                <NavLink
                  key={shortid.generate()}
                  onClick={() => toggleNav(t.toLowerCase().replace(/\s/g, ''))}
                  className={
                    active.toLowerCase().replace(/\s/g, '') ===
                    t.toLowerCase().replace(/\s/g, '')
                      ? 'nav-item-active'
                      : ''
                  }
                >
                  {t}
                </NavLink>
              ))}
          </NavLinks>
          {switchView && switchViewUrl && switchViewIcon && !isDeviceMobile() && (
            <Switch>
              <SwitchIcon src={switchViewIcon} alt="switch icon" />
              <SwitchButton
                onClick={() => (window.location.href = switchViewUrl)}
              >
                {switchView}
              </SwitchButton>
            </Switch>
          )}
        </Navs>
        <Breakline />
      </Wrapper>
    );
  }
}
