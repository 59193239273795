import React, { Component } from 'react';
import styled from 'styled-components';
import {
  graphite,
  aqua,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';
import { ImgixUrl } from '../../../_shared/utils';

const StationIconText = createSkeletonElement(styled.div`
  vertical-align: middle;
  background-color: ${({ outline }) => (outline ? 'none' : aqua)};
  border: ${({ outline }) => (outline ? `1px solid ${graphite}` : 'none')};
  color: #ffffff;
  border-radius: 3px;
  width: fit-content;
  padding: ${({ iconName }) => (iconName ? '5px 8px' : '4px')};
  display: -webkit-flex; /* Safari */
  -webkit-align-items: flex-start; /* Safari 7.0+ */
  display: inline-flex;
  /* margin: 0 0 10px 0; */
  @media (min-width: ${midBreakPoint}px) {
    padding: ${({ iconName }) => (iconName ? '6px 10px' : '3px 6px')};
  }
`);

const StationIcon = styled.img`
  width: 16px;
  @media (min-width: ${midBreakPoint}px) {
    width: ${({ iconName }) => (iconName ? '16px' : '25px')};
  }
`;

const Station = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-align-self: center; /* Safari 7.0+ */
  align-self: center;
  padding: 1px 0 0 8px;
  height: 17px;
  font-size: 14px;
  color: ${graphite};
`;

export default class StationIconWrapper extends Component {
  render() {
    const {
      iconPath,
      iconName,
      outline = false,
      noIcon,
      ...otherProps
    } = this.props;

    return (
      <StationIconText iconName={iconName} outline={outline} {...otherProps}>
        {!noIcon ? (
          <StationIcon
            iconName={iconName}
            src={`${ImgixUrl}/web-image-assets/icons/${iconPath}`}
            alt="transportation icon and text"
          />
        ) : null}
        {!noIcon && iconName ? <Station>{iconName}</Station> : null}
      </StationIconText>
    );
  }
}
