import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { ModalStoreContext } from '../../../common/context/modal/store';
import './index.css';
import { Field } from 'redux-form';
import { debounce, get } from 'lodash';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import Modal from '../../../_shared/components/ModalWrapper';
import { DRAFT_PARKING_WEEKLY_PRICE } from '../../../common/graphql/adminSchema';

const PriceSectionWrapper = styled.div``;

const DailyPricingSection = styled.div`
  height: 0;
`;

const PricingSection = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PerRateUnit = styled.div`
  width: 100px;
  margin: 45px 0 0 12px;
`;

const ModifyPricesModal = props => {
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const isOpen = activeModal.includes('ModifyPrices');

  const {
    isPublic,
    parkingId,
    change,
    values,
    changePricing,
    //redux-form props
    invalid
  } = props;

  const [getDraftWeeklyValue, { loading, data }] = useLazyQuery(
    DRAFT_PARKING_WEEKLY_PRICE
  );
  const changeWeeklyPrice = value =>
    getDraftWeeklyValue({
      variables: { dailyPrice: value, draftParkingId: parkingId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache' // TODO: follow up this issue
    });
  const debounceChangeWeeklyPrice = debounce(changeWeeklyPrice, 500);

  const debounceChangeMonthlyWeeklyPrice = (event, value) => {
    debounceChangeWeeklyPrice((value * 12) / 365);
  };

  const weeklyTotal = get(data, 'draftParkingWeeklyPrice.total');
  useEffect(() => {
    if (!isPublic && !loading && weeklyTotal) {
      change('weekly', `${weeklyTotal}`);
      change('daily', `${weeklyTotal / 7 / 0.9 / 1.2}`);
    }
  }, [weeklyTotal, isPublic, change, loading]);

  const updatePublicPrices = value => {
    change('weekly', `${((value * 12) / 52).toFixed(2)}`);
    change('daily', `${((value * 12) / 52 / 7 / 0.9 / 1.2).toFixed(2)}`);
  };

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Modify pricing options"
      handleClose={() => handleHideModal('ModifyPrices')}
      contentLabel="Modify Prices Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      handleSubmit={() => {
        if (invalid) return;
        changePricing({ isReview: true, ...values });
        handleHideModal('ModifyPrices');
      }}
      buttonDisabled={invalid}
      isForm={false}
    >
      <PriceSectionWrapper>
        <DailyPricingSection>
          <Field
            style={{ maxWidth: 190 }}
            name="daily"
            label="Daily rate ($)"
            component={ReduxInputField}
            disabled
            type="hidden"
          />
          {/* <PerRateUnit>per day</PerRateUnit> */}
        </DailyPricingSection>

        <PricingSection>
          <Field
            style={{ maxWidth: 190 }}
            name="monthly"
            label="Monthly rate ($)"
            component={ReduxInputField}
            onChange={(_, value) => {
              isPublic
                ? updatePublicPrices(value)
                : debounceChangeMonthlyWeeklyPrice(value);
            }}
          />
          <PerRateUnit>per month</PerRateUnit>
        </PricingSection>

        <PricingSection>
          <Field
            style={{ maxWidth: 190 }}
            name="weekly"
            label="Weekly rate ($)"
            disabled
            component={ReduxInputField}
          />
          <PerRateUnit>per week</PerRateUnit>
        </PricingSection>

        <div className="modify-prices-breakline-margin">
          <hr />
        </div>

        <PricingSection>
          <Field
            style={{ maxWidth: 190, marginTop: 30 }}
            name="deposit"
            label="Security bond"
            component={ReduxInputField}
          />
        </PricingSection>
      </PriceSectionWrapper>
    </Modal>
  );
};

export default ModifyPricesModal;
