import styled from 'styled-components';
import { midBreakPoint, spacing } from '../../../_shared/layout-constants';

export const InfoCell = styled.div`
  margin: 12px 0;
  text-align: ${({ align }) => (align ? `${align}` : 'left')};
  padding: 0 ${spacing(4)};
  width: 100%;
  @media (min-width: ${midBreakPoint}px) {
    width: 170px;
    padding: 0;
  }
`;
