import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFormValues, isInvalid } from 'redux-form';
import {
  LocationSection,
  FeaturesSection,
  DetailsSection,
  ContactSection,
  PriceSection,
  AvailabilitySection
} from '../../../admin/createListing/review';
import { CheckBox } from '../../../_shared/components/FormElements';
import { midBreakPoint, teal } from '../../../_shared/layout-constants';
import { AdminSubtitle } from '../../_shared/styles';
import { ImgixUrl } from '../../../_shared/utils';

const ReviewContent = styled.div``;

const ReviewSection = styled.div`
  margin-bottom: 30px;
`;

const Subtitle = styled(AdminSubtitle)`
  margin-bottom: 26px;
  justify-content: space-between;
  display: flex;
`;

const SectionWrapper = styled.div`
  @media (max-width: ${midBreakPoint}px) {
    display: ${({ show }) => (show ? 'inline' : 'none')};
  }
`;

const Hr = styled.hr`
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: ${({ show }) => (show ? 'none' : 'block')};
  }
`;

const ArrowIcon = styled.img`
  width: 32px;
  display: none;
  @media (max-width: ${midBreakPoint}px) {
    display: inline;
    transform: ${({ show }) => (show ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;

const Agreement = styled.div`
  a,
  a:hover {
    text-decoration: underline;
    color: ${teal};
  }
  margin-top: 14px;
  margin-bottom: 24px;
`;

const ReviewSectionWrapper = ({
  section,
  displayArrow,
  toggleState,
  children
}) => (
  <ReviewSection>
    <Subtitle onClick={() => toggleState(`show${section}`)}>
      {section}
      <ArrowIcon
        show={displayArrow}
        src={`${ImgixUrl}/web-image-assets/icons/collapse_arrow.svg`}
      />
    </Subtitle>
    <SectionWrapper show={displayArrow}>{children}</SectionWrapper>
    <Hr />
  </ReviewSection>
);

class Review extends Component {
  state = {
    showLocation: true,
    showFeature: true,
    showDetails: true,
    showContact: true,
    showPricing: true,
    showAvailability: true,
    agree: false
  };

  toggleState = stateName => {
    this.setState({
      [stateName]: !this.state[stateName]
    });
  };

  render() {
    const {
      values,
      invalid,
      updateFormValue,
      change,
      composeFeaturesFormValues,
      composeLocationFormValues,
      composeDetailsFormValues,
      composeContactFormValues,
      composePricingFormValues,
      composeAvailabilityFormValues,
      parkingId,
      isPublic,
      updateState,
      agree
    } = this.props;

    const {
      showLocation,
      showFeature,
      showDetails,
      showContact,
      showPricing,
      showAvailability
    } = this.state;

    // this logic is for listing details page
    if (updateState) {
      if (!values) {
        updateState({
          loading: true
        });
        return <div style={{ marginBottom: 24 }}>loading..</div>;
      } else {
        updateState({
          loading: false
        });
      }
    }

    return (
      <ReviewContent>
        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Location"
          displayArrow={showLocation}
        >
          <LocationSection
            address={
              this.props.composedValues && this.props.composedValues.address
            }
            values={values}
            changeLocation={composeLocationFormValues}
            invalid={invalid}
          />
        </ReviewSectionWrapper>

        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Feature"
          displayArrow={showFeature}
        >
          <FeaturesSection
            values={values}
            changeFeatures={composeFeaturesFormValues}
            invalid={invalid}
          />
        </ReviewSectionWrapper>

        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Details"
          displayArrow={showDetails}
          invalid={invalid}
        >
          <DetailsSection
            isPublic={isPublic}
            parkingId={parkingId}
            values={values}
            change={change}
            changeDetails={composeDetailsFormValues}
            invalid={invalid}
          />
        </ReviewSectionWrapper>

        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Contact"
          displayArrow={showContact}
        >
          <ContactSection
            values={values}
            changeContact={composeContactFormValues}
            invalid={invalid}
          />
        </ReviewSectionWrapper>

        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Pricing"
          displayArrow={showPricing}
        >
          <PriceSection
            values={values}
            changePricing={composePricingFormValues}
            invalid={invalid}
            change={change}
            isPublic={isPublic}
            parkingId={parkingId}
          />
        </ReviewSectionWrapper>

        <ReviewSectionWrapper
          toggleState={this.toggleState}
          section="Availability"
          displayArrow={showAvailability}
        >
          <AvailabilitySection
            updateFormValue={updateFormValue}
            change={change}
            values={values}
            changeAvailability={composeAvailabilityFormValues}
            invalid={invalid}
          />
        </ReviewSectionWrapper>
        {!isPublic && (
          <Agreement>
            <CheckBox
              style={{ display: 'inline' }}
              checked={agree}
              handleChange={() =>
                updateState({
                  agree: !agree
                })
              }
            />
            By creating the listing, I agree on the{' '}
            <Link to="/terms-and-conditions">terms and conditions</Link>
            {' and '}
            <Link to="/privacy-policy">privacy policy</Link> of Quokka Parking
          </Agreement>
        )}
      </ReviewContent>
    );
  }
}

export default connect((state, ownProps) => ({
  values: getFormValues(ownProps.name)(state),
  invalid: isInvalid(ownProps.name)(state)
}))(Review);
