import React, { Fragment, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { teal, graphite, spacing } from '../../../../_shared/layout-constants';
import PopupAlert from '../../../../_shared/components/PopupAlert';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import {
  DELETE_CREDIT_CARD,
  SET_DEFAULT_RESOURCE
} from '../../../../common/graphql/adminSchema';
import { renderCardIssuerLogo } from '../../../../_shared/utils';

const Wrapper = styled.div`
  width: 285px;
  height: 187px;
  border-radius: 6px;
  background-color: #fff;
  border: solid 2px ${graphite};
  padding: ${spacing(4)};
  display: grid;
  grid-gap: 0;
  grid-template-columns: 96px 80px 61px;
  grid-template-rows: 36px 20px 20px 43px 20px;
`;

const Logo = styled.img`
  grid-column: 1;
  grid-row: 1;
  width: 50px;
  height: 36px;
`;

const CardNumber = styled.div`
  grid-column: 1 / 4;
  grid-row: 3;
  height: 24px;
  font-size: 20px;
`;

const Default = styled.div`
  grid-column: 1;
  grid-row: 5;
  width: 55px;
  height: 20px;
`;

const MakeDefault = styled.a`
  grid-column: 1;
  grid-row: 5;
  width: 96px;
  height: 20px;
  color: ${teal};
  cursor: pointer;
`;

const Remove = styled.a`
  grid-column: 3;
  grid-row: 5;
  width: 61px;
  height: 20px;
  color: ${teal};
  cursor: pointer;
`;

const PaymentMethod = props => {
  const {
    actions: { handleShowModal, handleHideModal }
  } = useContext(ModalStoreContext);

  const [deleteCreditCardMutation] = useMutation(DELETE_CREDIT_CARD, {
    errorPolicy: 'all'
  });

  const [setDefaultResourceMutation] = useMutation(SET_DEFAULT_RESOURCE, {
    errorPolicy: 'all'
  });

  const deleteCreditCard = async () => {
    try {
      await deleteCreditCardMutation({
        variables: {
          id: props.creditCardId
        }
      });
      props.setErrorMsg(null);
      props.updatePaymentOption && props.updatePaymentOption();
    } catch (e) {
      const errorMsg = e.message && e.message.split(': ')[1]; // get rid of "Graphql error: "
      props.setErrorMsg(errorMsg);
    }
  };

  const makeCreditCardDefault = async () => {
    try {
      await setDefaultResourceMutation({
        variables: {
          id: props.creditCardId,
          type: 'PAYMENT_METHOD'
        }
      });
      props.setErrorMsg(null);
      if (props.updatePaymentOption) {
        props.updatePaymentOption();
      }
    } catch (e) {
      props.setErrorMsg(
        'Failed to make this payment method default. Please try again.'
      );
    }
  };

  // backend will have datamasked the card number, but here is just for a guarantee
  const encryptCardNumbers = numbers => {
    return 'xxxx xxxx xxxx '.concat(
      numbers.toString().slice(numbers.length - 4, numbers.length)
    );
  };

  const handleShowDeleteDefaultCreditCardAlert = () => {
    handleShowModal('deleteDefaultCreditCardAlert');
  };

  const handleHideDeleteDefaultCreditCardAlert = () => {
    handleHideModal('deleteDefaultCreditCardAlert');
  };

  const handleShowDeleteAlert = numberOfCreditCards => {
    // do not allow users to remove last default credit card
    //if (numberOfCreditCards <= 1) {

    // allow users to remove last default credit card
    if (numberOfCreditCards <= 0) {
      handleShowDeleteDefaultCreditCardAlert();
    } else {
      handleShowModal('deleteCreditCardAlert');
    }
  };

  const handleContinueDeletion = () => {
    deleteCreditCard();
    handleHideDeleteDefaultCreditCardAlert();
  };

  const { logo, cardNumber, defaultStatus, numberOfCreditCards } = props;

  return (
    <Fragment>
      <PopupAlert
        isAlert
        contentLabel="deleteCreditCardAlert"
        type="deleteCreditCardAlert"
        title="Delete credit card"
        message="Are you sure to delete this credit card?"
        button="Delete"
        secondaryButton="Cancel"
        disableClose={false}
        handleClick={deleteCreditCard}
      />
      <PopupAlert
        isAlert
        contentLabel="deleteDefaultCreditCardAlert"
        type="deleteDefaultCreditCardAlert"
        title="Delete default credit card"
        message="Sorry, you cannot remove default credit card as there could be ongoing payment if you have a monthly booking."
        button="Delete"
        secondaryButton="Understood"
        disableClose={false}
        handleClick={handleContinueDeletion}
      />
      <Wrapper>
        <Logo {...renderCardIssuerLogo(logo)} />

        <CardNumber>{encryptCardNumbers(cardNumber)}</CardNumber>

        {defaultStatus ? (
          <Default>Default</Default>
        ) : (
          <MakeDefault onClick={makeCreditCardDefault}>
            Make default
          </MakeDefault>
        )}

        <Remove onClick={() => handleShowDeleteAlert(numberOfCreditCards)}>
          Remove
        </Remove>
      </Wrapper>
    </Fragment>
  );
};

export default PaymentMethod;
