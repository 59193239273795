import React, { Component } from 'react';
import styled from 'styled-components';
import { greyDark } from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';

const AddressWrapper = createSkeletonElement(styled.div`
  color: ${greyDark};
  text-align: left;
`);

export default class Address extends Component {
  render() {
    return <AddressWrapper>{this.props.value}</AddressWrapper>;
  }
}
