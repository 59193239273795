import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import {
  teal,
  greyLight,
  midBreakPoint,
  greyExtraLight
} from '../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../_shared/utils';
import './AddVehicleButton.css';

const Wrapper = styled.a`
  height: 80px;
  color: #ffffff;
  text-decoration: none !important;
  border: 1px dashed ${greyLight};
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr auto 12px auto 1fr;
  grid-template-rows: 24px 32px 24px;
  margin: 24px 0 0 0;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 32px 12px 150px 1fr;
  }
  &:hover {
    background-color: ${greyExtraLight};
  }
`;

const AddIcon = styled.img`
  grid-column: 2;
  grid-row: 2;
  width: 25px;
  @media (min-width: ${midBreakPoint}px) {
    width: 32px;
  }
`;

const AddVehicleText = styled.div`
  grid-column: 4;
  grid-row: 2;
  color: ${teal};
  padding: 3px 0;
  cursor: pointer;
  @media (min-width: ${midBreakPoint}px) {
    padding: 6px 0;
    width: 150px;
  }
`;

const AddVehicleButton = () => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);
  return (
    <Wrapper onClick={() => handleShowModal('AddVehicle')}>
      <AddIcon
        src={`${ImgixUrl}/web-image-assets/icons/create-new-listing.svg`}
        alt="create a new listing icon"
      />
      <AddVehicleText>Add a new vehicle</AddVehicleText>
    </Wrapper>
  );
};

export default AddVehicleButton;
