import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { graphql } from '@apollo/react-hoc';
import {
  greyDark,
  defaultBorder,
  lightBoxShadow,
  greyExtraLight,
  graphite
} from '../../../_shared/layout-constants';
import { GET_USER_INFO } from '../../../common/graphql/adminSchema';
import { UserContext } from '../../../common/context/user';
import { ImgixUrl } from '../../../_shared/utils';

const ProfileLink = styled.button`
  border: 0px;
  color: ${greyDark};
  display: flex;
  background-color: white;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

const ProfilePic = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  object-fit: cover;
  margin-left: 7px;
`;

const DropdownWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: ${lightBoxShadow};
  border: ${defaultBorder};
  position: absolute;
  min-height: 100px;
  right: 12px;
  top: 83px;
  width: 192px;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const DropdownText = styled(Link)`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  &:hover {
    background-color: ${greyExtraLight};
  }
`;

const Greeting = styled.span`
  color: ${graphite};
  &:hover {
    color: ${greyDark};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ProfileDropdown = props => {
  const [showDropdownArea, setShowDropdownArea] = useState(false);

  const handleDropdownClickOpen = e => {
    e.preventDefault();
    setShowDropdownArea(true);
  };

  const handleDropdownClickClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropdownArea(false);
  };

  const handleDropdownItemClick = to => {
    window.location.href = to;
  };

  const ProfileDropdownMenu = () => (
    <div>
      <Overlay onClick={handleDropdownClickClose} />
      <DropdownWrapper>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/profile');
          }}
        >
          Profile
        </DropdownText>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/bookings/my-bookings');
          }}
        >
          Bookings
        </DropdownText>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/my-listings');
          }}
        >
          My Listings
        </DropdownText>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/favourite-bookings');
          }}
        >
          Favourite
        </DropdownText>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/billings');
          }}
        >
          Billing
        </DropdownText>
        <DropdownText
          onClick={e => {
            handleDropdownClickClose(e);
            handleDropdownItemClick('/admin/settings');
          }}
        >
          Settings
        </DropdownText>
        <DropdownText to="" onClick={props.handleLogout}>
          Log out
        </DropdownText>
      </DropdownWrapper>
    </div>
  );
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (props.getUserInfo.getUserInfo) {
      setUser(props.getUserInfo.getUserInfo);
    }
  }, [setUser, props.getUserInfo.getUserInfo]);

  return (
    <ProfileLink onClick={handleDropdownClickOpen}>
      <Greeting>Hey, {user && user.firstName}</Greeting>
      <span>
        <ProfilePic
          className="search-header-profile-image-placeholder"
          src={`${ImgixUrl}/${user && user.id}/profile/profile.png`}
          onError={e => {
            e.target.onerror = null;
            e.target.src = `${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder-grey.svg`;
          }}
          alt="profile"
        />
      </span>
      {showDropdownArea ? ProfileDropdownMenu() : null}
    </ProfileLink>
  );
};

export default graphql(GET_USER_INFO, {
  name: 'getUserInfo',
  options: {
    fetchPolicy: 'network-only'
  }
})(ProfileDropdown);
