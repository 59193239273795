import styled from 'styled-components';
import {
  RubikFontFamily,
  RubikSemiBold,
  navy
} from '../../../_shared/layout-constants';

export const Info = styled.div`
  height: 40px;
  font-family: ${RubikFontFamily};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${RubikSemiBold};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: ${navy};
  padding-top: ${({ extraMargin }) =>
    extraMargin ? `${extraMargin + 12}px` : '12px'};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;
