import styled from 'styled-components';
import { defaultBorder, teal, RubikFontFamily } from '../layout-constants';

export const BreakLine = styled.hr`
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  border: 0;
  height: 0;
  border-top: ${defaultBorder};
`;

export const HyperLink = styled.a`
  color: ${teal};
  font-family: ${RubikFontFamily};
  cursor: pointer;
`;

export const P1 = styled.p`
  font-size: 40px;
  font-family: ${RubikFontFamily};
`;

export const P2 = styled.p`
  font-size: 26px;
  font-family: ${RubikFontFamily};
`;

export const P3 = styled.p`
  font-size: 16px;
  font-family: ${RubikFontFamily};
`;
