import React, { useContext, useState } from 'react';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import {
  GET_PARKING_SPACES,
  ADD_PARKING_DRAFT,
  ADD_PARKING_SPACE,
  UPDATE_USER_INFORMATION
} from '../../../common/graphql/adminSchema';
import {
  ALL_VEHICLE_TYPES,
  ALL_AMENITIES,
  ALL_HEIGHT_OPTIONS,
  ALL_ACCESS_TYPES,
  ALL_PARKING_TYPES
} from '../../../common/graphql/carparkSchema';
import AdminWrapper from '../../_shared/components/AdminWrapper';
import validator from './_validation';
import { UserContext } from '../../../common/context/user';
import CreateListingForm from './_form';
import { localData } from '../../../_shared/local-storage';
import {
  PARKING_DRAFT_KEY,
  PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY
} from './_constants';

const CreateListingFormWithProps = props => {
  const { user } = useContext(UserContext);
  const {
    allParkingTypes,
    allAccessTypes,
    allHeightOptions,
    allAmenities,
    allVehicleTypes,
    getParkingSpaces
  } = props;
  if (
    !user ||
    allParkingTypes.loading ||
    allAccessTypes.loading ||
    allHeightOptions.loading ||
    allAmenities.loading ||
    allVehicleTypes.loading ||
    getParkingSpaces.loading
  ) {
    props.setLoading(true);
    return null;
  } else {
    props.setLoading(false);
    return <CreateListingForm user={user} {...props} />;
  }
};

const CreateListingPage = props => {
  const [loading, setLoading] = useState(false);
  return (
    <AdminWrapper loading={loading}>
      <CreateListingFormWithProps
        setLoading={setLoading}
        {...props}
        localData={
          new localData(PARKING_DRAFT_KEY, [
            PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY
          ])
        }
      />
    </AdminWrapper>
  );
};

export default compose(
  graphql(GET_PARKING_SPACES, { name: 'getParkingSpaces' }),
  graphql(UPDATE_USER_INFORMATION, { name: 'updateUserInformation' }),
  graphql(ADD_PARKING_DRAFT, { name: 'addParkingDraft' }),
  graphql(ADD_PARKING_SPACE, { name: 'addParkingSpace' }),
  graphql(ALL_PARKING_TYPES, { name: 'allParkingTypes' }),
  graphql(ALL_ACCESS_TYPES, { name: 'allAccessTypes' }),
  graphql(ALL_HEIGHT_OPTIONS, { name: 'allHeightOptions' }),
  graphql(ALL_AMENITIES, { name: 'allAmenities' }),
  graphql(ALL_VEHICLE_TYPES, { name: 'allVehicleTypes' }),
  connect(state => ({
    formSyncErrors: getFormSyncErrors('createListingForm')(state)
  })),
  reduxForm({ form: 'createListingForm', validate: validator })
)(CreateListingPage);
