import React from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import 'antd/dist/antd.css';
import Routes from './Routes';
import { App } from './components';
import { ModalStoreProvider } from './context/modal/store';
import { UserContextProvider } from './context/user';
import { MediaContextProvider } from './context/media';
import { GlobalStyle } from '../_shared/global-styles';

const Root = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <MediaContextProvider>
        <UserContextProvider>
          <ModalStoreProvider>
            <ConfigProvider locale={enUS}>
              <App>
                <Routes />
              </App>
            </ConfigProvider>
          </ModalStoreProvider>
        </UserContextProvider>
      </MediaContextProvider>
    </React.Fragment>
  );
};

export default Root;
