import React, { Component } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { Icon } from '../../../carpark';
import VehicleInfo from '../../../common/components/vehiclesInfo';
import {
  RubikSemiBold,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { createSkeletonElement } from '@trainline/react-skeletor';
import { ImgixUrl } from '../../../_shared/utils';

const SuitableVehicleWrapper = styled.div`
  padding: 24px 24px 12px 24px;
  @media (min-width: ${midBreakPoint}px) {
    padding: 24px 24px 6px 24px;
  }
`;

const Title = createSkeletonElement(styled.h2`
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
  text-align: left;
  margin: 0 0 40px 0;
`);

const IconsWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const InfoIcon = styled.img`
  width: 20px;
  margin-left: 12px;
  cursor: pointer;
`;

export default class SuitableVehicle extends Component {
  render() {
    const { vehicles, showVehicleInfo, toggleVehicleInfo } = this.props;

    return (
      <SuitableVehicleWrapper>
        <VehicleInfo
          isShow={showVehicleInfo}
          hide={() => toggleVehicleInfo(false)}
        />

        <Title>
          <span>This parking space is suitable for</span>
          <InfoIcon
            src={`${ImgixUrl}/web-image-assets/icons/info.png`}
            alt="info"
            onClick={() => toggleVehicleInfo(true)}
          />
        </Title>

        <IconsWrapper>
          {vehicles &&
            vehicles.length > 0 &&
            vehicles.map(v => (
              <Icon
                key={shortid.generate()}
                iconName={v.name}
                pathType="vehicles"
              />
            ))}
        </IconsWrapper>
      </SuitableVehicleWrapper>
    );
  }
}
