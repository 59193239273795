import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import { teal, greyExtraLight } from '../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../_shared/utils';
import { SmallSecondaryButton } from '../../../../_shared/components/Buttons';
import './AddListingButton.css';

const Wrapper = styled.div`
  width: 275px;
  height: 432px;
  color: #ffffff;
  text-decoration: none !important;
  border: 1px dashed #c7c7c7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 36px;
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    background-color: ${greyExtraLight};
  }
`;

const AddButton = styled.div`
  display: flex;
  margin-top: 16px;
`;

const ParkingIcon = styled.img`
  grid-column: 3;
  grid-row: 1;
  width: 76px;
  height: 76px;
`;

const AddIcon = styled.img`
  grid-column: 1;
  grid-row: 3;
  width: 32px;
  height: 32px;
`;

const CreateListingText = styled.div`
  grid-column: 2 / 5;
  grid-row: 3;
  padding: 4px 0 4px 8px;
  width: 100%;
  font-size: 20px;
  color: ${teal};
`;

const AddListingButton = ({
  hasPhoneNumber,
  hasPaymentMethod,
  hasPayoutOption,
  inline = false
}) => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);

  const handleClick = (hasPhoneNumber, hasPaymentMethod, hasPayoutOption) => {
    if (!hasPhoneNumber) {
      handleShowModal('VerifyListingModal');
      // if it's lister, we only require accurate payout option
      //} else if (!hasPaymentMethod || !hasPayoutOption) {
    } else if (!hasPayoutOption) {
      handleShowModal('AddBillingInfoAlert');
    } else {
      window.location.href = '/admin/create-listing';
    }
  };

  return inline ? (
    <SmallSecondaryButton
      onClick={() =>
        handleClick(hasPhoneNumber, hasPaymentMethod, hasPayoutOption)
      }
      style={{ marginBottom: 24 }}
    >
      Create a new listing
    </SmallSecondaryButton>
  ) : (
    <div
      onClick={() =>
        handleClick(hasPhoneNumber, hasPaymentMethod, hasPayoutOption)
      }
    >
      <Wrapper>
        <ParkingIcon
          src={`${ImgixUrl}/web-image-assets/branding/Logomark-P-blue.svg`}
          alt="create a new listing icon"
        />
        <AddButton>
          <AddIcon
            src={`${ImgixUrl}/web-image-assets/icons/create-new-listing.svg`}
            alt="create a new listing icon"
          />

          <CreateListingText>Create a new listing</CreateListingText>
        </AddButton>
      </Wrapper>
    </div>
  );
};

export default AddListingButton;
