import React, { Component } from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Row, Col } from 'react-flexa';
import debounce from 'lodash/debounce';
import {
  greyDark,
  defaultBorder,
  navy
} from '../../../_shared/layout-constants';
import {
  ReduxInputField,
  ReduxSelectField
} from '../../../_shared/components/FormElements';
import { PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY } from '../createListingPage/_constants';
import './Location.css';

const AusStates = require('../../../_shared/AusStates.json');

const LocationAutocomplete = styled.div`
  grid-column: 1 / 4;
  grid-row: 4;
  margin-bottom: 60px;
`;

const ManualInput = styled.div`
  grid-column: 1 / 4;
  grid-row: 5;
  padding-top: 48px;
`;

const AutoCompleteInputField = styled.input`
  height: 48px;
  border-radius: 3px;
  border: ${defaultBorder};
  padding: 11px 16px;
  color: ${greyDark};
  margin-right: 12px;
  width: 100%;
  &:focus {
    border-color: ${navy};
  }
  @media (min-width: 768px) {
    width: 528px;
  }
`;

class Location extends Component {
  componentDidMount() {
    this.initAutocomplete();
    this.updateField = debounce(e => {
      const obj = {};
      obj[e.target.name] = e.target.value;
      this.updateLocalData(obj);
    }, 300);

    this.updateFieldRaw = debounce(e => {
      const obj = {};
      obj[e.key] = e.value;
      this.updateLocalData(obj);
    }, 300);
  }

  updateLocalData(obj) {
    if (this.props.draftId === null) {
      this.props.localData.addItem(PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY, obj);
    }
  }

  initAutocomplete = () => {
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      { types: ['address'] }
    );
    this.autocomplete.setComponentRestrictions({ country: 'au' });
    this.autocomplete.addListener('place_changed', this.fillInAddress);
  };

  fillInAddress = () => {
    // Get the place details from the autocomplete object.
    const componentForm = [
      'street_number',
      'route',
      'locality',
      'country',
      'postal_code',
      'administrative_area_level_1'
    ];
    const place = this.autocomplete.getPlace();
    const addressComponents = place.address_components;
    addressComponents.forEach(addressComponent => {
      const addressType = addressComponent.types[0];
      if (componentForm.includes(addressType)) {
        const val = addressComponent;
        switch (addressType) {
          case 'street_number':
            this.props.change('streetNumber', val['short_name']);
            this.updateLocalData({ streetNumber: val['short_name'] });
            break;
          case 'route':
            const fullAddress = val['long_name'];
            const streetType = fullAddress.split(' ').pop();
            this.props.change('streetType', streetType);
            this.updateLocalData({ streetType });
            const streetName = fullAddress
              .split(' ')
              .slice(0, -1)
              .join(' ');
            this.props.change('streetName', streetName);
            this.updateLocalData({ streetName });
            break;
          case 'locality':
            this.props.change('suburb', val['long_name']);
            this.updateLocalData({ suburb: val['long_name'] });
            break;
          case 'administrative_area_level_1':
            const state = {
              value: val['short_name'],
              label: val['long_name']
            };
            this.props.change('state', state);
            this.updateLocalData({ state });
            break;
          // country is only AU for now
          // case 'country':
          //   this.setState({
          //     country: val['long_name'],
          //   });
          //   break;
          case 'postal_code':
            this.props.change('postcode', val['short_name']);
            this.updateLocalData({ postcode: val['short_name'] });
            break;
          default:
            return;
        }
      }
    });
  };

  render() {
    return (
      <div>
        {/* <p style={{ color: 'red' }}>{invalid ? 'false' : 'true'}</p> */}
        <LocationAutocomplete>
          <AutoCompleteInputField
            id="autocomplete"
            placeholder="Enter your address"
            onFocus={this.geolocate}
            onChange={this.handleInputChange}
            ref="autoCompletePlaces"
          />
        </LocationAutocomplete>
        <hr className="create-listing-location-break-line" />

        <ManualInput>
          <Row justifyContent="flex-start">
            <Col xs={12} md={5}>
              <Field
                name="parkingLotNumber"
                label="Lot Number"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
            <Col xs={12} md={5}>
              <Field
                name="streetNumber"
                label="Street number"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
          </Row>

          <Row justifyContent="flex-start">
            <Col xs={12} md={5}>
              <Field
                name="streetName"
                label="Street name"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
            <Col xs={12} md={5}>
              <Field
                name="suburb"
                label="Suburb"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
          </Row>

          <Row justifyContent="flex-start">
            <Col xs={12} md={5}>
              <Field
                name="streetType"
                label="Street type"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
            <Col xs={12} md={5}>
              <Field
                name="postcode"
                label="Postcode"
                component={ReduxInputField}
                onChange={e => {
                  this.updateField(e);
                }}
              />
            </Col>
            <Col md={1} />
          </Row>

          <Row justifyContent="flex-start">
            <Col xs={12} md={5}>
              <Field
                name="state"
                label="State"
                component={ReduxSelectField}
                options={AusStates}
                onChange={e => {
                  this.updateFieldRaw({ key: 'state', value: e });
                }}
              />
            </Col>
            <Col md={1} />
            <Col xs={12} md={5}>
              <Field
                name="country"
                label="Country"
                component={ReduxInputField}
                disabled
              />
            </Col>
            <Col md={1} />
          </Row>
        </ManualInput>
      </div>
    );
  }
}

export default Location;
