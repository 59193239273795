import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import {
  RubikSemiBold,
  teal,
  greyDark,
  midBreakPoint,
  gutter
} from '../../../../../_shared/layout-constants';
import { InvoiceModal } from '../../../../../common/modal';
import { GET_BOOKING_INVOICE_HISTORY } from '../../../../../common/graphql/bookingSchema';
import { ImgixUrl } from '../../../../../_shared/utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: repeat(4, 48px) auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 162px auto 102px;
    grid-template-rows: 20px 24px 28px 20px 20px 24px 20px;
    padding: ${gutter(1)};
  }
`;

const Title = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 145px;
  height: 20px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
`;

const StatusImg = styled.img`
  height: 28px;
  width: 28px;
`;

const Status = styled.div`
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 3;
    grid-column: 1 / 4;
  }
`;

const StatusInfo = styled.span`
  color: ${greyDark};
  padding-left: 12px;
`;

const NextPayment = styled.div`
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-row: 5;
    grid-column: 1 / 4;
  }
`;

const NextPaymentTitle = styled.span`
  color: ${greyDark};
`;

const NextPaymentDate = styled.span`
  font-weight: ${RubikSemiBold};
  margin-left: 6px;
`;

const EditPayment = styled.a`
  color: ${teal};
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 7;
  }
`;

const ViewInvoices = styled.a`
  color: ${teal};
  grid-row: 5;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 3;
    grid-row: 7;
  }
`;

const PaymentDetails = props => {
  const {
    startTime,
    getBookingInvoiceHistory,
    bookingId,
    isMonthlyBooking,
    bookingType,
    paymentStatus,
    nextPaymentDate,
    tz,
    isManagedBooking
  } = props;

  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);

  return (
    <Wrapper>
      <InvoiceModal
        invoices={
          getBookingInvoiceHistory &&
          getBookingInvoiceHistory.getBookingInvoiceHistory &&
          getBookingInvoiceHistory.getBookingInvoiceHistory.invoices
        }
        invoiceNum={
          getBookingInvoiceHistory &&
          getBookingInvoiceHistory.getBookingInvoiceHistory &&
          getBookingInvoiceHistory.getBookingInvoiceHistory.total
        }
      />

      <Title>Payment</Title>

      <Status>
        <span>
          <StatusImg
            src={`${ImgixUrl}/web-image-assets/icons/request-sent.svg`}
            alt="payment made previously status"
          />
        </span>
        <StatusInfo>
          Booking start from{' '}
          {startTime &&
            tz &&
            moment(startTime)
              .tz(tz)
              .format('Do MMM YYYY')}
          {paymentStatus && <span> (payment status: {paymentStatus})</span>}
        </StatusInfo>
      </Status>

      {isMonthlyBooking && nextPaymentDate && tz && (
        <NextPayment>
          <NextPaymentTitle>Next payment date:</NextPaymentTitle>
          <NextPaymentDate>
            {nextPaymentDate &&
              moment(nextPaymentDate)
                .tz(tz)
                .format('Do MMM YYYY')}
          </NextPaymentDate>
        </NextPayment>
      )}

      <EditPayment href="/admin/billings">
        {isManagedBooking ? 'Edit payment option' : 'Edit payment method'}
      </EditPayment>

      {bookingId &&
        (bookingType === 'active-booking' ||
          bookingType === 'historic-booking') && (
          <ViewInvoices onClick={() => handleShowModal('InvoiceModal')}>
            View invoices
          </ViewInvoices>
        )}
    </Wrapper>
  );
};

export default compose(
  graphql(GET_BOOKING_INVOICE_HISTORY, {
    name: 'getBookingInvoiceHistory',
    handleError: false,
    options: props => ({
      variables: {
        bookingTransactionId: props.bookingId,
        pageFrom: 1,
        pageSize: 100
      }
    })
  })
)(PaymentDetails);
