import styled from 'styled-components';
import {
  greyLight,
  RubikSemiBold,
  midBreakPoint
} from '../../_shared/layout-constants';

export const AdminSectionWrapper = styled.div`
  margin: 24px 0 0 0;
  @media (min-width: ${midBreakPoint}px) {
    border: solid 1px ${greyLight};
    padding: 24px;
  }
`;

export const AdminSubtitle = styled.div`
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
  font-size: 16px;
  margin: 24px 0;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 20px;
    margin: 24px 0;
  }
`;

export const WrapperSubtitle = styled.div`
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  line-height: 1.25;
  margin-bottom: 16px;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 16px;
  }
`;
