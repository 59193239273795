import React, { Component } from 'react';
import styled from 'styled-components';
import {
  defaultBorder,
  midBreakPoint,
  lightBoxShadow
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const ListerInfoWrapper = styled.div`
  background-color: #fff;
  border: ${defaultBorder};
  border-radius: 28px;
  box-shadow: ${lightBoxShadow};
  position: absolute;
  z-index: 0;
  width: 152px;
  height: 40px;
  bottom: -20px;
  @media (min-width: ${midBreakPoint}px) {
    bottom: -28px;
    width: 188px;
    height: 56px;
    left: -56px;
  }
`;

const Photo = styled.img`
  background-size: 100%;
  border-radius: 100%;
  float: left;
  object-fit: cover;
  width: 37px;
  height: 37px;
  @media (min-width: ${midBreakPoint}px) {
    width: 54px;
    height: 54px;
  }
`;

const ContentWrapper = styled.div`
  margin: 0;
  overflow: hidden;
  padding-left: 7px;
  @media (min-width: ${midBreakPoint}px) {
    padding: 5px 0 0 12px;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 400;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${midBreakPoint}px) {
    text-align: left;
    line-height: 24px;
    font-size: 20px;
  }
`;

const Status = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  @media (min-width: ${midBreakPoint}px) {
    font-size: 14px;
  }
`;

export default class ListerInfo extends Component {
  render() {
    const { userId, firstname, lastname } = this.props;

    return (
      <ListerInfoWrapper>
        <Photo
          src={`${ImgixUrl}/${userId}/profile/profile.png`}
          onError={e => {
            e.target.onerror = null;
            e.target.src = `${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder-grey.svg`;
          }}
          alt="lister profile photo"
        />
        <ContentWrapper>
          <Name>{firstname + ' ' + lastname}</Name>
          {firstname === 'Quokka' ? (
            <Status>Official</Status>
          ) : (
            <Status>Verified member</Status>
          )}
        </ContentWrapper>
      </ListerInfoWrapper>
    );
  }
}
