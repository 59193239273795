import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../common/context/modal/store';
import { TextArea } from '../../../../_shared/components/FormElements';
import { SecondaryButton } from '../../../../_shared/components/Buttons';
import Message from './components/message/Message';
import Modal from '../../../../_shared/components/ModalWrapper';

const ChatHistory = styled.div`
  overflow: hidden;
`;

const ReplyMessage = styled.div`
  width: 100%;
`;

const Button = styled(SecondaryButton)`
  align-self: flex-end;
  width: 165px;
  height: 48px;
`;

const ChatRoom = props => {
  const [replyMessage, setReplyMessage] = useState('');
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);

  const onReplyMessageChange = e => {
    setReplyMessage(e.target.value);
  };
  const isOpen = activeModal.includes('Login');
  const { person } = props;

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title={`Conversation with ${person}`}
      handleClose={() => handleHideModal('ChatRoom')}
      contentLabel="Invoice Modal"
      isOpen={isOpen}
    >
      <ChatHistory>
        <Message
          direction="left"
          person={person}
          message="Great, can I book a time to inspect the parking space? Would love to have a look before I can rent it for long."
        />
        <Message
          direction="right"
          person={person}
          message="The carkpark is just located behind the Crown Casino, only 5  min walk to trams"
        />
        <Message
          direction="left"
          person={person}
          message="Hi Jane, I’d like to book a time with you to get the garage key. Can you tell me what suits you best next week?"
        />
        <Message
          direction="right"
          person={person}
          message="Of course. Feel free to come any time!"
        />
      </ChatHistory>

      <ReplyMessage>
        <TextArea
          value={replyMessage}
          onChange={onReplyMessageChange}
          withBoxShadow={true}
        />
      </ReplyMessage>
      <Button>Send</Button>
    </Modal>
  );
};

export default ChatRoom;
