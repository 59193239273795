import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ModifyContactModal } from '../../../../../common/modal';
import {
  defaultBorder,
  midBreakPoint,
  spacing
} from '../../../../../_shared/layout-constants';
import './ContactSection.css';

const Wrapper = styled.div`
  width: 100%;
  min-height: 102px;
  display: grid;
  grid-gap: 0;
  padding: ${spacing(4)} 0;
  grid-template-rows: repeat(3, 44px) 1fr;
  grid-template-columns: 1fr;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr 1fr 51px;
    grid-template-rows: 44px;
    padding: ${spacing(6)} ${spacing(4)};
    border: ${defaultBorder};
  }
`;

const Person = styled.div`
  grid-row: 1;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 2;
    grid-row: 1;
  }
`;

const ContactNumber = styled.div`
  grid-row: 2;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2 / 3;
    grid-row: 1;
  }
`;

const Email = styled.div`
  grid-row: 3;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1 / 3;
    grid-row: 2;
  }
`;

const Modify = styled.div`
  cursor: pointer;
  grid-row: 4;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 4 / 5;
    grid-row: 1;
  }
`;

const ContactSection = props => {
  const { actions } = useContext(ModalStoreContext);
  const { contactName, contactNumber, email } = props.values;

  const { changeContact, invalid } = props;

  return (
    <Wrapper>
      <ModifyContactModal
        changeContact={changeContact}
        invalid={invalid}
        values={props.values}
      />

      <Person>
        <span className="admin-section-subtitle">Contact person</span>
        <span className="admin-content-font">{contactName}</span>
      </Person>

      <ContactNumber>
        <span className="admin-section-subtitle">Contact number</span>
        <span className="admin-content-font">{contactNumber}</span>
      </ContactNumber>

      <Email>
        <span className="admin-section-subtitle">Email</span>
        <span className="admin-content-font">{email}</span>
      </Email>

      <Modify>
        <div
          className="admin-modify-button"
          onClick={() => actions.handleShowModal('ModifyContact')}
        >
          Modify
        </div>
      </Modify>
    </Wrapper>
  );
};

export default ContactSection;
