import { GoogleMapApiKey } from './utils';

// Primary : graphite, yellow, navy

// Secondary
export const teal = '#0290be';
export const aqua = '#01bac7';
// export const pinkRed = '#ff0467';
export const red = '#cf0c0c';

// Yellow
export const yellowLight = '#FFE577'; // background
export const yellow = '#ffd730'; // main
export const yellowDark = '#f1c200'; // premium

// Navy
export const navyLight = '#5A7BB2';
export const navy = '#144392';
export const navyDark = '#103574'; // feature

// Grey
export const greySuperLight = '#F5F5F6';
export const greyExtraLight = '#eaebec';
export const greyLight = '#d6d9da';
export const grey = '#adb2b6';
export const greyDark = '#70787e';
export const transparentGrey = 'rgba(40,50,57,0.6)';
export const graphite = '#333F48';

// Box shadow
export const defaultBoxShadow = '0 2px 10px 0 rgba(157, 157, 157, 0.24)';
export const darkBoxShadow = '0 2px 10px 0 rgba(157, 157, 157, 0.5)';
export const lightBoxShadow = '0 2px 4px 0 rgba(157, 157, 157, 0.5)';

//
export const defaultBorder = `1px solid ${greyLight}`;
export const darkBorder = `1px solid ${graphite}`;
// Fonts
export const RubikNormal = 400;
export const RubikSemiBold = 500;
export const RubikBold = 700;

export const midBreakPoint = 1127;

export const bodyLineHeight = 1.3;
export const bodyFontSize = 16;

export const RubikFontFamily = "'Rubik', sans-serif";
export const defaultFontWeight = RubikNormal;

export const spacing = t => {
  switch (t) {
    case 1:
      return '4px';
    case 2:
      return '8px';
    case 3:
      return '16px';
    case 4:
      return '24px';
    case 5:
      return '32px';
    case 6:
      return '40px';
    case 7:
      return '48px';
    case 8:
      return '56px';
    default:
      return '4px';
  }
};

export const gutter = t => {
  switch (t) {
    case 1:
      return '24px';
    case 2:
      return '32px';
    case 3:
      return '48px';
    case 4:
      return '64px';
    case 5:
      return '96px';
    case 6:
      return '128px';
    default:
      return '24px';
  }
};

// google map constants
export const GMKEY = GoogleMapApiKey;
const DEFAULT_LAT = -37.8118469;
const DEFAULT_LNG = 144.9606698;
export const DEFAULT_CENTER = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
export const DEFAULT_ZOOM = 15;
export const MAP_STYLE = {
  streetViewControl: true,
  styles: [
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ]
};
