export const CREATE_LISTING_STEPS = [
  {
    step: 0,
    section: 'Location',
    pageTitle: "Where's your space?",
    pageProperties: [
      'streetNumber',
      'streetType',
      'streetName',
      'suburb',
      'state',
      'postcode',
      'country'
    ]
  },
  {
    step: 1,
    section: 'Features',
    pageTitle: "What's great about your space?",
    pageProperties: [
      'parkingTypes',
      'accessTypes',
      'maxHeight',
      'amenities',
      'suitableVehicles'
    ]
  },
  {
    step: 2,
    section: 'Details',
    pageTitle: 'Help your renters make the best choice!',
    pageProperties: [
      'listingTitle',
      'description',
      'accessInstruction',
      'deliveryInstruction'
    ]
  },
  {
    step: 3,
    section: 'Contact',
    pageTitle: 'Who should the seeker be contacting?',
    pageProperties: ['contactName', 'contactNumber', 'email', 'contactType']
  },
  {
    step: 4,
    section: 'Availability',
    pageTitle: 'When will your space be available?',
    pageProperties: ['availableDateFrom', 'daysAvailable']
  },
  {
    step: 5,
    section: 'Pricing',
    pageTitle: 'How much would you like to charge?',
    pageProperties: ['daily', 'weekly', 'monthly', 'deposit']
  },
  {
    step: 6,
    section: 'Review',
    pageTitle: "Nearly done! Let's make sure everything's right."
  }
];

export const PARKING_DRAFT_KEY = 'parking-draft';
export const PARKING_DRAFT_LOCAL_DATA_NEWITEM_KEY = '__NEW__';
