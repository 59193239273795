import React, { Component } from 'react';
import styled from 'styled-components';
import { ImgixUrl } from '../../../_shared/utils';

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

export default class ParkingIcon extends Component {
  render() {
    return (
      <Icon
        src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
        alt="parking sign in blue"
      />
    );
  }
}
