import React, { useContext } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexa';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { getFormValues, isInvalid, getFormSyncErrors } from 'redux-form';
import Notifications from '../../../_shared/components/Notification';
import { ModalStoreContext } from '../../../common/context/modal/store';
import { GET_USER_INFO } from '../../../common/graphql/adminSchema';
import { UpdateContactModal } from '../../../common/modal';
import {
  teal,
  RubikSemiBold,
  greyDark,
  defaultFontWeight,
  midBreakPoint,
  grey
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const EditLink = styled.a`
  font-size: 20px;
  text-align: right;
  color: ${teal};
  padding: 29px 5px 24px 0;
  cursor: pointer;
`;

const ContactPersonName = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  font-weight: ${RubikSemiBold};
  letter-spacing: -0.2px;
  margin: 24px 0 24px 0;
`;

const ContactNumber = styled.span`
  width: 130px;
  font-size: 20px;
  line-height: 20px;
  font-weight: ${defaultFontWeight};
  color: ${greyDark};
  margin: 0 8px;
  @media (min-width: ${midBreakPoint}px) {
    margin: 0 85px 0 8px;
  }
`;

const SummaryNote = styled.span`
  font-size: 14px;
  line-height: 1.33;
  color: ${grey};
  margin: 2px 0 0 5px;
`;

const Contact = props => {
  const { actions } = useContext(ModalStoreContext);

  const { composeContactFormValues, invalid, formSyncErrors, change } = props;
  const { contactName, contactNumber, contactType } = props.values;
  const invalidContact =
    formSyncErrors.contactName ||
    formSyncErrors.contactType ||
    formSyncErrors.email ||
    formSyncErrors.contactNumber;

  return (
    <React.Fragment>
      {invalidContact && (
        <Notifications
          type="alert1"
          text={'Please fill in the contact infomation!'}
          style={{ marginTop: 0, marginBottom: 20 }}
          hasClose={true}
        />
      )}
      <UpdateContactModal
        invalid={invalid}
        change={change}
        composeContactFormValues={composeContactFormValues}
        values={props.values}
        draftId={props.draftId}
        localData={props.localData}
      />
      <div>
        <span className="create-listing-subtitle">Contact details</span>
        <Row flexDirection="row">
          <Col xs={12} lg={12}>
            <ContactPersonName>
              {contactType}: {contactName}
              <ContactNumber>{contactNumber}</ContactNumber>
              <EditLink
                onClick={() => actions.handleShowModal('UpdateContact')}
              >
                Edit
              </EditLink>
            </ContactPersonName>
          </Col>
          <Col xs={12} lg={12}>
            <div>
              <img
                src={`${ImgixUrl}/web-image-assets/icons/Info.svg`}
                alt="info icon"
              />
              <SummaryNote>
                This is the person who will arrange access to this parking
                space.
              </SummaryNote>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default compose(
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  connect(state => ({
    formSyncErrors: getFormSyncErrors('createListingForm')(state),
    values: getFormValues('createListingForm')(state),
    invalid: isInvalid('createListingForm')(state)
  }))
)(Contact);
