const initialState = {
  activeModal: [],
  msg: null,
  interestedAddress: ''
};

const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  INTERESTED_ADDRESS: 'INTERESTED_ADDRESS'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        activeModal: [action.modal],
        msg: action.msg,
        interestedAddress: action.interestedAddress
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        activeModal: state.activeModal.filter(e => e !== action.modal),
        msg: null,
        interestedAddress: ''
      };
    case types.INTERESTED_ADDRESS:
      return {
        ...state,
        interestedAddress: action.interestedAddress
      };
    default:
      return state;
  }
};

export { initialState, types, reducer };
