import {
  GET_REQUESTED_BOOKINGS,
  GET_CONFIRMED_BOOKINGS,
  GET_COMPLETED_BOOKINGS,
  GET_PENDING_BOOKINGS,
  GET_BOOKING_PENDINGS,
  GET_BOOKING_REQUESTS,
  GET_BOOKING_CONFIRMATIONS,
  GET_BOOKING_COMPLETIONS
} from '../../common/graphql/bookingSchema';

export const BOOKING_STATUS = {
  requests: {
    lister: {
      title: 'Bookings requests of renters',
      query: GET_BOOKING_REQUESTS,
      name: ['getBookingRequests']
    },
    renter: {
      title: 'Bookings requests of listers',
      query: GET_REQUESTED_BOOKINGS,
      name: ['getRequestedBookings']
    }
  },
  confirmations: {
    renter: {
      title: 'Confirmed bookings of renters',
      query: GET_BOOKING_CONFIRMATIONS,
      name: ['getBookingConfirmations']
    },
    lister: {
      title: 'Confirmed bookings of listers',
      query: GET_CONFIRMED_BOOKINGS,
      name: ['getConfirmedBookings']
    }
  },
  pending: {
    renter: {
      title: 'Pending bookings of renters',
      query: GET_BOOKING_PENDINGS,
      name: [
        'getBookingPendingCompletions',
        'getBookingRequestToCancellations',
        'getBookingUnderInvestigations'
      ]
    },
    lister: {
      title: 'Pending bookings of listers',
      query: GET_PENDING_BOOKINGS,
      name: [
        'getPendingCompletedBookings',
        'getRequestToCancelBookings',
        'getUnderInvestigationBookings'
      ]
    }
  },
  history: {
    renter: {
      title: 'History bookings of renters',
      query: GET_BOOKING_COMPLETIONS,
      name: ['getBookingCancellations', 'getBookingCompletions']
    },
    lister: {
      title: 'History bookings of listers',
      query: GET_COMPLETED_BOOKINGS,
      name: ['getCancelledBookings', 'getCompletedBookings']
    }
  }
};
