import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/react-hoc';
import { reduxForm, Field } from 'redux-form';
import { ModalStoreContext } from '../../../common/context/modal/store';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
import { UPDATE_USER_INFORMATION } from '../../../common/graphql/adminSchema';
import Modal from '../../../_shared/components/ModalWrapper';
import { ReduxInputField } from '../../../_shared/components/FormElements';
import { InputField } from '../../../_shared/components/FormElements';
import './index.css';

const Firstname = styled.div``;

const Lastname = styled.div``;

const Email = styled.div``;

const ContactNumber = styled.div``;

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Please enter the contact first name';
  }
  if (!values.lastName) {
    errors.lastName = 'Please enter the contact last name';
  }
  if (!values.email) {
    errors.email = 'Please enter the contact email';
  }
  return errors;
};

const AddContactModal = props => {
  const { state, actions } = useContext(ModalStoreContext);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const addContact = async value => {
    await props
      .updateUserInformation({
        variables: {
          firstName: value.firstname,
          lastName: value.lastname
        },
        update: (_, { data: { updateUserInformation } }) => {
          actions.handleHideModal('AddContact');

          // update user info in booking process step 3
          if (
            props.updateUserInfo &&
            updateUserInformation &&
            updateUserInformation.user
          ) {
            props.updateUserInfo(updateUserInformation.user);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const onMobileNumberChange = (status, value, countryData, number, id) => {
    //console.log('number', number);
    setPhoneNumber(number.replace(/\s/g, ''));
  };
  const isOpen = state.activeModal.includes('AddContact');
  const {
    //redux-form props
    invalid,
    handleSubmit
  } = props;
  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Add a new contact"
      handleSubmit={handleSubmit(addContact)}
      handleClose={() => actions.handleHideModal('AddContact')}
      contentLabel="Add Contact Modal"
      buttonLabel="Save"
      isOpen={isOpen}
      buttonDisabled={invalid || !phoneNumber}
    >
      <Firstname>
        <Field
          name="firstname"
          label="First name"
          component={ReduxInputField}
        />
      </Firstname>

      <Lastname>
        <Field name="lastname" label="Last name" component={ReduxInputField} />
      </Lastname>

      <Email>
        <Field name="email" label="Email" component={ReduxInputField} />
      </Email>

      <ContactNumber>
        <InputField
          label="Mobile number"
          hideInputField={true}
          style={{ marginBottom: 0 }}
        />
        <IntlTelInput
          defaultCountry={'au'}
          css={['intl-tel-input', 'form-control']}
          utilsScript={'libphonenumber.js'}
          placeholder=""
          value={phoneNumber ? phoneNumber : ''}
          onPhoneNumberChange={onMobileNumberChange}
        />
      </ContactNumber>
    </Modal>
  );
};

const formOptions = {
  form: 'AddContactModal',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(UPDATE_USER_INFORMATION, { name: 'updateUserInformation' })
)(AddContactModal);
