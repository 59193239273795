import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { decamelize } from 'humps';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { UserContext } from '../../../common/context/user';
import * as Components from './_styles';
import { VerifyListingModal } from '../../../common/modal';
import { RoundPrimaryButton } from '../../../_shared/components/Buttons';
import { trackBookingStep } from '../../../_shared/tracker';
import { ShareModal, Gallery } from '../../../common/components';
import { StepOne, StepTwo, StepThree } from '../../../bookingProcess';
import CarparkMap from '../../../_shared/components/CarparkMap';
import {
  BreadcrumbNav,
  ParkingType,
  Title,
  Address,
  Access,
  MaxHeight,
  Description,
  // Surrounding,
  Amenity,
  SuitableVehicle,
  Button,
  ListerInfo
} from '../../../carpark';
import { ImgixUrl, imageExists } from '../../../_shared/utils';
import { yellowDark } from '../../../_shared/layout-constants';

const Label = styled.span`
  background-color: ${yellowDark};
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  padding: 1px 8px;
  font-style: italic;
  font-weight: 500;
  z-index: 1;
  width: 74px;
  height: 21px;
  position: absolute;
  float: left;
`;

const hasContact = p => {
  return p && p !== null;
};

const PageBody = ({
  parkingId,
  title,
  status,
  description,
  maxHeight,
  priceOptions,
  accessTypes,
  parkingTypes,
  amenities,
  address,
  owner,
  filters,
  suitableVehicleTypes,
  favouritesSet,
  availableDateFrom,
  bookingOccupiedDates,
  mobileFullScreenDisplay,
  currentPriceUnit,
  refetch,
  state,
  isFavourite,
  capitalizeWords,
  removeFavourite,
  addFavourite,
  toggleHandler,
  toggleBookingStep,
  handleShowModal,
  onDatesSelection,
  handleVehicleChange,
  handleContactChange,
  handlePaymentChange,
  isAuthenticated,
  toggleMonthlyOngoing,
  setPreview
}) => {
  const [isVehicleInfoOpen, toggleVehicleInfo] = useState(false);
  const { user } = useContext(UserContext);
  const validPhotos = [
    `${ImgixUrl}/${parkingId}/image0.png`,
    `${ImgixUrl}/${parkingId}/image1.png`
    //`${ImgixUrl}/${parkingId}/image2.png`
  ].filter(src => imageExists(src));
  const price = JSON.parse(priceOptions).find(
    s => s.type.short === currentPriceUnit.short
  );

  return (
    <Components.PageContent>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={`${get(address, 'value')}, ${description.substr(0, 180)}`}
        />
      </Helmet>
      <ShareModal />
      <Components.CarparkDetailsWrapper
        mobileFullScreenDisplay={mobileFullScreenDisplay}
        // innerRef={node => {
        //   this.layoutNode = node;
        // }}
        bookingStepOpened={state.showBookingStep}
      >
        <Components.GalleryWrapper>
          {status && status === 'I' && <Label>Booked</Label>}
          <Gallery
            lat={get(address, 'coordinate.lat')}
            lon={get(address, 'coordinate.lon')}
            page="PDP"
            totalSlides={validPhotos.length + 1}
            showMap={true}
            items={validPhotos.map((src, i) => (
              <Components.GalleryItem
                src={src}
                imgixParams={{
                  ar: '16:9',
                  auto: ['format', 'compress', 'enhance'],
                  mark: `${ImgixUrl}/watermark.png`,
                  markalign: 'left, bottom',
                  markpad: 15,
                  markscale: 30
                }}
              />
            ))}
          />
          <Components.MobileButtonWrapper>
            <Button
              iconPath="Share-button-white.svg"
              iconName="Share"
              withText={false}
              onClick={() => handleShowModal('ShareModal')}
            />
            {isFavourite(favouritesSet) && isAuthenticated ? (
              <Button
                iconPath="Star-filled.svg"
                withText={false}
                onClick={() => removeFavourite(refetch)}
              />
            ) : (
              <Button
                iconPath="Save-button-white.svg"
                iconName="Save"
                withText={false}
                onClick={() =>
                  isAuthenticated
                    ? addFavourite(refetch)
                    : handleShowModal('Login')
                }
              />
            )}
          </Components.MobileButtonWrapper>
        </Components.GalleryWrapper>

        <Components.CarparkInfo>
          <Components.ListerInfoWrapper>
            <ListerInfo
              firstname={owner && `${owner.firstName}`}
              lastname={owner && `${owner.lastName}`}
              userId={owner && owner.id}
            />
          </Components.ListerInfoWrapper>
          <BreadcrumbNav
            state={address.state}
            postalCode={address.postalCode}
            locality={address.locality}
          />
          <ParkingType
            type={
              parkingTypes.length !== 0
                ? decamelize(parkingTypes[0].name, {
                    separator: ' '
                  }).toUpperCase()
                : 'N/A'
            }
          />
          <Title title={title} />
          <Address
            value={
              address.value && address.value.replace('australia', 'Australia')
            }
          />

          <Components.AccessMaxHeightWrapper>
            <Access
              access={
                accessTypes.length !== 0
                  ? capitalizeWords(
                      decamelize(accessTypes[0].name, {
                        separator: ' '
                      })
                    )
                  : 'N/A'
              }
            />
            <Components.VerticleLine />
            <MaxHeight maxHeight={maxHeight} />
          </Components.AccessMaxHeightWrapper>

          <Description content={description} />
        </Components.CarparkInfo>

        <Components.ButtonWrapper>
          <Button
            iconPath="Share-button.svg"
            iconName="Share"
            onClick={() => handleShowModal('ShareModal')}
          />

          {isFavourite(favouritesSet) && isAuthenticated ? (
            <Button
              iconPath="Star-filled.svg"
              onClick={() => removeFavourite(refetch)}
            />
          ) : (
            <Button
              iconPath="Save-button.svg"
              iconName="Save"
              onClick={() =>
                isAuthenticated
                  ? addFavourite(refetch)
                  : handleShowModal('Login')
              }
            />
          )}
        </Components.ButtonWrapper>

        <Components.MapWrapper>
          <CarparkMap address={address.value} coordinate={address.coordinate} />
        </Components.MapWrapper>

        {/* <Components.SurroundingWrapper>
          <Surrounding noIcon={false} />
          <Components.Hr />
        </Components.SurroundingWrapper> */}

        <Components.VehicleTypesWrapper>
          <SuitableVehicle
            vehicles={suitableVehicleTypes}
            showVehicleInfo={isVehicleInfoOpen}
            toggleVehicleInfo={toggleVehicleInfo}
          />
        </Components.VehicleTypesWrapper>

        <Components.AmenitiesWrapper>
          <Components.Hr />
          <Amenity amenities={amenities} />
        </Components.AmenitiesWrapper>
      </Components.CarparkDetailsWrapper>

      {!isVehicleInfoOpen && (
        <Components.ResponsiveWrapper
          show={state.showBookingStep}
          isStepOne={state.step === 1}
          // ref={node => {
          //   this.layoutStyledNode = node;
          // }}
          // innerRef={node => {
          //   this.Components.responsiveWrapperNode = node;
          // }}
        >
          {state.step === 1 && (
            <StepOne
              parkingId={parkingId}
              toggleHandler={toggleHandler}
              priceOptions={JSON.parse(priceOptions)}
              chosenPrice={state.priceUnit}
              status={status}
              filters={filters}
              availableDateFrom={availableDateFrom}
              bookingOccupiedDates={bookingOccupiedDates}
              tz={address.tz}
              toggleBookingStep={toggleBookingStep}
              showBookingStep={state.showBookingStep}
              duration={state.duration}
              toggleMonthlyOngoing={toggleMonthlyOngoing}
              onDatesSelection={onDatesSelection}
              isAuthenticated={isAuthenticated}
              handleShowModal={handleShowModal}
              setPreview={setPreview}
              preview={state.preview}
              address={address.value}
              lister={owner}
            />
          )}
          {state.step === 2 && (
            <StepTwo
              toggleHandler={toggleHandler}
              selectedVehicleOption={state.vehicles.selectedOption}
              selectedContactOption={state.contacts.selectedOption}
              handleVehicleChange={handleVehicleChange}
              handleContactChange={handleContactChange}
              handleShowModal={handleShowModal}
            />
          )}
          {state.step === 3 && (
            <StepThree
              selectedPaymentOption={state.payment.selectedOption}
              handlePaymentChange={handlePaymentChange}
              handleShowModal={handleShowModal}
              toggleHandler={toggleHandler}
              parkingId={parkingId}
              tz={address.tz}
              duration={state.duration}
              vehicles={state.vehicles}
              contacts={state.contacts}
              payment={state.payment}
              priceOptions={JSON.parse(priceOptions)}
              preview={state.preview}
            />
          )}
        </Components.ResponsiveWrapper>
      )}
      {state.step === 1 && !state.showBookingStep && !isVehicleInfoOpen && (
        <Components.StickyMobileFooter>
          <Components.PriceNote>
            <Components.Price>
              {price.unit}
              {Math.round(price.value * 1.15 * 100) / 100}
            </Components.Price>{' '}
            per {currentPriceUnit && currentPriceUnit.unit}
          </Components.PriceNote>
          <Components.SideNote>
            Available for monthly ongoing
            {/* Also available {' '}
            {currentPriceUnit && currentPriceUnit.otherOptions[0]} and{' '}
            {currentPriceUnit && currentPriceUnit.otherOptions[1]} */}
          </Components.SideNote>
          <RoundPrimaryButton
            onClick={
              isAuthenticated
                ? hasContact(user && user.phoneNumber)
                  ? () => {
                      toggleBookingStep(true);
                      trackBookingStep(1, 2, parkingId);
                    }
                  : () => handleShowModal('VerifyListingModal')
                : () => handleShowModal('Signup')
            }
          >
            Request to book
          </RoundPrimaryButton>
          <VerifyListingModal />
        </Components.StickyMobileFooter>
      )}
    </Components.PageContent>
  );
};

export default PageBody;
