import React, { useState, useContext } from 'react';
import { graphql } from '@apollo/react-hoc';
import compose from 'lodash/flowRight';
import { ModalStoreContext } from '../../../common/context/modal/store';
import Modal from '../../../_shared/components/ModalWrapper';
import { reduxForm, Field } from 'redux-form';
import { CHANGE_PASSWORD } from '../../../common/graphql/authSchema';
import { ReduxInputField } from '../../../_shared/components/FormElements';

const validate = values => {
  const errors = {};
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;

  if (!values.currentPassword) {
    errors.currentPassword = 'Please enter your current password';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Please enter your password';
  } else if (values.newPassword.length < 8) {
    errors.newPassword =
      'Password length needs to be greater than 8. Please try again.';
  } else if (
    !values.newPassword.match(numbers) ||
    !values.newPassword.match(lowerCaseLetters) ||
    !values.newPassword.match(upperCaseLetters)
  ) {
    errors.newPassword =
      'Password needs to include more than 1 uppercase alphabetic letters and 1 number. Please try again.';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Please re-enter your password';
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword =
      "The re-entered password doesn't match your new password. Please try again.";
  }

  return errors;
};

const ChangePasswordModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    state: { activeModal },
    actions: { handleHideModal }
  } = useContext(ModalStoreContext);
  const isOpen = activeModal.includes('SettingsChangePassword');
  const { handleSubmit, invalid, toggleShowSuccessBanner } = props;

  const resetPassword = async value => {
    setLoading(true);

    //console.log(value);
    const { errors } = await props.changePassword({
      errorPolicy: 'all',
      variables: {
        oldPassword: value.currentPassword,
        newPassword: value.newPassword,
        confirmPassword: value.confirmPassword
      }
    });

    if (errors) {
      setLoading(false);
      //console.log(errors);
      setError(errors[0].message);
      return;
    }

    setLoading(false);
    setError(null);
    handleHideModal('SettingsChangePassword');
    //console.log(changePassword);
    toggleShowSuccessBanner();
  };

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <Modal
      title="Change password"
      handleClose={() => handleHideModal('SettingsChangePassword')}
      contentLabel="Change Password Modal"
      buttonLabel="Update password"
      handleSubmit={handleSubmit(resetPassword)}
      buttonDisabled={invalid}
      isOpen={isOpen}
      loading={loading}
      notification={error}
    >
      <Field
        name="currentPassword"
        label="Current password"
        type="password"
        component={ReduxInputField}
      />
      <Field
        name="newPassword"
        label="New password"
        type="password"
        component={ReduxInputField}
      />
      <Field
        name="confirmPassword"
        label="Confirm password"
        type="password"
        component={ReduxInputField}
      />
    </Modal>
  );
};

const formOptions = {
  form: 'ChangePasswordModal',
  validate
};

export default compose(
  reduxForm(formOptions),
  graphql(CHANGE_PASSWORD, { name: 'changePassword' })
)(ChangePasswordModal);
