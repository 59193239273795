import React, { useContext } from 'react';
import { ModalStoreContext } from './context/modal/store';
import ErrorBoundary from './errorBoundary';
import { Route, Redirect } from 'react-router-dom';
import { getQueryString } from '../_shared/utils';

const PrivateRoute = props => {
  const {
    actions: { handleShowModal }
  } = useContext(ModalStoreContext);

  const isAuthenticated = localStorage.getItem('authenticate') === 'true';
  const { component: Component, path, ...args } = props;

  // if(props.path ="/reset-password") {
  //   handleShowModal("Login");
  //   return <Redirect to="/search" />;
  // }
  if (path === '/reset-password') {
    const token = getQueryString('token');
    if (token) {
      handleShowModal('ResetPassword');
      return <Redirect to={`/search?token=${token}`} />;
    }
  }

  if (!isAuthenticated) {
    handleShowModal('Login');
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...args}
      render={props => (
        <ErrorBoundary>
          <Component path={path} {...props} />
        </ErrorBoundary>
      )}
    />
  );
};

export default PrivateRoute;
