export const MATCHING_AMENITIES = {
  'Indoor lot': [
    '24/7 access',
    'CCTV',
    'Underground',
    'Sheltered parking',
    'Lighting',
    'Security patrols',
    'Disabled access',
    'Security gates',
    'Arranged transfers'
  ],
  Undercover: [
    '24/7 access',
    'CCTV',
    'Underground',
    'Sheltered parking',
    'Lighting',
    'Disabled access',
    'Security gates',
    'Arranged transfers'
  ],
  Driveway: ['24/7 access', 'Lighting'],
  Outside: ['24/7 access', 'Lighting'],
  'Outdoor lot': ['24/7 access', 'CCTV', 'Lighting'],
  'Street parking': ['24/7 access', 'Lighting'],
  Carport: [
    '24/7 access',
    'Lighting',
    'Sheltered parking',
    'Arranged transfers'
  ],
  'Lock up garage': [
    '24/7 access',
    'Lighting',
    'Sheltered parking',
    'Arranged transfers'
  ]
};
