import React from 'react';
import styled from 'styled-components';
import LiveStatus from './components/liveStatus/LiveStatus';
import OffStatus from './components/offStatus/OffStatus';
import DraftStatus from './components/draftStatus/DraftStatus';
import {
  RubikSemiBold,
  yellowLight,
  defaultBoxShadow,
  defaultBorder,
  midBreakPoint
} from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const TicketContainer = styled.a`
  min-height: 360px;
  min-width: 275px;
  width: 275px;
  height: 432px;
  background-color: #fff;
  box-shadow: ${defaultBoxShadow};
  border: ${defaultBorder};
  display: grid;
  grid-gap: 0;
  overflow: hidden;
  grid-template-columns: 12px 74px 144px 32px 12px;
  grid-template-rows: 36px 160px 38px minmax(53px, auto) minmax(60px, auto) 48px;
  padding: 0 0 40px 0;
  &:hover {
    text-decoration: none;
  }
  margin-right: 24px;
  @media (min-width: ${midBreakPoint}px) {
    margin-right: 0;
  }
`;

const Status = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin-top: 12px;
  position: relative;
  z-index: 0;
`;

const Favourite = styled.img`
  grid-column: 4;
  grid-row: 1;
  margin-top: 12px;
  position: relative;
  z-index: 0;
`;

const Image = styled.img`
  grid-column: 1 / 6;
  grid-row: 1 / 3;
  width: 274px;
  height: 196px;
  object-fit: cover;
`;

const ParkingType = styled.div`
  grid-column: 1 / 6;
  grid-row: 3;
  width: 100%;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  text-align: left;
  padding: 12px 12px 20px 12px;
`;

const Title = styled.div`
  grid-column: 1 / 6;
  grid-row: 4;
  width: 100%;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
  padding: 0 12px 14px 12px;
`;

const Address = styled.div`
  grid-column: 1 / 6;
  grid-row: 5;
  width: 100%;
  font-size: 14px;
  text-align: left;
  padding: 12px 12px 12px 12px;
`;

const ParkingIcon = styled.img`
  grid-column: 2;
  grid-row: 6;
  height: 48px;
  width: 48px;
`;

const ParkingLot = styled.div`
  grid-column: 3 / 6;
  grid-row: 6;
  width: 100%;
  height: 17px;
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  padding: 17px 12px 14px 0;
`;

const BookThisSpace = styled.div`
  grid-column: 3 / 6;
  grid-row: 6;
  width: 100%;
  height: 36px;
`;

const BookingButton = styled.button`
  width: 164px;
  height: 36px;
  margin-top: 12px;
  border-radius: 3px;
  border: 0;
  background-color: ${yellowLight};
  text-align: center;
`;

const dashedAddress = (address, key) => {
  if (!address) {
    return null;
  }
  const splittedAddress = address.split(', ').reverse();
  const dashedAddress = splittedAddress[key]
    .split(' ')
    .join('-')
    .toLowerCase();
  return dashedAddress;
};

const directToPDP = (address, parkingId) => {
  let state = dashedAddress(address, 0);
  let city = dashedAddress(address, 1);
  let urlAddress = state && city ? `-${city}-${state}` : '';
  let url = `${window.location.origin}/carpark/parking-space${urlAddress}/${parkingId}`;
  return url;
};

const directToAdminParkingDetails = (status, id) => {
  return '/admin/my-listings/listing-detail/' + status + '/' + id;
};

export default ({
  id,
  title,
  address,
  parkingLot,
  parkingType,
  status,
  favourite,
  redirectToPDP
}) => {
  return (
    <TicketContainer
      href={
        redirectToPDP
          ? directToPDP(address, id)
          : directToAdminParkingDetails(status, id)
      }
    >
      {favourite ? (
        <Favourite
          src={`${ImgixUrl}/web-image-assets/icons/Fav-star.svg`}
          alt="favourite booking"
        />
      ) : (
        <Status>
          {status === 'live' ? (
            <LiveStatus />
          ) : status === 'off' ? (
            <OffStatus />
          ) : (
            <DraftStatus />
          )}
        </Status>
      )}

      <Image
        src={`${ImgixUrl}/${id}/image0.png`}
        onError={e => {
          e.target.onerror = null;
          e.target.src = `${ImgixUrl}/carparkPlaceholder.png`;
        }}
        alt="carpark-photo"
      />

      <ParkingType>
        {parkingType
          ? parkingType.name
            ? parkingType.name
            : parkingType
          : ' '}
      </ParkingType>

      <Title>{title}</Title>

      <Address>{address}</Address>

      <ParkingIcon
        src={`${ImgixUrl}/web-image-assets/icons/QPS-Navy-Transparent.svg`}
        alt="parking sign in blue"
      />

      {favourite ? (
        <BookThisSpace>
          <BookingButton type="submit">Book this space</BookingButton>
        </BookThisSpace>
      ) : (
        <ParkingLot>Parking lot {parkingLot ? parkingLot : null}</ParkingLot>
      )}
    </TicketContainer>
  );
};
