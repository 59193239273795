import React, { Component } from 'react';
import styled from 'styled-components';
import { createSkeletonElement } from '@trainline/react-skeletor';
import { RubikSemiBold, navy } from '../../../_shared/layout-constants';

const Type = createSkeletonElement(styled.div`
  font-size: 14px;
  font-weight: ${RubikSemiBold};
  line-height: 17px;
  text-align: left;
  color: ${navy};
`);

export default class ParkingType extends Component {
  render() {
    const { type } = this.props;
    return <Type>{type}</Type>;
  }
}
