import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalStoreContext } from '../../../../../common/context/modal/store';
import { ContactListerModal } from '../../../../../common/modal';
import {
  RubikSemiBold,
  teal,
  midBreakPoint
} from '../../../../../_shared/layout-constants';
import { ImgixUrl } from '../../../../../_shared/utils';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows: 48px auto 48px auto;
  @media (min-width: ${midBreakPoint}px) {
    grid-template-columns: 1fr auto;
    grid-template-rows: 20px 24px 56px 5px 20px 11px 20px;
    padding: 24px;
  }
`;

const Title = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 145px;
  height: 20px;
  font-size: 20px;
  font-weight: ${RubikSemiBold};
  line-height: 20px;
  letter-spacing: -0.2px;
`;

const ProfilePic = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  background-size: 100%;
  border-radius: 100%;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 3;
  }
`;

const Name = styled.div`
  font-weight: ${RubikSemiBold};
  margin-left: 6px;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 1;
    grid-row: 5;
  }
`;

const Contact = styled.a`
  width: 100%;
  color: ${teal};
  cursor: pointer;
  display: grid;
  @media (min-width: ${midBreakPoint}px) {
    grid-column: 2;
    grid-row: 7;
  }
`;

const ListerDetails = props => {
  const { actions } = useContext(ModalStoreContext);
  if (!props.listingOwner) return null;
  const {
    listingOwner: { firstName, lastName, id, email }
  } = props;
  return (
    <Wrapper>
      <Title>Listing contact</Title>

      <ProfilePic
        src={`${ImgixUrl}/${id}/profile/profile.png`}
        onError={e => {
          e.target.onerror = null;
          e.target.src = `${ImgixUrl}/web-image-assets/icons/Quokka-profile-placeholder-grey.svg`;
        }}
        alt="lister profile photo"
      />

      <Name>
        {firstName} {lastName}
      </Name>

      <Contact onClick={() => actions.handleShowModal('ContactListerModal')}>
        Contact {firstName}
      </Contact>
      <ContactListerModal email={email} person={firstName} />
    </Wrapper>
  );
};

export default ListerDetails;
