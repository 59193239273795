import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import PlacesAutocomplete from 'react-places-autocomplete';
import autoFindStyles from '../searchBar/searchBarStyle';
import autoFindStylesWithoutSearchIcon from '../searchBar/searchBarStyleWithoutIcon';
import * as moment from 'moment';
import { ImgixUrl } from '../../../_shared/utils';
import './index.css';

const formName = 'Search';

const formOptions = {
  form: formName
};

const addrAutocompleteOptions = {
  componentRestrictions: {
    country: 'au'
  }
};

const SearchIcon = styled.img`
  line-height: 50px;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  position: absolute;
  z-index: 1;
  margin-left: 24px;
`;

const Wrapper = styled.form`
  display: flex;
  align-items: center;
`;

class SearchBar extends React.Component {
  state = {
    tempSearchLocation: ''
  };

  recordSearchLocation = location => {
    this.setState({ tempSearchLocation: location });
  };

  updateSearchLocation = () => {
    const { tempSearchLocation } = this.state;
    //console.log(tempSearchLocation);
    this.setState(
      {
        searchLocation: tempSearchLocation
      },
      () => {
        const modifiedSearchLocation = this.state.searchLocation
          .replace(/\s+/g, '-')
          .replace(/,/g, '')
          .toLowerCase();

        //console.log(modifiedSearchLocation);

        window.location.href =
          '/find-parking-space' +
          '/address-' +
          modifiedSearchLocation +
          '/search?startDate=' +
          moment().format('DD-MM-YYYY') +
          '&endDate=' +
          moment()
            .add(1, 'months')
            .format('DD-MM-YYYY') +
          '&priceUnit={"value":"m","label":"Monthly"}' +
          '&priceRange={"min":0,"max":1500}';
      }
    );
  };

  handleSubmit = () => {
    if (this.props.onSearchLocationChange) {
      this.props.updateSearchLocation();
    } else {
      // if no props passed in, trigger the default update (redirect to specified url)
      this.updateSearchLocation();
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleSubmit();
    }
  };

  render() {
    const {
      address,
      onSearchLocationChange,
      height,
      placeholder,
      autocompletionOptions,
      displaySearchBar,
      showIcon = true,
      ...otherprops
    } = this.props;

    const { tempSearchLocation } = this.state;

    const inputProps = placeholder => {
      return {
        className: 'search-bar',
        value: address || tempSearchLocation,
        onChange: location =>
          onSearchLocationChange
            ? onSearchLocationChange(location)
            : this.recordSearchLocation(location),
        placeholder: placeholder
      };
    };

    return (
      <Wrapper
        onSubmit={this.handleSubmit}
        onKeyDown={e => {
          this.handleKeyDown(e);
        }}
        {...otherprops}
      >
        {showIcon && (
          <SearchIcon
            src={`${ImgixUrl}/web-image-assets/icons/Search.svg`}
            alt="search icon"
            height={height}
          />
        )}
        {displaySearchBar ? (
          <Field
            name="home-search-bar"
            inputProps={inputProps(placeholder)}
            component={PlacesAutocomplete}
            styles={showIcon ? autoFindStyles : autoFindStylesWithoutSearchIcon}
            options={
              autocompletionOptions
                ? autocompletionOptions
                : addrAutocompleteOptions
            }
          />
        ) : null}
      </Wrapper>
    );
  }
}

export default reduxForm(formOptions)(SearchBar);
