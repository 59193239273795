import React, { Component } from 'react';
import styled from 'styled-components';
import { midBreakPoint } from '../../../_shared/layout-constants';
import { ImgixUrl } from '../../../_shared/utils';

const ButtonWrapper = styled.button`
  display: inline-block;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

const ButtonIcon = styled.img`
  width: 22px;
  @media (min-width: ${midBreakPoint}px) {
    width: 32px;
  }
`;

const ButtonText = styled.div`
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  width: fit-content;
`;

export default class Button extends Component {
  render() {
    const { iconPath, iconName, withText = true, ...otherProps } = this.props;

    return (
      <ButtonWrapper {...otherProps}>
        <ButtonIcon
          src={`${ImgixUrl}/web-image-assets/icons/${iconPath}`}
          alt="share or save button"
        />
        {withText && <ButtonText>{iconName}</ButtonText>}
      </ButtonWrapper>
    );
  }
}
