import React, { Component } from 'react';
import Subtitle from '../../../components/subtitle/Subtitle';
import { InfoTable } from '../../../components/tableComponents/InfoTable';
import { InfoCell } from '../../../components/tableComponents/InfoCell';
import { Divider } from '../../../components/tableComponents/Divider';
import { Info } from '../../../components/tableComponents/Info';
import { Description } from '../../../components/tableComponents/Description';
import { Link } from '../../../components/tableComponents/Link';
import { Icon } from '../../../components/tableComponents/Icon';
import { ImgixUrl } from '../../../../_shared/utils';

export default class ListingOverview extends Component {
  render() {
    return (
      <div>
        <Subtitle title="Listing performance" />

        <InfoTable padding={24}>
          <InfoCell>
            <Info fontSize="36">2</Info>
            <Description>Your active listing(s)</Description>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="36">$ 920</Info>
            <Description>Earned in last 2 months</Description>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="36">
              <span>124</span>
              <span>
                <Icon
                  size={28}
                  src={`${ImgixUrl}/web-image-assets/icons/up-trend.svg`}
                  alt="views up trend"
                />
              </span>
            </Info>
            <Description>Total views</Description>
          </InfoCell>
          <Divider />
          <InfoCell>
            <Info fontSize="24" extraMargin={7}>
              3 Jun 2018
            </Info>
            <Description>Receive next payment</Description>
            <Link href="/admin/my-listings" margin={70}>
              View listings
            </Link>
          </InfoCell>
        </InfoTable>
      </div>
    );
  }
}
