import React, { Component } from 'react';
import styled from 'styled-components';
import { ImgixUrl } from '../../../_shared/utils';
import { midBreakPoint } from '../../../_shared/layout-constants';
import { QuokkaTooltip } from '../../../_shared/components/QuokkaTooltip';
import './Access.css';

const AccessWrapper = styled.div`
  padding: 15px 0;
  @media (min-width: ${midBreakPoint}px) {
    padding: 24px 0 26px 0;
    position relative;
  }
`;

const InfoIcon = styled.img`
  width: 14px;
  margin-left: 8px;
  margin-bottom: 2px;
  cursor: pointer;
`;

const MoreInfo = styled(QuokkaTooltip)`
  display: none;

  ${AccessWrapper}:hover & {
    display: block;
    width: 300px;
    height: 100px;
    position: absolute;
    right: -320px;
    top: -8px;
  }
`;

const displayBasedonAccess = access => {
  switch (access) {
    case 'Key':
      return 'Access type key helps you enter the garage gate or door.';
    case 'Passcode':
      return 'You can enter passcode to open the gate and enter garage or parking space.';
    case 'Remote Controller':
      return 'You can use the remote controller property owner provides to open the boom gate or garage.';
    case 'Permit':
      return 'If parking permit is required, during parking, you must present the permit provided by property owner.';
    case 'Security Patrols':
      return 'Security patrols are regularly guarding the area, property owner shall explain to them before you park.';
    case 'Swipe Card':
      return 'An ID card that helps you enter the parking area.';
    case 'Ticket':
      return 'The parking area uses ticketing system, you shall present the parking ticket while parking.';
    case 'Boom Gate':
      return 'Enter the boom gate with instruction provided by your property owner.';
    case 'Pay Onsite':
      return 'Prepare cash or credit card as you will be expected to pay onsite.';
    case 'None':
      return 'There is no access that you need for the parking area you are looking to book.';
    default:
      return;
  }
};

export default class Access extends Component {
  render() {
    const { access } = this.props;

    //console.log(access);
    //access && console.log(displayBasedonAccess(access));

    return (
      <AccessWrapper>
        <span>Access type: {access}</span>
        <InfoIcon
          src={`${ImgixUrl}/web-image-assets/icons/info.png`}
          alt="info"
        />
        <MoreInfo brief={access && displayBasedonAccess(access)} />
      </AccessWrapper>
    );
  }
}
